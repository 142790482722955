import React from 'react';
import { formatDate, formatDecimal } from '@utils';
import { Image, Text, View, Row, Column, Icon } from '@components-teammove';

import { ContainerCampaign, ContentName, ContentProgress, ContentImage, ContentUserRanking, Name, TableItens, Prize, PositionUserRanking, PositionUserRankingHighlighted, Expired, BoldTableText, RegularTableText } from './styles';
import Progression from './Progression';
import BadgePercentageGoal from './BadgePercentageGoal';

export default function CampanhaItens({ campaigns, loading, onClick, kind }) {
  const columns = [
    {
      title: 'Campanha',
      dataIndex: 'name',
      width: '40%',
      sorter: (a, b) => a.campaign.name.localeCompare(b.campaign.name),
      render: (text, { campaign, userRanking, usersRankingsTotals }) => (
        <ContainerCampaign>
          <ContentName>
            <Name>{campaign.name}</Name>
            <RegularTableText>{formatDate(campaign.startAt)} à {formatDate(campaign.endAt)}</RegularTableText>
            {kind === 'expired' && <Expired>Encerrada</Expired>}
          </ContentName>
          {campaign.relatedCampaigns && <ContentProgress>
            <Progression total={campaign.relatedCampaigns.length} achieved={userRanking?.relatedCampaignsGoalProjectionAchieved || usersRankingsTotals?.totalRelatedCampaignsGoalProjectionAchieved} achievedGoal={userRanking?.relatedCampaignsGoalProjectionAchievedGoal || usersRankingsTotals?.totalRelatedCampaignsGoalProjectionAchievedGoal} />
          </ContentProgress>}
        </ContainerCampaign>
      ),
    },
    {
      title: 'Objetivo',
      dataIndex: 'goal',
      width: '10%',
      align: 'start',
      sorter: (a, b) => (a.userRanking?.goal || a.usersRankingsTotals?.totalGoal) - (b.userRanking?.goal || b.usersRankingsTotals?.totalGoal),
      render: (text, { campaign, userRanking, usersRankingsTotals }) => {
        return(
          <>
            {userRanking || usersRankingsTotals ? 
              <BoldTableText>{formatDecimal(userRanking?.goal || usersRankingsTotals?.totalGoal, 2).replace('NaN', '0')}</BoldTableText> :
              <RegularTableText>-</RegularTableText>
            }
          </>
        );}
    },
    {
      title: 'Realizado',
      dataIndex: 'done',
      width: '10%',
      align: 'start',
      sorter: (a, b) => (a.userRanking?.percentageGoal || a.usersRankingsTotals?.totalPercentageGoal) - (b.userRanking?.percentageGoal || b.usersRankingsTotals?.totalPercentageGoal),
      render: (text, { campaign, userRanking, usersRankingsTotals }) => (
        <>
          {userRanking || usersRankingsTotals ? 
            <Column>
              <RegularTableText>{formatDecimal(userRanking?.percentageGoal || usersRankingsTotals?.totalPercentageGoal, 2).replace('NaN', '0')}%</RegularTableText>
              <BoldTableText>{formatDecimal(userRanking?.value || usersRankingsTotals?.totalValue, 2).replace('NaN', '0')}</BoldTableText>
              <RegularTableText fontSize={'12px'}>
                {(userRanking ? userRanking.goal > userRanking.value : usersRankingsTotals?.totalGoal > usersRankingsTotals?.totalValue) ? 'Falta' : 'Sobra'}: {formatDecimal(Math.abs(userRanking ? (userRanking.goal - userRanking.value) : (usersRankingsTotals?.totalGoal - usersRankingsTotals?.totalValue))).replace('NaN', '0')}
              </RegularTableText>
            </Column> :
            <RegularTableText>-</RegularTableText>}
        </>
      )
    },
    {
      title: 'Projetado',
      dataIndex: 'projected',
      width: '10%',
      align: 'start',
      sorter: (a, b) => (a.userRanking?.valueProjectionPercentageGoal || a.usersRankingsTotals?.totalValueProjectionPercentageGoal) - (b.userRanking?.valueProjectionPercentageGoal || b.usersRankingsTotals?.totalValueProjectionPercentageGoal),
      render: (text, { campaign, userRanking, usersRankingsTotals }) => (
        <>
          {campaign.usesProjection && (userRanking || usersRankingsTotals) ? 
            <Column>
              <BoldTableText>{formatDecimal(userRanking?.valueProjection || usersRankingsTotals?.totalValueProjection, 2).replace('NaN', '0')}</BoldTableText>
              <Row gap='4px' justify='flex-start'>
                <RegularTableText>{formatDecimal(userRanking?.valueProjectionPercentageGoal || usersRankingsTotals?.totalValueProjectionPercentageGoal, 2).replace('NaN', '0')}%</RegularTableText>
                <BadgePercentageGoal percent={userRanking?.valueProjectionPercentageGoal || usersRankingsTotals?.totalValueProjectionPercentageGoal} />
              </Row>
            </Column> :
            <RegularTableText>-</RegularTableText>}
        </>
      )
    },
    {
      title: 'Posição',
      dataIndex: 'position',
      align: 'center',
      sorter: (a, b) => (a.userRanking?.valueProjectionPosition) - (b.userRanking?.valueProjectionPosition),
      render: (text, { campaign, userRanking }) => (
        <Row justify='space-between' align='center' gap='40px'>
          {userRanking ? 
            <View>
              <ContentUserRanking>
                <Icon.Feather type='award'/>
                {((campaign?.relatedCampaigns?.length > 0 && userRanking.relatedCampaignsGoalProjectionPrize) || (!campaign?.relatedCampaigns && userRanking.valueProjectionPrize)) ? <PositionUserRankingHighlighted>{campaign?.relatedCampaigns?.length > 0 ? userRanking.relatedCampaignsGoalProjectionPosition : userRanking.valueProjectionPosition}°</PositionUserRankingHighlighted> : <PositionUserRanking>{campaign?.relatedCampaigns?.length > 0 ? userRanking.relatedCampaignsGoalProjectionPosition : userRanking.valueProjectionPosition}°</PositionUserRanking>}
              </ContentUserRanking>
              {((campaign?.relatedCampaigns?.length > 0 && userRanking.relatedCampaignsGoalProjectionPrize) || (!campaign?.relatedCampaigns && userRanking.valueProjectionPrize)) && <Prize>{campaign?.relatedCampaigns?.length > 0 ? userRanking.relatedCampaignsGoalProjectionPrize : userRanking.valueProjectionPrize}</Prize>}
            </View> : 
            <Text>-</Text>}
          <ContentImage>
            <Image src={campaign.image} alt="Imagem da Campanha" />
          </ContentImage>
        </Row>
      ),
    },
  ].filter(Boolean);

  return (
    <TableItens 
      loading={loading}
      rowKey='campaign'
      queries={{ 0: '(max-width: 599px)', 1: '(min-width: 600px) and (max-width: 740px)', 2: '(min-width: 741px) and (max-width: 1070px)', 3: '(min-width: 1071px)' }}
      columns={columns}
      dataSource={campaigns}
      hidePagination
      kind={kind}
      onRow={({ campaign }) => {
        return {
          onClick: () => {
            onClick(campaign);
          },
        };
      }}
    />
  );
}