import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import { Notification } from '@utils';
import { 
  getChecklistTemplates as getChecklistTemplatesSdk,
  getChecklistTemplate as getChecklistTemplateSdk,
  putChecklistTemplate as putChecklistTemplateSdk,
  delChecklistTemplate as delChecklistTemplateSdk,
  getChecklistTemplateConfiguration as getChecklistTemplateConfigurationSdk,
  putChecklistTemplateConfiguration as putChecklistTemplateConfigurationSdk,
  getStrategicActionTemplate as getStrategicActionTemplateSdk,
  putStrategicActionTemplate as putStrategicActionTemplateSdk,
} from '@sdk/ChecklistTemplates';

export const FILTERS_KEY = 'FILTROS/CHECKLIST_TEMPLATES';

//Action Types
export const Types = {
  GET_CHECKLIST_TEMPLATES: 'CHECKLIST_TEMPLATES/GET_CHECKLIST_TEMPLATES',
  GET_CHECKLIST_TEMPLATES_SUCCESS: 'CHECKLIST_TEMPLATES/GET_CHECKLIST_TEMPLATES_SUCCESS',
  GET_CHECKLIST_TEMPLATES_ERROR: 'CHECKLIST_TEMPLATES/GET_CHECKLIST_TEMPLATES_ERROR',

  GET_CHECKLIST_TEMPLATE: 'CHECKLIST_TEMPLATES/GET_CHECKLIST_TEMPLATE',
  GET_CHECKLIST_TEMPLATE_SUCCESS: 'CHECKLIST_TEMPLATES/GET_CHECKLIST_TEMPLATE_SUCCESS',
  GET_CHECKLIST_TEMPLATE_ERROR: 'CHECKLIST_TEMPLATES/GET_CHECKLIST_TEMPLATE_ERROR',

  GET_CHECKLIST_TEMPLATE_CONFIGURATION: 'CHECKLIST_TEMPLATES/GET_CHECKLIST_TEMPLATE_CONFIGURATION',
  GET_CHECKLIST_TEMPLATE_CONFIGURATION_SUCCESS: 'CHECKLIST_TEMPLATES/GET_CHECKLIST_TEMPLATE_CONFIGURATION_SUCCESS',
  GET_CHECKLIST_TEMPLATE_CONFIGURATION_ERROR: 'CHECKLIST_TEMPLATES/GET_CHECKLIST_TEMPLATE_CONFIGURATION_ERROR',

  GET_STRATEGIC_ACTION_TEMPLATE: 'CHECKLIST_TEMPLATES/GET_STRATEGIC_ACTION_TEMPLATE',
  GET_STRATEGIC_ACTION_TEMPLATE_SUCCESS: 'CHECKLIST_TEMPLATES/GET_STRATEGIC_ACTION_TEMPLATE_SUCCESS',
  GET_STRATEGIC_ACTION_TEMPLATE_ERROR: 'CHECKLIST_TEMPLATES/GET_STRATEGIC_ACTION_TEMPLATE_ERROR',

  PUT_CHECKLIST_TEMPLATE_CONFIGURATION: 'CHECKLIST_TEMPLATES/PUT_CHECKLIST_TEMPLATE_CONFIGURATION',
  PUT_CHECKLIST_TEMPLATE_CONFIGURATION_SUCCESS: 'CHECKLIST_TEMPLATES/PUT_CHECKLIST_TEMPLATE_CONFIGURATION_SUCCESS',
  PUT_CHECKLIST_TEMPLATE_CONFIGURATION_ERROR: 'CHECKLIST_TEMPLATES/PUT_CHECKLIST_TEMPLATE_CONFIGURATION_ERROR',

  PUT_STRATEGIC_ACTION_TEMPLATE: 'CHECKLIST_TEMPLATES/PUT_STRATEGIC_ACTION_TEMPLATE',
  PUT_STRATEGIC_ACTION_TEMPLATE_SUCCESS: 'CHECKLIST_TEMPLATES/PUT_STRATEGIC_ACTION_TEMPLATE_SUCCESS',
  PUT_STRATEGIC_ACTION_TEMPLATE_ERROR: 'CHECKLIST_TEMPLATES/PUT_STRATEGIC_ACTION_TEMPLATE_ERROR',

  PUT_CHECKLIST_TEMPLATE: 'CHECKLIST_TEMPLATES/PUT_CHECKLIST_TEMPLATE',
  PUT_CHECKLIST_TEMPLATE_SUCCESS: 'CHECKLIST_TEMPLATES/PUT_CHECKLIST_TEMPLATE_SUCCESS',
  PUT_CHECKLIST_TEMPLATE_ERROR: 'CHECKLIST_TEMPLATES/PUT_CHECKLIST_TEMPLATE_ERROR',

  DEL_CHECKLIST_TEMPLATE: 'CHECKLIST_TEMPLATES/DEL_CHECKLIST_TEMPLATE',
  DEL_CHECKLIST_TEMPLATE_SUCCESS: 'CHECKLIST_TEMPLATES/DEL_CHECKLIST_TEMPLATE_SUCCESS',
  DEL_CHECKLIST_TEMPLATE_ERROR: 'CHECKLIST_TEMPLATES/DEL_CHECKLIST_TEMPLATE_ERROR',

  RESET: 'CHECKLIST_TEMPLATES/RESET',
  RESET_CONFIGURATION: 'CHECKLIST_TEMPLATES/RESET_CONFIGURATION',
  RESET_STRATEGIC_ACTION: 'CHECKLIST_TEMPLATES/RESET_STRATEGIC_ACTION',
};

//Action Creators
export const getChecklistTemplates = (params) => ({ type: Types.GET_CHECKLIST_TEMPLATES, params });
export const getChecklistTemplatesSuccess = (response) => ({ type: Types.GET_CHECKLIST_TEMPLATES_SUCCESS, response });
export const getChecklistTemplatesError = (error) => ({ type: Types.GET_CHECKLIST_TEMPLATES_ERROR, error }); 

export const getChecklistTemplate = (id) => ({ type: Types.GET_CHECKLIST_TEMPLATE, id });
export const getChecklistTemplateSuccess = (response) => ({ type: Types.GET_CHECKLIST_TEMPLATE_SUCCESS, response });
export const getChecklistTemplateError = (error) => ({ type: Types.GET_CHECKLIST_TEMPLATE_ERROR, error });

export const getChecklistTemplateConfiguration = () => ({ type: Types.GET_CHECKLIST_TEMPLATE_CONFIGURATION });
export const getChecklistTemplateConfigurationSuccess = (response) => ({ type: Types.GET_CHECKLIST_TEMPLATE_CONFIGURATION_SUCCESS, response });
export const getChecklistTemplateConfigurationError = (error) => ({ type: Types.GET_CHECKLIST_TEMPLATE_CONFIGURATION_ERROR, error });

export const getStrategicActionTemplate = (id) => ({ type: Types.GET_STRATEGIC_ACTION_TEMPLATE, id });
export const getStrategicActionTemplateSuccess = (response) => ({ type: Types.GET_STRATEGIC_ACTION_TEMPLATE_SUCCESS, response });
export const getStrategicActionTemplateError = (error) => ({ type: Types.GET_STRATEGIC_ACTION_TEMPLATE_ERROR, error });

export const putChecklistTemplateConfiguration = (params) => ({ type: Types.PUT_CHECKLIST_TEMPLATE_CONFIGURATION, params });
export const putChecklistTemplateConfigurationSuccess = (response) => ({ type: Types.PUT_CHECKLIST_TEMPLATE_CONFIGURATION_SUCCESS, response });
export const putChecklistTemplateConfigurationError = (error) => ({ type: Types.PUT_CHECKLIST_TEMPLATE_CONFIGURATION_ERROR, error });

export const putChecklistTemplate = (params) => ({ type: Types.PUT_CHECKLIST_TEMPLATE, params });
export const putChecklistTemplateSuccess = (response) => ({ type: Types.PUT_CHECKLIST_TEMPLATE_SUCCESS, response });
export const putChecklistTemplateError = (error) => ({ type: Types.PUT_CHECKLIST_TEMPLATE_ERROR, error });

export const putStrategicActionTemplate = (params) => ({ type: Types.PUT_STRATEGIC_ACTION_TEMPLATE, params });
export const putStrategicActionTemplateSuccess = (response) => ({ type: Types.PUT_STRATEGIC_ACTION_TEMPLATE_SUCCESS, response });
export const putStrategicActionTemplateError = (error) => ({ type: Types.PUT_STRATEGIC_ACTION_TEMPLATE_ERROR, error });

export const delChecklistTemplate = (id) => ({ type: Types.DEL_CHECKLIST_TEMPLATE, id });
export const delChecklistTemplateSuccess = (response) => ({ type: Types.DEL_CHECKLIST_TEMPLATE_SUCCESS, response });
export const delChecklistTemplateError = (error) => ({ type: Types.DEL_CHECKLIST_TEMPLATE_ERROR, error });

export const reset = () => ({ type: Types.RESET });
export const resetConfiguration = () => ({ type: Types.RESET_CONFIGURATION });
export const resetStrategicAction = () => ({ type: Types.RESET_STRATEGIC_ACTION });

//saga
function* fetchGetChecklistTemplates(action) {
  try {
    const { params } = action;
    const checklistTemplates = yield call(getChecklistTemplatesSdk, params);
    yield put(getChecklistTemplatesSuccess(checklistTemplates));
  } catch (err) {
    Notification.error(err.message);
    yield put(getChecklistTemplatesError(err));
  }
}

function* fetchGetChecklistTemplate(action) {
  try {
    const { id } = action;
    const checklistTemplate = yield call(getChecklistTemplateSdk, id);
    yield put(getChecklistTemplateSuccess(checklistTemplate));
  } catch (err) {
    Notification.error(err.message);
    yield put(getChecklistTemplateError(err));
  }
}

function* fetchGetChecklistTemplateConfiguration(action) {
  try {
    const checklistTemplateConfiguration = yield call(getChecklistTemplateConfigurationSdk);
    yield put(getChecklistTemplateConfigurationSuccess(checklistTemplateConfiguration));
  } catch (err) {
    Notification.error(err.message);
    yield put(getChecklistTemplateConfigurationError(err));
  }
}

function* featchGetStrategicActionTemplate(action) {
  try {
    const { id } = action;
    const strategicActionTemplate = yield call(getStrategicActionTemplateSdk, id);
    yield put(getStrategicActionTemplateSuccess(strategicActionTemplate));
  } catch (err) {
    Notification.error(err.message);
    yield put(getStrategicActionTemplateError(err));
  }
}

function* fetchPutChecklistTemplateConfiguration(action) {
  try {
    const { params } = action;
    const checklistTemplateConfiguration = yield call(putChecklistTemplateConfigurationSdk, params);
    yield put(putChecklistTemplateConfigurationSuccess(checklistTemplateConfiguration));
    Notification.success('Configuração salva com sucesso!');
  } catch (err) {
    Notification.error(err.message);
    yield put(putChecklistTemplateConfigurationError(err));
  }
}

function* fetchPutChecklistTemplate(action) {
  try {
    const { params } = action;
    const checklistTemplate = yield call(putChecklistTemplateSdk, params);
    yield put(putChecklistTemplateSuccess(checklistTemplate));
  } catch (err) {
    Notification.error(err.message);
    yield put(putChecklistTemplateError(err));
  }
}

function* fetchPutStrategicActionTemplate(action) {
  try {
    const { params } = action;
    const strategicActionTemplate = yield call(putStrategicActionTemplateSdk, params);
    yield put(putStrategicActionTemplateSuccess(strategicActionTemplate));
  } catch (err) {
    Notification.error(err.message);
    yield put(putStrategicActionTemplateError(err));
  }
}

function* fetchDelChecklistTemplate(action) {
  try {
    const { id } = action;
    const checklistTemplate = yield call(delChecklistTemplateSdk, id);
    yield put(delChecklistTemplateSuccess(checklistTemplate));
    Notification.success('Checklist Template excluido com sucesso!');
  } catch (err) {
    Notification.error(err.message);
    yield put(delChecklistTemplateError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_CHECKLIST_TEMPLATES, fetchGetChecklistTemplates),
  takeLatest(Types.GET_CHECKLIST_TEMPLATE, fetchGetChecklistTemplate),
  takeLatest(Types.GET_CHECKLIST_TEMPLATE_CONFIGURATION, fetchGetChecklistTemplateConfiguration),
  takeLatest(Types.GET_STRATEGIC_ACTION_TEMPLATE, featchGetStrategicActionTemplate),
  takeLatest(Types.PUT_CHECKLIST_TEMPLATE_CONFIGURATION, fetchPutChecklistTemplateConfiguration),
  takeLatest(Types.PUT_CHECKLIST_TEMPLATE, fetchPutChecklistTemplate),
  takeLatest(Types.PUT_STRATEGIC_ACTION_TEMPLATE, fetchPutStrategicActionTemplate),
  takeLatest(Types.DEL_CHECKLIST_TEMPLATE, fetchDelChecklistTemplate),
];

// Reducer
const initialState = Map({
  checklistTemplates: List(),
  total: 0,
  loading: false,
  success: false,
  error: false,

  checklistTemplate: Map(),
  loadingChecklistTemplate: false,
  successChecklistTemplate: false,
  errorChecklistTemplate: false,

  checklistTemplateConfiguration: List(),
  loadingChecklistTemplateConfiguration: false,
  successChecklistTemplateConfiguration: false,
  errorChecklistTemplateConfiguration: false,

  strategicActionTemplate: Map(),
  loadingStrategicActionTemplate: false,
  successStrategicActionTemplate: false,
  errorStrategicActionTemplate: false,

  loadingSaveChecklistTemplateConfiguration: false,
  successSaveChecklistTemplateConfiguration: false,
  errorSaveChecklistTemplateConfiguration: false,

  loadingSaveChecklistTemplate: false,
  successSaveChecklistTemplate: false,
  errorSaveChecklistTemplate: false,

  loadingSaveStrategicActionTemplate: false,
  successSaveStrategicActionTemplate: false,
  errorSaveStrategicActionTemplate: false,

  loadingDelChecklistTemplate: false,
  successDelChecklistTemplate: false,
  errorDelChecklistTemplate: false,
});

const handleGetChecklistTemplates = (state, action) => {
  return state.set('loading', true).set('success', false).set('error', false);
};

const handleGetChecklistTemplatesSuccess = (state, action) => {
  const { response } = action;

  return state.set('checklistTemplates', response.templates).set('total', response.total)
    .set('loading', false).set('success', true).set('error', false);
};

const handleGetChecklistTemplatesError = (state, action) => {
  return state.set('loading', false).set('success', false).set('error', action.error);
};

const handleGetChecklistTemplate = (state, action) => {
  return state.set('loadingChecklistTemplate', true).set('successChecklistTemplate', false).set('errorChecklistTemplate', false);
};

const handleGetChecklistTemplateSuccess = (state, action) => {
  return state.set('checklistTemplate', action.response).set('loadingChecklistTemplate', false).set('successChecklistTemplate', true).set('errorChecklistTemplate', false);
};

const handleGetChecklistTemplateError = (state, action) => {
  return state.set('loadingChecklistTemplate', false).set('successChecklistTemplate', false).set('errorChecklistTemplate', action.error);
};

const handleGetChecklistTemplateConfiguration = (state, action) => {
  return state.set('loadingChecklistTemplateConfiguration', true).set('successChecklistTemplateConfiguration', false).set('errorChecklistTemplateConfiguration', false);
};

const handleGetChecklistTemplateConfigurationSuccess = (state, action) => {
  return state.set('checklistTemplateConfiguration', action.response).set('loadingChecklistTemplateConfiguration', false).set('successChecklistTemplateConfiguration', true).set('errorChecklistTemplateConfiguration', false);
};

const handleGetChecklistTemplateConfigurationError = (state, action) => {
  return state.set('loadingChecklistTemplateConfiguration', false).set('successChecklistTemplateConfiguration', false).set('errorChecklistTemplateConfiguration', action.error);
};

const handleGetStrategicActionTemplate = (state, action) => {
  return state.set('loadingStrategicActionTemplate', true).set('successStrategicActionTemplate', false).set('errorStrategicActionTemplate', false);
};

const handleGetStrategicActionTemplateSuccess = (state, action) => {
  return state.set('strategicActionTemplate', action.response).set('loadingStrategicActionTemplate', false).set('successStrategicActionTemplate', true).set('errorStrategicActionTemplate', false);
};

const handleGetStrategicActionTemplateError = (state, action) => {
  return state.set('loadingStrategicActionTemplate', false).set('successStrategicActionTemplate', false).set('errorStrategicActionTemplate', action.error);
};

const handlePutChecklistTemplateConfiguration = (state, action) => {
  return state.set('loadingSaveChecklistTemplateConfiguration', true).set('successSaveChecklistTemplateConfiguration', false).set('errorSaveChecklistTemplateConfiguration', false);
};

const handlePutChecklistTemplateConfigurationSuccess = (state, action) => {
  return state.set('loadingSaveChecklistTemplateConfiguration', false).set('successSaveChecklistTemplateConfiguration', true).set('errorSaveChecklistTemplateConfiguration', false);
};

const handlePutChecklistTemplateConfigurationError = (state, action) => {
  return state.set('loadingSaveChecklistTemplateConfiguration', false).set('successSaveChecklistTemplateConfiguration', false).set('errorSaveChecklistTemplateConfiguration', action.error);
};

const handlePutChecklistTemplate = (state, action) => {
  return state.set('loadingSaveChecklistTemplate', true).set('successSaveChecklistTemplate', false).set('errorSaveChecklistTemplate', false);
};

const handlePutChecklistTemplateSuccess = (state, action) => {
  return state.set('loadingSaveChecklistTemplate', false).set('successSaveChecklistTemplate', true).set('errorSaveChecklistTemplate', false);
};

const handlePutChecklistTemplateError = (state, action) => {
  return state.set('loadingSaveChecklistTemplate', false).set('successSaveChecklistTemplate', false).set('errorSaveChecklistTemplate', action.error);
};

const handlePutStrategicActionTemplate = (state, action) => {
  return state.set('loadingSaveStrategicActionTemplate', true).set('successSaveStrategicActionTemplate', false).set('errorSaveStrategicActionTemplate', false);
};

const handlePutStrategicActionTemplateSuccess = (state, action) => {
  return state.set('loadingSaveStrategicActionTemplate', false).set('successSaveStrategicActionTemplate', true).set('errorSaveStrategicActionTemplate', false);
};

const handlePutStrategicActionTemplateError = (state, action) => {
  return state.set('loadingSaveStrategicActionTemplate', false).set('successSaveStrategicActionTemplate', false).set('errorSaveStrategicActionTemplate', action.error);
};

const handleDelChecklistTemplate = (state, action) => {
  return state.set('loadingDelChecklistTemplate', true).set('successDelChecklistTemplate', false).set('errorDelChecklistTemplate', false);
};

const handleDelChecklistTemplateSuccess = (state, action) => {
  return state.set('loadingDelChecklistTemplate', false).set('successDelChecklistTemplate', true).set('errorDelChecklistTemplate', false);
};

const handleDelChecklistTemplateError = (state, action) => {
  return state.set('loadingDelChecklistTemplate', false).set('successDelChecklistTemplate', false).set('errorDelChecklistTemplate', action.error);
};

const handleReset = (state, action) => {
  return initialState;
};

const handleResetConfiguration = (state, action) => {
  return state.set('loadingSaveChecklistTemplateConfiguration', false).set('successSaveChecklistTemplateConfiguration', false).set('errorSaveChecklistTemplateConfiguration', false);
};

const handleResetStrategicAction = (state, action) => {
  return state.set('strategicActionTemplate', Map())
    .set('loadingStrategicActionTemplate', false).set('successStrategicActionTemplate', false).set('errorStrategicActionTemplate', false)
    .set('loadingSaveStrategicActionTemplate', false).set('successSaveStrategicActionTemplate', false).set('errorSaveStrategicActionTemplate', false);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_CHECKLIST_TEMPLATES:
      return handleGetChecklistTemplates(state, action);
    case Types.GET_CHECKLIST_TEMPLATES_SUCCESS:
      return handleGetChecklistTemplatesSuccess(state, action);
    case Types.GET_CHECKLIST_TEMPLATES_ERROR:
      return handleGetChecklistTemplatesError(state, action);

    case Types.GET_CHECKLIST_TEMPLATE:
      return handleGetChecklistTemplate(state, action);
    case Types.GET_CHECKLIST_TEMPLATE_SUCCESS:
      return handleGetChecklistTemplateSuccess(state, action);
    case Types.GET_CHECKLIST_TEMPLATE_ERROR:
      return handleGetChecklistTemplateError(state, action); 

    case Types.GET_CHECKLIST_TEMPLATE_CONFIGURATION:
      return handleGetChecklistTemplateConfiguration(state, action);
    case Types.GET_CHECKLIST_TEMPLATE_CONFIGURATION_SUCCESS:
      return handleGetChecklistTemplateConfigurationSuccess(state, action);
    case Types.GET_CHECKLIST_TEMPLATE_CONFIGURATION_ERROR:
      return handleGetChecklistTemplateConfigurationError(state, action);

    case Types.GET_STRATEGIC_ACTION_TEMPLATE:
      return handleGetStrategicActionTemplate(state, action);
    case Types.GET_STRATEGIC_ACTION_TEMPLATE_SUCCESS:
      return handleGetStrategicActionTemplateSuccess(state, action);
    case Types.GET_STRATEGIC_ACTION_TEMPLATE_ERROR:
      return handleGetStrategicActionTemplateError(state, action);

    case Types.PUT_CHECKLIST_TEMPLATE_CONFIGURATION:
      return handlePutChecklistTemplateConfiguration(state, action);
    case Types.PUT_CHECKLIST_TEMPLATE_CONFIGURATION_SUCCESS:
      return handlePutChecklistTemplateConfigurationSuccess(state, action);
    case Types.PUT_CHECKLIST_TEMPLATE_CONFIGURATION_ERROR:
      return handlePutChecklistTemplateConfigurationError(state, action);

    case Types.PUT_CHECKLIST_TEMPLATE:
      return handlePutChecklistTemplate(state, action);
    case Types.PUT_CHECKLIST_TEMPLATE_SUCCESS:
      return handlePutChecklistTemplateSuccess(state, action);
    case Types.PUT_CHECKLIST_TEMPLATE_ERROR:
      return handlePutChecklistTemplateError(state, action);

    case Types.PUT_STRATEGIC_ACTION_TEMPLATE:
      return handlePutStrategicActionTemplate(state, action);
    case Types.PUT_STRATEGIC_ACTION_TEMPLATE_SUCCESS:
      return handlePutStrategicActionTemplateSuccess(state, action);
    case Types.PUT_STRATEGIC_ACTION_TEMPLATE_ERROR:
      return handlePutStrategicActionTemplateError(state, action);

    case Types.DEL_CHECKLIST_TEMPLATE:
      return handleDelChecklistTemplate(state, action);
    case Types.DEL_CHECKLIST_TEMPLATE_SUCCESS:
      return handleDelChecklistTemplateSuccess(state, action);
    case Types.DEL_CHECKLIST_TEMPLATE_ERROR:
      return handleDelChecklistTemplateError(state, action);

    case Types.RESET:
      return handleReset(state, action);

    case Types.RESET_CONFIGURATION:
      return handleResetConfiguration(state, action);
    case Types.RESET_STRATEGIC_ACTION:
      return handleResetStrategicAction(state, action);

    default:
      return state;
  }
}
