import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import { getWhatsAppHistory as getWhatsAppHistorySdk, 
  sendWhatsAppMessage as sendWhatsAppMessageSdk,
  getWhatsAppTemplate as getWhatsAppTemplateSdk,
  postFinishActivityChat as postFinishActivityChatSdk,
  postUpdateChatStatus as postUpdateChatStatusSdk
} from '@sdk/WhatsApp';

//Action Types
export const Types = {
  GET_HISTORY: 'WHATSAPP/GET_HISTORY',
  GET_HISTORY_SUCCESS: 'WHATSAPP/GET_HISTORY_SUCCESS',
  GET_HISTORY_ERROR: 'WHATSAPP/GET_HISTORY_ERROR',

  SEND_MESSAGE: 'WHATSAPP/SEND_MESSAGE',
  SEND_MESSAGE_SUCCESS: 'WHATSAPP/SEND_MESSAGE_SUCCESS',
  SEND_MESSAGE_ERROR: 'WHATSAPP/SEND_MESSAGE_ERROR',

  GET_TEMPLATES: 'WHATSAPP/GET_TEMPLATES',
  GET_TEMPLATES_SUCCESS: 'WHATSAPP/GET_TEMPLATES_SUCCESS',
  GET_TEMPLATES_ERROR: 'WHATSAPP/GET_TEMPLATES_ERROR',

  POST_FINISH_ACTIVITY_CHAT: 'WHATSAPP/POST_FINISH_ACTIVITY_CHAT',
  POST_FINISH_ACTIVITY_CHAT_SUCCESS: 'WHATSAPP/POST_FINISH_ACTIVITY_CHAT_SUCCESS',
  POST_FINISH_ACTIVITY_CHAT_ERROR: 'WHATSAPP/POST_FINISH_ACTIVITY_CHAT_ERROR',

  POST_UPDATE_CHAT_STATUS: 'WHATSAPP/POST_UPDATE_CHAT_STATUS',
  POST_UPDATE_CHAT_STATUS_SUCCESS: 'WHATSAPP/POST_UPDATE_CHAT_STATUS_SUCCESS',
  POST_UPDATE_CHAT_STATUS_ERROR: 'WHATSAPP/POST_UPDATE_CHAT_STATUS_ERROR',

  RESET_HISTORY: 'WHATSAPP/RESET_HISTORY',
  RESET_UPDATE_CHAT_STATUS: 'WHATSAPP/RESET_UPDATE_CHAT_STATUS',
};

//Action Creators
export const getHistory = (userId, contactId, limit) => ({ type: Types.GET_HISTORY, userId, contactId, limit });
export const getHistorySuccess = (chats) => ({ type: Types.GET_HISTORY_SUCCESS, chats });
export const getHistoryError = (error) => ({ type: Types.GET_HISTORY_ERROR, error });

export const sendMessage = (body) => ({ type: Types.SEND_MESSAGE, body });
export const sendMessageSuccess = (body) => ({ type: Types.SEND_MESSAGE_SUCCESS, body });
export const sendMessageError = (error) => ({ type: Types.SEND_MESSAGE_ERROR, error });

export const getTemplates = () => ({ type: Types.GET_TEMPLATES });
export const getTemplatesSuccess = (templates) => ({ type: Types.GET_TEMPLATES_SUCCESS, templates });
export const getTemplatesError = (error) => ({ type: Types.GET_TEMPLATES_ERROR, error });

export const postFinishActivityChat = (data) => ({ type: Types.POST_FINISH_ACTIVITY_CHAT, data });
export const postFinishActivityChatSuccess = (resp) => ({ type: Types.POST_FINISH_ACTIVITY_CHAT_SUCCESS, resp });
export const postFinishActivityChatError = (error) => ({ type: Types.POST_FINISH_ACTIVITY_CHAT_ERROR, error });

export const postUpdateChatStatus = (data) => ({ type: Types.POST_UPDATE_CHAT_STATUS, data });
export const postUpdateChatStatusSuccess = (resp) => ({ type: Types.POST_UPDATE_CHAT_STATUS_SUCCESS, resp });
export const postUpdateChatStatusError = (error) => ({ type: Types.POST_UPDATE_CHAT_STATUS_ERROR, error });

export const resetHistory = () => ({ type: Types.RESET_HISTORY });
export const resetUpdateChatStatus = () => ({ type: Types.POST_UPDATE_CHAT_STATUS_SUCCESS });

//Saga
function* fetchGetHistory(action) {
  try {
    const { userId, contactId, limit } = action;
    const response = yield call(getWhatsAppHistorySdk, userId, contactId, limit);
    yield put(getHistorySuccess(response));
  } catch (error) {
    yield put(getHistoryError(error));
  }
}

function* fetchSendMessage({ body }) {
  try {
    const response = yield call(sendWhatsAppMessageSdk, body);
    yield put(sendMessageSuccess(response));
  } catch (error) {
    yield put(sendMessageError(error));
  }
}

function* fetchGetTemplates() {
  try {
    const response = yield call(getWhatsAppTemplateSdk);
    yield put(getTemplatesSuccess(response));
  } catch (error) {
    yield put(getTemplatesError(error));
  }
}

function* fetchPostFinishActivityChat(action) {
  try {
    yield call(postFinishActivityChatSdk, action.data);
    yield put(postFinishActivityChatSuccess(action.data.finishActivity));
  } catch (err) {
    Notification.error(err.message);
    yield put(postFinishActivityChatError(err));
  }
}

function* fetchPostUpdateChatStatus(action) {
  try {
    const res = yield call(postUpdateChatStatusSdk, action.data);
    yield put(postUpdateChatStatusSuccess(res));
  } catch (err) {
    Notification.error(err.message);
    yield put(postUpdateChatStatusError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_HISTORY, fetchGetHistory), 
  takeLatest(Types.SEND_MESSAGE, fetchSendMessage),
  takeLatest(Types.GET_TEMPLATES, fetchGetTemplates),
  takeLatest(Types.POST_FINISH_ACTIVITY_CHAT, fetchPostFinishActivityChat),
  takeLatest(Types.POST_UPDATE_CHAT_STATUS, fetchPostUpdateChatStatus)
];

// Reducer
const initialState = Map({
  chats: List(),
  loading: false,
  success: false,
  error: false,

  sending: false,
  successSend: false,
  errorSend: false,

  templates: List(),
  successGetTemplates: false,
  errorGetTemplates: false,

  finishActivityChat: false,
  successFinishActivityChat: false,
  errorFinishActivityChat: false,

  updateChatStatus: Map(),
  successUpdateChatStatus: false,
  errorUpdateChatStatus: false,
  loadingUpdateChatStatus: false
});

const handleGetHistory = (state) => {
  return state.set('loading', true).set('success', false).set('error', false);
};

const handleGetHistorySuccess = (state, action) => {
  return state.set('loading', false).set('success', true).set('error', false).set('chats', action.chats);
};

const handleGetHistoryError = (state, action) => {
  return state.set('loading', false).set('success', false).set('error', action.error);
};

const handleSendMessage = (state) => {
  return state.set('sending', true).set('successSend', false).set('errorSend', false);
};

const handleSendMessageSuccess = (state, action) => {
  return state.set('sending', false).set('successSend', true).set('errorSend', false);
};

const handleSendMessageError = (state, action) => {
  return state.set('sending', false).set('successSend', false).set('errorSend', action.error);
};

const handleGetTemplates = (state) => {
  return state.set('loading', true).set('successGetTemplates', false).set('errorGetTemplates', false);
};

const handleGetTemplatesSuccess = (state, action) => {
  return state.set('loading', false).set('successGetTemplates', true).set('errorGetTemplates', false).set('templates', action.templates);
};

const handleGetTemplatesError = (state, action) => {
  return state.set('loading', false).set('successGetTemplates', false).set('errorGetTemplates', action.error);
};

const handlePostFinishActivityChat = (state) => {
  return state.set('finishActivityChat', true).set('successFinishActivityChat', false).set('errorFinishActivityChat', false);
};

const handlePostFinishActivityChatSuccess = (state, action) => {
  return state.set('finishActivityChat', false).set('successFinishActivityChat', true).set('errorFinishActivityChat', false);
};

const handlePostFinishActivityChatError = (state, action) => {
  return state.set('finishActivityChat', false).set('successFinishActivityChat', false).set('errorFinishActivityChat', action.error);
};

const handlePostUpdateChatStatus = (state) => {
  return state.set('updateChatStatus', state).set('successUpdateChatStatus', false).set('errorUpdateChatStatus', false).set('loadingUpdateChatStatus', true);
};

const handlePostUpdateChatStatusSuccess = (state, action) => {
  return state.set('updateChatStatus', action.resp).set('successUpdateChatStatus', true).set('errorUpdateChatStatus', false).set('loadingUpdateChatStatus', false);
};

const handlePostUpdateChatStatusError = (state, action) => {  
  return state.set('updateChatStatus', false).set('successUpdateChatStatus', false).set('errorUpdateChatStatus', action.error).set('loadingUpdateChatStatus', false);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_HISTORY:
      return handleGetHistory(state, action);
    case Types.GET_HISTORY_SUCCESS:
      return handleGetHistorySuccess(state, action);
    case Types.GET_HISTORY_ERROR:
      return handleGetHistoryError(state, action);

    case Types.SEND_MESSAGE:
      return handleSendMessage(state, action);
    case Types.SEND_MESSAGE_SUCCESS:
      return handleSendMessageSuccess(state, action);
    case Types.SEND_MESSAGE_ERROR:
      return handleSendMessageError(state, action);

    case Types.GET_TEMPLATES:
      return handleGetTemplates(state, action);
    case Types.GET_TEMPLATES_SUCCESS:
      return handleGetTemplatesSuccess(state, action);
    case Types.GET_TEMPLATES_ERROR:
      return handleGetTemplatesError(state, action);

    case Types.POST_FINISH_ACTIVITY_CHAT:
      return handlePostFinishActivityChat(state, action);
    case Types.POST_FINISH_ACTIVITY_CHAT_SUCCESS:
      return handlePostFinishActivityChatSuccess(state, action);
    case Types.POST_FINISH_ACTIVITY_CHAT_ERROR:
      return handlePostFinishActivityChatError(state, action);

    case Types.POST_UPDATE_CHAT_STATUS:
      return handlePostUpdateChatStatus(state, action);
    case Types.POST_UPDATE_CHAT_STATUS_SUCCESS:
      return handlePostUpdateChatStatusSuccess(state, action);
    case Types.POST_UPDATE_CHAT_STATUS_ERROR:
      return handlePostUpdateChatStatusError(state, action);
      
    case Types.RESET_HISTORY:
      return initialState;

    case Types.RESET:
      return initialState;
  
    default:
      return state;
  }
}