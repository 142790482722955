import React, { useEffect, useState } from 'react';
import { useParams , useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Column, Row, Tooltip, Skeleton } from '@components-teammove';
import { InfoUsuario } from '@utils';
import { ContentDataGroup, Divider, FeatherIcon, StyleButton, TextStyle, ModalConfirm } from './styles';

import { getSteps } from '@ducks/chamadosEtapas';
import { enableCreationTicket } from '@sdk/Chamados';
import { changeVisibleBriefingReplyEtapas } from '@ducks/chamadosHistorico';

import ModalViewSteps from './ModalViewSteps';
import Progress from './Progress';
import ModalNovo from '../../../Cadastro';

import ModalQuestionarioReply from '../../../Questionario/Reply';
import { resetSuccesBriefing } from '@ducks/chamadosBriefingReply';
import { getCategorias as getCategoriesForOptions } from '@ducks/configuracoesChamados';

export default function Steps({ mainTicket }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const usuario = InfoUsuario.get();
  const history = useHistory();
  const steps = useSelector(({ chamadosEtapas }) => chamadosEtapas.get('steps'));
  const loadingSteps = useSelector(({ chamadosEtapas }) => chamadosEtapas.get('loadingSteps'));

  const success = useSelector(({ chamadosCadastro }) => chamadosCadastro.get('success'));

  const successFinishBriefingReply = useSelector(({ chamadosBriefingReply }) => chamadosBriefingReply.get('successFinishBriefingReply'));

  const visibleBriefingReplyEtapas = useSelector(({ chamadosHistorico }) => chamadosHistorico.get('visibleBriefingReplyEtapas'));

  const userTicketCreator = InfoUsuario.get('cdUsuario');

  const [visibleViewStep, setVisibleViewStep] = useState(false);

  const [visibleAddStep, setVisibleAddStep] = useState(false);
  const [loadingEnableCreationTicket, setLoadingEnableCreationTicket] = useState(false);
  const [visibleWarningNewTicket, setVisibleWarningNewTicket] = useState(false);

  const chamado = useSelector(({ chamadosCadastro }) => chamadosCadastro.get('chamado'));
  
  const [ currentStep, setCurrentStep ] = useState();
  const [ mainTicketUpdated, setMainTicketUpdated ] = useState(mainTicket);

  useEffect(() => {
    setMainTicketUpdated(mainTicket);
  }, [mainTicket]);
  
  useEffect(() => {
    dispatch(getCategoriesForOptions(true, false, true, InfoUsuario.get('cdUsuario')));
    dispatch(getSteps(id));
    if(successFinishBriefingReply) {
      dispatch(resetSuccesBriefing());
    }
    
  }, [success, successFinishBriefingReply]);
  
  const handleNewStep = async () => {
    setLoadingEnableCreationTicket(true);
    try {
      const res = await enableCreationTicket(usuario.cdUsuario);
      if (res) {
        setVisibleAddStep(true);
      } else {
        setVisibleWarningNewTicket(true);
      }
    } finally {
      setLoadingEnableCreationTicket(false);
    }
  };
  
  return(
    <>
      {(mainTicketUpdated?.status !== 'AGUARDANDO_BRIEFING' && mainTicketUpdated?.status !== 'ABERTO') && (
        <>
          {((userTicketCreator === mainTicketUpdated?.idUsuarioAtendimento) || (steps && steps.length > 0)) && (
            <>
              <Divider/>
              <ContentDataGroup>
                <Row justify='space-between' >
                  <Row align='center' gap='8px'>
                    <FeatherIcon type='flag'/>
                    <TextStyle font='16px' weight='700'>Etapas</TextStyle>
                  </Row>
                  <Tooltip title='Criar uma nova etapa vinculada a este ticket, você pode envolver outra pessoa ou departamento no processo de atendimento desta demanda.'>
                    <FeatherIcon $help type='help-circle'/>
                  </Tooltip>
                </Row>

                {loadingSteps ? <Skeleton.LargeBox/> : (
                  <>
                    {steps && steps.length > 0 && (
                      <Progress steps={steps}/>
                    )}
             
                    <Column gap='12px'>
                      {userTicketCreator === mainTicketUpdated?.idUsuarioAtendimento && (
                        mainTicketUpdated.status !== 'CANCELADO' && (
                          <StyleButton width='239px' size='small' type='tertiary' onClick={() => handleNewStep()} loading={loadingEnableCreationTicket}>Adicionar etapa</StyleButton>
                        )
                      )} 
                      {steps && steps.length > 0 && (
                        <StyleButton width='239px' size='small' type='tertiary' onClick={() => setVisibleViewStep(true)} transparent>Visualizar etapas</StyleButton>
                      )}
                
                    </Column>
                  </>
                )}
              </ContentDataGroup>
          
              <ModalNovo
                mainTicket={{ id:mainTicketUpdated?.id, companyId: mainTicketUpdated?.idUnidade }}
                visible={visibleAddStep}
                onCancel={() => setVisibleAddStep(false)}
                onOk={() => {
                  setVisibleAddStep(false);
                  setCurrentStep(chamado);
                }}
                type='ETAPA'
              />

              <ModalViewSteps
                mainTicket={mainTicketUpdated}
                steps={steps}
                loadingSteps={loadingSteps}
                visible={visibleViewStep}
                setVisibleViewStep={setVisibleViewStep}
                onCancel={()=> {
                  setVisibleViewStep(false);
                }}
                setCurrentStep={setCurrentStep}
                setMainTicket={setMainTicketUpdated}
              />

              {visibleBriefingReplyEtapas && (
                <ModalQuestionarioReply
                  visible={visibleBriefingReplyEtapas}
                  onCancel={() => {
                    dispatch(changeVisibleBriefingReplyEtapas(false));
                    history.push('#');
                  }}
                  tipo={'BRIEFING'} 
                  chamado={currentStep}
                />
              )}
              
              {visibleWarningNewTicket && (
                <ModalConfirm
                  visible={visibleWarningNewTicket}
                  title='Tickets pendentes'
                  content='Existem tickets pendentes para avaliação'
                  cancelText='Cancelar'
                  okText='Avaliar'
                  onOk={() => {
                    setVisibleWarningNewTicket(false);
                  }}
                  onCancel={() => setVisibleWarningNewTicket(false)}
                />
              )}
            </>
          )}
        </>
      )} 
    </>
  );
}