import React from 'react';
import { Checkbox, Text } from '@components-teammove';
import {
  StyledCollapse,
  CheckboxGroup,
  ActivityRow,
  HeaderRow,
} from './styles';

const CollapsibleCheckboxList = ({
  listName,
  checkboxLabels,
  list,
  value = [],
  onChange,
  idField = 'id',
  isDisabled,
  sortItems,
  getItemChecked,
  onSelectAll,
}) => {
  const handleChange = (itemId, type, checked) => {
    if (onChange) onChange(itemId, type, checked);
  };

  const sortedList = React.useMemo(() => {
    return sortItems ? [...list].sort(sortItems) : list;
  }, [list, sortItems]);

  // Calculate if all items are checked for all labels
  const isAllChecked = React.useMemo(() => {
    if (!sortedList.length || !checkboxLabels.length || !getItemChecked) return false;
    return sortedList.every((item) =>
      checkboxLabels.every((label) => getItemChecked(item[idField], label))
    );
  }, [sortedList, checkboxLabels, getItemChecked, idField]);

  return (
    <StyledCollapse defaultActiveKey={['1']} expandIconPosition="right">
      <StyledCollapse.Panel
        header={listName}
        key="1"
        checkboxProps={{
          onChange: (checked) => onSelectAll?.(checked),
          checked: isAllChecked,
        }}
      >
        <HeaderRow>
          {checkboxLabels.map((label) => (
            <span key={label} className="column-header">
              {label}
            </span>
          ))}
        </HeaderRow>
        <CheckboxGroup>
          {sortedList.map((item) => (
            <ActivityRow
              templateColumns="auto auto 1fr"
              key={item[idField]}
              style={{
                opacity: isDisabled?.(item[idField]) ? 0.5 : 1,
              }}
            >
              {checkboxLabels.map((label) => (
                <Checkbox
                  key={label}
                  checked={getItemChecked?.(item[idField], label)}
                  onChange={(checked) =>
                    handleChange(item[idField], label, checked)
                  }
                />
              ))}
              <Text>{item.name}</Text>
            </ActivityRow>
          ))}
        </CheckboxGroup>
      </StyledCollapse.Panel>
    </StyledCollapse>
  );
};

export default CollapsibleCheckboxList;
