import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Button, Row, Text } from '@components-teammove';
import { formatDateTime } from '@utils';
import { getAfastamentos } from '@ducks/afastamento';

const AfastamentosTable = ({ handleEditClick }) => {
  const dispatch = useDispatch();

  const afastamentos = useSelector(({ afastamento }) => afastamento.get('afastamentos').toArray());
  const loadingAfastamentos = useSelector(({ afastamento }) => afastamento.get('loadingAfastamentos'));
  const successSave = useSelector(({ afastamento }) => afastamento.get('successPutAfastamento'));

  useEffect(() => {
    dispatch(getAfastamentos());
  }, []);

  useEffect(() => {
    if (successSave) dispatch(getAfastamentos());
  }, [successSave]);

  const columns = [
    {
      title: 'Motivo',
      dataIndex: 'motivo',
      sorter: true,
    },
    {
      title: 'Início',
      dataIndex: 'inicio',
      sorter: 'datetime',
      render: (inicio) => <Text>{formatDateTime(inicio)}</Text>,
    },
    {
      title: 'Fim',
      dataIndex: 'fim',
      sorter: 'datetime',
      render: (fim, afastamento) => (
        <Row justify='space-between' align='center'>
          <Text>{formatDateTime(fim)}</Text>
          <Row>
            <Text singular="usuário" count={afastamento.usuarios ? afastamento.usuarios.length : 0} zeroText="" />
            <Text singular="perfil" plural="perfis" count={afastamento.perfis ? afastamento.perfis.length : 0} zeroText="" />
          </Row>
          <Button
            type='secondary'
            size='small'
            context='list'
            onClick={() => handleEditClick({
              ...afastamento,
              inicio: new Date(afastamento.inicio),
              fim: new Date(afastamento.fim),
            })}
          >
            Editar
          </Button>
        </Row>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={afastamentos}
      loading={loadingAfastamentos}
      rowKey="id"
    />
  );
};

export default AfastamentosTable;
