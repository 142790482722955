import { useCallback } from 'react';
import { Notification } from '@utils';

export const useTreeDragDrop = (treeData, operations, onUpdate) => {
  const { findParent, isDescendant } = operations;

  // This function determines if a drop operation is allowed
  const canDrop = useCallback(
    ({ dragNode, dropNode, dropPosition }) => {
      // Prevent dropping a node into one of its descendants
      if (isDescendant(dragNode, dropNode)) {
        Notification.warning(
          'Não pode mover um item para dentro de um dos seus descendentes'
        );
        return false;
      }

      // Find parent nodes for both drag and drop nodes
      const dragParent = findParent(dragNode.key, treeData);
      const dropParent = findParent(dropNode.key, treeData);

      // Explicitly prevent dropping inside another node (changing level)
      if (dropPosition === 0) {
        Notification.warning('Não pode mudar o nivel de um item');
        return false;
      }

      if (
        (dragParent === null && dropParent !== null) ||
        (dragParent !== null && dropParent === null) ||
        (dragParent && dropParent && dragParent.key !== dropParent.key)
      ) {
        Notification.warning(
          'Apenas itens dentro de um mesmo nodo podem ser reordenados'
        );
        return false;
      }

      return true;
    },
    [treeData, findParent, isDescendant]
  );

  const handleDrop = useCallback(
    ({ dragNode, node: dropNode, dropPosition }) => {
      if (!canDrop({ dragNode, dropNode, dropPosition })) {
        return;
      }

      const dragKey = dragNode.key;
      const dropKey = dropNode.key;

      const newData = JSON.parse(JSON.stringify(treeData));

      const dragParent = findParent(dragNode.key, treeData);
      let parentArray = null;

      if (dragParent === null) {
        parentArray = newData;
      } else {
        const findParentInCopy = (nodes, parentKey) => {
          for (const node of nodes) {
            if (node.key === parentKey) {
              return node.children;
            }
            if (node.children) {
              const result = findParentInCopy(node.children, parentKey);
              if (result) return result;
            }
          }
          return null;
        };

        parentArray = findParentInCopy(newData, dragParent.key);
      }

      if (!parentArray) {
        console.error('Failed to find parent array for reordering');
        return;
      }

      const dragIndex = parentArray.findIndex((node) => node.key === dragKey);
      const dropIndex = parentArray.findIndex((node) => node.key === dropKey);

      if (dragIndex === -1 || dropIndex === -1) {
        console.error('Failed to find nodes for reordering');
        return;
      }

      const [draggedNode] = parentArray.splice(dragIndex, 1);

      let newPosition = dropIndex;
      if (dragIndex < dropIndex) {
        newPosition--;
      }

      if (dropPosition > 0) {
        newPosition += 1;
      }

      parentArray.splice(newPosition, 0, draggedNode);

      onUpdate(newData);
    },
    [treeData, onUpdate, canDrop, findParent]
  );

  return {
    canDrop,
    handleDrop,
  };
};
