import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { InfoUsuario, TelasNomenclaturas } from '@utils';
import { Icon } from '@components-teammove';
import * as Containers from '@containers';
import Route from './Route';
import conteudos from './Conteudos';
import wikisEAD from './Wikis/EAD';
import gestaoEquipe from './GestaoEquipe';
import campanhas from './Campanhas';
import chamados from './Chamados';
import atividades from './Atividades';

export const routes = () => [
  {
    path: '/',
    icon: Icon.HomeOutlined,
    text: 'Início',
    match: /\/+$/,
    sider: true,
    component: Containers.Home,
    exact: true,
    secret: true,
  },
  {
    path: '/login',
    component: Containers.Login,
    exact: true,
  },
  {
    path: '/logout',
    component: Containers.Logout,
    exact: true,
    secret: true,
  },
  {
    path: '/recuperar-senha',
    component: Containers.RecuperarSenha,
    exact: true,
  },
  {
    path: '/perfil',
    component: Containers.Perfil,
    exact: true,
  },
  {
    path: '/alterar-senha',
    component: Containers.AlterarSenha,
    exact: true,
    secret: true,
  },
  {
    path: '/integracoes',
    icon: Icon.CloudOutlined,
    component: Containers.Integracoes,
    exact: true,
    secret: true,
  },
  {
    path: '/redirect',
    component: Containers.Redirect,
    exact: true,
  },
  ...chamados(),
  ...[...atividades(),
    {
      path: '/atividades/agenda/:idActivity/checklist/:idChecklist/finished',
      component: Containers.ChecklistFinished,
      exact: true,
    }],
  ...conteudos(),
  {
    path: '/comunicados',
    icon: Icon.NotificationOutlined,
    text: 'Comunicados',
    match: /^\/comunicados?/,
    component: Containers.Comunicados,
    exact: true,
    sider: InfoUsuario.perm('comunicados'),
    permissions: (infoUsuario) => InfoUsuario.perm('comunicados', infoUsuario) && InfoUsuario.permExtras('gruposComunicado', infoUsuario),
  },
  {
    path: '/comunicado/cadastro',
    component: Containers.ComunicadosCadastro,
    exact: true,
    permissions: (infoUsuario) => InfoUsuario.perm('comunicadosCadastro', infoUsuario) && InfoUsuario.permExtras('gruposComunicado', infoUsuario),
  },
  {
    path: '/comunicado/:id',
    match: /^\/comunicado?/,
    component: Containers.ComunicadosVisualizacao,
    exact: true,
    permissions: (infoUsuario) => InfoUsuario.perm('comunicados', infoUsuario) && InfoUsuario.permExtras('gruposComunicado', infoUsuario),
  },
  {
    path: '/wiki/editor/:idCategoria',
    component: Containers.WikisEditor,
    exact: true,
    secret: true,
    permissions: (infoUsuario) => InfoUsuario.perm('conteudos', infoUsuario),
  },
  {
    path: '/wiki/personalizacao/:idCategoria',
    component: Containers.WikisPersonalizacao,
    exact: true,
    secret: true,
    permissions: (infoUsuario) => InfoUsuario.perm('conteudos', infoUsuario),
  },
  ...wikisEAD(),
  ...campanhas(),
  {
    path: '/unidades',
    icon: Icon.ShopOutlined,
    text: TelasNomenclaturas.getNomenclatures('UNIDADES')?.plural,
    match: /^\/unidades?/,
    exact: true,
    sider: true,
    permissions: (infoUsuario) => InfoUsuario.perm('unidades', infoUsuario),
  },
  {
    path: '/listaUnidades',
    parent: '/unidades',
    text: `Lista de ${TelasNomenclaturas.getNomenclatures('UNIDADES')?.plural}`,
    component: Containers.UnidadesLista,
    match: /^\/listaUnidades?/,
    sider: InfoUsuario.perm('unidades'),
    exact: true,
    secret: true,
    permissions: (infoUsuario) => InfoUsuario.perm('unidades', infoUsuario),
  },
  {
    path: '/gestaoCarteira',
    parent: '/unidades',
    text: 'Gestão da Carteira',
    component: Containers.GestaoCarteira,
    match: /^\/gestaoCarteira?/,
    sider: InfoUsuario.perm('unidades'),
    exact: true,
    secret: true,
    permissions: (infoUsuario) => InfoUsuario.perm('unidades', infoUsuario),
  },
  {
    path: '/posicao-financeira',
    parent: '/unidades',
    text: 'Posição Financeira',
    component: Containers.UnidadeAdimplencia,
    match: /^\/posicao-financeira?/,
    sider: InfoUsuario.perm('unidadesVisualizacaoInadimplencia'),
    exact: true,
    secret: true,
    permissions: (infoUsuario) => InfoUsuario.perm('unidadesVisualizacaoInadimplencia', infoUsuario),
  },
  {
    path: '/gestaoCarteira/dashboardCluster',
    component: Containers.DashBoardCluster,
    exact: true,
    secret: true,
    permissions: (infoUsuario) => InfoUsuario.perm('unidades', infoUsuario),
  },
  ...gestaoEquipe,
  {
    path: '/unidades/editar',
    text: TelasNomenclaturas.getNomenclatures('UNIDADES')?.plural,
    match: /^\/unidades?/,
    component: Containers.EditarMultiplasUnidades,
    exact: true,
    permissions: (infoUsuario) => InfoUsuario.perm('unidades', infoUsuario),
  },
  {
    path: '/unidades/cadastro',
    component: Containers.CadastroUnidade,
    exact: true,
    permissions: (infoUsuario) => InfoUsuario.perm('unidadesCadastro', infoUsuario),
  },
  {
    path: '/unidades/:idUnidade',
    component: Containers.Unidade,
    exact: true,
    permissions: (infoUsuario) => InfoUsuario.perm('unidades', infoUsuario),
  },
  {
    path: '/unidades/:idUnidade/detalhes',
    component: Containers.UnidadeDetalhes,
    exact: true,
    permissions: (infoUsuario) => InfoUsuario.perm('unidades', infoUsuario),
  },
  {
    path: '/produtos',
    icon: Icon.ShoppingCartOutlined,
    text: 'Produtos',
    match: /^\/produtos?/,
    exact: true,
    secret: true,
    sider: InfoUsuario.perm('produtos') || InfoUsuario.perm('pedidos'),
    permissions: (infoUsuario) => InfoUsuario.perm('produtos', infoUsuario) || InfoUsuario.perm('pedidos', infoUsuario),
  },
  {
    path: '/mixprodutos',
    parent: '/produtos',
    text: 'Mix de produtos',
    component: Containers.Produtos,
    match: /^\/mixprodutos?/,
    sider: InfoUsuario.perm('produtos'),
    exact: true,
    secret: true,
    permissions: (infoUsuario) => InfoUsuario.perm('produtos', infoUsuario),
  },
  {
    path: '/mixprodutos/:idProduct/detalhes',
    component: Containers.ProductDetalhes,
    exact: true,
    permissions: (infoUsuario) => InfoUsuario.perm('produtos', infoUsuario),
  },
  {
    path: '/mixprodutos/cadastro/:id',
    component: Containers.ProductRegistration,
    exact: true,
    permissions: (infoUsuario) => InfoUsuario.perm('produtosCadastro', infoUsuario),
  },
  {
    path: '/pedidos',
    parent: '/produtos',
    text: 'Pedidos',
    component: Containers.Pedidos,
    match: /^\/pedidos?/,
    sider: InfoUsuario.perm('pedidos'),
    exact: true,
    secret: true,
    permissions: (infoUsuario) => InfoUsuario.perm('pedidos', infoUsuario),
  },
  {
    path: '/produtos/dashboard-pedidos',
    parent: '/produtos',
    text: 'Dashboard',
    component: Containers.DashboardPedidos,
    match: /^\/pedidos\/dashboard-pedidos/,
    sider: InfoUsuario.perm('pedidos'),
    exact: true,
    secret: true,
    permissions: (infoUsuario) => InfoUsuario.perm('pedidos', infoUsuario),
  },
  {
    path: '/produtos/dashboard-pedidos/ranking',
    component: Containers.RankingPedidos,
    exact: true,
    permissions: (infoUsuario) => InfoUsuario.perm('pedidos', infoUsuario),
  },
  {
    path: '/pedidos/:id',
    component: Containers.CadastroPedido,
    exact: true,
    secret: true,
    permissions: (infoUsuario) => InfoUsuario.perm('pedidosInserir', infoUsuario) || InfoUsuario.perm('pedidosEditar', infoUsuario),
  },
  {
    path: '/pedidos/:id/detalhes',
    component: Containers.DetalhesPedido,
    exact: true,
    secret: true,
    permissions: (infoUsuario) => InfoUsuario.perm('pedidos', infoUsuario),
  },
  {
    path: '/pedidos/:id/detalhes/print',
    component: Containers.ImpressaoPedido,
    exact: true,
    secret: true,
    permissions: (infoUsuario) => InfoUsuario.perm('pedidos', infoUsuario),
  },
  {
    path: '/configuracoes',
    component: Containers.Configuracoes, 
    exact: true,
    secret: true,
  },
  {
    path: '/voip',
    component: Containers.Voip, 
    exact: true,
    secret: true,
  },
  {
    path: '/configuracoes/chamados',
    parent: '/configuracoes',
    text: TelasNomenclaturas.getNomenclatures('CHAMADOS')?.plural,
    match: /^\/configuracoes\/chamados/,
    sider: InfoUsuario.perm('chamadosConfiguracao'),
    component: Containers.ConfiguracoesChamados,
    permissions: (infoUsuario) => InfoUsuario.perm('chamadosConfiguracao', infoUsuario),
    exact: true,
    secret: true,
  },
  {
    path: '/configuracoes/chamados/categoria',
    component: Containers.ConfiguracoesChamadosCategoria,
    permissions: (infoUsuario) => InfoUsuario.perm('chamadosConfiguracao', infoUsuario),
    exact: true,
    secret: true,
  },
  {
    path: '/configuracoes/chamados/atendimento',
    component: Containers.ConfiguracoesChamadosAtendimento,
    permissions: (infoUsuario) => InfoUsuario.perm('chamadosConfiguracao', infoUsuario),
    exact: true,
    secret: true,
  },
  {
    path: '/configuracoes/logoImpressao',
    parent: '/configuracoes',
    text: 'Logo impressao',
    match: /^\/configuracoes\/logoImpressao/,
    component: Containers.ConfiguracoesLogoImpressao,
    exact: true,
    secret: true,
  },
  {
    path: '/configuracoes/atendimento',
    parent: '/configuracoes',
    text: 'Atendimento',
    match: /^\/configuracoes\/atendimento/,
    component: Containers.ConfiguracoesAtendimento,
    exact: true,
    secret: true,
  },
  {
    path: '/configuracoes/atendimento/cadastro',
    parent: '/configuracoes/atendimento',
    text: 'Cadastro atendimento',
    match: /^\/configuracoes\/atendimento\/cadastro/,
    component: Containers.ConfiguracoesAtendimentoCadastro,
    exact: true,
    secret: true,
  },
  {
    path: '/configuracoes/atributos_usuarios',
    parent: '/configuracoes',
    text: 'Atributos de Usuários',
    match: /^\/configuracoes\/atributos_usuarios/,
    sider: InfoUsuario.perm('usuariosConfiguracao'),
    component: Containers.ConfiguracoesUsuarios,
    permissions: (infoUsuario) => InfoUsuario.perm('usuariosConfiguracao', infoUsuario),
    exact: true,
    secret: true,
  },
  {
    path: '/configuracoes/afastamentos',
    parent: '/configuracoes',
    text: 'Afastamentos',
    match: /^\/configuracoes\/afastamentos/,
    component: Containers.ConfiguracoesAfastamentos,
    permissions: (infoUsuario) => InfoUsuario.perm('afastamentosConfiguracao', infoUsuario) || true,
    exact: true,
    secret: true,
  },
  {
    path: '/configuracoes/unidades',
    parent: '/configuracoes',
    text: TelasNomenclaturas.getNomenclatures('UNIDADES')?.plural,
    match: /^\/configuracoes\/unidades/,
    sider: InfoUsuario.perm('unidadesConfiguracoesRegras'),
    component: Containers.ConfiguracoesUnidades,
    permissions: (infoUsuario) => InfoUsuario.perm('unidadesConfiguracoesRegras', infoUsuario),
    exact: true,
    secret: true,
  },
  {
    path: '/configuracoes/voip',
    parent: '/configuracoes',
    text: 'Voip',
    match: /^\/configuracoes\/voip/,
    component: Containers.ConfiguracoesVoip,
    exact: true,
    secret: true,
  },
  {
    path: '/configuracoes/unidades/regra_folga',
    component: Containers.ConfiguracoesUnidadesRegraFolga,
    permissions: (infoUsuario) => InfoUsuario.perm('unidadesConfiguracoesRegras', infoUsuario),
    exact: true,
    secret: true,
  },
  {
    path: '/configuracoes/unidades/tipos_unidades',
    component: Containers.ConfiguracoesUnidadesTiposUnidades,
    permissions: (infoUsuario) => InfoUsuario.perm('unidadesConfiguracoesRegras', infoUsuario),
    exact: true,
    secret: true,
  },
  {
    path: '/configuracoes/unidades/agrupadores',
    component: Containers.ConfiguracoesUnidadesAgrupadores,
    permissions: (infoUsuario) => InfoUsuario.perm('unidadesConfiguracoesRegras', infoUsuario),
    exact: true,
    secret: true,
  },
  {
    path: '/configuracoes/unidades/clusters',
    component: Containers.ConfiguracoesUnidadesClusters,
    permissions: (infoUsuario) => InfoUsuario.perm('unidadesConfiguracoesRegras', infoUsuario),
    exact: true,
    secret: true,
  },
  {
    path: '/configuracoes/unidades/campos_personalizados',
    component: Containers.ConfiguracoesUnidadesCamposPersonalizados,
    permissions: (infoUsuario) => InfoUsuario.perm('unidadesConfiguracoesRegras', infoUsuario),
    exact: true,
    secret: true,
  },
  {
    path: '/configuracoes/campanhas',
    parent: '/configuracoes',
    text: 'Campanhas',
    match: /^\/configuracoes\/campanhas/,
    sider: InfoUsuario.perm('campanhasVendasCadastro'),
    component: Containers.ConfiguracoesCampanhas,
    permissions: (infoUsuario) => InfoUsuario.perm('campanhasVendasCadastro', infoUsuario),
    exact: true,
    secret: true,
  },
  {
    path: '/configuracoes/comunicados',
    parent: '/configuracoes',
    text: 'Comunicados',
    match: /^\/configuracoes\/comunicados/,
    sider: InfoUsuario.perm('comunicadosConfiguracao'),
    component: Containers.ConfiguracoesComunicados,
    permissions: (infoUsuario) => InfoUsuario.perm('comunicadosConfiguracao', infoUsuario),
    exact: true,
    secret: true,
  },
  {
    path: '/configuracoes/telasNomenclaturas',
    component: Containers.TelasNomenclaturas,
    parent: '/configuracoes',
    text: 'Nomenclaturas',
    math: /^\/configuracoes\/telasNomenclaturas/,
    sider: InfoUsuario.perm('telasNomenclaturas'),
    permissions: (infoUsuario) => InfoUsuario.perm('telasNomenclaturas', infoUsuario),
  },
  {
    path: '/configuracoes/report',
    component: Containers.ConfiguracoesReport,
    parent: '/configuracoes',
    text: 'Report Semanal',
    math: /^\/configuracoes\/report/,
    sider: InfoUsuario.perm('gestaoEquipes'),
    permissions: (infoUsuario) => InfoUsuario.perm('gestaoEquipes', infoUsuario)
  },
  {
    path: '/configuracoes/atividades',
    component: Containers.ConfiguracoesAtividades,
    parent: '/configuracoes',
    text: 'Atividades',
    math: /^\/configuracoes\/atividades/,
    permissions: (infoUsuario) => InfoUsuario.perm('atividadesTiposConfiguracoes', infoUsuario) || InfoUsuario.perm('atividadesMetaCadastro', infoUsuario),
  },
  {
    path: '/configuracoes/whitelabels',
    component: Containers.ConfiguracoesWhitelabels,
    parent: '/configuracoes',
    text: 'Whitelabels',
    math: /^\/configuracoes\/whitelabels/,
    sider: true,
    permissions: (infoUsuario) => infoUsuario.senhaMaster
  },
  {
    path: '/configuracoes/mixprodutos',
    component: Containers.ConfiguracoesProdutos,
    parent: '/configuracoes',
    text: 'Mix de Produtos',
    match: /^\/configuracoes\/mixprodutos/,
    sider: InfoUsuario.perm('produtosConfigurar'),
    exact: true,
    permissions: (infoUsuario) => InfoUsuario.perm('produtosConfigurar', infoUsuario),
  },
  {
    path: '/configuracoes/mixprodutos/cadastro',
    component: Containers.ConfiguracoesCamposPersonalizadosCadastro,
    exact: true,
    match: /^\/configuracoes\/mixprodutos\/cadastro/,
    permissions: (infoUsuario) => InfoUsuario.perm('produtosConfigurar', infoUsuario),
  },
  {
    path: '/configuracoes/mixprodutos/:id',
    component: Containers.ConfiguracoesEditarTabela,
    exact: true,
    match: /^\/configuracoes\/mixprodutos\/:id/,
    permissions: (infoUsuario) => InfoUsuario.perm('produtosConfigurar', infoUsuario),
  },
  {
    path: '/configuracoes/whitelabel/:id',
    component: Containers.ConfiguracoesWhitelabel,
    match: /^\/configuracoes\/whitelabel\/:id/,
    exact: true,
    permissions: (infoUsuario) => infoUsuario.senhaMaster
  },
  {
    path: '/configuracoes/pedidos',
    component: Containers.ConfiguracoesPedidos,
    parent: '/configuracoes',
    text: 'Pedidos',
    match: /^\/configuracoes\/pedidos/,
    sider: InfoUsuario.perm('pedidosConfigurar'),
    exact: true,
    permissions: (infoUsuario) => InfoUsuario.perm('pedidosConfigurar', infoUsuario),
  },
  {
    path: '/configuracoes/indicadores',
    parent: '/configuracoes',
    text: 'Indicadores personalizados',
    match: /^\/configuracoes\/indicadores/,
    sider: InfoUsuario.perm('indicadoresPersonalizados'),
    component: Containers.ConfiguracoesIndicadores,
    permissions: (infoUsuario) => InfoUsuario.perm('indicadoresPersonalizados', infoUsuario),
    exact: true,
    secret: true,
  },
  {
    path: '/configuracoes/conteudos',
    parent: '/configuracoes',
    text: 'Conteúdos',
    match: /^\/configuracoes\/conteudos/,
    sider: InfoUsuario.get('senhaMaster'),
    component: Containers.ConfiguracoesConteudos,
    permissions: () => InfoUsuario.get('senhaMaster'),
    exact: true,
    secret: true,
  },
  {
    path: '/configuracoes/checklists',
    parent: '/configuracoes',
    text: 'Checklists',
    match: /^\/configuracoes\/checklists/,
    sider: InfoUsuario.perm('cadastraTemplateChecklist', InfoUsuario.get()) || InfoUsuario.perm('configuraRegrasChecklist', InfoUsuario.get()),
    component: Containers.ConfiguracoesChecklists,
    permissions: (infoUsuario) => InfoUsuario.perm('cadastraTemplateChecklist', InfoUsuario.get()) || InfoUsuario.perm('configuraRegrasChecklist', InfoUsuario.get()),
    exact: true,
    secret: true,
  },
  {
    path: '/whatsapp',
    // parent: '/configuracoes',
    text: 'WhatsApp',
    match: /^\/whatsapp/,
    // sider: InfoUsuario.perm('cadastraTemplateChecklist', InfoUsuario.get()) || InfoUsuario.perm('configuraRegrasChecklist', InfoUsuario.get()),
    component: Containers.WhatsApp,
    // permissions: (infoUsuario) => InfoUsuario.perm('cadastraTemplateChecklist', InfoUsuario.get()) || InfoUsuario.perm('configuraRegrasChecklist', InfoUsuario.get()),
    exact: true,
    secret: true,
  },
  {
    path: '/configuracoes/checklists/cadastro/:id?',
    parent: '/configuracoes/checklists',
    text: 'Cadastro checklists',
    match: /^\/configuracoes\/checklists\/cadastro/,
    sider: InfoUsuario.perm('cadastraTemplateChecklist', InfoUsuario.get()) || InfoUsuario.perm('configuraRegrasChecklist', InfoUsuario.get()),
    component: Containers.ConfiguracoesChecklistsCadastro,
    permissions: (infoUsuario) => InfoUsuario.perm('cadastraTemplateChecklist', InfoUsuario.get()) || InfoUsuario.perm('configuraRegrasChecklist', InfoUsuario.get()),
    exact: true,
    secret: true,
  },
  {
    path: '/configuracoes/perfil_usuarios',
    parent: '/configuracoes',
    text: 'Perfil de Usuários',
    match: /^\/configuracoes\/perfil_usuarios/,
    component: Containers.ConfiguracoesPerfilUsuarios,
    permissions: (infoUsuario) => InfoUsuario.perm('cargosConfiguracao', infoUsuario) || true,
    exact: true,
    secret: true,
  },
  {
    path: '/configuracoes/perfil_usuarios/cadastro/:id?',
    parent: '/configuracoes/perfil_usuarios',
    text: 'Cadastro de Perfil de Usuários',
    match: /^\/configuracoes\/perfil_usuarios\/cadastro/,
    component: Containers.ConfiguracoesPerfilUsuariosCadastro,
    permissions: (infoUsuario) => InfoUsuario.perm('cargosConfiguracao', infoUsuario) || true,
    exact: true,
    secret: true,
  },
  {
    path: '/configuracoes/unidades',
    parent: '/configuracoes',
    text: TelasNomenclaturas.getNomenclatures('UNIDADES')?.plural,
    match: /^\/configuracoes\/unidades/,
    sider: InfoUsuario.perm('unidadesConfiguracoesRegras'),
    component: Containers.ConfiguracoesUnidades,
    permissions: (infoUsuario) => InfoUsuario.perm('unidadesConfiguracoesRegras', infoUsuario),
    exact: true,
    secret: true,
  },
  {
    path: '/access-denied',
    component: Containers.AccessDenied,
  },
  {
    path: '*',
    component: Containers.NotFound,
  },
];

const Routes = () => {
  const routes = useSelector(({ app }) => app.get('routes'));

  return (
    <Router>
      <Switch>
        {routes
          .filter((item) => item.component)
          .map((route, index) => (
            <Route key={route.path || index} {...route} />
          ))}
      </Switch>
    </Router>
  );
};

export default Routes;
