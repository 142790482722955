import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getTicket } from '@ducks/chamadosBriefingReply';
import { View, Text } from '@components';
import { Skeleton, Row } from '@components-teammove';
import Perguntas from './Pergunta';
import {
  HighContrastText,
  Container,
  FlexView,
  BiggerMarginText,
  ModalTitle,
  SpaceBetweenRow,
  Label,
  InfoText,
  FillView,
  FixedText,
  Margin,
  TitleContainer,
  Divider,
  HighContrastTextContent,
  BackIcon,
  ModalQuestionario,
  DividerContainer,
  ContainerInfo
} from './styles';
import { formatDateTime, TelasNomenclaturas, InfoUsuario, getSearchParams } from '@utils';
import { getUnidade } from '@sdk/Unidades';
import PrintProvider, { Print, NoPrint } from 'react-easy-print';
import { STATUS } from '../../rules';

export default function ModalQuestionarioVisualizacao({ visible, onCancel, tipo }) {
  const { id } = useParams();
  const idTicket = getSearchParams('id');

  const idFromTicket = idTicket || id;

  const history = useHistory();
  const dispatch = useDispatch();
  const ticket = useSelector(({ chamadosBriefingReply }) =>
    chamadosBriefingReply.get('ticket')
  );
  const loadingGetTicket = useSelector(({ chamadosBriefingReply }) =>
    chamadosBriefingReply.get('loadingGetTicket')
  );
  const successGetTicket = useSelector(({ chamadosBriefingReply }) =>
    chamadosBriefingReply.get('successGetTicket')
  );

  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  const [perguntas, setPerguntas] = useState();
  const [userReply, setUserReply] = useState();
  const [unidade, setUnidade] = useState();
  const [loadingUnidade, setLoadingUnidade] = useState(true);

  const usuario = InfoUsuario.get();

  const permiteAdministrar = InfoUsuario.perm('chamadosAdministrador', usuario);
  const statusNaoPermiteEditar = [STATUS.CONCLUIDO, STATUS.FECHADO, STATUS.AGUARDANDO_AVALIACAO];
  const permiteEditar = !statusNaoPermiteEditar.includes(ticket?.status);

  useEffect(() => {
    if (idFromTicket) {
      const handleDispatchGetTicket = (id) => {
        dispatch(getTicket(id));
      };
      handleDispatchGetTicket(idFromTicket);
    }
  }, [idFromTicket]);

  useEffect(() => {
    if (successGetTicket) {
      const tipoQuestionario = ticket.questionario.filter(
        (quest) => quest.tipoQuestionario === tipo
      );
      setPerguntas(tipoQuestionario[0].respostas);
      
      ticket.historico.forEach((historicoText) => {
        const { mensagem } = historicoText;
      
        if ((tipo === 'BRIEFING' && mensagem.includes('Briefing concluído em')) ||
            (tipo !== 'BRIEFING' && mensagem.includes('Report concluído em'))) {
          setUserReply(historicoText.nmUsuario);
        }
      });

      (async () => {
        const unidadeTicket = await getUnidade(ticket.idUnidade);
        setUnidade(unidadeTicket);
        setLoadingUnidade(false);
      })();
    }
  }, [successGetTicket]);
  return (
    <ModalQuestionario
      visible={visible}
      width={958}
      title={
        loadingGetTicket ? null : ticket?.questionario?.filter(
          (quest) => quest.tipoQuestionario === tipo
        ) ? (
            <>
              <SpaceBetweenRow>
                <BackIcon
                  type='arrow-left'
                  onClick={onCancel}
                />
                <FillView>
                  <ModalTitle>
                    {
                    ticket?.questionario.filter(
                      (quest) => quest.tipoQuestionario === tipo
                    )[0].questionarioTitulo
                    }
                  </ModalTitle>
                </FillView>
              </SpaceBetweenRow>
              <Row margin='0 0 0 35px'>
                <Text>
                  <Label>Data:&nbsp;</Label>
                  <HighContrastText>
                    {formatDateTime(
                    ticket.questionario.filter(
                      (quest) => quest.tipoQuestionario === tipo
                    )[0]?.dtRealizacao
                    )}
                  </HighContrastText>
                </Text>
                <BiggerMarginText>
                  <Label>Por:&nbsp;</Label>
                  <HighContrastText>
                    {userReply}
                  </HighContrastText>
                </BiggerMarginText>
              </Row>
            </>
          ) : (
            <Skeleton.SearchBar />
          )
      }
      contrastHeader
      numberOfButtons={(permiteAdministrar && permiteEditar && ticket?.status !== 'CANCELADO') ? 2 : 1}
      cancelText='Editar'
      cancelButtonProps={{ 
        loading: loadingGetTicket,
        onClick: () => onCancel(tipo)
      }}
      okText='Imprimir'
      onOk={() => {
        const basePath = history.location.pathname;
        const printPath = idTicket ? `/${idTicket}/printing` : '/printing';
      
        const queryParams = new URLSearchParams({
          id: idFromTicket,
          creationUserName: userReply,
          responsible: userReply,
          companyName: unidade?.name,
          companyShortName: unidade?.identifier,
          loadingCompany: loadingUnidade,
          tipoQuestionario: tipo,
        }).toString();
      
        window.open(`${basePath}${printPath}?${queryParams}`);
      }}
      okButtonProps={{ loading: loadingUnidade }}
      onCancel={onCancel}
    >
      <PrintProvider>
        <NoPrint>
          <Container id='printable'>
            <Print>
              {loadingUnidade ? (
                <>
                  <Skeleton.MinorLine />
                  <Skeleton.SearchBar />
                  <Skeleton.MinorLine />
                  <Skeleton.SearchBar />
                  <Skeleton.MinorLine />
                  <Skeleton.SearchBar />
                </>
              ) : (
                <View>
                  <Row gap='12px'>
                    <ContainerInfo>
                      <TitleContainer>
                        <InfoText>Ticket&nbsp;</InfoText>
                      </TitleContainer>
                      <FlexView>
                        <View>
                          <HighContrastTextContent>
                            {ticket?.protocolo}
                          </HighContrastTextContent>
                          <InfoText>{ticket?.assunto}</InfoText>
                          <HighContrastTextContent>
                            {ticket?.nmCategoriaCompleto}
                          </HighContrastTextContent>
                        </View>
                      </FlexView>
                    </ContainerInfo>
                    <ContainerInfo>
                      <TitleContainer>
                        <InfoText>
                          {companyNomenclature.nomenclatura}
                        &nbsp;
                        </InfoText>
                      </TitleContainer>
                      {!loadingUnidade ? (
                        <FlexView>
                          <View>
                            <HighContrastTextContent>{unidade?.identifier}</HighContrastTextContent>
                            <Row>
                              <InfoText>{unidade?.name}</InfoText>
                            </Row>
                            <HighContrastTextContent>
                              {ticket?.unidadeEndereco}
                            </HighContrastTextContent>
                          </View>
                        </FlexView>
                      ) : (
                        <Skeleton MediumLine/>
                      )}
                    </ContainerInfo>
                  </Row>
                </View>
              )}
              <DividerContainer>
                <Divider />
              </DividerContainer>
              <Margin>
                <FixedText>Informações:</FixedText>
              </Margin>
              <Perguntas perguntas={perguntas} />
            </Print>
          </Container>
        </NoPrint>
      </PrintProvider>
    </ModalQuestionario>
  );
}