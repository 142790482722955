export const getSearchParams = (search) => {
  return new URL(location.href).searchParams.get(search);
};

export const useSearchParams = () => {
  const params = {};
  const entries = new URL(location.href).searchParams.entries();

  for (const entrie of entries) {
    params[entrie[0]] = entrie[1];
  }

  return params;
};

export const getQueryString = (params) => {
  return Object.keys(params)
    .filter((key) => params[key])
    .map((key) => `${key}=${Array.isArray(params[key]) ? params[key].join(' ') : params[key]}`)
    .join('&');
};
