import styled from 'styled-components';
import { Column, Row, Text, Icon } from '@components-teammove';

export const ColumnTitle = styled.h2`
  font-size: 1.2em;
  margin-bottom: 10px;
`;

export const HeaderContainer = styled(Row)`
  width: 100%;
  height: ${({ collapsed }) => collapsed ? '100%' : 'auto'};
  flex-direction: ${({ collapsed }) => collapsed ? 'column' : 'row'};
  align-items: center;
  justify-content: ${({ collapsed }) => collapsed ? 'flex-start' : 'space-between'};
  gap: 16px;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  padding: 16px;
  background-color: ${({ theme }) => theme.darkPrimaryColor};
  border-bottom: ${({ theme, color, collapsed }) => collapsed ? 'none' : '4px solid ' + (color ? color : theme.darkHighlightColor)};
  border-top: ${({ theme, color, collapsed }) => collapsed ? '12px solid ' + (color ? color : theme.darkHighlightColor) : 'none'};
  transition: all 0.3s ease; 
`;

export const ArrowIcon = styled(Icon.Feather)`
  cursor: pointer;
`;

export const TitleHeader = styled(Text)`
  writing-mode: ${({ collapsed }) => collapsed ? 'vertical-rl' : 'horizontal-tb'};
  color: ${({ theme }) => theme.textColor};
  font-size: 18px;
  font-weight: 700;
`;

export const ContainerHeaderInfo = styled(Row)`
  gap: 4px;
  flex-direction: ${({ collapsed }) => collapsed ? 'column' : 'row'};
`;

export const ColumnContainer = styled(Column)`
  width: ${({ collapsed }) => collapsed ? '50px' : '100%'};
  max-width: 400px;
  transition: all 0.3s ease; 
  background-color: ${({ theme }) => theme.darkSecondaryColor};
  border-radius: 12px;
  ${({ collapsed }) => !collapsed && `
    min-height: 500px;
    min-width: 400px;
  `}
`;

export const OverflowContainerColumn = styled.div`
  padding: 10px;
`;

export const NumberOfTickets = styled.div`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.darkHighlightColor};
  padding: 4px 6px;
  color: ${({ theme }) => theme.textColor};
  font-size: 1rem;
  font-weight: 400;
`;