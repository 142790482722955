import React from 'react';
import { CollapsibleCheckboxList } from '@components-teammove';

const ActivityTypes = ({ value, onChange, activities }) => {
  const handleChange = (itemId, type, checked) => {
    let newValue = [...(value || [])];

    if (newValue.length === 0) {
      newValue = activities.map((item) => ({
        id: item.id,
        canView: false,
        canInsert: false,
        fastAction: item.fastAction,
        order: item.order,
      }));
    }

    const existingIndex = newValue.findIndex((item) => item.id === itemId);

    if (existingIndex === -1) {
      const listItem = activities.find((item) => item.id === itemId);
      newValue.push({
        id: itemId,
        canView: type === 'Visualizar' ? checked : false,
        canInsert: type === 'Inserir' ? checked : false,
        fastAction: listItem.fastAction,
        order: listItem.order,
      });
    } else {
      if (type === 'Visualizar') newValue[existingIndex].canView = checked;
      if (type === 'Inserir') newValue[existingIndex].canInsert = checked;
    }

    if (onChange) onChange(newValue);
  };

  const handleSelectAll = (checked) => {
    const newValue = activities.map((item) => ({
      id: item.id,
      canView: checked,
      canInsert: checked,
      fastAction: item.fastAction,
      order: item.order,
    }));
    if (onChange) onChange(newValue);
  };

  const getItemChecked = (itemId, type) => {
    const permissions = value || [];
    const item = permissions.find((permission) => permission.id === itemId);
    if (!item) return false;

    if (type === 'Visualizar') return item.canView;
    if (type === 'Inserir') return item.canInsert;
    return false;
  };

  return (
    <CollapsibleCheckboxList
      listName="Atividades Permitidas"
      checkboxLabels={['Visualizar', 'Inserir']}
      list={activities}
      idField="id"
      value={value || []}
      onChange={handleChange}
      onSelectAll={handleSelectAll}
      getItemChecked={getItemChecked}
      isDisabled={(id) =>
        !activities.find((item) => item.id === id)?.fastAction
      }
      sortItems={(a, b) => a.order - b.order}
    />
  );
};

export default ActivityTypes;
