export const filterRankingBySearch = (search, ranking) => {

  if (!search) return ranking;

  return ranking.filter(
    (item) => (
      item.name?.toLowerCase().includes(search.toLowerCase()) || 
      item.prize?.toLowerCase().includes(search.toLowerCase()) || 
      item.goal?.toString().toLowerCase().includes(search.toLowerCase()) ||
      item.value?.toString().toLowerCase().includes(search.toLowerCase())
    )
  );
};

export const filterRankingToShortList = (user, ranking) => {
  return ranking.filter((item) => user.hierarquia?.includes(item.usuario?.cd_usuario) || item.valueProjectionPrize || item.relatedCampaignsGoalProjectionPrize);
};