import styled from 'styled-components';
import { Input, Text, Icon, Form, Row, Button } from '@components-teammove';

export const TitleText = styled(Text)`
    font-size: 20px;
    font-weight: 600;
    margin-top: 20px;
    line-height: 27.24px;
    color:rgb(0, 0, 0);
`;

export const IconContainer = styled.div`
    width: 62px;
    height: 62px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.darkSecondaryColor};
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${({ borderColor, theme }) => borderColor === 'green' ? 'var(--success-color)' : borderColor === 'red' ? 'var(--danger-color)' : borderColor === 'white' ? theme.textColor : theme.darkPrimaryColor};
    cursor: pointer;
`;

export const IconFeedback = styled(Icon.Feather)`
    font-size: 32px;
    color: ${({ colorIcon, theme }) => colorIcon === 'green' ? 'var(--success-color)' : colorIcon === 'red' ? 'var(--danger-color)' : theme.textColor};
`;

export const TextFeedback = styled(Text)`
    font-size: 12px;
    font-weight: ${({ optionsFeedback }) => optionsFeedback ? 600 : 400};
    line-height: 16.36px;
    color: ${({ theme }) => theme.textColor};
    opacity: ${({ optionsFeedback }) => optionsFeedback ? '1' : '0.5'};
`;

export const FormContainer = styled(Form)`
    margin-top: 8px;
    width: 100%;
`;

export const ObservationBox = styled(Input.TextArea)`
  &.ant-input {
    height: 175px !important;
    max-height: 175px !important;
  }
`;

export const ButtonFooter = styled(Button)`
  width: 100% !important;
`;

export const RowButton = styled(Row)`
  margin-bottom: 20px;
`;

export const SubTitle = styled(Text)`
  font-size: 16px;
  opacity: 0.5;
  font-weight: 400;
  line-height: 21.79px;
  margin-top: ${({ marginSubTitle }) => marginSubTitle ? '20px' : '0'};
`;

export const Title = styled(Text)`
  font-size: 20px;
  font-weight: 600;
  line-height: 27.24px;
  margin-top: ${({ marginTitle }) => marginTitle ? '20px' : '0'};
`;
