import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { formatTime, subtractHours, moment, download, Notification } from '@utils';
import io from 'socket.io-client';
import App from '@app';
import { Body } from '@components';
import { Button, Text, Menu, AudioPlayer, Column, Icon, Overlay, NativeInput, Skeleton, Row, PageHeader } from '@components-teammove';
import { authorization } from '@sdk/api';
import { getHistory, sendMessage, getTemplates, resetHistory, postUpdateChatStatus, resetUpdateChatStatus } from '@ducks/whatsapp';
import {
  ChatHeader, ChatMessages, ChatWrapper, UserName,
  UserProfilePicture, MessageBubbleAttachment, MessageTimeAndStatus,
  MessageTimeText, MessageStatus, ChatInputContainer,
  ChatMessagesContainer, StyledImage, StyledVideo, TextImage,
  AudioMessageContainer, MessageTimeAndStatusAudio, AudioPlayerWrapper,
  MessageTimeAndStatusImage, ModalImage, ModalVideo, CloseButton,
  VideoContainer, PlayButton, StyledSticker, MessageBubbleText, 
  GradientOverlay, ImageContainer, MessageTimeAttachment, UploadFill, 
  UploadIcon, StickerContainer, TextMessage, ChatInputGeneral, 
  StyledButton, DropdownContainer, MessageAlert, AlertIcon, ModalContainer,
  ButtonFinish, ChatTitle, ChatContainer, IconContainer, IconFeedback, ChatInfo
} from './styles';
import Classification from '@containers/WhatsApp/Classification';

const { REACT_APP_API } = window.env;
const { REACT_APP_API_NODE } = window.env;

function WhatsApp() {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const messagesEndRef = useRef(null);
  const location = useLocation();

  const infoUsuario = useSelector(({ login }) => login.get('infoUsuario'));
  const theme = useSelector(({ app }) => app.get('theme'));
  const { chats = [], sessionActive, hasBalance, activity = {}, lastChat } = useSelector(({ whatsapp }) => whatsapp.get('chats') || {});
  const messages = chats.flatMap((chat) => chat.messages || []);
  const loading = useSelector(({ whatsapp }) => whatsapp.get('loading'));
  const successGetWhatsApp = useSelector(({ whatsapp }) => whatsapp.get('success'));
  const { activityId, finishActivity } = activity || {};
  const templates = useSelector(({ whatsapp }) => whatsapp.get('templates'));
  const successFinishActivityChat = useSelector(({ whatsapp }) => whatsapp.get('successFinishActivityChat'));
  const updateChatStatus = useSelector(({ whatsapp }) => whatsapp.get('updateChatStatus'));
  const loadingUpdateChatStatus = useSelector(({ whatsapp }) => whatsapp.get('loadingUpdateChatStatus'));

  const [templateSid, setTemplateSid] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [showClearButton, setShowClearButton] = useState(false);
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [allMessages, setAllMessages] = useState(messages);
  const [sessionActiveTrigger, setSessionActiveTrigger] = useState(sessionActive || true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isActivityFinished, setIsActivityFinished] = useState(finishActivity);

  const { whatsAppInfo } = location.state || {};
  const statusOrder = {
    queued: 1,
    sent: 2,
    undelivered: 3,
    delivered: 4,
    read: 5,
  };

  const options = [
    { id: 'Relevante', label: 'Relevante', borderColor: 'green', iconColor: 'green', iconType: 'thumbs-up' },
    { id: 'Nao_Relevante', label: 'Não relevante', borderColor: 'red', iconColor: 'red', iconType: 'thumbs-down' },
    { id: 'Sem_Contato', label: 'Sem contato', borderColor: 'white', iconType: 'user-x' }
  ];

  useEffect(() => {
    const socket = setupSocketConnection(infoUsuario.cdUsuario, whatsAppInfo.idContact);
    dispatch(getHistory(infoUsuario.cdUsuario, whatsAppInfo.idContact, 40));
    dispatch(getTemplates());

    return () => {
      socket.disconnect();
      socket.off('message');
      setAllMessages([]);
      dispatch(resetHistory());
      dispatch(resetUpdateChatStatus());
    };
  }, []);

  useEffect(() => {
    if (!successGetWhatsApp) return;

    if (hasBalance) {
      setIsInputDisabled(false);
    } else { 
      Notification.info('Você não possui saldo suficiente para enviar mensagens no WhatsApp');
      setIsInputDisabled(true);
    }

    setAllMessages(messages);
    setSessionActiveTrigger(sessionActive);
  }, [successGetWhatsApp]);

  useEffect(() => {
    if (messages.length > 0) {
      // setRealtimeMessages((prevMessages) => [...messages, ...prevMessages]);
    }
  }, [messages]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }}, [allMessages]);

  const enableSendButtonByTemplate = useMemo(() => {
    return templateSid.length > 0;
  }, [templateSid]);

  useEffect(() => {
    setIsActivityFinished(finishActivity);
  }, [finishActivity]);

  useEffect(() => {
    if (!successFinishActivityChat) return;
    setIsModalOpen(false);
  }, [successFinishActivityChat]);

  const onUpdateChatStatus = () => {
    if (!lastChat.chatId) return;
    dispatch(postUpdateChatStatus({ chatId: lastChat.chatId, userId: infoUsuario.cdUsuario }));
  };

  const messageAlert = () => {
    if (!hasBalance) {
      return 'Recarregue seu saldo para enviar mensagens';
    }

    return 'Use um template para inciar a conversa';
  };

  const setupSocketConnection = (userId, contactId) => {
    const socket = io(REACT_APP_API_NODE);
  
    socket.on('connect', () => {
      socket.emit('register', { userId, contactId });
    });
  
    socket.on('activityUpdate', (data) => {
      setIsActivityFinished(data.finishActivity);
    });

    socket.on('updateMessageStatus', (updatedStatus) => {
      if (!updatedStatus) return;
    
      setAllMessages((prevMessages) => {
        return prevMessages.map((msg) => {
          if (msg.sid !== updatedStatus.sid) return msg;
    
          const currentStatusOrder = statusOrder[msg.receiptStatus] || 0;
          const newStatusOrder = statusOrder[updatedStatus.receiptStatus] || 0;
    
          if (newStatusOrder > currentStatusOrder) {
            return { ...msg, receiptStatus: updatedStatus.receiptStatus };
          }
    
          return msg;
        });
      });
    });
  
    socket.on('newMessage', (message) => {
      if (!message) return;
      setSessionActiveTrigger(true);
      setAllMessages((prevMessages) => {
        const combinedMessages = [...messages, ...prevMessages];
        const existingMessageIndex = combinedMessages.findIndex((msg) => msg.sid === message.sid);
    
        if (existingMessageIndex !== -1) {
          const existingMessage = combinedMessages[existingMessageIndex];
          const currentStatusOrder = statusOrder[existingMessage.receiptStatus] || 0;
          const newStatusOrder = statusOrder[message.receiptStatus] || 0;
    
          if (newStatusOrder > currentStatusOrder) {
            return combinedMessages.map((msg, index) =>
              index === existingMessageIndex
                ? {
                  ...msg,
                  receiptStatus: message.receiptStatus,
                  attachments: message.attachments || msg.attachments,
                }
                : msg
            );
          }
    
          return combinedMessages;
        }
    
        return [...prevMessages, message];
      });
    });
    
    socket.on('disconnect', () => {
      socket.emit('disconnecting-info', { userId, contactId });
    });
  
    return socket;
  };

  const handleSendMessage = (attachment) => {
    const { value } = inputRef.current;
    if (value.trim() || attachment) {
      const messageBody = {
        userId: infoUsuario.cdUsuario,
        contactId: whatsAppInfo.idContact,
        contactNumber: formatContactNumber(whatsAppInfo.numberContact),
        content: value.trim(),
        templateSid: templateSid || undefined,
        nameContact: whatsAppInfo.nameContact,
        mediaUrl: attachment,
        userName: infoUsuario.nmUsuario,
        idCompany: whatsAppInfo.idCompany,
        mustCreateActivity: true
      };

      dispatch(sendMessage(messageBody));
      inputRef.current.value = '';
      setTemplateSid('');
      handleInputKeyUp();
      enableChatInput();
      setShowClearButton(false);
      resizeChatInput();
    }
  };

  const enableChatInput = () => {
    setIsInputDisabled(false);
  };

  const removeText = () => {
    inputRef.current.value = '';
    setTemplateSid('');
    setShowClearButton(false);
    enableChatInput();
    resizeChatInput();
  };

  const handleInputKeyUp = () => {
    const hasText = inputRef.current?.value.trim().length > 0 || templateSid.length > 0;
    setShowClearButton(hasText);
  };

  const formatContactNumber = (number) => {
    const cleanedNumber = number.replace(/\D/g, '');
    let formattedNumber = cleanedNumber.startsWith('55') ? cleanedNumber.slice(2) : cleanedNumber;
    formattedNumber = formattedNumber.startsWith('489') ? formattedNumber.replace(/^489/, '48') : formattedNumber;
    
    return `whatsapp:+55${formattedNumber}`;
  };

  const isChatDisabled = () => {
    return updateChatStatus?.status === 'FINALIZADO' || 
           lastChat?.chatStatus === 'FINALIZADO' || 
           !(lastChat?.userResponsible === infoUsuario.cdUsuario || lastChat?.userResponsible === '');
  };
  
  const isSendMessageDisabled = () => {
    return (!hasBalance || (!sessionActiveTrigger && !enableSendButtonByTemplate) || 
            lastChat?.userResponsible === '' || 
            lastChat?.userResponsible !== infoUsuario.cdUsuario) && 
            !updateChatStatus?.status;
  };
  
  const isActivityFinishDisabled = () => {
    return isActivityFinished || 
           lastChat?.activityId === '' || 
           !(lastChat?.userResponsible === infoUsuario.cdUsuario);
  };

  const getChatStatusText = () => {
    if (updateChatStatus?.status) {
      return updateChatStatus.status === 'INICIADO' ? 'Finalizar atendimento' : 'Iniciar atendimento';
    }
    return lastChat?.chatStatus === 'INICIADO' ? 'Finalizar atendimento' : 'Iniciar atendimento';
  };

  const renderStatusIcon = (status) => {
    switch (status) {
      case 'queued':
        return 'clock-time-eight-outline';
      case 'sent':
        return 'check';
      case 'delivered':
      case 'read':
        return 'check-all';
      default:
        return null;
    }
  };

  const resizeChatInput = () => {
    if (inputRef.current) {
      inputRef.current.style.height = 'auto';
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const handleTemplateSelect = (templateBody, sid) => {
    inputRef.current.value = templateBody;
    setTemplateSid(sid);
    
    setIsInputDisabled(true);
    handleInputKeyUp();
    resizeChatInput();
  };
  
  const handleImageClick = (url) => {
    setSelectedImage(url);
  };

  const handleVideoClick = (url) => {
    setSelectedVideo(url);
    setSelectedImage(null);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setSelectedVideo(null);
  };

  const handleUploadChange = ({ file }) => {
    switch (file.status) {
      case 'uploading': {
        break;
      }
      case 'error': {
        Notification.error('Erro ao fazer upload. ' + file.error);
        break;
      }
      case 'done': {
        handleSendMessage(file.response);
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleDownloadPDF = (urlPdf, fileName) => {
    download(urlPdf,`${fileName}.pdf`); 
  };

  const messagesByChat = allMessages.reduce((acc, message) => {
    if (!acc[message.chatId]) {
      acc[message.chatId] = [];
    }
    acc[message.chatId].push(message);
    return acc;
  }, {});  
  
  const menu = (
    <Menu>
      {templates.map((template) => (
        <Menu.Item
          key={template.sid}
          onClick={() => handleTemplateSelect(template.types['twilio/text'].body, template.sid)}
        >
          {template.types['twilio/text'].body}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader title="Whatsapp" onBack={() => window.history.back()}>
          <Row gap="20px">
            <ChatWrapper>
              <ChatHeader>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <UserProfilePicture src="https://placehold.co/50" alt="User" />
                  <UserName>{whatsAppInfo?.nameContact}</UserName>
                </div>   
                <DropdownContainer disabled={!hasBalance} overlay={menu} trigger={['click']}>
                  <Text>Templates</Text>
                </DropdownContainer>
                {isModalOpen && (
                  <>
                    <Overlay onClick={() => setIsModalOpen(false)} />
                    <ModalContainer>
                      <Classification
                        info={{ name: whatsAppInfo.nameContact, number: whatsAppInfo.numberContact }}
                        classification={null}
                        activityId={activityId}
                        userId={infoUsuario.cdUsuario}
                      />
                    </ModalContainer>
                  </>
                )}
              </ChatHeader>
              <ChatMessagesContainer
                headers={authorization()}
                URI={REACT_APP_API}
                folder={`Whatsapp/${infoUsuario.cdCliente}/${whatsAppInfo.phoneUser}/${whatsAppInfo.numberContact}`}
                onDownload={(file) => download(file.response)}
                showUploadList={false}
                onChange={(info) => handleUploadChange(info)}
                accept="image/*,video/*,.pdf"
              >
                {loading ? <Skeleton.Chat/> : successGetWhatsApp && (
                  <div>
                    {Object.entries(messagesByChat).map(([chatId, messages]) => {
                      const chat = chats.find((chat) => chat.id === Number(chatId));
                      return (
                        <ChatContainer key={chatId}>
                          <ChatInfo>
                            <ChatTitle>
                              {chat && chat.createdAt ? new Date(chat.createdAt).toLocaleString(undefined, { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' }) : 'Atividade atual'}</ChatTitle>
                            {chat?.classification && (
                              <Column gap="7px" justify="center" align="center">
                                <IconContainer
                                  borderColor={options.find((option) => option.id === chat.classification)?.borderColor}
                                >
                                  <IconFeedback
                                    colorIcon={options.find((option) => option.id === chat.classification)?.iconColor}
                                    type={options.find((option) => option.id === chat.classification)?.iconType}
                                  />
                                </IconContainer>
                              </Column>
                            )}
                          </ChatInfo>
                          <ChatMessages>
                            {messages.map((message) => {
                              const isAudioMessage =
                            message.attachments &&
                            message.attachments.some((attachment) => attachment.type === 'audio');

                              if (isAudioMessage) {
                                const audioAttachment = message.attachments.find(
                                  (attachment) => attachment.type === 'audio'
                                );

                                return (
                                  <AudioMessageContainer key={message.id}>
                                    <AudioPlayerWrapper>
                                      <AudioPlayer
                                        showOptions={false}
                                        widthContainer="100%"
                                        src={audioAttachment.url}
                                        downloadFileName={`Audio - ${moment().format('DD-MM-YYYY HH:mm:ss')}.mp3`}
                                      />
                                      <MessageTimeAndStatusAudio>
                                        <MessageTimeAttachment>
                                          {subtractHours(formatTime(message.createdAt), 3)}
                                        </MessageTimeAttachment>
                                        {message.direction === 'outbound-api' && (
                                          <MessageStatus
                                            status={message.receiptStatus}
                                            type={renderStatusIcon(message.receiptStatus)}
                                          />
                                        )}
                                      </MessageTimeAndStatusAudio>
                                    </AudioPlayerWrapper>
                                  </AudioMessageContainer>
                                );
                              }

                              const hasAttachments = message.attachments && message.attachments.length > 0;
                              const hasAttachmentAndContent = hasAttachments && message.content;

                              if (hasAttachments) {
                                return (
                                  <MessageBubbleAttachment
                                    key={message.id}
                                    isSender={message.direction === 'outbound-api'}
                                    isImage={hasAttachments}
                                    attachmentAndContent={hasAttachmentAndContent}
                                  >
                                    {message.attachments.map((attachment) => {
                                      const { type, url, contentType } = attachment;
                                      if (type === 'sticker') {
                                        return (
                                          <StickerContainer key={attachment.id} onClick={() => handleImageClick(url)}>  
                                            <StyledSticker key={attachment.id} src={url} alt="Figurinha" />
                                            <GradientOverlay content={message.content} />
                                          </StickerContainer>
                                        );
                                      }
                                      if (type === 'image') {
                                        return (
                                          <ImageContainer key={attachment.id} onClick={() => handleImageClick(url)}>
                                            <StyledImage src={url} alt="Imagem anexada" />
                                            <GradientOverlay content={message.content} />
                                          </ImageContainer>
                                        );
                                      }
                                      if (type === 'video') {
                                        return (
                                          <VideoContainer key={attachment.id}>
                                            <StyledVideo onClick={() => handleVideoClick(url)}>
                                              <source src={url}/>
                                              <track kind="captions" srcLang="pt" label="Portuguese captions" />
                          Seu navegador não suporta o elemento de vídeo.
                                            </StyledVideo>
                                            <PlayButton onClick={() => handleVideoClick(url) }>
                                              <Icon.MDI type='play' size='2.5rem'/>
                                            </PlayButton>
                                            <GradientOverlay content={message.content} />
                                          </VideoContainer>
                                        );
                                      }
                                      if (contentType === 'application/pdf' || contentType === 'document') {
                                        return (
                                          <Column key={attachment.id} gap='12px' align='center' justify='center' height='50px' width='200px' onClick={() => handleDownloadPDF(url, message.content || 'File')}  >
                                            <Icon.MDI type='file-pdf-box' size='5.5rem'/>
                                          </Column>
                                        );
                                      }
                                      return null;
                                    })}

                                    {message.content && <TextImage>{message.content}</TextImage>}

                                    <MessageTimeAndStatusImage hasAttachmentAndContent={!!message.content}>
                                      <MessageTimeAttachment>
                                        {subtractHours(formatTime(message.createdAt), 3)}
                                      </MessageTimeAttachment>
                                      {message.direction === 'outbound-api' && (
                                        <MessageStatus
                                          status={message.receiptStatus}
                                          type={renderStatusIcon(message.receiptStatus)}
                                        />
                                      )}
                                    </MessageTimeAndStatusImage>
                                  </MessageBubbleAttachment>
                                );
                              }

                              return (
                                <MessageBubbleText key={message.id} isSender={message.direction === 'outbound-api'} >
                                  <TextMessage whiteSpace="pre-line">
                                    {message.content}
                                  </TextMessage>
                                  <MessageTimeAndStatus>
                                    <MessageTimeText isSender={message.direction === 'outbound-api'}>
                                      {subtractHours(formatTime(message.createdAt), 3)}
                                    </MessageTimeText>
                                    {message.direction === 'outbound-api' && (
                                      <MessageStatus
                                        status={message.receiptStatus}
                                        type={renderStatusIcon(message.receiptStatus)}
                                      />
                                    )}
                                  </MessageTimeAndStatus>
                                </MessageBubbleText>
                              );
                            })}
                            <div ref={messagesEndRef} />
                            {chatId === Object.keys(messagesByChat).pop() && (!hasBalance || !sessionActiveTrigger) && (
                              <MessageAlert>
                                <AlertIcon type="alert-triangle" />
                                <p>{messageAlert()}</p>
                              </MessageAlert>
                            )}
                          </ChatMessages>
                        </ChatContainer>
                      );
                    })}
                  </div>
                )}
              </ChatMessagesContainer>
              <ChatInputContainer>
                <UploadFill
                  headers={authorization()}
                  URI={REACT_APP_API}
                  folder={`Whatsapp/${infoUsuario.cdCliente}/${whatsAppInfo.phoneUser}/${whatsAppInfo.numberContact}`}
                  onDownload={(file) => download(file.response)}
                  showUploadList={false}
                  onChange={(info) => handleUploadChange(info)}
                  accept="image/*,video/*,.pdf"
                  disabled={!hasBalance || !sessionActiveTrigger}
                >
                  <UploadIcon type="plus" />
                </UploadFill>
                <ChatInputGeneral>
                  <NativeInput
                    placeholder='Digite uma mensagem'
                    rows="1"
                    ref={inputRef}
                    onKeyUp={handleInputKeyUp}
                    onKeyDown={handleKeyDown}
                    resizeChatInput={resizeChatInput}
                    autoSize={{ minRows: 1, maxRows: 3 }}
                    disabled={!sessionActiveTrigger || isInputDisabled}
                  />
                  {showClearButton && (
                    <StyledButton 
                      type="x"
                      onClick={removeText}
                    />
                  )}
                </ChatInputGeneral>
                <Button type="primary" onClick={() => handleSendMessage(null)} disabled={isSendMessageDisabled()}>Enviar</Button>
              </ChatInputContainer>
            </ChatWrapper>
            {(selectedImage || selectedVideo) && (
              <Overlay onClick={closeModal} >
                {selectedImage && <ModalImage src={selectedImage} alt="Imagem Selecionada" />}
                {selectedVideo && (
                  <ModalVideo
                    onClick={(event) => { 
                      event.stopPropagation();
                    }}
                    controls 
                    autoPlay
                  >
                    <source src={selectedVideo} />
                      Seu navegador não suporta o elemento de vídeo.
                  </ModalVideo>
                )}
                <CloseButton onClick={closeModal}>Fechar</CloseButton>
              </Overlay>
            )}
            <ButtonFinish disabled={isActivityFinishDisabled()} onClick={() => setIsModalOpen(true)}>Finalizar atividade</ButtonFinish>
            {loading ? (
              <Row justify='center' align='center' height='100%' margin='20px 0 0 0'>
                <Skeleton.ExtraButton/>
              </Row>
            ) : (<ButtonFinish loading={loadingUpdateChatStatus} disabled={isChatDisabled()} onClick={() => onUpdateChatStatus()}>{getChatStatusText()}</ButtonFinish>)}
          </Row>
        </PageHeader>
      </Body>
    </App>
  );      
}

export default WhatsApp;
