import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import App from '@app';
import { Body, PageHeader, Breadcrumb, Text, View, Icon } from '@components-teammove';
import { Spin } from '@components';
import { formatTimeWithSeconds } from '@utils';
import { getConteudo } from '@ducks/wikis';
import { apiPostNewAudiencia } from '@ducks/wikisAcoes';
import { getListaProdutos } from '@ducks/wikisPersonalizacao';
import { changeVisibleAudiencia, changeVisibleConfirmacoes, changeVisibleInformacoes, changeVisibleNewFeedback } from '@ducks/comunicados';
import WikiBreadcrumb from '../../Wikis/Conteudos/Breadcrumb';
import WikiEstouCiente from '../../Wikis/Conteudos/EstouCiente';
import VisualizacaoImagem from './Imagem';
import VisualizacaoPDF from './PDF';
import VisualizacaoHtml from '../Item/InnerLevelItem/Html';
import VisualizacaoVideo from './Video';
import VisualizacaoLink from './Link';
import VisualizacaoArquivo from './Arquivo';
import VisualizacaoOpcoes from './Opcoes';
import VisualizacaoQuiz from './Quiz';
import { getListaPersonalizacaoBloqueio } from '@sdk/Wikis/Personalizacao';
import { checkBlockByListProduct } from './rules';
import WikiAudiencias from '../Item/InnerLevelItem/Audiencias';
import ConfirmacoesLeitura from '../Item/InnerLevelItem/ConfirmacoesLeitura';
import Informacoes from '../Item/InnerLevelItem/Informacoes';
import NewFeedback from '../Item/InnerLevelItem/NewFeedback';

export default function WikiVisualizacao() {
  const [breadcrumb, setBreadcrumb] = useState({});
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const theme = useSelector(({ app }) => app.get('theme'));
  const conteudo = useSelector(({ wikis }) => wikis.get('conteudo'));
  const loadingConteudo = useSelector(({ wikis }) => wikis.get('loadingConteudo'));
  const infoUsuario = useSelector((state) => state.login.get('infoUsuario'));

  const listProduct = useSelector(({ wikisPersonalizacao }) => wikisPersonalizacao.get('listaProdutos'));
  const successSaveValuesListProduct = useSelector(({ wikisPersonalizacao }) => wikisPersonalizacao.get('successSaveValuesListProduct'));

  const visibleAudiencia = useSelector(({ comunicados }) => comunicados.get('visibleAudiencia'));
  const visibleConfirmacoes = useSelector(({ comunicados }) => comunicados.get('visibleConfirmacoes'));
  const visibleInformacoes = useSelector(({ comunicados }) => comunicados.get('visibleInformacoes'));
  const visibleNewFeedback = useSelector(({ comunicados }) => comunicados.get('visibleNewFeedback'));
  const audienciaParams = useSelector(({ comunicados }) => comunicados.get('audienciaParams'));
  const confirmacoesParams = useSelector(({ comunicados }) => comunicados.get('confirmacoesParams'));
  const informacoesParams = useSelector(({ comunicados }) => comunicados.get('informacoesParams'));
  const newFeedbackParams = useSelector(({ comunicados }) => comunicados.get('newFeedbackParams'));

  const [blockCustomization, setBlockCustomization] = useState(false);
  const [loadingBlockCustomization, setLoadingBlockCustomization] = useState(false);

  const inicio = new Date();

  const criarAudiencia = () => {
    if (conteudo.get('tipo') === 'GRUPO') return;

    apiPostNewAudiencia({
      idCategoria: id,
      horaInicio: formatTimeWithSeconds(inicio),
      horaFim: formatTimeWithSeconds(new Date()),
    });
  };

  const onUnload = (e) => {
    e.preventDefault();
    criarAudiencia();
  };

  useEffect(() => {
    window.addEventListener('beforeunload', onUnload);
    dispatch(getConteudo(id));

    return () => {
      criarAudiencia();
      window.removeEventListener('beforeunload', onUnload);
    };
  }, [id]);

  useEffect(() => {
    if (conteudo.get('tipo') === 'GRUPO') {
      history.push(`/comunicados?id=${id}`);
    } else {
      if (conteudo.get('parametrosImagem')) {
        const parametros = getObjectFromString(conteudo.get('parametrosImagem'));
        if (parametros?.textos?.some((item) => item.type === 'LP' && item.productList > 0 && item.productItemList > 0)) {
          //Search list products
          const items = [...new Set(parametros.textos.map((item) => item.productItemList))].toString().replaceAll(',', '+');
          handleCheckBloqueioByApi(items);
          dispatch(getListaProdutos(items));
        }
      }
    }

    setBreadcrumb(WikiBreadcrumb(conteudo.get('path'), id, true));
  }, [conteudo]);

  useEffect(() => {
    if (successSaveValuesListProduct) {
      handleCheckBloqueioByListProduct();
    }
  }, [successSaveValuesListProduct]);

  const handleCheckBloqueioByListProduct = () => {
    setBlockCustomization(true);
    setLoadingBlockCustomization(true);
    setBlockCustomization(checkBlockByListProduct(listProduct));
    setLoadingBlockCustomization(false);
  };

  const handleCheckBloqueioByApi = (items) => {
    setBlockCustomization(true);
    setLoadingBlockCustomization(true);
    getListaPersonalizacaoBloqueio(
      items, 
      infoUsuario.cdUsuario
    )
      .then((res) => {
        setBlockCustomization(!res);
        setLoadingBlockCustomization(false);
      })
      .catch((err) => {
        setLoadingBlockCustomization(false);
      });
  };

  const getObjectFromString = (string) => {
    try {
      return JSON.parse(string);
    } catch (err) {
      return null;
    }
  };

  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title={conteudo.get('titulo')}
          breadcrumb={(
            <Breadcrumb 
              items={id && id !== '-1' && breadcrumb ? [
                { path: '/', name: 'Home' }, 
                ...breadcrumb.routes?.map((route) => ({ ...route, name: route.breadcrumbName })) || [],
                { path: `/comunicados/${id}`, name: conteudo.get('titulo'), current: true }
              ].filter((item) => item) : [
                { path: '/', name: 'Home' }, 
                { path: '/comunicados', name: 'Comunicados', current: true }
              ]}
            />
          )}
          extra={<VisualizacaoOpcoes item={conteudo} />}
          onBack={() => window.history.back()}
        >
          {loadingConteudo ? (
            <View alignItems='center'>
              <Spin />
            </View>
          ) : (
            <>
              <WikiEstouCiente item={conteudo} />
              <Visualizacao conteudo={conteudo} blockCustomization={blockCustomization} loadingBlockCustomization={loadingBlockCustomization} />
            </>
          )}
        </PageHeader>

        {visibleAudiencia && (
          <WikiAudiencias
            visible={visibleAudiencia}
            onCancel={() => dispatch(changeVisibleAudiencia(false))}
            ds={audienciaParams.titulo}
            id={audienciaParams.id}
          />
        )}

        {visibleConfirmacoes && (
          <ConfirmacoesLeitura
            visible={visibleConfirmacoes}
            onCancel={() => dispatch(changeVisibleConfirmacoes(false))}
            ds={confirmacoesParams.titulo}
            id={confirmacoesParams.id}
          />
        )}

        {visibleInformacoes && (
          <Informacoes
            visible={visibleInformacoes}
            onCancel={() => dispatch(changeVisibleInformacoes(false))}
            conteudo={informacoesParams.item}
          />
        )}

        {visibleNewFeedback && (
          <NewFeedback
            visible={visibleNewFeedback}
            onCancel={() => dispatch(changeVisibleNewFeedback(false))}
            feedbackGivenCallback={newFeedbackParams.callback}
            id={newFeedbackParams.id}
          />
        )}
      </Body>
    </App>
  );
}

function Visualizacao({ conteudo, origin = 'conteudos', ...props }) {
  const tipo = conteudo.get('tipo');
  const conteudoText = conteudo.get('conteudo');

  const videoContains = ['youtube', 'vimeo'];

  if(!conteudoText) {
    return (
      <Text>
        Comunicado sem conteúdo <Icon.FrownOutlined />
      </Text>
    );
  }

  switch (tipo) {
    case 'IMAGEM':
      return <VisualizacaoImagem conteudo={conteudo} {...props} />;
    case 'PDF':
      return <VisualizacaoPDF conteudo={conteudo} {...props} />;
    case 'HTML':
      return <VisualizacaoHtml conteudo={conteudo} />;
    case 'VIDEO':
      return <VisualizacaoVideo conteudo={conteudo} autoplay={origin !== 'ead'} {...props} />;
    case 'LINK':
      if (videoContains.some((value) => conteudoText.includes(value))) {
        return <VisualizacaoVideo conteudo={conteudo} autoplay={origin !== 'ead'} {...props} />;
      }
      return <VisualizacaoLink conteudo={conteudo} />;
    case 'ARQUIVO':
      switch (conteudo.get('arquivoTipo')) {
        case 'IMAGEM':
          return <VisualizacaoImagem conteudo={conteudo} />;
        case 'PDF':
          return <VisualizacaoPDF conteudo={conteudo} {...props} />;
        default:
          return <VisualizacaoArquivo conteudo={conteudo} />;
      }
    case 'QUIZ':
      return <VisualizacaoQuiz conteudo={conteudo} {...props} />;
    default:
      return (
        <Text>
          Ainda não é possível renderizar esse tipo de conteúdo <Icon.FrownOutlined />
        </Text>
      );
  }
}

export { Visualizacao };
