import React, { useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import App from '@app';
import {
  Body,
  PageHeader,
  Breadcrumb,
  Button,
  Row,
  Column,
  Text,
  Skeleton,
} from '@components-teammove';
import { InfoUsuario } from '@utils';
import { OrganogramContainer } from './styles';
import {
  getProfileHierarchy,
  updateNodeParentProfile,
  resetProfileHierarchy,
} from '../../../store/ducks/configuracoesUsuariosPerfis';
import Organogram from '../../../ui-teammove/Organogram';

const selectProfileState = (state) => ({
  hierarchyProfiles: state.configuracoesUsuariosPerfis.get('hierarchyProfiles'),
  independentProfiles: state.configuracoesUsuariosPerfis.get(
    'independentProfiles'
  ),
  loading: state.configuracoesUsuariosPerfis.get('loadingProfileHierarchy'),
  theme: state.app.get('theme'),
  error: state.configuracoesUsuariosPerfis.get('error'),
});

export default function ConfiguracoesPerfilUsuarios() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { hierarchyProfiles, independentProfiles, loading, theme, error } =
    useSelector(selectProfileState);

  const permissions = useMemo(
    () => ({
      InserirEditar:
        InfoUsuario.perm('inserirEditarPerfil', InfoUsuario.get()) || true,
      Visualizar:
        InfoUsuario.perm('visualizarPerfil', InfoUsuario.get()) || true,
    }),
    []
  );

  const handleNodeClick = useCallback(
    (nodeId) => {
      if (nodeId === 'company') return;

      history.push('/configuracoes/perfil_usuarios/cadastro?perfil=' + nodeId);
    },
    [history, hierarchyProfiles, independentProfiles, dispatch]
  );

  const handleUpdateNodeParent = (draggedId, targetId) => {
    dispatch(updateNodeParentProfile(draggedId, targetId));
    dispatch(getProfileHierarchy());
  };

  const handleNewProfile = useCallback(() => {
    history.push('/configuracoes/perfil_usuarios/cadastro');
  }, [history]);

  const addExpandedProperty = useCallback((data) => {
    if (Array.isArray(data)) {
      return data.map((node) => addExpandedProperty(node));
    }

    const node = { ...data };
    if (node.children?.length > 0) {
      node.isExpanded = true;
      node.children = node.children.map((child) => addExpandedProperty(child));
    }
    return node;
  }, []);

  const hierarchyData = useMemo(() => {
    if (!hierarchyProfiles) return null;

    return {
      id: 'company',
      name: 'Sua Empresa',
      isExpanded: true,
      children: [...hierarchyProfiles.map(addExpandedProperty)],
      color: theme.darkPrimaryColor,
      independentProfiles,
    };
  }, [
    hierarchyProfiles,
    independentProfiles,
    theme.darkPrimaryColor,
    addExpandedProperty,
  ]);

  useEffect(() => {
    dispatch(getProfileHierarchy());

    return () => {
      dispatch(resetProfileHierarchy());
    };
  }, [dispatch]);

  return (
    <App bgColor={theme['@global-background-color']} hideFooter>
      <Body fixed>
        <PageHeader
          title="Perfil de Usuários"
          extra={[
            <Row key="controls" gap="12px" align="center">
              {permissions.InserirEditar && (
                <Button
                  type="primary"
                  size="small"
                  context="header"
                  onClick={handleNewProfile}
                >
                  Novo Perfil
                </Button>
              )}
            </Row>,
          ]}
          breadcrumb={
            <Breadcrumb
              items={[
                { path: '/', name: 'Home' },
                { path: '/configuracoes', name: 'Configurações' },
                {
                  path: '/configuracoes/perfil_usuarios',
                  name: 'Perfil de Usuários',
                  current: true,
                },
              ]}
            />
          }
          onBack={() => window.history.back()}
        />
        <OrganogramContainer>
          {error && (
            <Column
              width="100%"
              height="100%"
              align="center"
              justify="center"
              gap="16px"
            >
              <Text size="20px" weight="400">
                Erro ao carregar os perfis. Por favor, tente novamente.
              </Text>
              <Button onClick={() => dispatch(getProfileHierarchy())}>
                Tentar Novamente
              </Button>
            </Column>
          )}
          {loading ? (
            <Column
              width="100%"
              height="100%"
              align="center"
              justify="center"
              gap="16px"
            >
              <Skeleton height="100%" />
            </Column>
          ) : (
            hierarchyData && (
              <Organogram
                data={hierarchyData}
                onNodeClick={handleNodeClick}
                onUpdateNodeParent={handleUpdateNodeParent}
              />
            )
          )}
        </OrganogramContainer>
      </Body>
    </App>
  );
}
