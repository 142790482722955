import React, { useEffect, useState } from 'react';
import { CloseIcon, ContainerStyles, DetailsStyled, DropdownStyles, LoadingIcon, StyledSelectComponent } from './styles';
import { Icon, Spin } from '@components-teammove';
import { selectFirstOption } from './rules';
import { Select } from 'antd';
import PropTypes from 'prop-types';

const StyledSelect = ({ action, onChange, children, disabled, onRenderOption, optionLabelProp, defaultActiveFirstOption, form, name, loading, filterOptions, ...otherProps }) => {
  const uniqueId = Date.now();
  const [loadingData, setLoadingData] = useState((children) ? false : true);
  const [error, setError] = useState();
  const [options, setOptions] = useState(children || []);

  useEffect(() => {
    const fetchData = async () => {
      return action().then((res) => {
        setOptions(res);

        if (defaultActiveFirstOption) {
          selectFirstOption(res, form, name);
        }
      }).catch((err) => {
        Notification.error(`Erro ao carregar ${name}`);
        setError(err);
      }).finally(() => setLoadingData(false));
    };

    if ((action) && (!children)) {
      setLoadingData(true);
      fetchData();
    } else {
      setLoadingData(false);
    }
  }, []);

  const renderOption = (item) => {
    if (onRenderOption) {
      if (!optionLabelProp) console.warn('optionLabelProp not found for', options);
      return onRenderOption(item);
    }
    return <>
      {item.details ? <><DetailsStyled dangerouslySetInnerHTML={{ __html: item.details }} /></> : item.title || item.value}
    </>;
  };

  return (
    <ContainerStyles id={uniqueId}>
      <StyledSelectComponent
        bordered={false}
        dropdownRender={(menu) => (
          <DropdownStyles>
            {menu}
          </DropdownStyles>
        )}
        allowClear={false}
        showArrow
        suffixIcon={
          loading || loadingData ? (
            <Spin
              indicator={
                <LoadingIcon
                  spin
                />
              }
            />
          ) : (
            (Array.isArray(otherProps.value) && otherProps.value?.length) > 0 ? (
              <CloseIcon type='X' onClick={() => onChange()} />
            ) : (
              <Icon.Feather type='fi-chevron-down' />
            )
          )
        }
        getPopupContainer={() => document.getElementById(uniqueId)}
        onChange={onChange}
        showSearch
        loading={loading || loadingData}
        error={error}
        disabled={(loading || loadingData) ? true : disabled}
        optionLabelProp={optionLabelProp}
        filterOption={(input, option) => (option[option.title ? 'title' : 'label']?.toLowerCase().indexOf(input.toLowerCase()) >= 0) || (option.title?.toLowerCase().indexOf(input.toLowerCase()) >= 0) || (option.details?.toLowerCase().indexOf(input.toLowerCase()) >= 0)}
        {...otherProps}
      >
        {(children) ? children : options.filter(filterOptions).map((item) => (
          <Select.Option key={item.key} {...item}>{renderOption(item)}</Select.Option>
        ))}
      </StyledSelectComponent>
    </ContainerStyles>
  );
};

StyledSelect.Option = StyledSelectComponent.Option;
StyledSelect.propTypes = {
  action: PropTypes.func,
  onChange: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  disabled: PropTypes.bool,
  onRenderOption: PropTypes.func,
  optionLabelProp: PropTypes.string,
  defaultActiveFirstOption: PropTypes.bool,
  form: PropTypes.object,
  name: PropTypes.string,
  filterOptions: PropTypes.func,
  loading: PropTypes.bool,
};

StyledSelect.defaultProps = {
  defaultActiveFirstOption: true,
  filterOptions: (item) => true,
};

export default StyledSelect;