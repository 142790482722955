import { useState, useEffect } from 'react';

export const useTreeState = (
  initialData = [],
  defaultExpandAll = false,
  initialCheckedKeys = []
) => {
  const [state, setState] = useState({
    data: [],
    expandedKeys: [],
    checkedKeys: initialCheckedKeys, // Initialize with provided checkedKeys
  });

  useEffect(() => {
    const processTreeData = (data) =>
      data?.map((item) => ({
        ...item,
        disabled: item.disabled || false,
        children: item.children ? processTreeData(item.children) : undefined,
      }));

    const extractAllKeys = (items) =>
      items.reduce((keys, item) => {
        keys.push(item.key);
        if (item.children && item.children.length > 0) {
          keys.push(...extractAllKeys(item.children));
        }
        return keys;
      }, []);

    const processedData = processTreeData(initialData);

    setState((prev) => {
      const newExpandedKeys = defaultExpandAll
        ? extractAllKeys(initialData)
        : prev.expandedKeys.length === 0
        ? []
        : prev.expandedKeys;

      return {
        ...prev,
        data: processedData,
        expandedKeys: newExpandedKeys,
        checkedKeys:
          prev.checkedKeys.length === 0 ? initialCheckedKeys : prev.checkedKeys, // Preserve or set initial checkedKeys
      };
    });
  }, [initialData, defaultExpandAll, initialCheckedKeys]);

  return [state, setState];
};
