import React from 'react';
import { useDraggable, useDroppable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { Column, Text } from '@components-teammove';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import {
  NodeWrapper,
  ToggleButton,
  VerticalConnector,
  HorizontalNodeRow,
  NodeTitle,
  IndependentProfilesContainer,
  StyledCollapse
} from './style';

export default function OrganogramNode({
  node,
  onToggle,
  onNodeClick,
  isRoot = false,
  level = 0,
}) {
  const {
    attributes,
    listeners,
    setNodeRef: setDragRef,
    transform: dragTransform,
    isDragging,
  } = useDraggable({
    id: node.id,
    data: {
      type: 'employee',
      node,
    },
    disabled: isRoot,
  });

  const { setNodeRef: setDropRef, isOver } = useDroppable({
    id: node.id,
    data: {
      type: 'employee',
      node,
    },
  });

  const style = {
    transform: CSS.Translate.toString(dragTransform),
    opacity: isDragging ? 0.5 : 1,
  };

  const setRefs = (element) => {
    setDragRef(element);
    setDropRef(element);
  };

  const handleNodeClick = (e) => {
    e.stopPropagation();
    // Only call preventDefault if not a node with children
    if (!(node.children?.length > 0 && !isRoot)) {
      e.preventDefault();
    }
    onNodeClick(node.id);
  };

  const handleToggleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onToggle(node.id);
  };

  const renderNode = () => (
    <NodeWrapper
      key={node.id}
      ref={setRefs}
      style={{
        ...style,
      }}
      nodeColor={node.color}
      isDragging={isDragging}
      isOver={isOver}
      onClick={handleNodeClick}
      isRoot={isRoot}
      haveIndependentProfiles={node.independentProfiles}
      {...(!isRoot ? attributes : {})}
      {...(!isRoot ? listeners : {})}
    >
      {node.independentProfiles ? (
        <>
          <NodeTitle isRoot={isRoot}>
            {node.name}
          </NodeTitle>
          <StyledCollapse itemsLen={node.independentProfiles?.length} defaultActiveKey={node.independentProfiles.length > 10 ? undefined : ['1']} expandIconPosition="right" ghost>
            <StyledCollapse.Panel
              header={`${node.independentProfiles?.length} Perfis Independentes`}
              key="1"
            >
              {node.independentProfiles?.map((profile) => (
                <IndependentProfilesContainer
                  key={profile.id}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onNodeClick(profile.id);
                  }}
                >
                  <Text style={{ breakWord: 'break-all' }}>
                    {profile.name}
                  </Text>
                </IndependentProfilesContainer>
              ))}
            </StyledCollapse.Panel>
          </StyledCollapse>
        </>
      ) : (
        <>
          {node.children?.length > 0 && !isRoot && (
            <ToggleButton
              onClick={handleToggleClick}
            >
              {node.isExpanded ? <DownOutlined /> : <UpOutlined />}
            </ToggleButton>
          )}

          <NodeTitle isRoot={isRoot}>{node.name}</NodeTitle>
        </>
      )}
    </NodeWrapper>
  );

  return (
    <Column align="center">
      {renderNode()}
      {node.isExpanded && node.children?.length > 0 && (
        <>
          <VerticalConnector />
          <HorizontalNodeRow childCount={node.children.length}>
            {node.children.map((child) => (
              <OrganogramNode
                key={child.id}
                node={child}
                onToggle={onToggle}
                onNodeClick={onNodeClick}
                isRoot={false}
                level={level + 1}
              />
            ))}
          </HorizontalNodeRow>
        </>
      )}
    </Column>
  );
}