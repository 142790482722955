import React, { useEffect, useMemo, useState } from 'react';
import { Icon, Checkbox, Table, Tooltip, View } from '@components-teammove';

import { CellText } from '../styles';
import { InfoUsuario, formatCnpj, TelasNomenclaturas } from '@utils';
import { Container, LoadMoreButton } from './styles';
import { useHistory, Link } from 'react-router-dom';
import { ContainerCheckbox } from '../Unidade/ActivityHistory/styles';

export default function ListaUnidades({
  unidades, isFiltering, selecting, handleUnityClick, loading, handleDispatch, total, orderBy, setOrderBy, title, sucess
}) {
  const history = useHistory();

  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  const [visibleClientsInactives, setVisibleClientsInactives] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  const visibleClients = useMemo(() => {
    return visibleClientsInactives ? unidades : unidades.filter(({ active }) => active);
  }, [visibleClientsInactives, unidades]);

  useEffect(() => {
    if (sucess && loadingMore) {
      setLoadingMore(false);
    }
  }, [sucess]);

  const removeFromQueryString = (pieceToRemove) => {
    let newQuery = history.location.search.replace(pieceToRemove, '').replace(',,', ',').replace('=,', '=');
    newQuery = newQuery[newQuery.length - 1] === ',' ? newQuery.substring(0, newQuery.length - 1) : newQuery;
    return !newQuery.split('=')[1] ? '#' : newQuery;
  };

  const handleToggleSelect = (companyId) => {
    if (history.location.search && history.location.search.split('=')[1].split(',').includes(String(companyId))) {
      history.push(removeFromQueryString(companyId));
    } else {
      history.push(`?selecting=${history.location.search ? history.location.search.split('=')[1] + ',' + companyId : companyId}`);
    }
  };

  const handleLoadMore = () => {
    setLoadingMore(true);
    handleDispatch(unidades.length + 50, orderBy);
  };

  const columns = [
    selecting ? {
      width: 38,
      title: isFiltering && (
        <Tooltip
          title='Aviso: Também serão selecionados para alteração os dados que não foram carregadas.'
          onClick={() => {
            if (history.location.search && history.location.search.split('=')[1].split(',').length === unidades.length) {
              history.push('#');
            } else {
              history.push(`?selecting=${unidades.reduce((state, { id }) => state ? state + ',' + id : id, '')}`);
            }
          }}
        >
          <Checkbox
            checked={history.location.search && history.location.search.split('=')[1].split(',').length === unidades.length}
          />
        </Tooltip>
      ),
      render: (txt, row) => (
        <Checkbox
          checked={history.location.search && history.location.search.split('=')[1].split(',').includes(String(row.id))}
        />
      )
    } : null,
    {
      title: 'Identificador',
      dataIndex: 'identifier',
      defaultSortOrder: orderBy && orderBy.field === 'identifier' ? orderBy.order + 'end' : false,
      sorter: (a, b) => a.id - b.id,
      render: (text, row) => (<Link to={`/unidades/${row?.id}`}><CellText strong disabled={!row.active}>{text.toString()}</CellText></Link>),
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      defaultSortOrder: orderBy && orderBy.field === 'name' ? orderBy.order + 'end' : false,
      sorter: true,
      render: (text, row) => {
        return <CellText disabled={!row.active}>{text}</CellText>;
      }
    },
    InfoUsuario.get('senhaMaster') || InfoUsuario.perm('unidadesCNPJ') ? {
      title: 'CNPJ',
      dataIndex: 'cnpj',
      defaultSortOrder: orderBy && orderBy.field === 'cnpj' ? orderBy.order + 'end' : false,
      sorter: true,
      render: (text, row) => {
        const textWithoutFormat = text?.replace(/\D/g, ''); // remove formatting first so it doesn't get formatted twice
        return textWithoutFormat && <CellText disabled={!row.active}>{formatCnpj(textWithoutFormat)}</CellText>;
      }
    } : null,
    InfoUsuario.perm('exibeRazaoSocialListagemUnidades') ? {
      title: 'Razão Social',
      dataIndex: 'fullName',
      defaultSortOrder: orderBy && orderBy.field === 'fullName' ? orderBy.order + 'end' : false,
      sorter: true,
      render: (text, row) => {
        return <CellText disabled={!row.active}>{text}</CellText>;
      }
    } : null,
    {
      title: 'UF',
      dataIndex: 'state',
      defaultSortOrder: orderBy && orderBy.field === 'state' ? orderBy.order + 'end' : false,
      sorter: true,
      render: (text, row) => {
        return <CellText disabled={!row.active}>{text}</CellText>;
      }
    },
    {
      title: 'Cidade',
      dataIndex: 'city',
      defaultSortOrder: orderBy && orderBy.field === 'city' ? orderBy.order + 'end' : false,
      sorter: true,
      render: (text, row) => {
        return <CellText disabled={!row.active}>{text}</CellText>;
      }
    },
    {
      title: 'Agrupador',
      dataIndex: 'group',
      defaultSortOrder: orderBy && orderBy.field === 'group' ? orderBy.order + 'end' : false,
      sorter: true,
      render: (text, row) => {
        return <CellText disabled={!row.active}>{text?.name}</CellText>;
      }
    },
    {
      title: 'Clusters',
      dataIndex: 'clusters',
      render: (text, row) => {
        return (
          <>
            {text.map(({ name, id }, index) => (
              <CellText key={id} disabled={!row.active}>
                {index > 0 ? ', ' : ''}{name}
              </CellText>
            ))}
          </>
        );
      },
    },
  ].filter((col) => col);

  return (
    <Container>
      <Table
        loading={loading && !loadingMore}
        columns={columns}
        dataSource={visibleClients}
        hidePagination
        rowKey='id'
        status='active'
        onChange={(pagination, filters, { field, order }) => setOrderBy(order ? { field, order: order.replace('end', '') } : false)}
        onRow={(record) => {
          return {
            onClick: selecting ? () => handleToggleSelect(record.id) : () => handleUnityClick(record),
          };
        }}
      />
      <ContainerCheckbox>
        <Checkbox checked={visibleClientsInactives} onChange={(checked) => setVisibleClientsInactives(checked)}>{`Mostrar ${companyNomenclature.plural.toLowerCase()} inativ${companyNomenclature.artigo}s`}</Checkbox>
      </ContainerCheckbox>
      {unidades.length < total && (!loading || loadingMore) && (
        <Tooltip title={selecting ? `Não é permitido carregar mais ${title} enquanto selecionando` : null}>
          <View>
            <LoadMoreButton type='secondary' icon={<Icon.ReloadOutlined />} onClick={handleLoadMore} loading={loadingMore} disabled={selecting}>Carregar mais</LoadMoreButton>
          </View>
        </Tooltip>
      )}
    </Container>
  );
}