import React, { ReactElement } from 'react';
import { ActivityTypeIconWrapper, ActivityTypeIcon } from './styles';

export function ActivityKindSquare({
  name,
  color,
  icon,
}: {
  name: string;
  color: string;
  icon: string;
}): ReactElement {
  return (
    <ActivityTypeIconWrapper color={color} size='small' title={name}>
      <ActivityTypeIcon type={icon}/>
    </ActivityTypeIconWrapper>
  );
}