import { call, put, takeLatest } from 'redux-saga/effects';
import { List, Map } from 'immutable';
import { getTicketsByClassifications as getTicketsByClassificationsSdk } from '@sdk/ChamadosKanban';

//Action Types
export const Types = {
  GET_TICKETS_BY_CLASSIFICATIONS: 'CHAMADOS_KANBAN/GET_TICKETS_BY_CLASSIFICATIONS',
  GET_TICKETS_BY_CLASSIFICATIONS_SUCCESS: 'CHAMADOS_KANBAN/GET_TICKETS_BY_CLASSIFICATIONS_SUCCESS',
  GET_TICKETS_BY_CLASSIFICATIONS_ERROR: 'CHAMADOS_KANBAN/GET_TICKETS_BY_CLASSIFICATIONS_ERROR',

  VISIBLE_TICKET_MENU: 'CHAMADOS_KANBAN/VISIBLE_TICKET_MENU',
  TICKET_INFO: 'CHAMADOS_KANBAN/TICKET_INFO',
  RESET: 'CHAMADOS_KANBAN/RESET',
};

//Action Creators
export const getTicketsByClassifications = (id, filtros = {}, search, limit) => ({ type: Types.GET_TICKETS_BY_CLASSIFICATIONS, id, filtros, search, limit });
export const getTicketsByClassificationsSuccess = (tickets) => ({ type: Types.GET_TICKETS_BY_CLASSIFICATIONS_SUCCESS, tickets });
export const getTicketsByClassificationsError = (error) => ({ type: Types.GET_TICKETS_BY_CLASSIFICATIONS_ERROR, error });

export const setVisibleTicketMenu = (visible) => ({ type: Types.VISIBLE_TICKET_MENU, visible });
export const setTicketInfo = (ticketInfo) => ({ type: Types.TICKET_INFO, ticketInfo });
export const reset = () => ({ type: Types.RESET });

//saga
function* getTicketsByClassificationsSaga(action) {
  try {
    const { id, filtros, search, limit } = action;
    const tickets = yield call(getTicketsByClassificationsSdk, id, filtros, search, limit);
    yield put(getTicketsByClassificationsSuccess(tickets));
  } catch (error) {
    yield put(getTicketsByClassificationsError(error));
  }
}

export const saga = [
  takeLatest(Types.GET_TICKETS_BY_CLASSIFICATIONS, getTicketsByClassificationsSaga),
];

// Reducer
const initialState = Map({
  ticketsByClassification: List(),
  successGetTicketsByClassifications: false,
  errorGetTicketsByClassifications: false,
  loadingGetTicketsByClassifications: false,

  visibleTicketMenu: false,

  ticketInfo: Map(),
  successSetTicketInfo: false,
  errorSetTicketInfo: false,
  loadingSetTicketInfo: false,
});

const handleGetTicketsByClassifications = (state, action) => {
  return state.set('loadingGetTicketsByClassifications', true).set('errorGetTicketsByClassifications', false).set('successGetTicketsByClassifications', false);
};

const handleGetTicketsByClassificationsSuccess = (state, action) => {
  return state.set('ticketsByClassification', action.tickets)
    .set('successGetTicketsByClassifications', true)
    .set('errorGetTicketsByClassifications', false)
    .set('loadingGetTicketsByClassifications', false);
};

const handleGetTicketsByClassificationsError = (state, action) => {
  return state.set('errorGetTicketsByClassifications', true).set('loadingGetTicketsByClassifications', false).set('successGetTicketsByClassifications', false);
};

const handleVisibleTicketMenu = (state, action) => {
  return state.set('visibleTicketMenu', action.visible);
};

const handleTicketInfo = (state, action) => {
  return state.set('ticketInfo', action.ticketInfo)
    .set('successSetTicketInfo', true)
    .set('errorSetTicketInfo', false)
    .set('loadingSetTicketInfo', false);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_TICKETS_BY_CLASSIFICATIONS: return handleGetTicketsByClassifications(state, action);
    case Types.GET_TICKETS_BY_CLASSIFICATIONS_SUCCESS: return handleGetTicketsByClassificationsSuccess(state, action);
    case Types.GET_TICKETS_BY_CLASSIFICATIONS_ERROR: return handleGetTicketsByClassificationsError(state, action);
    
    case Types.VISIBLE_TICKET_MENU: return handleVisibleTicketMenu(state, action);
    case Types.TICKET_INFO: return handleTicketInfo(state, action);
    case Types.RESET: return initialState;

    default:
      return state;
  }
}
