import { Map, List } from 'immutable';
import { 
  saveDelinquenciesFromExcel as saveDelinquenciesFromExcelSdk,
  getCompaniesDelinquencyList as getCompaniesDelinquencyListSdk,
  getCompaniesDelinquencyIndicators as getCompaniesDelinquencyIndicatorsSdk,
  getCompaniesDelinquentsTotals as getCompaniesDelinquentsTotalsSdk,
} from '@sdk/CompanyDelinquency';
import { call, put, takeLatest } from 'redux-saga/effects';
import { Notification } from '@utils';

export const FILTERS_KEY = 'FILTROS/UNIDADES_ADIMPLENCIA';

//Action Types
export const Types = {
  UPLOAD_INADIMPLENCIA: 'UNIDADES_INADIMPLENCIA/UPLOAD_INADIMPLENCIA',
  UPLOAD_INADIMPLENCIA_SUCCESS: 'UNIDADES_INADIMPLENCIA/UPLOAD_INADIMPLENCIA_SUCCESS',
  UPLOAD_INADIMPLENCIA_ERROR: 'UNIDADES_INADIMPLENCIA/UPLOAD_INADIMPLENCIA_ERROR',

  GET_ADIMPLENCIA_LISTA: 'UNIDADES_INADIMPLENCIA/GET_ADIMPLENCIA_LISTA',
  GET_ADIMPLENCIA_LISTA_SUCCESS: 'UNIDADES_INADIMPLENCIA/GET_ADIMPLENCIA_LISTA_SUCCESS',
  GET_ADIMPLENCIA_LISTA_ERROR: 'UNIDADES_INADIMPLENCIA/GET_ADIMPLENCIA_LISTA_ERROR',

  GET_ADIMPLENCIA_INDICADORES: 'UNIDADES_INADIMPLENCIA/GET_ADIMPLENCIA_INDICADORES',
  GET_ADIMPLENCIA_INDICADORES_SUCCESS: 'UNIDADES_INADIMPLENCIA/GET_ADIMPLENCIA_INDICADORES_SUCCESS',
  GET_ADIMPLENCIA_INDICADORES_ERROR: 'UNIDADES_INADIMPLENCIA/GET_ADIMPLENCIA_INDICADORES_ERROR',

  GET_ADIMPLENCIA_TOTAIS: 'UNIDADES_INADIMPLENCIA/GET_ADIMPLENCIA_TOTAIS',
  GET_ADIMPLENCIA_TOTAIS_SUCCESS: 'UNIDADES_INADIMPLENCIA/GET_ADIMPLENCIA_TOTAIS_SUCCESS',
  GET_ADIMPLENCIA_TOTAIS_ERROR: 'UNIDADES_INADIMPLENCIA/GET_ADIMPLENCIA_TOTAIS_ERROR',

  RESET: 'UNIDADES_INADIMPLENCIA/RESET',
  RESET_SUCCESS: 'UNIDADES_INADIMPLENCIA/RESET_SUCCESS',
};

//Action Creators
export const uploadInadimplencia = (excel) => ({ type: Types.UPLOAD_INADIMPLENCIA, excel });
export const uploadInadimplenciaSuccess = (response) => ({ type: Types.UPLOAD_INADIMPLENCIA_SUCCESS, response });
export const uploadInadimplenciaError = (error) => ({ type: Types.UPLOAD_INADIMPLENCIA_ERROR, error });

export const getAdimplenciaList = (params) => ({ type: Types.GET_ADIMPLENCIA_LISTA, params });
export const getAdimplenciaListSuccess = (response) => ({ type: Types.GET_ADIMPLENCIA_LISTA_SUCCESS, response });
export const getAdimplenciaListError = (error) => ({ type: Types.GET_ADIMPLENCIA_LISTA_ERROR, error });

export const getAdimplenciaIndicadores = (params) => ({ type: Types.GET_ADIMPLENCIA_INDICADORES, params });
export const getAdimplenciaIndicadoresSuccess = (response) => ({ type: Types.GET_ADIMPLENCIA_INDICADORES_SUCCESS, response });
export const getAdimplenciaIndicadoresError = (error) => ({ type: Types.GET_ADIMPLENCIA_INDICADORES_ERROR, error });

export const getAdimplenciaTotais = (params) => ({ type: Types.GET_ADIMPLENCIA_TOTAIS, params });
export const getAdimplenciaTotaisSuccess = (response) => ({ type: Types.GET_ADIMPLENCIA_TOTAIS_SUCCESS, response });
export const getAdimplenciaTotaisError = (error) => ({ type: Types.GET_ADIMPLENCIA_TOTAIS_ERROR, error });

export const reset = () => ({ type: Types.RESET });
export const resetSuccess = () => ({ type: Types.RESET_SUCCESS });

function* fetchUploadInadimplencia(action) {
  try {
    const { excel } = action;
    const response = yield call(saveDelinquenciesFromExcelSdk, excel);
    yield put(uploadInadimplenciaSuccess(response));
  } catch (err) {
    Notification.error(err.message);
    yield put(uploadInadimplenciaError(err));
  }
}

function* fetchGetInadimplenciaList(action) {
  try {
    const response = yield call(getCompaniesDelinquencyListSdk, action.params);
    yield put(getAdimplenciaListSuccess(response));
  } catch (err) {
    Notification.error(err.message);
    yield put(getAdimplenciaListError(err));
  }
}

function* fetchGetInadimplenciaIndicadores(action) {
  try {
    const response = yield call(getCompaniesDelinquencyIndicatorsSdk, action.params);
    yield put(getAdimplenciaIndicadoresSuccess(response));
  } catch (err) {
    Notification.error(err.message);
    yield put(getAdimplenciaIndicadoresError(err));
  }
}

function* fetchGetInadimplenciaTotais(action) {
  try {
    const response = yield call(getCompaniesDelinquentsTotalsSdk, action.params);
    yield put(getAdimplenciaTotaisSuccess(response));
  } catch (err) {
    Notification.error(err.message);
    yield put(getAdimplenciaTotaisError(err));
  }
}

export const saga = [
  takeLatest(Types.UPLOAD_INADIMPLENCIA, fetchUploadInadimplencia),
  takeLatest(Types.GET_ADIMPLENCIA_LISTA, fetchGetInadimplenciaList),
  takeLatest(Types.GET_ADIMPLENCIA_INDICADORES, fetchGetInadimplenciaIndicadores),
  takeLatest(Types.GET_ADIMPLENCIA_TOTAIS, fetchGetInadimplenciaTotais),
];

// Reducer
const initialState = Map({
  uploadInadimplenciaResponse: {},
  loadingUploadInadimplencia: false,
  successUploadInadimplencia: false,
  errorUploadInadimplencia: false,

  adimplenciaList: List(),
  adimplenciaListTotal: 0,
  loadingAdimplenciaList: false,
  successAdimplenciaList: false,
  errorAdimplenciaList: false,

  adimplenciaIndicadores: {},
  loadingAdimplenciaIndicadores: false,
  successAdimplenciaIndicadores: false,
  errorAdimplenciaIndicadores: false,

  adimplenciaTotais: {},
  loadingAdimplenciaTotais: false,
  successAdimplenciaTotais: false,
  errorAdimplenciaTotais: false,
});

const handleUploadInadimplencia = (state, action) => {
  return state.set('loadingUploadInadimplencia', true).set('successUploadInadimplencia', false).set('errorUploadInadimplencia', false);
};

const handleUploadInadimplenciaSuccess = (state, action) => {
  const { response } = action;
  return state.set('uploadInadimplenciaResponse', response).set('loadingUploadInadimplencia', false).set('successUploadInadimplencia', true).set('errorUploadInadimplencia', false);
};

const handleUploadInadimplenciaError = (state, action) => {
  return state.set('loadingUploadInadimplencia', false).set('successUploadInadimplencia', false).set('errorUploadInadimplencia', action.error);
};

const handleGetAdimplenciaList = (state, action) => {
  return state.set('loadingAdimplenciaList', true).set('successAdimplenciaList', false).set('errorAdimplenciaList', false);
};

const handleGetAdimplenciaListSuccess = (state, action) => {
  const { response } = action;
  return state.set('adimplenciaList', response.companies).set('adimplenciaListTotal', response.total)
    .set('loadingAdimplenciaList', false).set('successAdimplenciaList', true).set('errorAdimplenciaList', false);
};

const handleGetAdimplenciaListError = (state, action) => {
  return state.set('loadingAdimplenciaList', false).set('successAdimplenciaList', false).set('errorAdimplenciaList', action.error);
};

const handleGetAdimplenciaIndicadores = (state, action) => {
  return state.set('loadingAdimplenciaIndicadores', true).set('successAdimplenciaIndicadores', false).set('errorAdimplenciaIndicadores', false);
};

const handleGetAdimplenciaIndicadoresSuccess = (state, action) => {
  const { response } = action;
  return state.set('adimplenciaIndicadores', response)
    .set('loadingAdimplenciaIndicadores', false).set('successAdimplenciaIndicadores', true).set('errorAdimplenciaIndicadores', false);
};

const handleGetAdimplenciaIndicadoresError = (state, action) => {
  return state.set('loadingAdimplenciaIndicadores', false).set('successAdimplenciaIndicadores', false).set('errorAdimplenciaIndicadores', action.error);
};

const handleGetAdimplenciaTotais = (state, action) => {
  return state.set('loadingAdimplenciaTotais', true).set('successAdimplenciaTotais', false).set('errorAdimplenciaTotais', false);
};

const handleGetAdimplenciaTotaisSuccess = (state, action) => {
  const { response } = action;
  return state.set('adimplenciaTotais', response)
    .set('loadingAdimplenciaTotais', false).set('successAdimplenciaTotais', true).set('errorAdimplenciaTotais', false);
};

const handleGetAdimplenciaTotaisError = (state, action) => {
  return state.set('loadingAdimplenciaTotais', false).set('successAdimplenciaTotais', false).set('errorAdimplenciaTotais', action.error);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.UPLOAD_INADIMPLENCIA: return handleUploadInadimplencia(state);
    case Types.UPLOAD_INADIMPLENCIA_SUCCESS: return handleUploadInadimplenciaSuccess(state, action);
    case Types.UPLOAD_INADIMPLENCIA_ERROR: return handleUploadInadimplenciaError(state, action);

    case Types.GET_ADIMPLENCIA_LISTA: return handleGetAdimplenciaList(state);
    case Types.GET_ADIMPLENCIA_LISTA_SUCCESS: return handleGetAdimplenciaListSuccess(state, action);
    case Types.GET_ADIMPLENCIA_LISTA_ERROR: return handleGetAdimplenciaListError(state, action);

    case Types.GET_ADIMPLENCIA_INDICADORES: return handleGetAdimplenciaIndicadores(state);
    case Types.GET_ADIMPLENCIA_INDICADORES_SUCCESS: return handleGetAdimplenciaIndicadoresSuccess(state, action);
    case Types.GET_ADIMPLENCIA_INDICADORES_ERROR: return handleGetAdimplenciaIndicadoresError(state, action);

    case Types.GET_ADIMPLENCIA_TOTAIS: return handleGetAdimplenciaTotais(state);
    case Types.GET_ADIMPLENCIA_TOTAIS_SUCCESS: return handleGetAdimplenciaTotaisSuccess(state, action);
    case Types.GET_ADIMPLENCIA_TOTAIS_ERROR: return handleGetAdimplenciaTotaisError(state, action);

    case Types.RESET_ALL_VALUES: return initialState;
    case Types.RESET_SUCCESS_DOWNLOAD_TEMPLATE: return state.set('loadingUploadInadimplencia', false).set('successTemplateUrl', false).set('errorTemplateUrl', false);
    
    default: return state;
  }
}