import React from 'react';
import { Row, Text, Column, Divider } from '@components-teammove';
import { getDiffDays, moment, formatDate, getUrlAvatar } from '@utils';
import { OpacityText, TitleCard, BoldText, IconStyled, RoundedContainer, RowClassificacao, SmallAvatar } from './styles';
import ClassificacoesTag from '../../../../../ui-teammove/Classification/ClassificacoesTag';
import Tag from '../../../../../components-teammove/Tag';

const CardInfo = ({ ticket }) => {
  const { company, categories, assignee, classificationOptions, dueDate, protocol, numberOfUnreadMessages, title } = ticket;
  const categoriesString = categories.map((category) => category.title).join(' / ');
  const deadline = Math.abs(getDiffDays(dueDate));

  const deadlineText = (() => {
    const hasPassedDueDate = moment(dueDate).isBefore(moment());

    const isClosed = ['FE', 'CA', 'AA'].includes(ticket.status.id);

    const deadlineLabel = hasPassedDueDate && !isClosed ? 
      'Atrasado' : 
      'Prazo';
    const deadlineContent = hasPassedDueDate || deadline > 15 ? 
      formatDate(dueDate) : 
      (`${deadline} dia${deadline > 1 ? 's' : ''}`);

    return `${deadlineLabel}: ${deadlineContent}`;
  })();

  const classificationFormatted = classificationOptions
    .map(({ color, id, title }) => {
      return { id, corOpcaoEscolhida: color, nomeOpcaoEscolhida: title };
    });

  return (
    <Column gap='12px'>
      <Column>
        <Row justify='space-between' align='center' width='100%'>
          <OpacityText>{protocol}</OpacityText>
          <Row gap='4px'>
            <Tag closable={false} small bgColor={ticket.status.color}>{ticket.status.description}</Tag>
            {numberOfUnreadMessages > 0 && (
              <RoundedContainer>
                <Text>{numberOfUnreadMessages}</Text>
              </RoundedContainer>
            )}
          </Row>
        </Row>
        <TitleCard>{title}</TitleCard>
        <OpacityText type>{categoriesString}</OpacityText>
      </Column>
      <Row gap='12px' align='center'>
        <IconStyled type='home' />
        <Column>
          <Row align='center' gap='8px'>
            <BoldText>{company.shortName}</BoldText>
            <OpacityText>{company.name}</OpacityText>
          </Row>
          <Row align='center' gap='8px'>
            <OpacityText>{company.city} - {company.state}</OpacityText>
          </Row>
        </Column>
      </Row>
      <RowClassificacao gap='8px'>
        {classificationFormatted.length > 0 && classificationFormatted.map((classificacao) => (
          classificacao.nomeOpcaoEscolhida && (
            <ClassificacoesTag 
              key={classificacao.id} 
              classification={{
                id: classificacao.id, 
                chosenOptionColor: classificacao.corOpcaoEscolhida, 
                chosenOptionName: classificacao.nomeOpcaoEscolhida
              }}
            />
          )
        ))}
      </RowClassificacao>
      <Divider />
      <Row align='center' justify='space-between' width='100%'>
        <Row gap='6px'>
          <SmallAvatar src={getUrlAvatar(assignee.picture)}/>
          <Text>{assignee.name}</Text>
        </Row>
        <Row align='center' gap='4px'>
          <IconStyled type='clock' clock />
          <OpacityText>{deadlineText}</OpacityText>
        </Row>
      </Row>
    </Column>
  );
};

export default CardInfo;