import React, { useCallback, useEffect, useRef, useMemo } from 'react';
import { useTreeState } from '../../hooks/Tree/useTreeState';
import { useTreeOperations } from '../../hooks/Tree/useTreeOperations';
import { useTreeDragDrop } from '../../hooks/Tree/useTreeDragDrop';
import { Notification } from '@utils';
import { StyledTree } from './styles';
import { Icon } from '@components-teammove';

const ModularTree = React.memo(
  ({
    initialData = [],
    draggable = false,
    checkable = true,
    onChange = () => {},
    checkStrictly = true,
    name = '',
    notificationMessage = 'Por favor, marque o nodo pai primeiro',
    idField = 'id',
    parentIdField = 'parentId',
    defaultExpandAll = false,
  }) => {
    const hasInitialized = useRef(false);

    const sortTreeDataByOrderProfile = useMemo(
      () => (nodes) => {
        const sortedNodes = [...nodes].sort((a, b) => {
          const orderA =
            a.orderProfile !== undefined ? a.orderProfile : Infinity;
          const orderB =
            b.orderProfile !== undefined ? b.orderProfile : Infinity;
          return orderA - orderB;
        });
        return sortedNodes.map((node) => {
          if (node.children && node.children.length > 0) {
            return {
              ...node,
              children: sortTreeDataByOrderProfile(node.children),
            };
          }
          return { ...node };
        });
      },
      []
    );

    const addDisabledPropToChildren = useMemo(
      () => (nodes, checkedKeys) => {
        const checkedKeysArray = Array.isArray(checkedKeys)
          ? checkedKeys
          : checkedKeys?.checked || [];

        const processNode = (node, isParentChecked) => {
          const isChecked = checkedKeysArray.includes(node.key);
          const newNode = { ...node };
          newNode.disabled = !isParentChecked;

          if (node.children && node.children.length > 0) {
            newNode.children = node.children.map((child) =>
              processNode(child, isChecked)
            );
          }

          return newNode;
        };

        return nodes.map((node) => processNode(node, true));
      },
      []
    );

    // Memoize callback functions to prevent unnecessary re-renders
    const extractAllKeys = useCallback((items) => {
      return items.reduce((keys, item) => {
        keys.push(item.key);
        if (item.children && item.children.length > 0) {
          keys.push(...extractAllKeys(item.children));
        }
        return keys;
      }, []);
    }, []);

    const findCheckedNodes = useCallback((nodes, result = []) => {
      for (const node of nodes) {
        if (node.value === true || node.valueProfile === true) {
          result.push(node.key);
        }
        if (node.children && node.children.length > 0) {
          findCheckedNodes(node.children, result);
        }
      }
      return result;
    }, []);

    // Performance optimization: Memoize expensive computations
    const sortedInitialData = useMemo(
      () => sortTreeDataByOrderProfile(initialData),
      [initialData]
    );

    const initialCheckedKeys = useMemo(
      () => findCheckedNodes(sortedInitialData),
      [sortedInitialData, findCheckedNodes]
    );

    const [treeState, setTreeState] = useTreeState(
      sortedInitialData,
      defaultExpandAll,
      initialCheckedKeys
    );

    const operations = useTreeOperations();
    const previousExpandedKeys = useRef([]);

    // Memoize update function to reduce unnecessary recreations
    const updateTreeWithDisabledStates = useCallback((data, checkedKeys) => {
      const newData = JSON.parse(JSON.stringify(data));
      return addDisabledPropToChildren(newData, checkedKeys);
    }, []);

    // Memoize complex functions to prevent unnecessary recreations
    const extractOrderedNodesData = useCallback(
      (nodes, parentKey = null, level = 0, customOrders = {}) => {
        return nodes.flatMap((node, index) => {
          const order =
            customOrders[node.key] !== undefined
              ? customOrders[node.key]
              : index;
          const nodeData = {
            key: node.key,
            parentKey: parentKey,
            title: node.title,
            description: node.description || '',
            order: order,
            level,
          };
          if (node.children?.length) {
            return [
              nodeData,
              ...extractOrderedNodesData(
                node.children,
                node.key,
                level + 1,
                customOrders
              ),
            ];
          }
          return [nodeData];
        });
      },
      []
    );

    const processTreeDataForChange = useCallback(
      (currentData, currentCheckedKeys) => {
        const orderedNodesRaw = extractOrderedNodesData(currentData);
        const checkedKeysArray = Array.isArray(currentCheckedKeys)
          ? currentCheckedKeys
          : currentCheckedKeys?.checked || [];

        const orderedNodes = orderedNodesRaw.map((node) => ({
          [idField]: node.key,
          [parentIdField]: node.parentKey || null,
          title: node.title,
          description: node.description || '',
          value: checkedKeysArray.includes(node.key),
          order: node.order,
        }));

        return { checkedKeysArray, orderedNodes };
      },
      [extractOrderedNodesData, idField, parentIdField]
    );

    const { handleDrop: originalHandleDrop, canDrop } = useTreeDragDrop(
      treeState.data,
      operations,
      (newData) => {
        const updatedData = updateTreeWithDisabledStates(
          newData,
          treeState.checkedKeys
        );
        setTreeState((prev) => ({ ...prev, data: updatedData }));
        const { checkedKeysArray, orderedNodes } = processTreeDataForChange(
          updatedData,
          treeState.checkedKeys
        );
        onChange(checkedKeysArray, orderedNodes);
      }
    );

    const findAllDescendants = useCallback((nodeKey, data) => {
      const findNode = (nodes, key) => {
        for (const node of nodes) {
          if (node.key === key) return node;
          if (node.children) {
            const found = findNode(node.children, key);
            if (found) return found;
          }
        }
        return null;
      };

      const collectDescendantKeys = (node, keys = []) => {
        if (!node.children) return keys;

        for (const child of node.children) {
          keys.push(child.key);
          collectDescendantKeys(child, keys);
        }
        return keys;
      };

      const node = findNode(data, nodeKey);
      return node ? collectDescendantKeys(node) : [];
    }, []);

    // Optimize check handling with memoization
    const handleCheck = useCallback(
      (checkedKeys, info) => {
        const { node, checked } = info;
        let newCheckedKeys = Array.isArray(checkedKeys)
          ? [...checkedKeys]
          : [...(checkedKeys?.checked || [])];

        if (checked) {
          const parent = operations.findParent(node.key, treeState.data);
          if (parent && !newCheckedKeys.includes(parent.key)) {
            Notification.info(notificationMessage);
            return;
          }
        } else {
          const descendantKeys = findAllDescendants(node.key, treeState.data);
          newCheckedKeys = newCheckedKeys.filter(
            (key) => !descendantKeys.includes(key)
          );
        }

        const updatedData = updateTreeWithDisabledStates(
          JSON.parse(JSON.stringify(treeState.data)),
          newCheckedKeys
        );

        setTreeState((prev) => ({
          ...prev,
          checkedKeys: newCheckedKeys,
          data: updatedData,
        }));

        const { checkedKeysArray, orderedNodes } = processTreeDataForChange(
          updatedData,
          newCheckedKeys
        );
        onChange(checkedKeysArray, orderedNodes);
      },
      [
        treeState.data,
        operations,
        notificationMessage,
        findAllDescendants,
        onChange,
        processTreeDataForChange,
        updateTreeWithDisabledStates,
      ]
    );

    // Memoize expand handler
    const handleExpand = useCallback((expandedKeys, info) => {
      previousExpandedKeys.current = expandedKeys;
      setTreeState((prev) => ({
        ...prev,
        expandedKeys,
      }));
    }, []);

    // Optimize useEffect to reduce unnecessary updates
    useEffect(() => {
      if (!initialData || initialData.length === 0) {
        return;
      }

      if (!hasInitialized.current) {
        const updatedData = updateTreeWithDisabledStates(
          sortedInitialData,
          initialCheckedKeys
        );
        setTreeState((prev) => ({
          ...prev,
          data: updatedData,
          checkedKeys: initialCheckedKeys,
          expandedKeys: defaultExpandAll
            ? extractAllKeys(updatedData)
            : prev.expandedKeys,
        }));
        hasInitialized.current = true;
      } else if (
        JSON.stringify(sortedInitialData) !== JSON.stringify(treeState.data)
      ) {
        const updatedData = updateTreeWithDisabledStates(
          sortedInitialData,
          treeState.checkedKeys
        );
        setTreeState((prev) => ({
          ...prev,
          data: updatedData,
        }));
      }
    }, [
      initialData,
      defaultExpandAll,
      sortedInitialData,
      initialCheckedKeys,
      updateTreeWithDisabledStates,
      extractAllKeys,
    ]);

    return (
      <StyledTree
        name={name}
        checkStrictly={checkStrictly}
        treeData={treeState.data}
        checkable={checkable}
        draggable={draggable}
        expandedKeys={treeState.expandedKeys}
        checkedKeys={treeState.checkedKeys}
        onCheck={handleCheck}
        onExpand={handleExpand}
        onDrop={draggable ? originalHandleDrop : undefined}
        allowDrop={canDrop}
        blockNode
        selectable={false}
        switcherIcon={<Icon.Feather type="chevron-down" />}
      />
    );
  }
);

export default ModularTree;
