/* eslint-disable camelcase */
import App from '@app';
import { Body, Breadcrumb, Button, Column, Form, PageHeader } from '@components-teammove';
import { getChecklistTemplate, reset, putChecklistTemplate, delChecklistTemplate } from '@ducks/checklistTemplates';
import { getIdTemp, arrayReorder, Notification } from '@utils';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import AcoesEstrategicasChecklist from './AcoesEstrategicasChecklist';
import GruposChecklist from './GruposChecklist';
import NovaPerguntaGrupoChecklist from './NovaPergunta';
import NovoGrupoChecklist from './NovoGrupo';
import { copyGroupsChecklistTemplate, formatOpcoesAnexo, initialValues, mapperQuestionToForm } from './rules';
import TituloChecklist from './TituloChecklist';

const CadastroTemplatesChecklist = () => {
  const { id } = useParams();

  const [form] = Form.useForm();
  const [formPerguntas] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const tempIdCabecalho = getIdTemp();

  const [visibleGroupAdd, setVisibleGroupAdd] = useState(false);
  const [visibleAddAsk, setVisibleAddAsk] = useState(false);
  const [groups, setGroups] = useState([]);
  const [groupId, setGroupId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isCopyingTemplate, setIsCopyingTemplate] = useState(false);

  const theme = useSelector(({ app }) => app.get('theme'));

  const checklistTemplate = useSelector(({ checklistTemplates }) => checklistTemplates.get('checklistTemplate'));
  const successChecklistTemplate = useSelector(({ checklistTemplates }) => checklistTemplates.get('successChecklistTemplate'));

  const successSaveChecklistTemplate = useSelector(({ checklistTemplates }) => checklistTemplates.get('successSaveChecklistTemplate'));
  const loadingSaveChecklistTemplate = useSelector(({ checklistTemplates }) => checklistTemplates.get('loadingSaveChecklistTemplate'));

  const loadingDelChecklistTemplate = useSelector(({ checklistTemplates }) => checklistTemplates.get('loadingDelChecklistTemplate'));
  const successDelChecklistTemplate = useSelector(({ checklistTemplates }) => checklistTemplates.get('successDelChecklistTemplate'));

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, []);

  useEffect(() => {
    if (!id) {
      // this is for when copying existing template
      if(groups.length > 0) return;
      setGroups([{ nome: 'Cabeçalho', temp_id: tempIdCabecalho, perguntas: [], ordem: 0 }]);
      form.setFieldsValue(initialValues);
      return;
    }
    dispatch(getChecklistTemplate(id));
  }, [id]);

  useEffect(() => {
    if (!successChecklistTemplate) return;
    form.setFieldsValue(checklistTemplate);
    setGroups(checklistTemplate.grupos);
  }, [successChecklistTemplate]);

  useEffect(() => {
    if((successDelChecklistTemplate || successSaveChecklistTemplate) && !isCopyingTemplate) {
      history.goBack();
    }
    if(isCopyingTemplate) {   
      handleToResetAndSetNewFieldsForCopy();
    }
  }, [successSaveChecklistTemplate, successDelChecklistTemplate]);

  const handleSave = () => {
    form.validateFields().then((values) => {
      const idTemplate = Number(id) || null;
      const finalValues = { ...values, id: idTemplate, grupos: groups };
      dispatch(putChecklistTemplate(finalValues));
    });
  };

  const handleDelete = () => {
    if(checklistTemplate.hasRelatedActivityKinds) {
      Notification.info('Não é possível excluir um checklist que está vinculado a uma ou mais empresas');
      return;
    }
    dispatch(delChecklistTemplate(id));
  };

  const handleAddNewGroup = () => {
    form.validateFields(['nome_grupo'])
      .then((value) => {
        const groupName = value.nome_grupo;
        if (isEditing) {
          const idGroup = form.getFieldValue('id');
          const newGroups = groups.map((group) => {
            if ((group.id || group.temp_id) === idGroup) {
              return {
                ...group,
                nome: groupName
              };
            } else {
              return group;
            }
          });
          setGroups(newGroups);
        } else {
          setGroups([...groups, { nome: groupName, temp_id: getIdTemp(), perguntas: [], ordem: groups.length }]);
        }

        handleCloseGroupAdd();
      })
      .catch((errorInfo) => console.error('Erro na validação:', errorInfo));
  };

  const handleAddAsk = () => {
    formPerguntas.validateFields()
      .then((values) => {
        const {
          id,
          temp_id,
          titulo, 
          observacao, 
          grupo_pertencente,
          opcional, 
          tipo, 
          opcoes_anexo, 
          permissao_camera_galeria, 
          utiliza_ultima_resposta, 
          tags, 
          tipo_upload, 
          tipo_observacao, 
          monetario, 
          casas_decimal, 
          intervalo_maximo, 
          intervalo_minimo, 
          peso, 
          importante,
          ...optionsFields
        } = values;

        const optionsKeys = Object.keys(optionsFields).filter((key) => key.startsWith('peso_opcao_'));
        const optionsValues = optionsKeys.map((key) => optionsFields[key] || 0);

        if (tipo === 'CAIXA_SELECAO') {
          const optionsSum = optionsValues.reduce((sum, value) => sum + value, 0);
        
          if (optionsSum > peso) {
            const errorFields = optionsKeys.map((key) => ({
              name: key,
              errors: [`A soma dos pesos excedeu o peso máximo permitido (${peso}).`],
            }));
        
            formPerguntas.setFields(errorFields);
            return;
          }
        } else {
          const invalidOption = optionsKeys.find((key, index) => optionsValues[index] > peso);
        
          if (invalidOption) {
            formPerguntas.setFields([{
              name: invalidOption,
              errors: ['O peso da opção não pode exceder o peso do item.'],
            }]);
            return;
          }
        }

        const temp_id_pergunta = temp_id || getIdTemp();
        const formattedTags = tags?.join(',') || ''; 

        const opcoes = Object.keys(optionsFields)
          .filter((key) => key.includes('opcao_'))
          .reduce((options, key) => {
            const keyParts = key.split('_');
            const index = keyParts[keyParts.length - 1];
            const formattedKey = keyParts.slice(0, -2).join('_');
            const keyValue = optionsFields[key];
            const existingOption = options.find((option) => option.index === index);
            const parsedValues = formattedKey === 'enabled_questions' 
              ? { enabled_questions: (keyValue ?? []).map((value) => typeof value === 'string' ? { temp_id: value } : { id: value }) }
              : formattedKey.startsWith('opcoes') 
                ? formatOpcoesAnexo(keyValue)
                : { [formattedKey]: keyValue };

            return existingOption ? 
              options.map((option) => option.index === index ? ({
                ...option,
                ...parsedValues,
                temp_id: option.temp_id || getIdTemp(), 
                id: option.id || undefined, 
              }) : option)
              : 
              [
                ...options,
                {
                  index,
                  ...parsedValues,
                  temp_id: getIdTemp(),
                }
              ];
          }, []);

        const allConditionalQuestions = opcoes.flatMap((opcao) => 
          (Array.isArray(opcao.enabled_questions) ? opcao.enabled_questions : [opcao.enabled_questions])
            .map((enabledQuestion) => {
              const enabledQuestionId = enabledQuestion.id || enabledQuestion.temp_id; 
              return {
                enabledQuestionId,
                idPerguntaPai: id || temp_id_pergunta,
                nomePerguntaPai: titulo,
                nomeOpcaoPerguntaPai: opcao.valor,
                idOpcaoPerguntaPai: opcao.id || opcao.temp_id,
              };
            })
        );

        const allConditionalIds = allConditionalQuestions.map((cond) => cond.enabledQuestionId);
        const updatedGroups = groups.map((group) => {
          return {
            ...group,
            perguntas: group.perguntas.map((pergunta) => {
              const conditionalInfo = allConditionalQuestions.find(
                (cond) => cond.enabledQuestionId === (pergunta.id || pergunta.temp_id)
              );
              if (conditionalInfo) {
                return { ...pergunta, ...conditionalInfo };
              }
              const wasConditional = pergunta.idPerguntaPai === (id || temp_id_pergunta);
              return wasConditional && !allConditionalIds.includes(pergunta.id || pergunta.temp_id)
                ? { ...pergunta, idPerguntaPai: null, idOpcaoPerguntaPai: null, nomePerguntaPai: null, nomeOpcaoPerguntaPai: null }
                : pergunta;
            })
          };
        });

        const ordem = groups.find((group) => (group.temp_id || group.id) === grupo_pertencente)?.perguntas.length || 0;
        const newPergunta = {
          id: id,
          titulo,
          opcional,
          tipo,
          observacao,
          ordem,
          ...formatOpcoesAnexo(opcoes_anexo),
          permissao_camera_galeria,
          utiliza_ultima_resposta,
          tags: formattedTags,
          tipo_upload,
          tipo_observacao,
          monetario,
          casas_decimal,
          intervalo_maximo,
          intervalo_minimo,
          peso,
          temp_id: temp_id_pergunta,
          opcoes,
          importante
        };
        const finalGroups = updatedGroups.map((group) => {
          if ((group.temp_id || group.id) === grupo_pertencente) {
            const updatedPerguntas = group.perguntas.map((pergunta) => 
              (pergunta.id || pergunta.temp_id) === (id || temp_id_pergunta) 
                ? { ...pergunta, ...newPergunta, ordem: pergunta.ordem }
                : pergunta
            );
            if (!group.perguntas.some((pergunta) => (pergunta.id || pergunta.temp_id) === (id || temp_id_pergunta))) {
              updatedPerguntas.push({ ...newPergunta, ordem: group.perguntas.length });
            }
  
            return { ...group, perguntas: updatedPerguntas };
          }
          return {
            ...group,
            perguntas: group.perguntas.filter((pergunta) => (pergunta.id || pergunta.temp_id) !== (id || temp_id_pergunta)),
          };
        });

        handleCloseQuestionAdd();
        setGroups(finalGroups);
      }).catch((errorInfo) => console.error('Erro na validação:', errorInfo));
  };

  const handleCloseGroupAdd = () => {
    setVisibleGroupAdd(false);
    setIsEditing(false);
    form.resetFields(['nome_grupo', 'groupId']);
  };

  const handleCloseQuestionAdd = () => {
    setVisibleAddAsk(false);
    formPerguntas.resetFields();
  };

  const handleDeleteGroup = (id) => {
    const newGroups = groups.filter((group) => (group.id || group.temp_id) !== id);
    setGroups(newGroups);
  };

  const handleDeleteQuestion = (questionToDelete, groupToModify) => {  
    const newGroups = groups.map((group) => {
      if ((group.temp_id || group.id) === (group.id || groupToModify.temp_id)) {
        return {
          ...group,
          perguntas: group.perguntas
            .filter((pergunta) => (pergunta.id || pergunta.temp_id) !== (questionToDelete.id || questionToDelete.temp_id))
            .map((pergunta) => {
              if (pergunta.idPerguntaPai === (questionToDelete.id || questionToDelete.temp_id)) {
                const updatedPergunta = { ...pergunta };
                delete updatedPergunta.idPerguntaPai;
                delete updatedPergunta.nomePerguntaPai;
                delete updatedPergunta.nomeOpcaoPerguntaPai;
                return updatedPergunta; 
              }
              return pergunta;
            })
        };
      }
      return group;
    });
  
    setGroups(newGroups);
  };

  const handleEditQuestion = (question) => {
    formPerguntas.setFieldsValue(mapperQuestionToForm(question));
    setVisibleAddAsk(true);
  };

  const handleEditGroup = (groupName, groupId) => {
    setIsEditing(true);
    setGroupId(groupId);
    form.setFieldsValue({ nome_grupo: groupName, id: groupId });
    setVisibleGroupAdd(true);
  };

  const handleReorderGroup = (oldOrder, newOrder) => {
    setGroups(() => {
      const newGroups =
        arrayReorder(groups, oldOrder, newOrder)
          .map((item, index) => ({ ...item, ordem: index }));
      return newGroups;
    });
  };

  const handleReorderQuestion = (idGrupo, oldOrder, newOrder) => {
    setGroups(() => {
      const newGroups = groups.map((group) => {
        if ((group.id || group.temp_id) === idGrupo) {
          return {
            ...group,
            perguntas: arrayReorder(group.perguntas, oldOrder, newOrder)
              .map((item, index) => ({ ...item, ordem: index }))
          };
        }
        return group;
      });
      return newGroups;
    });
  };

  const handleCopyQuestion = (question, idGroup) => {
    formPerguntas.resetFields();
    setGroupId(idGroup); 
    formPerguntas.setFieldsValue(mapperQuestionToForm(question, true));
    setVisibleAddAsk(true);
  };

  const handleCopyChecklist = () => {
    setIsCopyingTemplate(true);
    handleSave();
  };

  const handleToResetAndSetNewFieldsForCopy = () => {
    history.replace('/configuracoes/checklists/cadastro');
    const currentTemplate = form.getFieldsValue();
    const newCopyTemplate = { ...currentTemplate, id: null, nome: `${currentTemplate.nome} (Cópia)` };
    const newCopyGroupsTemplate = copyGroupsChecklistTemplate(groups);
    form.resetFields();
    formPerguntas.resetFields();
    form.setFieldsValue(newCopyTemplate);
    setGroups(newCopyGroupsTemplate);      
    setIsCopyingTemplate(false);
  };

  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title="Configurações / Checklists"
          extra={[
            <Button key="1" size='small' context='header' type='secondary'  onClick={() => history.goBack()} disabled={loadingSaveChecklistTemplate || loadingDelChecklistTemplate}>Cancelar</Button>,
            <Button key='2' size='small' context='header' type="danger" onClick={handleDelete} disabled={!id || loadingSaveChecklistTemplate} loading={loadingDelChecklistTemplate}>Excluir</Button>,
            <Button key="3" size='small' context='header' type="primary" onClick={handleSave} disabled={loadingDelChecklistTemplate} loading={loadingSaveChecklistTemplate}>Salvar</Button>,
          ]}
          breadcrumb={(
            <Breadcrumb
              items={[
                { path: '/', name: 'Home' },
                { path: '/configuracoes/checklists', name: 'Configurações checklists' },
                { path: '/configuracoes/checklist/cadastro', name: 'Configurações de checklist', current: true },
              ]}
            />
          )}
          onBack={() => history.goBack()}
        >
          <Column gap='20px' margin='30px 0 0 0' align='center' width='100%'>
            <TituloChecklist 
              handleCopyChecklist={handleCopyChecklist}
              form={form} 
            />
            <AcoesEstrategicasChecklist form={form} idChecklistTemplate={id}/>
            <GruposChecklist
              groups={groups}
              setVisibleAddAsk={setVisibleAddAsk}
              handleEditGroup={handleEditGroup}
              setGroupId={setGroupId}
              handleDeleteGroup={handleDeleteGroup}
              handleDeleteQuestion={handleDeleteQuestion}
              handleEditQuestion={handleEditQuestion}
              handleReorderGroup={handleReorderGroup}
              handleReorderQuestion={handleReorderQuestion}
              handleCopyQuestion={handleCopyQuestion}
            />
            <Button size='small' type="primary" onClick={() => setVisibleGroupAdd(true)}>
              Criar novo grupo
            </Button>
          </Column>
        </PageHeader>
      </Body>
      {visibleGroupAdd && (
        <NovoGrupoChecklist
          visible={visibleGroupAdd}
          onCancel={handleCloseGroupAdd}
          handleAddNewGroup={handleAddNewGroup}
          form={form}
        />
      )}
      {visibleAddAsk && (
        <NovaPerguntaGrupoChecklist
          visible={visibleAddAsk}
          onCancel={handleCloseQuestionAdd}
          handleAddAsk={handleAddAsk}
          formPerguntas={formPerguntas}
          groups={groups}
          groupId={groupId}
        />
      )}
    </App>

  );
};

export default CadastroTemplatesChecklist;