import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Skeleton } from '@components-teammove';
import { InfoUsuario } from '@utils';
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay
} from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { getUsuarios } from '@sdk/Chamados';
import { reset } from '@ducks/chamadosKanban';
import { changeVisibleBriefingReply , getChamado } from '@ducks/chamadosHistorico';
import { putTicketClassificationOption,delTicketClassificationOption, postTicketClassificationOption } from '@ducks/classificacoes';
import Column from './Column';
import CardLayout from './CardLayout';
import CardInfo from './CardLayout/CardInfo';
import MenuItem from '../Chat/MenuItem/Index';
import { mapHistoricos } from '../Chat/rules';
import { BoardContainer } from './styles';
import { STATUS } from '../rules';

export default function Kanban({ classificationId, seeSteps, ...props }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const ticketsByClassification = useSelector(({ chamadosKanban }) => chamadosKanban.get('ticketsByClassification'));
  const loadingGetTicketsByClassifications = useSelector(({ chamadosKanban }) => chamadosKanban.get('loadingGetTicketsByClassifications'));
  const successGetTicketsByClassifications = useSelector(({ chamadosKanban }) => chamadosKanban.get('successGetTicketsByClassifications'));

  const visibleMenu = useSelector(({ chamadosKanban }) => chamadosKanban.get('visibleTicketMenu'));  
  const ticketInfo = useSelector(({ chamadosKanban }) => chamadosKanban.get('ticketInfo'));
  const successSetTicketInfo = useSelector(({ chamadosKanban }) => chamadosKanban.get('successSetTicketInfo'));

  const historicos = useSelector(({ chamadosHistorico }) => mapHistoricos(chamadosHistorico.get('historicos').toList().toArray(), ticketInfo));

  const [columns, setColumns] = useState(ticketsByClassification);
  const [columnsFilteredBySeeSteps, setColumnsFilteredBySeeSteps] = useState([]);
  const [activeBeingDraggedId, setActiveBeingDraggedId] = useState(null);
  const [possibleAssignees, setPossibleAssignees] = useState([]);

  useEffect(() => {
    if(!successGetTicketsByClassifications) return;
    setColumns(ticketsByClassification);
  }, [successGetTicketsByClassifications]);

  useEffect(() => {
    if(!successSetTicketInfo) return;
    getUsuarios(true, ticketInfo.categories[0].id, ticketInfo.company.id).then((usuarios) => {
      setPossibleAssignees(usuarios);
    });

    return () => {
      dispatch(reset());
    };
  }, [successSetTicketInfo]);

  useEffect(() => {
    setColumnsFilteredBySeeSteps(columns.map((column) => ({ ...column, tickets: column.tickets?.filter((ticket) => seeSteps || !ticket.parentTicket) })));
  }, [seeSteps, columns]);

  useEffect(() => {
    if(!visibleMenu) return;

    history.replace(`${location.pathname}?id=${ticketInfo.id}`);
    dispatch(getChamado(ticketInfo.id));
  }, [visibleMenu, ticketInfo]);

  const cardDragged = useMemo(() => {
    if(!activeBeingDraggedId) return null;

    for (const column of columns) {
      const foundCard = column.tickets.find((ticket) => ticket.id === activeBeingDraggedId);
      if (foundCard) return foundCard;
    }
  
    return null;
  }, [activeBeingDraggedId]); 

  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 5 } })
  );

  function handleDragStart(event) {
    const { active } = event;
    setActiveBeingDraggedId(active.id);
  }

  function handleDragEnd(event) {
    const { active, over } = event;
    if (!over) return;
    const activeColumnId = columns.find((col) =>
      col.tickets.some((ticket) => ticket.id === active.id)
    )?.id;

    if (!activeColumnId) return;

    const activeColumn = columns.find((col) => col.id === activeColumnId);
    if (!activeColumn) return;

    const overColumnId = columns.find((col) => 
      col.tickets.some((ticket) => ticket.id === over.id)
    )?.id || columns.find((col) => col.id === over.id)?.id || activeColumnId;
  
    const overColumn = columns.find((col) => col.id === overColumnId);  

    const oldIndex = activeColumn.tickets.findIndex((ticket) => ticket.id === active.id);
    let newIndex = activeColumn.tickets.findIndex((ticket) => ticket.id === over.id);

    if (newIndex === -1 || newIndex === undefined) {
      newIndex = overColumn?.tickets.length ?? 0;
    }

    let newColumns = [...columns];
    
    if (activeColumnId === overColumnId) {
    // Apenas reordena dentro da mesma coluna
      newColumns = newColumns.map((col) => {
        if (col.id === activeColumnId) {
          return {
            ...col,
            tickets: arrayMove(col.tickets, oldIndex, newIndex)
          };
        }
        return col;
      });
    } else {
    // Move para outra coluna
      const ticketToMove = activeColumn.tickets[oldIndex];

      newColumns = newColumns.map((col) => {
        if (col.id === activeColumnId) {
          return {
            ...col,
            tickets: col.tickets.filter((ticket) => ticket.id !== active.id),
            numberOfTickets: col.numberOfTickets - 1
          };
        }
        if (col.id === overColumnId) {
          const updatedTickets = [...col.tickets];
          updatedTickets.splice(newIndex, 0, ticketToMove);
          return {
            ...col,
            tickets: updatedTickets,
            numberOfTickets: col.numberOfTickets + 1
          };
        }
        return col;
      });
    }

    setColumns(newColumns);

    if(active.id && classificationId && overColumnId) {
      if (activeColumnId === -1) {
        dispatch(postTicketClassificationOption(active.id, classificationId, overColumnId));
      } else if (overColumnId === -1) {
        dispatch(delTicketClassificationOption(active.id, classificationId, activeColumnId));
      } else {
        dispatch(putTicketClassificationOption(active.id, classificationId, overColumnId));
      }
    }
    setActiveBeingDraggedId(null);
  }

  const handleOpenChat = () => {
    if (ticketInfo.status.longId === STATUS.AGUARDANDO_BRIEFING && ticketInfo.creator.id === InfoUsuario.get('cdUsuario')) {
      handleRedirectToBriefingReply(ticketInfo);
    } else {
      // if(!busca) dispatch(setChamadoId(ticketInfo.id));
      // sessionStorage.setItem('chamadosTickets/Limit', JSON.stringify(chamados.length));
      history.push(`/chamados/tickets/${ticketInfo.id}`);
    }
  };

  const handleRedirectToBriefingReply = (ticket) => {
    dispatch(changeVisibleBriefingReply(true));
    history.push(`?ticket=${ticket.id}`); 
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <BoardContainer>
        {loadingGetTicketsByClassifications && !successGetTicketsByClassifications ? (
          <Skeleton.Kanban/>
        ) : (
          columnsFilteredBySeeSteps.map((column) => (
            <Column key={column.id} column={column}/>
          ))
        )} 
        {visibleMenu && (
          <MenuItem possibleAssignees={possibleAssignees} data={ticketInfo} historicos={historicos} handleOpenChat={handleOpenChat}/>
        )}
      </BoardContainer>
      <DragOverlay>
        {activeBeingDraggedId ? (
          <CardLayout cardInfo={cardDragged}>
            <CardInfo ticket={cardDragged} />
          </CardLayout>
        ) : null}
      </DragOverlay>
    </DndContext>
  );
}