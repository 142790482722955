import { stateOptions } from '../../utils/Geography';
import { TelasNomenclaturas } from '@utils';

import { getClustersByGroup } from '@ducks/gestaoCarteira';

const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

export const customFields = {
  CNPJ: 'cnpj',
  IE: 'stateRegistration',
  RAZAO_SOCIAL: 'fullName',
  TELEFONE_1: 'telephone',
  TELEFONE_2: 'telephoneSecond',
  CELULAR_1: 'cell',
  CELULAR_2: 'cellSecond',
  LICENCIADO: 'licensed',
  LOCALIZACAO: 'location',
  PROJETO: 'typeProject',
  SEGMENTO: 'segment',
  INAUGURACAO: 'dtOpening',
  MEDIDAS_VITRINE: 'showcaseMeasures',
  METRAGEM: 'totalFootage',
  PARTICIPACAO: 'participation',
  SISTEMA: 'system',
};

export const customFieldsWithList = [
  'SISTEMA', 'LOCALIZACAO', 'PROJETO', 'SEGMENTO'
];

export const initialFilters = [
  {
    order: 1,
    type: 'SELECT',
    label: 'Agrupador',
    name: 'companyGroups',
    placeholder: 'Selecione os Agrupadores',
    options: []
  },
  {
    order: 2,
    type: 'SELECT',
    label: 'Estado',
    name: 'states',
    placeholder: 'Selecione os Estados',
    options: stateOptions
  },
  {
    order: 3,
    type: 'SELECT',
    label: `Tipo de ${companyNomenclature.nomenclatura}`,
    name: 'companyKinds',
    placeholder: `Selecione ${companyNomenclature.artigo}s ${companyNomenclature.nomenclatura}s`,
    options: []
  },
  {
    order: 4,
    type: 'SELECT',
    label: `Cluster de ${companyNomenclature.nomenclatura}s`,
    name: 'clusterGroups',
    placeholder: 'Selecione um grupo de cluster',
    options: [],
  },
  {
    order: 5,
    type: 'SELECT',
    label: 'Cluster',
    name: 'clusters',
    placeholder: 'Selecione os clusters',
    options: [],
    dependsOn: 'clusterGroups',
    request: getClustersByGroup,
    hasParam: true,
  },
];