import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Modal, Form, Input, Select, Skeleton, Row, Button, View, Icon, Column } from '@components-teammove';
import { useDispatch, useSelector } from 'react-redux';
import { getCluster, resetSuccess, saveCluster, resetCluster, deleteCluster, moveCompanieCluster } from '@ducks/configuracoesUnidadesClusters';
import { ActivityIcon, ActivityName, AddClusterButton, ClusterColumns, ClusterColumnsTitle, FixedHeightButton, HorizontalLine, IconWrapper, InactiveTag, NumberInput, ActionButton, StyleSelect, Title, ViewContainer } from './styles';
import { getPerfis } from '@sdk/Perfil';
import { getDadosCadastro } from '@ducks/atividades';
import { TelasNomenclaturas, arrayReorder } from '@utils';
import { getTiposAtividades } from '@ducks/configuracoesAtividadesTipos';
import { getTiposUnidades } from '@ducks/configuracoesUnidadesTiposUnidades';
import ModalMoverUnidades from './ModalMoverUnidades';

export default function ClustersCadastro({ visible, onCancel }) {
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const grupoClusters = useSelector(({ configuracoesUnidadesClusters }) => configuracoesUnidadesClusters.get('grupoClusters'));
  const loading = useSelector(({ configuracoesUnidadesClusters }) => configuracoesUnidadesClusters.get('loading'));
  const success = useSelector(({ configuracoesUnidadesClusters }) => configuracoesUnidadesClusters.get('success'));

  const loadingSave = useSelector(({ configuracoesUnidadesClusters }) => configuracoesUnidadesClusters.get('loadingSave'));
  const successSave = useSelector(({ configuracoesUnidadesClusters }) => configuracoesUnidadesClusters.get('successSave'));
  const loadingDelete = useSelector(({ configuracoesUnidadesClusters }) => configuracoesUnidadesClusters.get('loadingDelete'));
  const successDelete = useSelector(({ configuracoesUnidadesClusters }) => configuracoesUnidadesClusters.get('successDelete'));

  const active = useSelector(({ configuracoesAtividadesTipos }) => configuracoesAtividadesTipos.get('active'));
  const inactive = useSelector(({ configuracoesAtividadesTipos }) => configuracoesAtividadesTipos.get('inactive'));
  const loadingTiposAtividades = useSelector(({ configuracoesAtividadesTipos }) => configuracoesAtividadesTipos.get('loadingTiposAtividades'));

  const companyTypes = useSelector(({ configuracoesUnidadesTiposUnidades }) => configuracoesUnidadesTiposUnidades.get('tiposUnidades'));
  const loadingCompany = useSelector(({ configuracoesUnidadesTiposUnidades }) => configuracoesUnidadesTiposUnidades.get('loading'));

  const [newClusters, setNewClusters] = useState([]);
  const [clusters, setClusters] = useState([]);
  const [visibleConfirmExclusionModal, setVisibleConfirmExclusionModal] = useState(false);
  const [originalFieldsValue, setOriginalFieldsValue] = useState();
  const [idsToMove, setIdsToMove] = useState([]);
  const [clusterToRemove, setClusterToRemove] = useState(null);

  const agroupActivities = [...inactive, ...active];

  const id = location.state?.cluster.id;

  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  const clustersOptions  = useMemo(() => {
    return [
      ...clusters
        .filter((clusterFilter) => clusterFilter.id !== clusterToRemove.id)
        .map((clusterMap) => ({ label: clusterMap.nome, value: clusterMap.id })),
      { label: 'Sem cluster', value: -1 }
    ];
  }, [clusterToRemove]);

  useEffect(() => {
    if (id) {

      dispatch(getCluster(id));
    }
    dispatch(getDadosCadastro());
    dispatch(getTiposAtividades());
    dispatch(getTiposUnidades());

    return () => {
      dispatch(resetCluster());
      dispatch(resetSuccess());
      if (id) {
        history.goBack();
      }
    };
  }, []);

  useEffect(() => {
    if (success && id) {
      setClusters(grupoClusters.clusters);
      const clustersInfo = {};
      for (const cluster of grupoClusters.clusters) {
        const propriedadeNome = `nameClus${cluster.id}`;
        const propriedadeQtDias = `dayClus${cluster.id}`;
        const propriedadeOrdem = `ordemClus${cluster.id}`;
        const propriedadeQntCompanies = `qntCompanies${cluster.id}`;

        clustersInfo[propriedadeNome] = cluster.nome;
        clustersInfo[propriedadeQtDias] = cluster.qtDias;
        clustersInfo[propriedadeOrdem] = cluster.ordem;
        clustersInfo[propriedadeQntCompanies] = cluster.numberOfCompanies;
      }
      form.setFieldsValue({ ...grupoClusters, ...clustersInfo });
      setOriginalFieldsValue(form.getFieldsValue(true));
    }
  }, [success]);

  useEffect(() => {
    const activitiesCluster = {};
    for (const cluster of clusters) {
      const propriedadeQntCompanies = `qntCompanies${cluster.id}`;

      activitiesCluster[propriedadeQntCompanies] = cluster.numberOfCompanies;
    }
    form.setFieldsValue({ ...activitiesCluster });
  }, [clusters]);

  useEffect(() => {
    if (successSave || successDelete) {
      onCancel();
    }
  }, [successSave, successDelete]);

  const handleDelete = ({ id }) => {
    dispatch(deleteCluster(id));
  };

  const handleSalvar = () => {
    form.validateFields().then(async (values) => {
      let saveObj = {};
      const formValuesWithoutClusters = Object.keys(values)
        .filter(
          (key) => !key.includes('nameClus') && !key.includes('dayClus') && !key.startsWith('qntCompanies') && !key.startsWith('ordemClus')
        )
        .reduce((acc, cur) => ({ ...acc, [cur]: values[cur] }), {});

      const clustersToSend = Object.keys(values)
        .filter((key) => key.includes('nameClus') || key.includes('dayClus') || key.includes('ordemClus'))
        .reduce((acc, cur) => {
          const match = cur.match(/(name|day|ordem)Clus(\d+)/);
          if (match) {
            // eslint-disable-next-line no-unused-vars
            const [_, type, id] = match;
            const idValue = parseInt(id);
            const existingObj = acc.find((obj) => obj.id === idValue);
            existingObj
              ? Object.assign(existingObj, { id: idValue, [type === 'name' ? 'nome' : type === 'day' ? 'qtDias' : 'ordem']: values[cur] })
              : acc.push({ id: idValue, [type === 'name' ? 'nome' : type === 'day' ? 'qtDias' : 'ordem']: values[cur] });
          }
          return acc;
        }, []);

      const newClustersWithoutId = newClusters.filter((objeto) => objeto.nome !== '' && objeto.qtDias !== 0)
        .map(({ id, ...restoDoObjeto }, index) => ({ ...restoDoObjeto, ordem: clusters.length + index + 1 }));

      saveObj = {
        id: grupoClusters?.id,
        ...formValuesWithoutClusters,
        clusters: [...clustersToSend, ...newClustersWithoutId]
      };
      dispatch(saveCluster(saveObj));
      dispatch(moveCompanieCluster(idsToMove));
    });
  };

  const renderActivityTypeOption = (activityType, selectedValues) => {
    const isSelected = selectedValues.includes(activityType.id);
    return (
      <Row gap='5px' align='center'>
        <IconWrapper color={activityType.cor}>
          <ActivityIcon type={activityType.icone} size='18px' />
        </IconWrapper>
        <ActivityName>{activityType.nome}</ActivityName>
        {!activityType.ativo && !isSelected && <InactiveTag>Inativo</InactiveTag>}
      </Row>
    );
  };

  const addCluster = () => {
    setNewClusters([...newClusters, {
      id: newClusters.length + 1,
      nome: '',
      qtDias: 0
    }]);
  };

  const handleClusterName = (item, id, value) => {
    setNewClusters(newClusters.map((cluster) => (
      cluster.id === id ? { ...cluster, nome: value.target.value } : { ...cluster }
    )));
  };

  const handleClusterDay = (item, id, value) => {
    setNewClusters(newClusters.map((cluster) => (
      cluster.id === id ? { ...cluster, qtDias: value } : { ...cluster }
    )));
  };

  const onBeforeCancel = () => {
    if(originalFieldsValue) {
      const currentFormValues = form.getFieldsValue(true);
      const valuesChanged = !Object.entries(originalFieldsValue).every(
        ([key, value]) => currentFormValues[key] === value
      );
      if(valuesChanged) return valuesChanged;
      const numberOfClusterHaveChanged = grupoClusters?.clusters.length !== clusters.length || newClusters.length > 0;
      return numberOfClusterHaveChanged;
    } 
    return form.isFieldsTouched();  
  }; 

  const handleDeleteUniqueCluster = (cluster, isClusterNew) => {
    if (cluster.numberOfCompanies > 0) {
      setClusterToRemove(cluster);
      setVisibleConfirmExclusionModal(true); 
    } else {
      isClusterNew
        ? setNewClusters(newClusters.filter((newCluster) => newCluster.id !== cluster.id))
        : setClusters(clusters.filter((oldCluster) => oldCluster.id !== cluster.id));
    }
  };

  const handleCloseModalMoverUnidades = (newClusterId) => {             
    if(newClusterId) {             
      setIdsToMove([...idsToMove, { oldCluster: clusterToRemove.id, newCluster: newClusterId }]);
      setClusters(clusters.map((clusterMap) => {
        if (clusterMap.id === newClusterId) {
          return {
            ...clusterMap,
            numberOfCompanies: clusterMap.numberOfCompanies + clusterToRemove.numberOfCompanies
          };
        }
        return clusterMap;
      }).filter((clusterFilter) => clusterFilter.id !== clusterToRemove.id));
    }
    setVisibleConfirmExclusionModal(false);
  };

  const handleReorder = (currentIndex, newIndex) => {
    const clusterOnCurrentIndex = clusters[currentIndex];
    const clusterOnNewIndex = clusters[newIndex];

    form.setFieldsValue({
      ['ordemClus' + clusterOnCurrentIndex.id]: newIndex,
      ['ordemClus' + clusterOnNewIndex.id]: currentIndex
    });

    const newClusters = arrayReorder(clusters, currentIndex, newIndex)
      .map((customField, index) => ({ ...customField, order: index }));
          
    setClusters(newClusters);
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title={'Configurações / ' + (companyNomenclature.plural) + ' / Clusters'}
      numberOfButtons={id ? 2 : 1}
      cancelText='Excluir'
      onBeforeCancel={onBeforeCancel}
      cancelButtonConfirm
      cancelButtonConfirmProps={{ title: 'Deseja realmente excluir este grupo de cluster?', onConfirm: () => handleDelete(grupoClusters) }}
      cancelButtonProps={{ loading: loadingDelete, type: 'danger', onClick: null, title: grupoClusters?.inUseByCompanies ? `Grupo em uso por ${(companyNomenclature.artigo) === 'a' ? 'uma' : 'um'} ou mais ${companyNomenclature.plural}` : 'Excluir grupo de cluster', disabled: grupoClusters?.inUseByCompanies }}
      onOk={handleSalvar}
      okButtonProps={{ loading: loadingSave }}
    >
      {(loading || loadingTiposAtividades || loadingCompany) ? <Skeleton.Form /> : (
        <>
          <Form gap='20px' layout='vertical' form={form}>
            <Form.Item label='Grupo de cluster' name='name' rules={[{ required: true, message: 'Informe o grupo de cluster!' }]} colon={false}>
              <Input placeholder='Informe o grupo de cluster' autoFocus />
            </Form.Item>

            <Form.Item tooltip='Informe o perfil de usuários responsáveis por realizar as atividades' label='Perfis responsáveis' name='assigneeProfiles' rules={[{ required: true, message: 'Informe os perfis responsável!' }]} colon={false}>
              <Select mode="multiple" placeholder='Selecione o perfil responsavel' action={getPerfis} />
            </Form.Item>

            <Form.Item tooltip='Informe o perfil de usuário que pode visualizar este cluster' label='Perfis visualização' name='visualizationProfiles' rules={[{ required: true, message: 'Selecione o perfil' }]} colon={false}>
              <Select mode="multiple" placeholder='Selecione o perfil' action={getPerfis} />
            </Form.Item>

            <Form.Item label='Tipos de atividade' name='typesOfActivities' rules={[{ required: true, message: 'Selecione os tipos de atividades do cluster' }]} colon={false}>
              <StyleSelect
                placeholder='Selecione os tipos de atividades do cluster'
                options={agroupActivities.map((activityType) => ({
                  label: renderActivityTypeOption(activityType, form.getFieldValue('typesOfActivities') || []),
                  title: activityType.nome,
                  value: activityType.id,
                  style: { opacity: activityType.ativo ? 1 : 0.5 },
                }))}
                loading={loadingTiposAtividades}
                mode="multiple"
              />
            </Form.Item>

            <Form.Item label='Tipos de unidade' name='companyKinds' rules={[{ required: true, message: 'Selecione os tipos de unidade do cluster' }]} colon={false}>
              <Select
                options={companyTypes?.map((companyType) => ({ label: companyType.name, title: companyType.name, value: companyType.id }))}
                placeholder='Selecione os tipos de unidade do cluster'
                loading={loadingCompany}
                mode="multiple"
              />
            </Form.Item>

            <HorizontalLine />
          </Form>

          <Title>Clusters</Title>
          <Form layout='vertical' form={form}>
            <Column gap='20px'>
              {clusters?.map((cluster, index) => (
                <Row gap='12px' align='flex-end' key={cluster.id}>
                  <Form.Item label={index === 0 && 'Nome do cluster'} name={'nameClus' + cluster.id} rules={[{ required: true, message: 'Digite o nome do cluster' }]} colon={false} marginless>
                    <Input label='Nome do cluster' placeholder='Digite o nome do cluster' />
                  </Form.Item>
                  <Form.Item label={index === 0 && 'Frequência'} name={'dayClus' + cluster.id} rules={[{ required: true, message: 'Digite o número de dias do cluster' }]} colon={false} marginless>
                    <NumberInput label='Quantidade de dias' placeholder='Digite o nº de dias do cluster' />
                  </Form.Item>
                  <Form.Item label={index === 0 && `Qnt. de ${(companyNomenclature.plural).toLowerCase()}`} name={'qntCompanies' + cluster.id} colon={false} marginless>
                    <NumberInput label='Unidades' disabled placeholder={form.getFieldValue(`qntCompanies${cluster.id}`) === 0 ? 0 : ''}/>
                  </Form.Item>
                  <Form.Item name={'ordemClus' + cluster.id} hidden/>
                  <Button.Group>
                    <ActionButton
                      type='secondary'
                      size='smaller'
                      context='list'
                      icon={<Icon.UpOutlined />}
                      title='Mover para cima'
                      disabled={index === 0}
                      onClick={() => handleReorder(index, index - 1)}
                    />
                    <ActionButton
                      type='secondary'
                      size='smaller'
                      context='list'
                      icon={<Icon.DownOutlined />}
                      title='Mover para baixo'
                      disabled={index === clusters.length - 1}
                      onClick={() => handleReorder(index, index + 1)}
                    />
                  </Button.Group>
                  <ActionButton type='secondary' size='small' icon={<Icon.DeleteOutlined />} title='Excluir' onClick={() => handleDeleteUniqueCluster(cluster, false)} />
                </Row>
              ))}

              {newClusters.map((item, index) => (
                <Row gap='12px' key={item.id}>
                  <Form.Item marginless>
                    <Input value={item.nome} name='nomeNew' onChange={(value) => handleClusterName(item, item.id, value)} placeholder='Digite o nome do cluster' />
                  </Form.Item>
                  <Form.Item marginless>
                    <NumberInput value={item.qtDias} name='qtDiasNew' onChange={(value) => handleClusterDay(item, item.id, value)} placeholder='Digite o nº de dias do cluster' />
                  </Form.Item>
                  <ActionButton type='secondary' size='small' icon={<Icon.DeleteOutlined />} title='Excluir' onClick={() => handleDeleteUniqueCluster(item, true)} />
                </Row>
              ))}

              <AddClusterButton>
                <Button fillwidth type='secondary' onClick={addCluster}><Icon.Feather type='plus' />Adicionar cluster</Button>
              </AddClusterButton>
            </Column>
            <HorizontalLine top='20px' />

          </Form>
          {visibleConfirmExclusionModal && (
            <ModalMoverUnidades
              clustersOptions ={clustersOptions}
              clusterToRemove={clusterToRemove}
              visibleConfirmExclusionModal={visibleConfirmExclusionModal}
              onClose={handleCloseModalMoverUnidades}
            />
          )}
        </>
      )}
    </Modal>
  );
}