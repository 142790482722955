import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import { Notification } from '@utils';
import {
  getWorkingJourneyList as getWorkingJourneyListSdk,
  getProfileRegisterData as getProfileRegisterDataSdk,
  createProfile as createProfileSdk,
  updateProfile as updateProfileSdk,
  deleteProfile as deleteProfileSdk,
  updateNodeParentProfile as updateNodeParentProfileSdk,
  getProfileHierarchy as getProfileHierarchySdk,
  getHomeIndicatorList as getHomeIndicatorListSdk,
} from '@sdk/PerfilUsuarios';

// Action Types
export const Types = {
  GET_WORKING_JOURNEY_LIST: 'PERFIL_USUARIOS/GET_WORKING_JOURNEY_LIST',
  GET_WORKING_JOURNEY_LIST_SUCCESS:
    'PERFIL_USUARIOS/GET_WORKING_JOURNEY_LIST_SUCCESS',
  GET_WORKING_JOURNEY_LIST_ERROR:
    'PERFIL_USUARIOS/GET_WORKING_JOURNEY_LIST_ERROR',

  GET_HOME_INDICATOR_LIST: 'PERFIL_USUARIOS/GET_HOME_INDICATOR_LIST',
  GET_HOME_INDICATOR_LIST_SUCCESS:
    'PERFIL_USUARIOS/GET_HOME_INDICATOR_LIST_SUCCESS',
  GET_HOME_INDICATOR_LIST_ERROR:
    'PERFIL_USUARIOS/GET_HOME_INDICATOR_LIST_ERROR',

  GET_PROFILE_REGISTER_DATA: 'PERFIL_USUARIOS/GET_PROFILE_REGISTER_DATA',
  GET_PROFILE_REGISTER_DATA_SUCCESS:
    'PERFIL_USUARIOS/GET_PROFILE_REGISTER_DATA_SUCCESS',
  GET_PROFILE_REGISTER_DATA_ERROR:
    'PERFIL_USUARIOS/GET_PROFILE_REGISTER_DATA_ERROR',

  CREATE_PROFILE: 'PERFIL_USUARIOS/CREATE_PROFILE',
  CREATE_PROFILE_SUCCESS: 'PERFIL_USUARIOS/CREATE_PROFILE_SUCCESS',
  CREATE_PROFILE_ERROR: 'PERFIL_USUARIOS/CREATE_PROFILE_ERROR',

  UPDATE_PROFILE: 'PERFIL_USUARIOS/UPDATE_PROFILE',
  UPDATE_PROFILE_SUCCESS: 'PERFIL_USUARIOS/UPDATE_PROFILE_SUCCESS',
  UPDATE_PROFILE_ERROR: 'PERFIL_USUARIOS/UPDATE_PROFILE_ERROR',

  DELETE_PROFILE: 'PERFIL_USUARIOS/DELETE_PROFILE',
  DELETE_PROFILE_SUCCESS: 'PERFIL_USUARIOS/DELETE_PROFILE_SUCCESS',
  DELETE_PROFILE_ERROR: 'PERFIL_USUARIOS/DELETE_PROFILE_ERROR',

  UPDATE_NODE_PARENT_PROFILE: 'PERFIL_USUARIOS/UPDATE_NODE_PARENT_PROFILE',
  UPDATE_NODE_PARENT_PROFILE_SUCCESS:
    'PERFIL_USUARIOS/UPDATE_NODE_PARENT_PROFILE_SUCCESS',
  UPDATE_NODE_PARENT_PROFILE_ERROR:
    'PERFIL_USUARIOS/UPDATE_NODE_PARENT_PROFILE_ERROR',

  GET_PROFILE_HIERARCHY: 'PERFIL_USUARIOS/GET_PROFILE_HIERARCHY',
  GET_PROFILE_HIERARCHY_SUCCESS:
    'PERFIL_USUARIOS/GET_PROFILE_HIERARCHY_SUCCESS',
  GET_PROFILE_HIERARCHY_ERROR: 'PERFIL_USUARIOS/GET_PROFILE_HIERARCHY_ERROR',

  RESET: 'PERFIL_USUARIOS/RESET',
  RESET_PROFILE_REGISTER_DATA: 'PERFIL_USUARIOS/RESET_PROFILE_REGISTER_DATA',
  RESET_PROFILE_HIERARCHY: 'PERFIL_USUARIOS/RESET_PROFILE_HIERARCHY',
  RESET_CREATE_OR_UPDATE_PROFILE:
    'PERFIL_USUARIOS/RESET_CREATE_OR_UPDATE_PROFILE',
};

// Utility Functions (moved from SDK)
const transformToTree = (items) => {
  if (!items || items.length === 0) return [];

  // Coleta todos os permissionIds válidos em um objeto para consulta rápida
  const validIds = {};
  items.forEach((item) => {
    validIds[item.permissionId] = true;
  });

  // Agrupamos os itens por parentPermissionId
  const groupedByParent = items.reduce((acc, item) => {
    // Se o parentPermissionId não existe na lista, tratamos como 'root'
    const parentId =
      item.parentPermissionId && validIds[item.parentPermissionId]
        ? item.parentPermissionId
        : 'root';

    if (!acc[parentId]) {
      acc[parentId] = [];
    }
    acc[parentId].push({
      title: item.name,
      key: item.permissionId,
      disabled: false,
      value: item.value,
      valueProfile: item.valueProfile,
      orderProfile: item.orderProfile,
      children: [],
    });
    return acc;
  }, {});

  // Função recursiva para construir a árvore
  const buildTree = (parentId = 'root') => {
    const children = groupedByParent[parentId] || [];
    return children.map((node) => {
      const childNodes = buildTree(node.key);
      if (childNodes.length > 0) {
        node.children = childNodes;
      }
      return node;
    });
  };

  return buildTree();
};

// Action Creators
export const getWorkingJourneyList = () => ({
  type: Types.GET_WORKING_JOURNEY_LIST,
});
export const getWorkingJourneyListSuccess = (workingJourneyList) => ({
  type: Types.GET_WORKING_JOURNEY_LIST_SUCCESS,
  workingJourneyList,
});
export const getWorkingJourneyListError = (error) => ({
  type: Types.GET_WORKING_JOURNEY_LIST_ERROR,
  error,
});

export const getHomeIndicatorList = () => ({
  type: Types.GET_HOME_INDICATOR_LIST,
});
export const getHomeIndicatorListSuccess = (homeIndicatorList) => ({
  type: Types.GET_HOME_INDICATOR_LIST_SUCCESS,
  homeIndicatorList,
});
export const getHomeIndicatorListError = (error) => ({
  type: Types.GET_HOME_INDICATOR_LIST_ERROR,
  error,
});

export const getProfileRegisterData = (profileId) => ({
  type: Types.GET_PROFILE_REGISTER_DATA,
  profileId,
});
export const getProfileRegisterDataSuccess = (data) => ({
  type: Types.GET_PROFILE_REGISTER_DATA_SUCCESS,
  data,
});
export const getProfileRegisterDataError = (error) => ({
  type: Types.GET_PROFILE_REGISTER_DATA_ERROR,
  error,
});

export const createProfile = (profileData) => ({
  type: Types.CREATE_PROFILE,
  profileData,
});
export const createProfileSuccess = (data) => ({
  type: Types.CREATE_PROFILE_SUCCESS,
  data,
});
export const createProfileError = (error) => ({
  type: Types.CREATE_PROFILE_ERROR,
  error,
});

export const updateProfile = (profileData) => ({
  type: Types.UPDATE_PROFILE,
  profileData,
});
export const updateProfileSuccess = (data) => ({
  type: Types.UPDATE_PROFILE_SUCCESS,
  data,
});
export const updateProfileError = (error) => ({
  type: Types.UPDATE_PROFILE_ERROR,
  error,
});

export const deleteProfile = (profileId) => ({
  type: Types.DELETE_PROFILE,
  profileId,
});
export const deleteProfileSuccess = (data) => ({
  type: Types.DELETE_PROFILE_SUCCESS,
  data,
});
export const deleteProfileError = (error) => ({
  type: Types.DELETE_PROFILE_ERROR,
  error,
});

export const updateNodeParentProfile = (draggedId, targetId) => ({
  type: Types.UPDATE_NODE_PARENT_PROFILE,
  draggedId,
  targetId,
});
export const updateNodeParentProfileSuccess = (data) => ({
  type: Types.UPDATE_NODE_PARENT_PROFILE_SUCCESS,
  data,
});
export const updateNodeParentProfileError = (error) => ({
  type: Types.UPDATE_NODE_PARENT_PROFILE_ERROR,
  error,
});

export const getProfileHierarchy = () => ({
  type: Types.GET_PROFILE_HIERARCHY,
});
export const getProfileHierarchySuccess = (data) => ({
  type: Types.GET_PROFILE_HIERARCHY_SUCCESS,
  data,
});
export const getProfileHierarchyError = (error) => ({
  type: Types.GET_PROFILE_HIERARCHY_ERROR,
  error,
});

export const reset = () => ({ type: Types.RESET });
export const resetProfileRegisterData = () => ({
  type: Types.RESET_PROFILE_REGISTER_DATA,
});
export const resetProfileHierarchy = () => ({
  type: Types.RESET_PROFILE_HIERARCHY,
});

export const resetCreateOrUpdateProfile = () => ({
  type: Types.RESET_CREATE_OR_UPDATE_PROFILE,
});

// Sagas
function* fetchWorkingJourneyList() {
  try {
    const workingJourneyList = yield call(getWorkingJourneyListSdk);
    yield put(getWorkingJourneyListSuccess(workingJourneyList));
  } catch (err) {
    Notification.error(err.message);
    yield put(getWorkingJourneyListError(err));
  }
}

function* fetchHomeIndicatorList() {
  try {
    const homeIndicatorList = yield call(getHomeIndicatorListSdk);
    yield put(getHomeIndicatorListSuccess(homeIndicatorList));
  } catch (err) {
    Notification.error(err.message);
    yield put(getHomeIndicatorListError(err));
  }
}

function* fetchProfileRegisterData(action) {
  try {
    const { profileId } = action;
    const profileRegisterData = yield call(
      getProfileRegisterDataSdk,
      profileId
    );

    // Transform data here
    const {
      profile,
      actionPermissions,
      visualizationPermissions,
      activitiesTypes,
    } = profileRegisterData;

    const getPermissions = (permissions) => {
      return permissions?.map((permission) => ({
        ...permission,
        parentPermissionId: permission.parentPermissionId || null,
      }));
    };

    const findProfilePermission = (permissions, permissionId) => {
      return permissions?.find((item) => item.permissionId === permissionId);
    };

    const transformPermissionsWithProfile = (
      permissions,
      profilePermissions
    ) => {
      return getPermissions(permissions)?.map((permission) => {
        const profilePermission = findProfilePermission(
          profilePermissions,
          permission.permissionId
        );
        return {
          ...permission,
          valueProfile: profilePermission?.value ?? false,
          orderProfile: profilePermission?.order ?? 0,
        };
      });
    };

    const transformActivitiesTypes = (activities, permissions) => {
      return activities?.map((activity) => {
        const permission =
          permissions?.find(
            (permission) => permission.activityTypeId === activity.id
          ) || {};
        return {
          ...activity,
          canView: permission.canView || false,
          canInsert: permission.canInsert || false,
          fastAction: permission.fastAction || false,
          order: permission.order || 0,
        };
      });
    };

    const transformedData = {
      ...profileRegisterData,
      profile: {
        ...profile,
        activitiesTypes: activitiesTypes,
      },
      actionPermissions: transformToTree(
        transformPermissionsWithProfile(
          actionPermissions,
          profile?.actionsPermissions
        )
      ),
      visualizationPermissions: transformToTree(
        transformPermissionsWithProfile(
          visualizationPermissions,
          profile?.visualizationsPermissions
        )
      ),
      activitiesTypes: transformActivitiesTypes(
        activitiesTypes,
        profile?.activitiesTypesPermissions
      ),
      workingJourneyId: profile?.workingJourneyId || null,
    };

    yield put(getProfileRegisterDataSuccess(transformedData));
  } catch (err) {
    Notification.error(err.message);
    yield put(getProfileRegisterDataError(err));
  }
}

function* fetchCreateProfile(action) {
  try {
    const { profileData } = action;
    const createdProfile = yield call(createProfileSdk, profileData);
    yield put(createProfileSuccess(createdProfile));
    Notification.success('Perfil criado com sucesso');
  } catch (err) {
    Notification.error(err.message);
    yield put(createProfileError(err));
  }
}

function* fetchUpdateProfile(action) {
  try {
    const { profileData } = action;
    const updatedProfile = yield call(updateProfileSdk, profileData);
    yield put(updateProfileSuccess(updatedProfile));
    Notification.success('Perfil atualizado com sucesso');
  } catch (err) {
    Notification.error(err.message);
    yield put(updateProfileError(err));
  }
}

function* fetchDeleteProfile(action) {
  try {
    const { profileId } = action;
    const deletedProfile = yield call(deleteProfileSdk, profileId);
    yield put(deleteProfileSuccess(deletedProfile));
    Notification.success('Perfil excluído com sucesso');
  } catch (err) {
    Notification.error(err.message);
    yield put(deleteProfileError(err));
  }
}

function* fetchUpdateNodeParentProfile(action) {
  try {
    const { draggedId, targetId } = action;
    const updatedProfile = yield call(
      updateNodeParentProfileSdk,
      draggedId,
      targetId
    );
    yield put(updateNodeParentProfileSuccess(updatedProfile));
  } catch (err) {
    Notification.error(err.message);
    yield put(updateNodeParentProfileError(err));
  }
}

function* fetchProfileHierarchy() {
  try {
    const res = yield call(getProfileHierarchySdk);

    // Prepare the response with transformed data
    const transformedRes = {
      ...res,
    };

    yield put(getProfileHierarchySuccess(transformedRes));
    return transformedRes;
  } catch (error) {
    yield put(getProfileHierarchyError(error));
    Notification.error({
      message: 'Erro ao carregar hierarquia de perfis',
      description:
        error.message || 'Não foi possível carregar a hierarquia de perfis',
    });
    throw error;
  }
}

export const saga = [
  takeLatest(Types.GET_WORKING_JOURNEY_LIST, fetchWorkingJourneyList),
  takeLatest(Types.GET_HOME_INDICATOR_LIST, fetchHomeIndicatorList),
  takeLatest(Types.GET_PROFILE_REGISTER_DATA, fetchProfileRegisterData),
  takeLatest(Types.CREATE_PROFILE, fetchCreateProfile),
  takeLatest(Types.UPDATE_PROFILE, fetchUpdateProfile),
  takeLatest(Types.DELETE_PROFILE, fetchDeleteProfile),
  takeLatest(Types.UPDATE_NODE_PARENT_PROFILE, fetchUpdateNodeParentProfile),
  takeLatest(Types.GET_PROFILE_HIERARCHY, fetchProfileHierarchy),
];

// Reducer
const initialState = Map({
  workingJourneyList: List(),
  loadingWorkingJourneyList: false,
  successWorkingJourneyList: false,
  errorWorkingJourneyList: false,

  homeIndicatorList: List(),
  loadingHomeIndicatorList: false,
  successHomeIndicatorList: false,
  errorHomeIndicatorList: false,

  profileRegisterData: Map(),
  loadingProfileRegisterData: false,
  successProfileRegisterData: false,
  errorProfileRegisterData: false,

  createdProfile: Map(),
  loadingCreateProfile: false,
  successCreateProfile: false,
  errorCreateProfile: false,

  updatedProfile: Map(),
  loadingUpdateProfile: false,
  successUpdateProfile: false,
  errorUpdateProfile: false,

  deletedProfile: Map(),
  loadingDeleteProfile: false,
  successDeleteProfile: false,
  errorDeleteProfile: false,

  updatedNodeParentProfile: Map(),
  loadingUpdateNodeParentProfile: false,
  successUpdateNodeParentProfile: false,
  errorUpdateNodeParentProfile: false,

  loadingProfileHierarchy: false,
  successProfileHierarchy: false,
  errorProfileHierarchy: false,
  hierarchyProfiles: null,
  independentProfiles: null,
});

const handleGetWorkingJourneyList = (state) => {
  return state
    .set('loadingWorkingJourneyList', true)
    .set('successWorkingJourneyList', false)
    .set('errorWorkingJourneyList', false);
};

const handleGetWorkingJourneyListSuccess = (state, action) => {
  const { workingJourneyList } = action;

  return state
    .set('workingJourneyList', List(workingJourneyList))
    .set('loadingWorkingJourneyList', false)
    .set('successWorkingJourneyList', true)
    .set('errorWorkingJourneyList', false);
};

const handleGetWorkingJourneyListError = (state, action) => {
  return state
    .set('loadingWorkingJourneyList', false)
    .set('successWorkingJourneyList', false)
    .set('errorWorkingJourneyList', action.error);
};

const handleGetHomeIndicatorList = (state) => {
  return state
    .set('loadingHomeIndicatorList', true)
    .set('successHomeIndicatorList', false)
    .set('errorHomeIndicatorList', false);
};

const handleGetHomeIndicatorListSuccess = (state, action) => {
  const { homeIndicatorList } = action;

  return state
    .set('homeIndicatorList', List(homeIndicatorList))
    .set('loadingHomeIndicatorList', false)
    .set('successHomeIndicatorList', true)
    .set('errorHomeIndicatorList', false);
};

const handleGetHomeIndicatorListError = (state, action) => {
  return state
    .set('loadingHomeIndicatorList', false)
    .set('successHomeIndicatorList', false)
    .set('errorHomeIndicatorList', action.error);
};

const handleGetProfileRegisterData = (state) => {
  return state
    .set('loadingProfileRegisterData', true)
    .set('successProfileRegisterData', false)
    .set('errorProfileRegisterData', false);
};

const handleGetProfileRegisterDataSuccess = (state, action) => {
  const { data } = action;

  return state
    .set('profileRegisterData', data)
    .set('loadingProfileRegisterData', false)
    .set('successProfileRegisterData', true)
    .set('errorProfileRegisterData', false);
};

const handleGetProfileRegisterDataError = (state, action) => {
  return state
    .set('loadingProfileRegisterData', false)
    .set('successProfileRegisterData', false)
    .set('errorProfileRegisterData', action.error);
};

const handleCreateProfile = (state) => {
  return state
    .set('loadingCreateProfile', true)
    .set('successCreateProfile', false)
    .set('errorCreateProfile', false);
};

const handleCreateProfileSuccess = (state, action) => {
  const { data } = action;

  return state
    .set('createdProfile', data)
    .set('loadingCreateProfile', false)
    .set('successCreateProfile', true)
    .set('errorCreateProfile', false);
};

const handleCreateProfileError = (state, action) => {
  return state
    .set('loadingCreateProfile', false)
    .set('successCreateProfile', false)
    .set('errorCreateProfile', action.error);
};

const handleUpdateProfile = (state) => {
  return state
    .set('loadingUpdateProfile', true)
    .set('successUpdateProfile', false)
    .set('errorUpdateProfile', false);
};

const handleUpdateProfileSuccess = (state, action) => {
  const { data } = action;

  return state
    .set('updatedProfile', data)
    .set('loadingUpdateProfile', false)
    .set('successUpdateProfile', true)
    .set('errorUpdateProfile', false);
};

const handleUpdateProfileError = (state, action) => {
  return state
    .set('loadingUpdateProfile', false)
    .set('successUpdateProfile', false)
    .set('errorUpdateProfile', action.error);
};

const handleDeleteProfile = (state) => {
  return state
    .set('loadingDeleteProfile', true)
    .set('successDeleteProfile', false)
    .set('errorDeleteProfile', false);
};

const handleDeleteProfileSuccess = (state, action) => {
  const { data } = action;

  return state
    .set('deletedProfile', data)
    .set('loadingDeleteProfile', false)
    .set('successDeleteProfile', true)
    .set('errorDeleteProfile', false);
};

const handleDeleteProfileError = (state, action) => {
  return state
    .set('loadingDeleteProfile', false)
    .set('successDeleteProfile', false)
    .set('errorDeleteProfile', action.error);
};

const handleUpdateNodeParentProfile = (state) => {
  return state
    .set('loadingUpdateNodeParentProfile', true)
    .set('successUpdateNodeParentProfile', false)
    .set('errorUpdateNodeParentProfile', false);
};

const handleUpdateNodeParentProfileSuccess = (state, action) => {
  const { data } = action;

  return state
    .set('updatedNodeParentProfile', data)
    .set('loadingUpdateNodeParentProfile', false)
    .set('successUpdateNodeParentProfile', true)
    .set('errorUpdateNodeParentProfile', false);
};

const handleUpdateNodeParentProfileError = (state, action) => {
  return state
    .set('loadingUpdateNodeParentProfile', false)
    .set('successUpdateNodeParentProfile', false)
    .set('errorUpdateNodeParentProfile', action.error);
};

const handleGetProfileHierarchy = (state) => {
  return state.merge({
    loadingProfileHierarchy: true,
    errorProfileHierarchy: null,
  });
};

const handleGetProfileHierarchySuccess = (state, action) => {
  return state.merge({
    loadingProfileHierarchy: false,
    hierarchyProfiles: action.data.hierarchyProfiles,
    independentProfiles: action.data.independentProfiles,
    errorProfileHierarchy: null,
  });
};

const handleGetProfileHierarchyError = (state, action) => {
  return state.merge({
    loadingProfileHierarchy: false,
    hierarchyProfiles: null,
    independentProfiles: null,
    errorProfileHierarchy: action.error,
  });
};

const handleReset = (state) => {
  return initialState;
};

const handleResetProfileRegisterData = (state) => {
  return state
    .set('profileRegisterData', Map())
    .set('createdProfile', Map())
    .set('updatedProfile', Map())
    .set('deletedProfile', Map())
    .set('updatedNodeParentProfile', Map())
    .set('successDeleteProfile', false)
    .set('loadingDeleteProfile', false);
};

const handleResetProfileHierarchy = (state) => {
  return state
    .set('hierarchyProfiles', List())
    .set('loadingProfileHierarchy', false)
    .set('successProfileHierarchy', false)
    .set('errorProfileHierarchy', false);
};

const handleResetCreateOrUpdateProfile = (state) => {
  return state
    .set('createdProfile', Map())
    .set('updatedProfile', Map())
    .set('loadingUpdateProfile', false)
    .set('successUpdateProfile', false)
    .set('errorUpdateProfile', false)
    .set('loadingCreateProfile', false)
    .set('successCreateProfile', false)
    .set('errorCreateProfile', false);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_WORKING_JOURNEY_LIST:
      return handleGetWorkingJourneyList(state, action);
    case Types.GET_WORKING_JOURNEY_LIST_SUCCESS:
      return handleGetWorkingJourneyListSuccess(state, action);
    case Types.GET_WORKING_JOURNEY_LIST_ERROR:
      return handleGetWorkingJourneyListError(state, action);

    case Types.GET_HOME_INDICATOR_LIST:
      return handleGetHomeIndicatorList(state, action);
    case Types.GET_HOME_INDICATOR_LIST_SUCCESS:
      return handleGetHomeIndicatorListSuccess(state, action);
    case Types.GET_HOME_INDICATOR_LIST_ERROR:
      return handleGetHomeIndicatorListError(state, action);

    case Types.GET_PROFILE_REGISTER_DATA:
      return handleGetProfileRegisterData(state, action);
    case Types.GET_PROFILE_REGISTER_DATA_SUCCESS:
      return handleGetProfileRegisterDataSuccess(state, action);
    case Types.GET_PROFILE_REGISTER_DATA_ERROR:
      return handleGetProfileRegisterDataError(state, action);

    case Types.CREATE_PROFILE:
      return handleCreateProfile(state, action);
    case Types.CREATE_PROFILE_SUCCESS:
      return handleCreateProfileSuccess(state, action);
    case Types.CREATE_PROFILE_ERROR:
      return handleCreateProfileError(state, action);

    case Types.UPDATE_PROFILE:
      return handleUpdateProfile(state, action);
    case Types.UPDATE_PROFILE_SUCCESS:
      return handleUpdateProfileSuccess(state, action);
    case Types.UPDATE_PROFILE_ERROR:
      return handleUpdateProfileError(state, action);

    case Types.DELETE_PROFILE:
      return handleDeleteProfile(state, action);
    case Types.DELETE_PROFILE_SUCCESS:
      return handleDeleteProfileSuccess(state, action);
    case Types.DELETE_PROFILE_ERROR:
      return handleDeleteProfileError(state, action);

    case Types.UPDATE_NODE_PARENT_PROFILE:
      return handleUpdateNodeParentProfile(state, action);
    case Types.UPDATE_NODE_PARENT_PROFILE_SUCCESS:
      return handleUpdateNodeParentProfileSuccess(state, action);
    case Types.UPDATE_NODE_PARENT_PROFILE_ERROR:
      return handleUpdateNodeParentProfileError(state, action);

    case Types.GET_PROFILE_HIERARCHY:
      return handleGetProfileHierarchy(state, action);
    case Types.GET_PROFILE_HIERARCHY_SUCCESS:
      return handleGetProfileHierarchySuccess(state, action);
    case Types.GET_PROFILE_HIERARCHY_ERROR:
      return handleGetProfileHierarchyError(state, action);

    case Types.RESET:
      return handleReset(state, action);
    case Types.RESET_PROFILE_REGISTER_DATA:
      return handleResetProfileRegisterData(state, action);
    case Types.RESET_PROFILE_HIERARCHY:
      return handleResetProfileHierarchy(state, action);
    case Types.RESET_CREATE_OR_UPDATE_PROFILE:
      return handleResetCreateOrUpdateProfile(state, action);

    default:
      return state;
  }
}
