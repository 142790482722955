import React, { useEffect, useState } from 'react';
import App from '@app';
import {
  Body,
  PageHeader,
  Breadcrumb,
  Button,
  Tag,
  Progress,
  Skeleton,
  Column,
  Row,
} from '@components-teammove';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  getChecklistPreview,
  finishChecklist,
  resetFinishError,
  getChecklistFinishPreview,
  reset,
} from '@ducks/checklists';
import {
  AbsoluteDiv,
  AnsweredIcon,
  AnsweredOfTotal,
  ChecklistProgressSummaryTitle,
  ColumnContainer,
  ConditionalContainerQuestion,
  Container,
  ContentContainer,
  ContentTitle,
  GroupContentContainer,
  GroupTitleColumn,
  GroupTitleRow,
  ProgressPercentage,
  QuestionContainer,
  QuestionText,
  WarningIcon,
} from './styles';
import { ActivityTypeIcon, ActivityTypeIconWrapper } from '../styles';
import {
  dateToAnsi,
  moment,
  InfoUsuario,
  Notification,
  getCurrentMomentStartOfMonth,
} from '@utils';
import { getMetaAtividades } from '@ducks/configuracoesMetaAtividades';

export default function Checklist() {
  const { idActivity, idChecklist } = useParams();
  const history = useHistory();

  const dispatch = useDispatch();

  const theme = useSelector(({ app }) => app.get('theme'));

  const preview = useSelector(({ checklists }) =>
    checklists.get('checklistPreview')
  );
  const previewFinish = useSelector(({ checklists }) =>
    checklists.get('checklistFinishPreview')
  );
  const loadingChecklistPreview = useSelector(({ checklists }) =>
    checklists.get('loadingChecklistPreview')
  );
  const loadingFinishChecklist = useSelector(({ checklists }) =>
    checklists.get('loadingFinishChecklist')
  );
  const successFinishChecklist = useSelector(({ checklists }) =>
    checklists.get('successFinishChecklist')
  );
  const errorFinishChecklist = useSelector(({ checklists }) =>
    checklists.get('errorFinishChecklist')
  );

  const [toRedirectToRespond, setToRedirectToRespond] = useState(
    sessionStorage.getItem('toRedirectToRespond') || false
  );

  useEffect(() => {
    if (idChecklist) {
      dispatch(getChecklistPreview(idChecklist));
      dispatch(getChecklistFinishPreview(idChecklist));
    }
  }, [idChecklist]);

  useEffect(() => {
    if (successFinishChecklist) {
      const redirectPath = `/atividades/agenda/${idActivity}/checklist/${idChecklist}/finished`;
      const redirectState = { idTypeActivity: preview.idTypeActivity };

      if (preview?.isCancelationChecklist) {
        dispatch(getMetaAtividades(getCurrentMomentStartOfMonth()));
      }

      history.push(redirectPath, redirectState);
    }
  }, [successFinishChecklist, preview?.isCancelationChecklist, preview?.idTypeActivity]);

  useEffect(() => {
    if (toRedirectToRespond === 'true' && !preview?.isCancelationChecklist && successFinishChecklist) {
      setToRedirectToRespond(false);
      sessionStorage.setItem('toRedirectToRespond', false);
      history.push(`/atividades/agenda/${idActivity}/checklist/${idChecklist}/answer`);
    }
  }, [toRedirectToRespond, preview?.isCancelationChecklist, successFinishChecklist]);

  useEffect(() => {
    if (errorFinishChecklist) {
      if (preview?.isCancelationChecklist) {
        Notification.error('Há perguntas pendentes');
      }
      history.push(`/atividades/agenda/${idActivity}/checklist/${idChecklist}/finish`);
    }

    return () => {
      dispatch(resetFinishError());
    };
  }, [errorFinishChecklist, preview?.isCancelationChecklist]);

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, []);

  const handleChecklistFinish = () => {
    const templateIsConfigured =
      previewFinish.sendEmail !== 'N' ||
      previewFinish.typeConsultingVision !== 'NAO_PERMITE' ||
      previewFinish.strategicAction !== 'N';
    if (preview?.isCancelationChecklist || !templateIsConfigured) {
      dispatch(
        finishChecklist(idActivity, idChecklist, {
          date: dateToAnsi(moment()),
          idUserFinish: InfoUsuario.get('cdUsuario'),
        })
      );
    } else {
      history.push(
        `/atividades/agenda/${idActivity}/checklist/${idChecklist}/finish`
      );
    }
  };

  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title={
            <Row gap="14px" align="center">
              {preview?.nameActivity}
              <ActivityTypeIconWrapper color={preview?.colorTypeActivity}>
                <ActivityTypeIcon type={preview?.iconTypeActivity} />
              </ActivityTypeIconWrapper>
            </Row>
          }
          onBack={() => history.goBack()}
          breadcrumb={
            <Breadcrumb
              items={[
                { path: '/', name: 'Home' },
                { path: '/atividades/agenda', name: 'Agenda' },
                {
                  path: `/atividades/agenda/${idActivity}`,
                  name: preview?.nameActivity || '...',
                },
                {
                  path: `/atividades/agenda/${idActivity}/checklist/${idChecklist}`,
                  name: 'Checklist',
                  current: true,
                },
              ]}
            />
          }
          extra={[
            <Button
              size="small"
              key="finish"
              loading={loadingFinishChecklist}
              type="primary"
              disabled={previewFinish?.statusChecklist === 'CONCLUIDO'}
              onClick={() => handleChecklistFinish()}
            >
              Finalizar Checklist
            </Button>,
          ]}
        >
          <Container>
            <Column gap="12px">
              <ChecklistProgressSummaryTitle>
                Resumo
              </ChecklistProgressSummaryTitle>
              {loadingChecklistPreview ? (
                <Skeleton.LargeBox />
              ) : (
                preview?.progressRiskActivity !== 0 && (
                  <ContentContainer>
                    <Column gap="14px" align="center">
                      <Column gap="9px" align="center">
                        <ContentTitle>Progresso</ContentTitle>
                        <Progress.Star
                          percent={preview?.progressRiskActivity}
                          color={preview?.riskColor}
                        />
                      </Column>
                      <ProgressPercentage>
                        {preview?.progressRiskActivity?.toFixed(2)}%
                      </ProgressPercentage>
                    </Column>
                  </ContentContainer>
                )
              )}
            </Column>
            {loadingChecklistPreview ? (
              <Skeleton.LargeBox />
            ) : (
              preview?.groups?.map(
                (
                  { name, idGroup, totalAsks, totalAsksAnswered, asks },
                  index
                ) => (
                  <Column gap="20px" key={idGroup}>
                    <GroupTitleColumn
                      gap="12px"
                      onClick={() =>
                        history.push(
                          `/atividades/agenda/${idActivity}/checklist/${idChecklist}/answer`,
                          { group: idGroup }
                        )
                      }
                    >
                      <GroupTitleRow>
                        <ContentTitle>{name}</ContentTitle>
                        <Tag bgColor={index === 0 ? '#50ABA0' : '#5FB0E3'}>
                          <AnsweredOfTotal>
                            {totalAsksAnswered}/{totalAsks}
                          </AnsweredOfTotal>
                        </Tag>
                      </GroupTitleRow>
                      <Progress
                        percent={(totalAsksAnswered / totalAsks) * 100}
                        showInfo={false}
                        strokeColor={index === 0 ? '#50ABA0' : '#5FB0E3'}
                        strokeWidth={10}
                      />
                    </GroupTitleColumn>
                    <GroupContentContainer>
                      {asks.reduce((acc, question, idx) => {
                        const {
                          id,
                          order,
                          title,
                          answered,
                          idParentQuestion,
                          titleParentQuestion,
                          titleParentQuestionOption,
                        } = question;
                        const previousQuestion = idx > 0 ? asks[idx - 1] : null;
                        const shouldRenderConditionalContainer =
                          !previousQuestion ||
                          previousQuestion.idParentQuestion !==
                            idParentQuestion;
                        const nextQuestionShouldRenderConditionalContainer =
                          asks[idx + 1]
                            ? asks[idx + 1].idParentQuestion !==
                              idParentQuestion
                            : false;

                        acc.push(
                          <ColumnContainer width="100%" key={id}>
                            {shouldRenderConditionalContainer &&
                              idParentQuestion && (
                              <ConditionalContainerQuestion>
                                {titleParentQuestion}{' '}
                                {titleParentQuestionOption}
                              </ConditionalContainerQuestion>
                            )}
                            {idParentQuestion && (
                              <AbsoluteDiv
                                renderConditionalTag={
                                  shouldRenderConditionalContainer &&
                                  idParentQuestion
                                }
                              />
                            )}
                            <QuestionContainer
                              isFirstIndex={idx === 0}
                              nextQuestionShouldRenderConditionalTag={
                                nextQuestionShouldRenderConditionalContainer
                              }
                              renderConditionalTag={
                                shouldRenderConditionalContainer &&
                                idParentQuestion
                              }
                              isConditional={idParentQuestion}
                              answered={answered}
                              onClick={() =>
                                history.push(
                                  `/atividades/agenda/${idActivity}/checklist/${idChecklist}/answer`,
                                  { question: id }
                                )
                              }
                            >
                              <QuestionText>
                                {order + 1} - {title}
                              </QuestionText>
                              {answered ? (
                                <AnsweredIcon type="check" />
                              ) : (
                                <WarningIcon type="alert-triangle" />
                              )}
                            </QuestionContainer>
                          </ColumnContainer>
                        );

                        return acc;
                      }, [])}
                    </GroupContentContainer>
                  </Column>
                )
              )
            )}
          </Container>
        </PageHeader>
      </Body>
    </App>
  );
}
