import { getUnidades } from '@sdk/Unidades';
import { TelasNomenclaturas } from '@utils';

const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

export const statusOptions = [
  { value:'ABERTO', label: 'Aberto' },
  { value:'EM_ANDAMENTO', label: 'Em Andamento' },
  { value:'FATURADO', label: 'Faturado' },
  { value:'ENVIADO', label: 'Enviado' },
  { value:'ENTREGUE', label: 'Entregue' },
  { value:'CANCELADO', label: 'Cancelado' },
];

export const filterFields = [
  {
    type: 'RANGE',
    label: 'Data Criação',
    name: 'dateRange'
  },
  {
    type: 'SELECT',
    label: `${companyNomenclature.nomenclatura}`,
    name: 'companies',
    placeholder: `Selecione ${(companyNomenclature.artigo) === 'a' ? 'as' : 'os'} ${companyNomenclature.plural}}`,
    optionLabelProp: 'title',
    mode: 'multiple',
    action: getUnidades,
  },
  {
    type: 'SELECT',
    label: 'Status',
    name: 'status',
    placeholder: 'Selecione os Status',
    options: statusOptions,
  }
];

export const getStatusColor = (status) => {
  switch (status) {
    case 'ABERTO':
      return '#FD8509';
    case 'EM_ANDAMENTO':
      return '#1E80F0';
    case 'FATURADO':
      return '#157562';
    case 'ENVIADO':
      return '#3AB8CF';
    case 'ENTREGUE':
      return '#40A33F';
    case 'CANCELADO':
      return '#5A5A5A';
    default:
      return '';
  }
};