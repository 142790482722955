import styled from 'styled-components';
import { Image, Row, Text, Icon, Dropdown, Column, Upload, Button } from '@components-teammove';

export const ChatHeader = styled(Row)`
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: ${({ theme }) => theme.darkPrimaryColor};
`;

export const UserProfilePicture = styled(Image)`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
`;

export const UserName = styled(Text)`
  font-size: 1.2em;
  color: white;
`;

export const ChatMessagesContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: ${({ theme }) => theme.darkSecondaryColor};
  overflow-y: auto;
  max-height: 550px;
  min-height: 550px;
`;

export const ChatMessages = styled(Column)`
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.darkSecondaryColor};
`;

export const MessageBubbleAttachment = styled(Row)`
  max-width: ${({ attachmentAndContent }) => (attachmentAndContent ? '220px' : '70%')};
  margin: 2px;
  padding: 4px;
  border-radius: 10px;
  background-color: ${({ isSender, theme }) => (isSender ? '#005C4B' : theme.backgroundColor)};
  color: white;
  align-self: ${({ isSender }) => (isSender ? 'flex-end' : 'flex-start')};
  flex-direction: column;
  gap: 5px;
  word-wrap: break-word;
  position: relative;
`;

export const MessageBubbleText = styled(Row)`
  max-width: 70%;
  margin: 2px;
  padding: 10px;
  border-radius: 10px;
  background-color: ${({ isSender, theme }) => (isSender ? '#005C4B' : theme.backgroundColor)};
  color: white;
  align-self: ${({ isSender }) => (isSender ? 'flex-end' : 'flex-start')};
  flex-direction: row;
  gap: 10px;
  word-wrap: break-word;
  position: relative;
`;

export const StyledImage = styled.img`
  max-width: 100%;
  max-height: 250px;
  width: 220px;
  height: auto;
  min-width: 150px;
  min-height: 100px;
  border-radius: 10px;
  object-fit: cover;
`;

export const ImageContainer = styled.div`
  position: relative;
  max-width: 100%;
  max-height: 250px;
  width: 220px;
  height: auto;
  min-width: 150px;
  min-height: 100px;
  border-radius: 10px;
  overflow: hidden;
`;

export const StickerContainer = styled.div`
  position: relative;
  max-width: 100%;
  max-height: 250px;
  width: 160px;
  height: auto;
  min-width: 150px;
  min-height: 100px;
  border-radius: 10px;
  overflow: hidden;
`;

export const GradientOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30px;
  background: ${({ content }) => content ? 'none' : 'linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent)'};
  border-radius: 0px 0px 10px 10px;
  pointer-events: none;
`;

export const StyledVideo = styled.video`
  max-width: 100%;
  max-height: 250px;
  width: 220px;
  height: 250px;
  min-width: 150px;
  min-height: 100px;
  border-radius: 10px;
  object-fit: cover;
  margin-bottom: 4px;
`;

export const VideoContainer = styled.div`
  position: relative;
  display: inline-block;
  max-width: 100%;
  max-height: 250px;
  width: 220px;
  height: auto;
  min-width: 150px;
  min-height: 100px;
`;

export const PlayButton = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
`;

export const TextImage = styled.p`
  margin-left: 10px;
  margin-right: 10px;
  padding: 0;
  word-wrap: break-word;
`;

export const MessageTimeAndStatus = styled(Row)`
  margin-top: 10px;
  margin-bottom: -6px;
  align-self: flex-end;
`;

export const MessageTimeText = styled(Text)`
  opacity: 0.5;
  font-size: 0.8em;
  color: #ccc;
  margin-right: ${({ isSender }) => (isSender ? '8px' : '0px')};
`;

export const MessageTimeAttachment = styled(Text)`
  opacity: 0.5;
  font-size: 0.8em;
  color: #ccc;
  margin-right: 8px;
`;

export const MessageStatus = styled(Icon.MDI)`
  width: 16px !important;
  height: 16px !important;
  color: ${(props) => (props.status === 'read' ? '#4fc3f7' : '#ccc')};
`;

export const UploadFill = styled(Upload.Dragger)`
  max-width: 60px;
  max-height: 60px;
  width: 60px;
  height: 60px;
  background-color: ${({ theme }) => theme.darkSecondaryColor};
  border: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  
  .ant-upload {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  svg {
    color: ${({ theme }) => theme.actionColor};
    font-size: 18px;
  }

  &:hover {
    background-color: ${({ theme, disabled }) => disabled ? theme.darkSecondaryColor : `${theme.actionColor}20`};
  }
`;

export const UploadIcon = styled(Icon.Feather)`
  color: ${({ theme }) => theme.actionColor};

  * {
    color: ${({ theme }) => theme.actionColor};
  }
`;

export const ChatInputContainer = styled.div`
  gap: 10px;
  display: flex;
  padding: 10px;
  background-color: ${({ theme }) => theme.darkPrimaryColor};
  align-items: center;
`;

export const ChatInputGeneral = styled.div`
  display: flex;
  flex: 1;
  padding: 16px;
  border: none !important;
  border-radius: 8px;
  margin-right: 10px;
  outline: none;
  color: white;
  background-color: ${({ theme }) => theme.darkSecondaryColor} !important;
  resize: none;
  :hover {
    cursor: pointer;
  }
  :focus {
    border: 2px solid ${({ theme }) => theme.actionColor} !important; 
  }

    ::placeholder {
      color: #F5F5F580;
    }
`;

export const ChatWrapper = styled.div`
  max-width: 900px;
  margin: auto;
  flex: 1;
`;

export const PdfLink = styled.a`
  color: #1890ff;
  text-decoration: underline;
  display: block;
  margin: 10px 0;
`;

export const AudioMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
  position: relative;
`;

export const AudioPlayerWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
`;

export const MessageTimeAndStatusAudio = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 5px;
  padding-right: 15px;
  box-sizing: border-box;
`;

export const MessageTimeAndStatusImage = styled(Row)`
  position: absolute;
  bottom: 8px;
  right: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

export const ModalImage = styled.img`
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
`;

export const ModalVideo = styled.video`
  width: 80%;
  height: 80%;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  background-color: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
`;

export const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: ${({ theme }) => theme.backgroundColor};
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

export const StyledButton = styled(Icon.Feather)`
  width: 30px;
  height: 25px;
  padding: 2px;
  color: #FFFFFF;
  opacity: 0.5;
  margin-left: 16px;
  border: none;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:active {
    transform: scale(0.95);
  }
`;

export const ButtonFinish = styled(Button)`
  margin-top: 20px;
`;

export const StyledSticker = styled.img`
  width: 160px;
  height: 160px;
`;

export const TextMessage = styled(Text)`
  color: ${({ theme }) => theme.textColor};
  word-break: break-word;
  overflow-wrap: break-word;
`;

export const DropdownContainer = styled(Dropdown)`
  padding: 10px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.actionColor};
`;

export const MessageAlert = styled.div`
  width: 50%;
  height: 100px;
  padding: 10px;
  border-radius: 12px;
  margin-top: 24px;
  margin-bottom: 24px;
  background-color: ${({ theme }) => theme.actionColor};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  color: white;

  strong {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  p {
    font-size: 18px;
    font-weight: 400;
    margin: 0;
  }
`;

export const AlertIcon = styled(Icon.Feather)`
  margin: 12px;
  height: 24px;
  width: 24px;
  color: #FFFFFF;
`;

export const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  margin-top: 12px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.backgroundColor};
  width: 100%;
`;

export const ChatTitle = styled.h2`
  font-size: 1.2rem;
  font-weight: bold;
  color: #F5F5F580;
  margin-bottom: 8px;
  text-align: left;
`;

export const IconContainer = styled.div`
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.darkSecondaryColor};
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${({ borderColor, theme }) => borderColor === 'green' ? 'var(--success-color)' : borderColor === 'red' ? 'var(--danger-color)' : borderColor === 'white' ? theme.textColor : theme.darkPrimaryColor};
    cursor: pointer;
`;

export const IconFeedback = styled(Icon.Feather)`
    font-size: 32px;
    color: ${({ colorIcon, theme }) => colorIcon === 'green' ? 'var(--success-color)' : colorIcon === 'red' ? 'var(--danger-color)' : theme.textColor};
`;

export const ChatInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 10px;
`;