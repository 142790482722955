import React, { useState } from 'react';
import App from '@app';
import { Body, PageHeader, Breadcrumb, Column, Button } from '@components-teammove';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AfastamentoCadastro from './Cadastro';
import AfastamentosTable from './table';

export default function ConfiguracoesAfastamentos() {
  const theme = useSelector(({ app }) => app.get('theme'));
  const history = useHistory();
  const [visibleRegisterAfastamento, setVisibleRegisterAfastamento] = useState(false);

  const handleButtonClick = (data) => {
    if (data) {
      history.push('?usuario=' + data.id, data);
    }
    setVisibleRegisterAfastamento(true);
  };

  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title='Afastamentos'
          extra={[
            <Button key="1" type="primary" size='small' context='header' onClick={() => handleButtonClick()}>
              Novo
            </Button>
          ]}
          breadcrumb={(
            <Breadcrumb
              items={[
                { path: '/', name: 'Home' },
                { path: '/configuracoes', name: 'Configurações' },
                { path: '/configuracoes/afastamentos', name: 'Afastamentos', current: true }
              ]}
            />
          )}
          onBack={() => window.history.back()}
        >
          <Column gap='12px'>
            <AfastamentosTable handleEditClick={handleButtonClick} />
          </Column>

          {visibleRegisterAfastamento && (
            <AfastamentoCadastro
              visible={visibleRegisterAfastamento}
              onCancel={() => setVisibleRegisterAfastamento(false)}
            />
          )}
        </PageHeader>
      </Body>
    </App>
  );
}
