import { call, put, takeLatest } from 'redux-saga/effects';
import { Map } from 'immutable';
import { getWhatsAppPermissionAndMessageCount as getWhatsAppPermissionAndMessageCountSdk } from '@sdk/NotificacoesWhatsApp';

// Action Types
export const Types = {
  GET_WHATSAPP_PERMISSION_AND_MESSAGE_COUNT: 'NOTIFICACOES_WHATSAPP/GET_WHATSAPP_PERMISSION_AND_MESSAGE_COUNT',
  GET_WHATSAPP_PERMISSION_AND_MESSAGE_COUNT_SUCCESS: 'NOTIFICACOES_WHATSAPP/GET_WHATSAPP_PERMISSION_AND_MESSAGE_COUNT_SUCCESS',
  GET_WHATSAPP_PERMISSION_AND_MESSAGE_COUNT_ERROR: 'NOTIFICACOES_WHATSAPP/GET_WHATSAPP_PERMISSION_AND_MESSAGE_COUNT_ERROR',
};

// Action Creators
export const getWhatsAppPermissionAndMessageCount = (userId) => ({ type: Types.GET_WHATSAPP_PERMISSION_AND_MESSAGE_COUNT, userId });
export const getWhatsAppPermissionAndMessageCountSuccess = (whatsAppPermissionAndMessageCount) => ({ type: Types.GET_WHATSAPP_PERMISSION_AND_MESSAGE_COUNT_SUCCESS, whatsAppPermissionAndMessageCount });
export const getWhatsAppPermissionAndMessageCountError = (error) => ({ type: Types.GET_WHATSAPP_PERMISSION_AND_MESSAGE_COUNT_ERROR, error });

// Sagas
export function* getWhatsAppPermissionAndMessageCountSaga(action) {
  try {
    const { userId } = action;
    const response = yield call(getWhatsAppPermissionAndMessageCountSdk, userId);
    yield put(getWhatsAppPermissionAndMessageCountSuccess(response));
  } catch (error) {
    yield put(getWhatsAppPermissionAndMessageCountError(error));
  }
}

export const saga = [
  takeLatest(Types.GET_WHATSAPP_PERMISSION_AND_MESSAGE_COUNT, getWhatsAppPermissionAndMessageCountSaga),
];

// Reducer
const initialState = Map({
  whatsAppPermissionAndMessageCount: Map(),
  loadingWhatsAppPermissionAndMessageCount: false,
  successWhatsAppPermissionAndMessageCount: false,
  errorWhatsAppPermissionAndMessageCount: false,
});

const handleGetWhatsAppPermissionAndMessageCount = (state) => {
  return state.set('loadingWhatsAppPermissionAndMessageCount', true).set('successWhatsAppPermissionAndMessageCount', false).set('errorWhatsAppPermissionAndMessageCount', false);
};

const handleGetWhatsAppPermissionAndMessageCountSuccess = (state, action) => {
  return state.set('whatsAppPermissionAndMessageCount', action.whatsAppPermissionAndMessageCount).set('loadingWhatsAppPermissionAndMessageCount', false).set('successWhatsAppPermissionAndMessageCount', true).set('errorWhatsAppPermissionAndMessageCount', false);
};

const handleGetWhatsAppPermissionAndMessageCountError = (state) => {
  return state.set('loadingWhatsAppPermissionAndMessageCount', false).set('successWhatsAppPermissionAndMessageCount', false).set('errorWhatsAppPermissionAndMessageCount', true);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_WHATSAPP_PERMISSION_AND_MESSAGE_COUNT:
      return handleGetWhatsAppPermissionAndMessageCount(state);

    case Types.GET_WHATSAPP_PERMISSION_AND_MESSAGE_COUNT_SUCCESS:
      return handleGetWhatsAppPermissionAndMessageCountSuccess(state, action);

    case Types.GET_WHATSAPP_PERMISSION_AND_MESSAGE_COUNT_ERROR:
      return handleGetWhatsAppPermissionAndMessageCountError(state);

    default:
      return state;
  }
}