import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {  Row, Column, Text, Skeleton, Divider, ChartPie, Button } from '@components-teammove';
import { Container } from '../OrderEvolution/styles';
import { getParticipationsPerFamilies } from '@ducks/pedidosDashboard';
import { formatCurrency, formatDecimal } from '@utils';
import { SelectStyle, StyleEmpty } from './styles';
import AllFamiliesModal from './AllFamiliesModal';
import { getColorDefault } from './rules';

const ParticipationsPerFamilies = ({ currentFilters, currentParams }) => {
  const dispatch = useDispatch();

  const participationsPerFamilies = useSelector(({ pedidosDashboard }) => pedidosDashboard.get('participationsPerFamilies').toArray());
  const loadingParticipationsPerFamilies = useSelector(({ pedidosDashboard }) => pedidosDashboard.get('loadingParticipationsPerFamilies'));
  const successParticipationsPerFamilies = useSelector(({ pedidosDashboard }) => pedidosDashboard.get('successParticipationsPerFamilies'));

  const [selectedFamilie, setSelectedFamilie] = useState();
  const [allFamiliesSelected, setAllFamiliesSelected] = useState(); 
  const [visibleAllFamilies, setVisibleAllFamilies] = useState(false);

  useEffect(() => {
    if(Object.keys(currentFilters).length === 0 && Object.keys(currentParams).length === 0) return;
    dispatch(getParticipationsPerFamilies({ ...currentFilters, ...currentParams }));
  }, [currentFilters, currentParams]);

  useEffect(() => {
    if (successParticipationsPerFamilies && participationsPerFamilies?.length > 0) {
      setAllFamiliesSelected(participationsPerFamilies[0]);
      const slicedFamilies = participationsPerFamilies[0].families.slice(0, 5);
      const familiesWithColor = getColorDefault(slicedFamilies);
      setSelectedFamilie({
        ...participationsPerFamilies[0], 
        families: familiesWithColor, 
      });
    }
  }, [successParticipationsPerFamilies]);

  const changeSelectedFamilies = (classificationId) => {
    if (classificationId) {
      const foundFamilie = participationsPerFamilies.find((familieDash) => familieDash.id === classificationId);
      setAllFamiliesSelected(foundFamilie);

      const slicedFamilies = foundFamilie.families.slice(0, 5);
      const familiesWithColor = getColorDefault(slicedFamilies);
      const limitedFoundFamilies = { ...foundFamilie, families: familiesWithColor };
      setSelectedFamilie(limitedFoundFamilies);
    }
  };

  return(
    loadingParticipationsPerFamilies ? (
      <Skeleton.Map/>
    ) : successParticipationsPerFamilies && (
      <Container gap='22px'>
        <Row justify='space-between'>
          <Column gap='4px'> 
            <Text size='18px' weight='400' line='20px' lowOpacity>Ranking de</Text>
            <Text size='20px' weight='800' line='20px'>Famílias</Text>
          </Column>
          {participationsPerFamilies.length > 0 && (
            <SelectStyle
              value={selectedFamilie?.id}
              onChange={(classification) => changeSelectedFamilies(classification)}
              placeholder='Selecione a classificação' 
              options={participationsPerFamilies.map((classification) => ({ value: classification.id, label: classification.name }))}
              fitContent
            />
          )}
        </Row>
        <Divider/>
        {participationsPerFamilies.length < 1 || !selectedFamilie ? 
          <StyleEmpty description="Sem dados" /> 
          : (
            <Column>
              <Row justify='space-between' align='center'>
                <ChartPie large data={selectedFamilie?.families.map(({ name, color, quantity }) => ({ label: name, color, quantity }))}/>
                <Column gap='10px' padding='0 30px 0 0 '>
                  {selectedFamilie?.families?.map((option) => (
                    <Row key={option.id} gap='29px' justify='space-between'>
                      <Text size='20px' weight='700'>{currentParams.typeValue === 'value' ? formatCurrency(option.quantity) : currentParams.typeValue === 'weight' ? `${formatDecimal(option.quantity, 1)} kg` : option.quantity.toFixed(0)}</Text>
                      <Text size='20px' weight='400' lowOpacity>{formatDecimal(option.quantityPercent, 2)}%</Text>
                    </Row>
                  ))}
                </Column>
              </Row>
              <Row justify='flex-end'>
                <Button type='primary' size='small' onClick={() => setVisibleAllFamilies(true)}>
                  <Text size='20px' weight='700'>Ver mais</Text>
                </Button>
              </Row>
            </Column>
          )}
        {visibleAllFamilies && (
          <AllFamiliesModal visible={visibleAllFamilies} onClose={() => setVisibleAllFamilies(false)} allFamilies={allFamiliesSelected}/>
        )}
      </Container>
    )
  );
};

export default ParticipationsPerFamilies;