import styled from 'styled-components';
import { Text, Icon } from '@components-teammove';

export const TitleText = styled(Text)`
    font-size: 20px;
    font-weight: 700;
    color: ${({ theme }) => theme.textColor};
`;

export const SettingsIcon = styled(Icon.Feather)`
    color: ${({ theme }) => theme.textColor};
    cursor: ${({ disableMouseEventer }) => disableMouseEventer ? 'not-allowed' : 'pointer'};
`;