export const formatInternationalPhoneNumber = (number, countryCode) => {
  if (!number) return undefined;

  if(!number.trim().startsWith('+') && countryCode) {
    return `+${countryCode} ${number}`;
  }

  const [codigoPais, ...maskedNumber] = number.split(' ');
  return `${codigoPais} ${maskedNumber.join(' ')}`;
};

export const processInternationalPhoneNumber = (number) => {
  if(!number) return { dialCode: '', numero: '' };

  const isInternational = number.trim().startsWith('+');

  if (isInternational) {
    const [codigoPais, ...numero] = number.split(' ');
    return { dialCode: codigoPais.replace('+', ''), numero: numero.join(' ') };
  }

  return { dialCode: '55', numero: number };
};