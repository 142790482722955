import React from 'react';
import { Form, ModularTree } from '@components-teammove';

const PermissoesTab = ({ form, actionPermissions }) => {
  const handleTreeChange = (checkedKeys = [], orderedNodes = []) => {
    if (checkedKeys.length === 0) return;
    if (orderedNodes.length === 0) return;

    const safeCheckedKeys = Array.isArray(checkedKeys) ? checkedKeys : [];

    const transformedData = orderedNodes.map((node) => ({
      permissionId: node.permissionId,
      parentPermissionId: node.parentPermissionId,
      name: node.title,
      value: safeCheckedKeys.includes(node.permissionId),
      order: node.order,
    }));

    form.setFieldsValue({ permissions: transformedData });
  };

  return (
    <Form.Item name="permissions" label="Permissões">
      <ModularTree
        name="permissions"
        initialData={actionPermissions || []}
        onChange={handleTreeChange}
        notificationMessage="Por favor, marque a permissão pai primeiro"
        idField="permissionId"
        parentIdField="parentPermissionId"
      />
    </Form.Item>
  );
};

export default PermissoesTab;
