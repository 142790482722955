import React, { useEffect, useState } from 'react';
import { Card } from '../styles';
import { TitleText, SettingsIcon } from './styles';
import { Grid, Input, Form, Select, Row, Skeleton, Tooltip } from '@components-teammove';
import { optionsStrategyActions } from './rules';
import { useSelector } from 'react-redux';
import ConfiguracoesAcoesEstrategicas from './ConfiguracoesAcoesEstrategicas';

const AcoesEstrategicasChecklist = ({ form, idChecklistTemplate }) => {
  const loadingChecklistTemplate = useSelector(({ checklistTemplates }) => checklistTemplates.get('loadingChecklistTemplate'));
  const [opcaoEstrategica, setOpcaoEstrategica] = useState('N');
  const [visibleStrategicActionModal, setVisibleStrategicActionModal] = useState(false);

  useEffect(() => {
    setOpcaoEstrategica(form.getFieldValue('acao_estrategica'));
  }, [form.getFieldValue('acao_estrategica')]);

  return loadingChecklistTemplate ? (
    <Skeleton.Card/>
  ) : (
    <Card>
      <Row justify='space-between' width='100%' align='center' margin='0 0 20px 0'>
        <TitleText>Ações estratégicas</TitleText>
        <Tooltip title={!idChecklistTemplate ? 'Salve o checklist para configurar ações estratégicas' : ''}>
          <SettingsIcon
            type='settings'
            disableMouseEventer={opcaoEstrategica === 'N' || !idChecklistTemplate} 
            onClick={() => {
              if(opcaoEstrategica === 'N' || !idChecklistTemplate) return;
              setVisibleStrategicActionModal(true);
            }}
          />
        </Tooltip>
      </Row>
      <Form form={form} layout='vertical'>
        <Grid numberOfColumns={2} gap='0 20px' width='100%'>
          <Form.Item label="Utiliza ação estratégica" name="acao_estrategica" rules={[{ required: true, message: 'Por favor, insira este campo' }]}>
            <Select placeholder='Escolha uma opção' options={optionsStrategyActions} onChange={(value) => setOpcaoEstrategica(value)}/>
          </Form.Item>
          <Form.Item hidden={opcaoEstrategica === 'N'} label="Nome da ação estratégica" name="nome_plano_acao">
            <Input placeholder='Plano de ação'/>
          </Form.Item>
        </Grid>
      </Form>
      {visibleStrategicActionModal && (
        <ConfiguracoesAcoesEstrategicas
          visible={visibleStrategicActionModal}
          onCancel={() => setVisibleStrategicActionModal(false)}
          idChecklistTemplate={idChecklistTemplate}
        />
      )}
    </Card>
  );
};

export default AcoesEstrategicasChecklist;