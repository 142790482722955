import styled from 'styled-components';
import { Text, InputNumber, Select, Tag, View, Button, Form, Icon } from '@components-teammove';

export const HorizontalLine = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.darkHighlightColor};
  margin-top: ${({ top }) => (top ? top : '40px')};
  margin-bottom: 20px;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.textForegroundColor} !important;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
`;

export const ClusterColumns = styled.div`
  display: grid;
  grid-template-columns: 2.5fr 1fr 1.2fr 0.3fr;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: fit-content;
`;

export const AddClusterButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const IconWrapper = styled.div`
  border-radius: 50%;
  padding: 4px;
  background-color: ${({ color }) => color};
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ClusterColumnsTitle = styled.div`
  color: ${({ theme }) => theme.textForegroundColor} !important;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 8px;
  text-align: ${({ textAlignCenter }) => textAlignCenter ? 'center' : 'left'};
`;

export const ActivityIcon = styled(Icon.MDI)``;

export const ActivityName = styled(Text)`
  &.ant-typography {
    font-size: 16px;
    font-weight: 400;
    color: ${({ theme }) => theme.textColor};
  }
`;

export const NumberInput = styled(InputNumber)`
  height: 20px;
`;

export const StyleSelect = styled(Select)`
  &.ant-select-multiple .ant-select-selection-item {
    padding-left: 4px;
  }
`;

export const InactiveTag = styled(Tag)`
  background-color: ${({ theme }) => theme.darkSecondaryColor};
`;

export const ActionButton = styled(Button)`
  &.ant-btn {
    padding: 18px;
    height: 56px;
  }
`;

export const ViewContainer = styled(View)`
  margin-bottom: 22px;
`;

export const ModalConfirmationText = styled(Text)`
 &.ant-typography {
    font-size: 20px;
    font-weight: 400;
    color: ${(props) => props.theme.textColor};
    line-height: 27.24px;
  }
`;

export const FormItem = styled(Form.Item)`
  margin-bottom: 12px !important;
  ${({ name }) => name === 'clusterGroup' && 'margin-top: 20px !important;'};
  height: ${({ selectOpen, dropdownHeight }) => (selectOpen && dropdownHeight > 0) ? `${dropdownHeight}px` : 'auto'};
`;

export const DescriptionDeleteModal = styled(Text)`
   &.ant-typography {
    font-size: 18px;
    font-weight: 400;
    color: ${(props) => props.theme.textColor};
    line-height: 27.24px;
    margin-bottom: 20px !important;
  }
`;