import { get, post, put, del, patch } from '../api';

const useApiNode = true;

export const getProfileHierarchy = () => {
  return get('/api/profiles/hierarchy');
};

export const getProfileRegisterData = (profileId) => {
  const url = profileId
    ? `/api/profiles/register?profileId=${profileId}`
    : '/api/profiles/register';

  return get(url);
};

export const createProfile = (profileData) => {
  return post('/api/profiles', profileData);
};

export const updateProfile = (profileData) => {
  return put('/api/profiles', profileData);
};

export const deleteProfile = (profileId) => {
  return del(`/api/profile/${profileId}`, {}, useApiNode);
};

export const updateNodeParentProfile = (draggedId, targetId) => {
  if (!draggedId || !targetId) return false;

  return patch('/api/profile', {
    id: draggedId,
    parentProfileId: targetId,
  }, {}, useApiNode);
};

export const getWorkingJourneyList = () => {
  return get('/api/workingJourneys');
};

export const getHomeIndicatorList = () => {
  return get('/api/indicators/dashboard', {}, useApiNode);
};
