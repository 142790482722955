import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import { Notification } from '@utils';
import {
  getUnidadesSemAgrupadores as getUnidadesSdk,
  getUnidadesByClusters as getUnidadesByClustersSdk,
  getUnidade as getUnidadeSdk,
  postUnidade as postUnidadeSdk,
  updateUnidade as updateUnidadeSdk,
  updateUnidades as updateUnidadesSdk,
  updateAllUnidades as updateAllUnidadesSdk,
  deleteUnidade as deleteUnidadeSdk,
  getActivities as getActivitiesSdk,
  getMultipleClustersOptions as getMultipleClustersOptionsSdk,
} from '@sdk/Unidades';

export const FILTERS_KEY = 'FILTROS/UNIDADES';

//Action Types
export const Types = {
  GET_UNIDADES: 'UNIDADES/GET_UNIDADES',
  GET_UNIDADES_SUCCESS: 'UNIDADES/GET_UNIDADES_SUCCESS',
  GET_UNIDADES_ERROR: 'UNIDADES/GET_UNIDADES_ERROR',

  GET_UNIDADES_BY_CLUSTERS: 'UNIDADES/GET_UNIDADES_BY_CLUSTERS',
  GET_UNIDADES_BY_CLUSTERS_SUCCESS: 'UNIDADES/GET_UNIDADES_BY_CLUSTERS_SUCCESS',
  GET_UNIDADES_BY_CLUSTERS_ERROR: 'UNIDADES/GET_UNIDADES_BY_CLUSTERS_ERROR',

  POST_UNIDADE: 'UNIDADES/POST_UNIDADE',
  POST_UNIDADE_SUCCESS: 'UNIDADES/POST_UNIDADE_SUCCESS',
  POST_UNIDADE_ERROR: 'UNIDADES/POST_UNIDADE_ERROR',

  UPDATE_UNIDADE: 'UNIDADES/UPDATE_UNIDADE',
  UPDATE_UNIDADE_SUCCESS: 'UNIDADES/UPDATE_UNIDADE_SUCCESS',
  UPDATE_UNIDADE_ERROR: 'UNIDADES/UPDATE_UNIDADE_ERROR',

  UPDATE_UNIDADES: 'UNIDADES/UPDATE_UNIDADES',
  UPDATE_UNIDADES_SUCCESS: 'UNIDADES/UPDATE_UNIDADES_SUCCESS',
  UPDATE_UNIDADES_ERROR: 'UNIDADES/UPDATE_UNIDADES_ERROR',

  UPDATE_ALL_UNIDADES: 'UNIDADES/UPDATE_ALL_UNIDADES',
  UPDATE_ALL_UNIDADES_SUCCESS: 'UNIDADES/UPDATE_ALL_UNIDADES_SUCCESS',
  UPDATE_ALL_UNIDADES_ERROR: 'UNIDADES/UPDATE_ALL_UNIDADES_ERROR',

  GET_MULTIPLE_CLUSTERS_OPTIONS: 'UNIDADES/GET_MULTIPLE_CLUSTERS_OPTIONS',
  GET_MULTIPLE_CLUSTERS_OPTIONS_SUCCESS: 'UNIDADES/GET_MULTIPLE_CLUSTERS_OPTIONS_SUCCESS',
  GET_MULTIPLE_CLUSTERS_OPTIONS_ERROR: 'UNIDADES/GET_MULTIPLE_CLUSTERS_OPTIONS_ERROR',

  DELETE_UNIDADE: 'UNIDADES/DELETE_UNIDADE',
  DELETE_UNIDADE_SUCCESS: 'UNIDADES/DELETE_UNIDADE_SUCCESS',
  DELETE_UNIDADE_ERROR: 'UNIDADES/DELETE_UNIDADE_ERROR',

  GET_UNIDADE: 'UNIDADES/GET_UNIDADE',
  GET_UNIDADE_SUCCESS: 'UNIDADES/GET_UNIDADE_SUCCESS',
  GET_UNIDADE_ERROR: 'UNIDADES/GET_UNIDADE_ERROR',

  SET_BUSCA: 'UNIDADES/SET_BUSCA',

  GET_PENDING_ACTIVITIES: 'UNIDADES/GET_PENDING_ACTIVITIES',
  GET_PENDING_ACTIVITIES_SUCCESS: 'UNIDADES/GET_PENDING_ACTIVITIES_SUCCESS',
  GET_PENDING_ACTIVITIES_ERROR: 'UNIDADES/GET_PENDING_ACTIVITIES_ERROR',

  GET_COMPLETE_ACTIVITIES: 'UNIDADES/GET_COMPLETE_ACTIVITIES',
  GET_COMPLETE_ACTIVITIES_SUCCESS: 'UNIDADES/GET_COMPLETE_ACTIVITIES_SUCCESS',
  GET_COMPLETE_ACTIVITIES_ERROR: 'UNIDADES/GET_COMPLETE_ACTIVITIES_ERROR',

  RESET_SUCCESS_SAVE: 'UNIDADES/RESET_SUCCESS_SAVE',
  RESET: 'UNIDADES/RESET',
};

//Action Creators
export const getUnidades = (filtros, limit, search, orderBy, count) => ({ type: Types.GET_UNIDADES, filtros, limit, search, orderBy, count });
export const getUnidadesSuccess = (unidades, total) => ({ type: Types.GET_UNIDADES_SUCCESS, unidades, total });
export const getUnidadesError = (error) => ({ type: Types.GET_UNIDADES_ERROR, error });

export const getUnidadesByClusters = (filters, search) => ({ type: Types.GET_UNIDADES_BY_CLUSTERS, filters, search });
export const getUnidadesByClustersSuccess = (clustersSummary) => ({ type: Types.GET_UNIDADES_BY_CLUSTERS_SUCCESS, clustersSummary });
export const getUnidadesByClustersError = (error) => ({ type: Types.GET_UNIDADES_BY_CLUSTERS_ERROR, error });

export const postUnidade = (unidade) => ({ type: Types.POST_UNIDADE, unidade });
export const postUnidadeSuccess = (unidade) => ({ type: Types.POST_UNIDADE_SUCCESS, unidade });
export const postUnidadeError = (error) => ({ type: Types.POST_UNIDADE_ERROR, error });

export const updateUnidade = (unidade, id) => ({ type: Types.UPDATE_UNIDADE, unidade, id });
export const updateUnidadeSuccess = (unidade) => ({ type: Types.UPDATE_UNIDADE_SUCCESS, unidade });
export const updateUnidadeError = (error) => ({ type: Types.UPDATE_UNIDADE_ERROR, error });

export const updateUnidades = (idsCompanies, arrayCompanies) => ({ type: Types.UPDATE_UNIDADES, idsCompanies, arrayCompanies });
export const updateUnidadesSuccess = (companies) => ({ type: Types.UPDATE_UNIDADES_SUCCESS, companies });
export const updateUnidadesError = (error) => ({ type: Types.UPDATE_UNIDADES_ERROR, error });

export const updateAllUnidades = (search, arrayCompanies) => ({ type: Types.UPDATE_ALL_UNIDADES, search, arrayCompanies });
export const updateAllUnidadesSuccess = (unidades) => ({ type: Types.UPDATE_ALL_UNIDADES_SUCCESS, unidades });
export const updateAllUnidadesError = (error) => ({ type: Types.UPDATE_ALL_UNIDADES_ERROR, error });

export const getMultipleClustersOptions = (companies) => ({ type: Types.GET_MULTIPLE_CLUSTERS_OPTIONS, companies });
export const getMultipleClustersOptionsSuccess = (multipleClustersOptions) => ({ type: Types.GET_MULTIPLE_CLUSTERS_OPTIONS_SUCCESS, multipleClustersOptions });
export const getMultipleClustersOptionsError = (error) => ({ type: Types.GET_MULTIPLE_CLUSTERS_OPTIONS_ERROR, error });

export const deleteUnidade = (id) => ({ type: Types.DELETE_UNIDADE, id });
export const deleteUnidadeSuccess = () => ({ type: Types.DELETE_UNIDADE_SUCCESS });
export const deleteUnidadeError = (error) => ({ type: Types.DELETE_UNIDADE_ERROR, error });

export const getUnidade = (id, useOtherFields) => ({ type: Types.GET_UNIDADE, id, useOtherFields });
export const getUnidadeSuccess = (unidade) => ({ type: Types.GET_UNIDADE_SUCCESS, unidade });
export const getUnidadeError = (error) => ({ type: Types.GET_UNIDADE_ERROR, error });

export const setBusca = (busca) => ({ type: Types.SET_BUSCA, busca });

export const getPendingActivities = (idUnit, idUser, status, order, limit) => ({ type: Types.GET_PENDING_ACTIVITIES, idUnit, idUser, status, order, limit });
export const getPendingActivitiesSuccess = (pendingActivities) => ({ type: Types.GET_PENDING_ACTIVITIES_SUCCESS, pendingActivities });
export const getPendingActivitiesError = (error) => ({ type: Types.GET_PENDING_ACTIVITIES_ERROR, error });

export const getCompleteActivities = (idUnit, idUser, status, order, limit) => ({ type: Types.GET_COMPLETE_ACTIVITIES, idUnit, idUser, status, order, limit });
export const getCompleteActivitiesSuccess = (completeActivities) => ({ type: Types.GET_COMPLETE_ACTIVITIES_SUCCESS, completeActivities });
export const getCompleteActivitiesError = (error) => ({ type: Types.GET_COMPLETE_ACTIVITIES_ERROR, error });

export const resetSuccessSave = () => ({ type: Types.RESET_SUCCESS_SAVE });
export const reset = () => ({ type: Types.RESET });

//saga
function* fetchGetUnidades(action) {
  try {
    const { filtros, limit, search, orderBy, count } = action;
    const { companies, total } = yield call(getUnidadesSdk, filtros, limit, search, orderBy, count);
    yield put(getUnidadesSuccess(companies, total));
  } catch (err) {
    Notification.error(err.message);
    yield put(getUnidadesError(err));
  }
}

function* fetchGetUnidadesByClusters(action) {
  try {
    const { filters, search } = action;

    const clusters = yield call(getUnidadesByClustersSdk, filters, search);
    yield put(getUnidadesByClustersSuccess(clusters));
  } catch (err) {
    Notification.error(err.message);
    yield put(getUnidadesByClustersError(err));
  }
}

function* fetchDeleteUnidade(action) {
  try {
    const { id } = action;
    const unidade = yield call(deleteUnidadeSdk, id);
    yield put(deleteUnidadeSuccess(unidade));
  } catch (err) {
    Notification.error(err.message);
    yield put(deleteUnidadeError(err));
  }
}

function* fetchGetUnidade(action) {
  try {
    const { id, useOtherFields } = action;
    const unidade = yield call(getUnidadeSdk, id, useOtherFields);
    yield put(getUnidadeSuccess(unidade));
  } catch (err) {
    Notification.error(err.message);
    yield put(getUnidadeError(err));
  }
}

function* fetchPostUnidade(action) {
  try {
    let { unidade } = action;

    unidade = yield call(postUnidadeSdk, unidade);
    yield put(postUnidadeSuccess(unidade));
  } catch (err) {
    if (err.message.includes('Identifica')) {
      Notification.warning('O identificador informado já existe, altere e tente novamente');
    } else {
      Notification.error(err.message);
    }
    yield put(postUnidadeError(err));
  }
}

function* fetchUpdateUnidade(action) {
  try {
    let { unidade } = action;
    let { id } = action;

    unidade = yield call(updateUnidadeSdk, unidade, id);
    yield put(updateUnidadeSuccess(unidade));
  } catch (err) {
    if (err.message.includes('Identifica')) {
      Notification.warning('O identificador informado já existe, altere e tente novamente');
    } else {
      Notification.error(err.message);
    }
    yield put(updateUnidadeError(err));
  }
}

function* fetchUpdateUnidades(action) {
  try {
    let { idsCompanies, arrayCompanies } = action;

    idsCompanies = yield call(updateUnidadesSdk, idsCompanies, arrayCompanies);
    yield put(updateUnidadesSuccess(idsCompanies));
  } catch (err) {
    Notification.error(err.message);
    yield put(updateUnidadesError(err));
  }
}

function* fetchUpdateAllUnidades(action) {
  try {
    let { search, arrayCompanies } = action;

    const unidadesIds = yield call(updateAllUnidadesSdk, search, arrayCompanies);
    yield put(updateUnidadesSuccess(unidadesIds));
  } catch (err) {
    Notification.error(err.message);
    yield put(updateUnidadesError(err));
  }
}

function* fetchGetMultipleClustersOptions(action) {
  try {
    const { companies } = action;
    const multipleClustersOptions = yield call(getMultipleClustersOptionsSdk, companies);
    yield put(getMultipleClustersOptionsSuccess(multipleClustersOptions));
  } catch (err) {
    Notification.error(err.message);
    yield put(getMultipleClustersOptionsError(err));
  }
}

function* fetchGetPendingActivities(action) {
  try {
    const { idUnit, idUser, status, order, limit } = action;
    const pendingActivities = yield call(getActivitiesSdk, idUnit, idUser, status, order, limit);
    yield put(getPendingActivitiesSuccess(pendingActivities));
  } catch (err) {
    Notification.error(err.message);
    yield put(getPendingActivitiesError(err));
  }
}

function* fetchGetCompleteActivities(action) {
  try {
    const { idUnit, idUser, status, order, limit } = action;
    const completeActivities = yield call(getActivitiesSdk, idUnit, idUser, status, order, limit);
    yield put(getCompleteActivitiesSuccess(completeActivities));
  } catch (err) {
    Notification.error(err.message);
    yield put(getCompleteActivitiesError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_UNIDADES, fetchGetUnidades),
  takeLatest(Types.GET_UNIDADES_BY_CLUSTERS, fetchGetUnidadesByClusters),
  takeLatest(Types.GET_UNIDADE, fetchGetUnidade),
  takeLatest(Types.POST_UNIDADE, fetchPostUnidade),
  takeLatest(Types.UPDATE_UNIDADE, fetchUpdateUnidade),
  takeLatest(Types.UPDATE_UNIDADES, fetchUpdateUnidades),
  takeLatest(Types.UPDATE_ALL_UNIDADES, fetchUpdateAllUnidades),
  takeLatest(Types.DELETE_UNIDADE, fetchDeleteUnidade),
  takeLatest(Types.GET_PENDING_ACTIVITIES, fetchGetPendingActivities),
  takeLatest(Types.GET_COMPLETE_ACTIVITIES, fetchGetCompleteActivities),
  takeLatest(Types.GET_MULTIPLE_CLUSTERS_OPTIONS, fetchGetMultipleClustersOptions),
];

// Reducer
const initialState = Map({
  unidades: List(),
  loadingUnidades: false,
  successUnidades: false,
  errorUnidades: false,
  total: 0,
  busca: '',
  clustersSummary: Map(),
  clusters: List(),
  loadingUnidadesByClusters: false,
  successUnidadesByClusters: false,
  errorUnidadesByClusters: false,
  unidade: Map(),
  unidadeRespSave: Map(),
  loading: false,
  success: false,
  error: false,
  loadingSave: false,
  successSave: false,
  errorSave: false,
  loadingDelete: false,
  successDelete: false,
  errorDelete: false,

  pendingActivities: List(),
  loadingPendingActivities: false,
  successPendingActivities: false,
  errorPendingActivities: false,

  completeActivities: List(),
  loadingCompleteActivities: false,
  successCompleteActivities: false,
  errorCompleteActivities: false,

  multipleClustersOptions: List(),
  loadingMultipleClustersOptions: false,
  successMultipleClustersOptions: false,
  errorMultipleClustersOptions: false,
});

const handleGetUnidades = (state, action) => {
  return state.set('loadingUnidades', true).set('successUnidades', false).set('errorUnidades', false);
};

const handleGetUnidadesSuccess = (state, action) => {
  const { unidades, total } = action;

  return state.set('unidades', unidades).set('loadingUnidades', false).set('successUnidades', true).set('errorUnidades', false).set('total', total);
};

const handleGetUnidadesError = (state, action) => {
  return state.set('loadingUnidades', false).set('successUnidades', false).set('errorUnidades', action.error);
};

const handleGetUnidadesByClusters = (state, action) => {
  return state.set('loadingUnidadesByClusters', true).set('successUnidadesByClusters', false).set('errorUnidadesByClusters', false);
};

const handleGetUnidadesByClustersSuccess = (state, action) => {
  const { clustersSummary } = action;

  return state.set('clusters', clustersSummary.clusters).set('clustersSummary', clustersSummary).set('loadingUnidadesByClusters', false).set('successUnidadesByClusters', true).set('errorUnidadesByClusters', false);
};

const handleGetUnidadesByClustersError = (state, action) => {
  return state.set('loadingUnidadesByClusters', false).set('successUnidadesByClusters', false).set('errorUnidadesByClusters', action.error);
};

const handleGetUnidade = (state, action) => {
  return state.set('loading', true).set('success', false).set('error', false);
};

const handleGetUnidadeSuccess = (state, action) => {
  const { unidade } = action;

  return state.set('unidade', unidade).set('loading', false).set('success', true).set('error', false);
};

const handleGetUnidadeError = (state, action) => {
  return state.set('loading', false).set('success', false).set('error', action.error);
};

const handleDeleteUnidade = (state, action) => {
  return state.set('loadingDelete', true).set('successDelete', false).set('errorDelete', false);
};

const handleDeleteUnidadeSuccess = (state, action) => {
  return state.set('loadingDelete', false).set('successDelete', true).set('errorDelete', false);
};

const handleDeleteUnidadeError = (state, action) => {
  return state.set('loadingDelete', false).set('successDelete', false).set('errorDelete', action.error);
};

const handleSave = (state, action) => {
  return state.set('loadingSave', true).set('successSave', false).set('errorSave', false);
};

const handleSaveSuccess = (state, action) => {
  return state.set('loadingSave', false).set('successSave', true).set('errorSave', false).set('unidadeRespSave', action.unidade);
};

const handleSaveError = (state, action) => {
  return state.set('loadingSave', false).set('successSave', false).set('errorSave', action.error);
};

const handleGetMultipleClustersOptions = (state, action) => {
  return state.set('loadingMultipleClustersOptions', true).set('successMultipleClustersOptions', false).set('errorMultipleClustersOptions', false);
};

const handleGetMultipleClustersOptionsSuccess = (state, action) => {
  return state.set('multipleClustersOptions', action.multipleClustersOptions).set('loadingMultipleClustersOptions', false).set('successMultipleClustersOptions', true).set('errorMultipleClustersOptions', false);
};

const handleGetMultipleClustersOptionsError = (state, action) => {
  return state.set('loadingMultipleClustersOptions', false).set('successMultipleClustersOptions', false).set('errorMultipleClustersOptions', action.error);
};

const handleSetBusca = (state, action) => {
  return state.set('busca', action.busca);
};

const handleGetPendingActivities = (state, action) => {
  return state.set('loadingPendingActivities', true).set('successPendingActivities', false).set('errorPendingActivities', false);
};

const handleGetPendingActivitiesSuccess = (state, action) => {
  return state.set('pendingActivities', action.pendingActivities).set('loadingPendingActivities', false).set('successPendingActivities', true).set('errorPendingActivities', false);
};

const handleGetPendingActivitiesError = (state, action) => {
  return state.set('loadingPendingActivities', false).set('successPendingActivities', false).set('errorPendingActivities', action.error);
};

const handleGetCompleteActivities = (state, action) => {
  return state.set('loadingCompleteActivities', true).set('successCompleteActivities', false).set('errorCompleteActivities', false);
};

const handleGetCompleteActivitiesSuccess = (state, action) => {
  return state.set('completeActivities', action.completeActivities).set('loadingCompleteActivities', false).set('successCompleteActivities', true).set('errorCompleteActivities', false);
};

const handleGetCompleteActivitiesError = (state, action) => {
  return state.set('loadingCompleteActivities', false).set('successCompleteActivities', false).set('errorCompleteActivities', action.error);
};

const handleResetSuccessSave = (state, action) => {
  return state.set('successSave', false);
};

const handleReset = (state, action) => {
  return initialState;
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_UNIDADES:
      return handleGetUnidades(state, action);
    case Types.GET_UNIDADES_SUCCESS:
      return handleGetUnidadesSuccess(state, action);
    case Types.GET_UNIDADES_ERROR:
      return handleGetUnidadesError(state, action);

    case Types.GET_UNIDADES_BY_CLUSTERS:
      return handleGetUnidadesByClusters(state, action);
    case Types.GET_UNIDADES_BY_CLUSTERS_SUCCESS:
      return handleGetUnidadesByClustersSuccess(state, action);
    case Types.GET_UNIDADES_BY_CLUSTERS_ERROR:
      return handleGetUnidadesByClustersError(state, action);

    case Types.GET_UNIDADE:
      return handleGetUnidade(state, action);
    case Types.GET_UNIDADE_SUCCESS:
      return handleGetUnidadeSuccess(state, action);
    case Types.GET_UNIDADE_ERROR:
      return handleGetUnidadeError(state, action);

    case Types.DELETE_UNIDADE:
      return handleDeleteUnidade(state, action);
    case Types.DELETE_UNIDADE_SUCCESS:
      return handleDeleteUnidadeSuccess(state, action);
    case Types.DELETE_UNIDADE_ERROR:
      return handleDeleteUnidadeError(state, action);

    case Types.POST_UNIDADE:
      return handleSave(state, action);
    case Types.POST_UNIDADE_SUCCESS:
      return handleSaveSuccess(state, action);
    case Types.POST_UNIDADE_ERROR:
      return handleSaveError(state, action);

    case Types.UPDATE_UNIDADE:
      return handleSave(state, action);
    case Types.UPDATE_UNIDADE_SUCCESS:
      return handleSaveSuccess(state, action);
    case Types.UPDATE_UNIDADE_ERROR:
      return handleSaveError(state, action);

    case Types.UPDATE_UNIDADES:
      return handleSave(state, action);
    case Types.UPDATE_UNIDADES_SUCCESS:
      return handleSaveSuccess(state, action);
    case Types.UPDATE_UNIDADES_ERROR:
      return handleSaveError(state, action);

    case Types.UPDATE_ALL_UNIDADES:
      return handleSave(state, action);
    case Types.UPDATE_ALL_UNIDADES_SUCCESS:
      return handleSaveSuccess(state, action);
    case Types.UPDATE_ALL_UNIDADES_ERROR:
      return handleSaveError(state, action);

    case Types.GET_MULTIPLE_CLUSTERS_OPTIONS:
      return handleGetMultipleClustersOptions(state, action);
    case Types.GET_MULTIPLE_CLUSTERS_OPTIONS_SUCCESS:
      return handleGetMultipleClustersOptionsSuccess(state, action);
    case Types.GET_MULTIPLE_CLUSTERS_OPTIONS_ERROR:
      return handleGetMultipleClustersOptionsError(state, action);

    case Types.GET_PENDING_ACTIVITIES:
      return handleGetPendingActivities(state, action);
    case Types.GET_PENDING_ACTIVITIES_SUCCESS:
      return handleGetPendingActivitiesSuccess(state, action);
    case Types.GET_PENDING_ACTIVITIES_ERROR:
      return handleGetPendingActivitiesError(state, action);

    case Types.GET_COMPLETE_ACTIVITIES:
      return handleGetCompleteActivities(state, action);
    case Types.GET_COMPLETE_ACTIVITIES_SUCCESS:
      return handleGetCompleteActivitiesSuccess(state, action);
    case Types.GET_COMPLETE_ACTIVITIES_ERROR:
      return handleGetCompleteActivitiesError(state, action);

    case Types.SET_BUSCA:
      return handleSetBusca(state, action);
    case Types.RESET_SUCCESS_SAVE:
      return handleResetSuccessSave(state, action);
    case Types.RESET:
      return handleReset(state, action);

    default:
      return state;
  }
}