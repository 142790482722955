import React from 'react';
import { StyledCollapse, DefaultCloseIcon, DefaultOpenIcon } from './styles';
import { Checkbox } from '@components-teammove';

const Collapse = ({ children, CloseIcon, OpenIcon, ...props }) => (
  <StyledCollapse
    {...props}
    expandIcon={(panelProps) => {
      if (panelProps.isActive) {
        if (CloseIcon) return <CloseIcon size="1.5rem" />;
        return <DefaultCloseIcon size="1.5rem" />;
      } else {
        if (OpenIcon) return <OpenIcon size="1.5rem" />;
        return <DefaultOpenIcon size="1.5rem" />;
      }
    }}
  >
    {children}
  </StyledCollapse>
);

Collapse.Panel = ({ children, checkboxProps, ...props }) => (
  <StyledCollapse.Panel
    {...props}
    extra={checkboxProps && <Checkbox {...checkboxProps} />}
  >
    {children}
  </StyledCollapse.Panel>
);

export default Collapse;
