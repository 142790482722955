import styled from 'styled-components';
import { Collapse } from 'antd';
import { FiX, FiChevronDown } from 'react-icons/fi';

export const DefaultCloseIcon = styled(FiX)`
  cursor: pointer;
  opacity: .5;
`;

export const DefaultOpenIcon = styled(FiChevronDown)`
  cursor: pointer;
  opacity: .5;
`;

export const StyledCollapse = styled(Collapse)`
  &.ant-collapse {
    background-color: ${({ theme }) => theme.darkSecondaryColor};

    * {
      color: ${({ theme }) => theme.textColor};
    }

    .ant-collapse-item {
      .ant-collapse-header {
        display: flex !important;
        align-items: center !important;
        gap: 8px;
        position: relative !important;

        > * {
          margin: 0 !important;
          padding: 0 !important;
        }

        .ant-collapse-header-text {
          flex: 1 !important;
          order: 1 !important;
        }

        .ant-collapse-extra {
          order: 2 !important;
          margin-left: auto !important;
          margin-right: 32px !important;
        }

        .ant-collapse-arrow {
          position: absolute !important;
          top: 50% !important;
          transform: translateY(-50%) !important;
          right: 12px !important;
        }
      }

      .ant-collapse-content {
        background-color: ${({ theme }) => theme.darkSecondaryColor};
      }
    }
  }
`;