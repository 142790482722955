import React, { useMemo } from 'react';
import { Form, ModularTree } from '@components-teammove';

const VisualizacoesTab = ({ form, visualizationPermissions }) => {
  const handleTreeChange = (checkedKeys = [], orderedNodes = []) => {
    if (checkedKeys.length === 0) return;
    if (orderedNodes.length === 0) return;

    const safeCheckedKeys = Array.isArray(checkedKeys) ? checkedKeys : [];

    const transformedData = orderedNodes.map((node) => ({
      permissionId: node.permissionId,
      parentPermissionId: node.parentPermissionId,
      name: node.title,
      value: safeCheckedKeys.includes(node.permissionId),
      order: node.order,
    }));

    form.setFieldsValue({ views: transformedData });
  };

  function filterEnabledItems(data) {
    return data
      .filter((item) => !item.disabled) // Filter out disabled items at current level
      .map((item) => {
        if (item.children && item.children.length > 0) {
          return {
            ...item,
            children: filterEnabledItems(item.children),
          };
        }
        return { ...item };
      });
  }

  const filteredPermissions = useMemo(() => {
    return filterEnabledItems(visualizationPermissions) || [];
  }, [visualizationPermissions]);

  return (
    <Form.Item name="views" label="Visualizações">
      <ModularTree
        name="views"
        initialData={visualizationPermissions}
        draggable
        onChange={handleTreeChange}
        notificationMessage="Por favor, marque a visualização pai primeiro"
        idField="permissionId"
        parentIdField="parentPermissionId"
        defaultExpandAll
      />
    </Form.Item>
  );
};

export default VisualizacoesTab;
