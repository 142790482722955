import { del, get, post, put } from '../api';
import { InfoUsuario, Filtros } from '@utils';
import { FILTERS_KEY } from '@ducks/unidades';
import { getDetailsHTML, getQueryParamsUnidades } from './rules';

export const getUnidades = (idUsuario = InfoUsuario.get('cdUsuario'), splitCompanyInfo, extraHeaders = {}) => {
  const details = InfoUsuario.perm('exibeDetalhesUnidade');

  const mapItem = (item) => {
    const commonProps = {
      key: item?.cd_empresa || '',
      value: item?.cd_empresa || '',
      title: item?.nm_reduzido || '',
    };

    return splitCompanyInfo ? {
      ...commonProps,
      nmReduzido: item?.nm_reduzido?.toUpperCase() || '',
      nmEmpresa: item?.nm_empresa?.toUpperCase() || '',
      endereco: item?.endereco?.toUpperCase() || '',
      cidade: item?.cidade?.toUpperCase() || '',
      estado: item?.estado?.toUpperCase() || '',
    } : {
      ...commonProps,
      details: details ? getDetailsHTML(item) : null,
    };

  };

  return get(
    `/api/companies?loggedUser=${idUsuario}`,
    {
      'x-cache-control': 'cache',
      'x-cache-ttl': 60 * 60 * 24, // 24h
      ...extraHeaders
    },
    true
  ).then((res) => res.companies.map(mapItem));
};

export const getAllUnidades = (idUsuario = 0) => {
  return get(`/api/unidades?idUsuario=${idUsuario}`);
};

export const putUnidadeEscalaRegra = (tipo, unidadesValor) => {
  return put(`/api/unidades/escala_folga_regra/${tipo}`, unidadesValor);
};

export const getUnidadesSemAgrupadores = (filters, limit, search, orderBy, count = false) => {
  const baseParams = `?loggedUser=${InfoUsuario.get('cdUsuario')}&getWholeList=true`;
  const filterParams = getQueryParamsUnidades(filters, false);
  const limitParam = limit ? `&limit=${limit}` : '';
  const searchParam = search ? `&search=${search}` : '';
  const cnpjParam = InfoUsuario.get('senhaMaster') || InfoUsuario.perm('unidadesCNPJ') ? '&searchByCnpj=true' : '';
  const orderParam = orderBy ? `&orderByField=${orderBy.field}&orderByDirection=${orderBy.order}` : '';
  const countParam = `&count=${count}&getClusterSituation=true`;

  return get(
    `/api/companies${baseParams}${filterParams}${limitParam}${searchParam}${cnpjParam}${orderParam}${countParam}`,
    {},
    true
  );
};

export const getUnidadesByClusters = (filters, search) => {
  return get(`/api/clusters/dash?idUser=${InfoUsuario.get('cdUsuario')}${getQueryParamsUnidades(filters)}${search ? `&search=${search}` : ''}${InfoUsuario.get('senhaMaster') || InfoUsuario.perm('unidadesCNPJ') ? '&searchByCnpj=true' : ''}${InfoUsuario.perm('exibeRazaoSocialListagemUnidades') ? '&searchByRazaoSocial=true' : ''}`);
};

export const getUnidade = (id, useOtherFields = true) => {
  return get(`/api/companies/${id}?useOtherFields=${useOtherFields}`);
};

export const postUnidade = (unidade) => {
  return post('/api/companies', unidade);
};

export const updateUnidade = (unidade, id) => {
  return put(`/api/companies/${id}`, unidade);
};

export const updateUnidades = (idsCompanies, arrayCompanies) => {
  return put(`/api/companies?companies=${idsCompanies.join(' ')}&idUser=${InfoUsuario.get('cdUsuario')}`, arrayCompanies);
};

export const updateAllUnidades = (search, arrayCompanies) => {
  return put(
    `/api/companies/all?idUser=${InfoUsuario.get('cdUsuario')}${getQueryParamsUnidades(Filtros.get(FILTERS_KEY))}${search ? `&search=${search}` : ''}${InfoUsuario.get('senhaMaster') || InfoUsuario.perm('unidadesCNPJ') ? '&searchByCnpj=true' : ''}${InfoUsuario.perm('exibeRazaoSocialListagemUnidades') ? '&searchByRazaoSocial=true' : ''}`,
    arrayCompanies
  );
};

export const getMultipleClustersOptions = (companies) => {
  return get(`/api/companies/multipleClustersOptions?companies=${companies.join(' ')}`);
};

export const deleteUnidade = (id) => {
  return del(`/api/companies/${id}`);
};

export const getDuplicatedIdApi = () => {
  return get('/api/companies/duplicated/idapi');
};

export const getActivities = (idUnit, idUser, status, order, limit) => {
  return get(`/api/companies/${idUnit}/activities?userId=${idUser}&status=${status}&order=${order ? order : ''}${limit ? `&limit=${limit}` : ''}`);
};