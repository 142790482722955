import React, { useCallback, useState } from 'react';
import { SortableContext,  verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useDroppable } from '@dnd-kit/core';
import { ArrowIcon, ColumnContainer, ContainerHeaderInfo, HeaderContainer, NumberOfTickets, OverflowContainerColumn, TitleHeader } from './styles';
import SortableCard from '../SortableCard';
import CardInfo from '../CardLayout/CardInfo';
import { useSelector } from 'react-redux';

const Column = ({ column }) => {
  const { tickets } = column;
  const { setNodeRef } = useDroppable({ id: column.id });

  const theme = useSelector(({ app }) => app.get('theme'));

  const [collapsed, setCollapsed] = useState(false);

  const renderRow = useCallback(({ index, style }) => {
    const ticket = tickets[index];

    if (!ticket) return null;
    return (
      <div style={style} key={ticket.id}>
        <SortableCard 
          key={ticket.id} 
          id={ticket.id} 
          ticket={ticket}
        >
          <CardInfo ticket={ticket} />
        </SortableCard>
      </div>
    );
  }, [tickets]);

  return (
    <ColumnContainer collapsed={collapsed}>
      <HeaderContainer collapsed={collapsed} color={column.id === -1 ? theme['@global-dark-highlight-color'] : column.color}>
        <ContainerHeaderInfo collapsed={collapsed} align='center'>
          <ArrowIcon onClick={() => setCollapsed(!collapsed)} type={collapsed ? 'chevron-right' : 'chevron-down'} color='white' collapsed={collapsed}/>
          <TitleHeader collapsed={collapsed}>{column.title}</TitleHeader>
        </ContainerHeaderInfo>
        <NumberOfTickets>{column.numberOfTickets}</NumberOfTickets>
      </HeaderContainer>
      {!collapsed && (
        <OverflowContainerColumn ref={setNodeRef}>
          <SortableContext 
            items={tickets.map((ticket) => ticket.id)}
            strategy={verticalListSortingStrategy}           
          >
            {tickets.map((ticket, index) => renderRow({ index, style: {} }))}
          </SortableContext>
        </OverflowContainerColumn>
      )}
    </ColumnContainer>
  );
};

export default Column;