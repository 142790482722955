import { get, post } from '../api'; 
import { InfoUsuario } from '../../utils';

export const getPendingGeolocationCompanies = () => {
  const response = get(`/api/companies/geolocation?userId=${InfoUsuario.get('cdUsuario')}`, {}, true);
  return response;
};

export const updatePendingGeolocationCompanies = () => {
  return post(`/api/companies/geolocation?userId=${InfoUsuario.get('cdUsuario')}`, {}, {}, true);
};