import styled from 'styled-components';
import { Column } from '@components-teammove';

export const OrganogramContainer = styled(Column)`
  padding: 24px;
  flex: 1;
  min-height: calc(100vh - 170px);
  max-height: calc(100vh - 170px);
  height: 100%;
  width: 100%;
  overflow: hidden;
`;
