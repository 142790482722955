import React, { useEffect, useMemo, useState } from 'react';
import App from '@app';
import {
  Body,
  PageHeader,
  Breadcrumb,
  Button,
  Progress,
  Form,
  Tooltip,
  Skeleton,
  Column,
  Row,
  Text,
} from '@components-teammove';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams, Link } from 'react-router-dom';
import {
  getChecklistQuestionGroups,
  getAnswersHistoric,
  postAnswers,
  resetAnswersHistoric,
  getChecklistFinishPreview,
  resetSaveSuccesses,
} from '@ducks/checklists';
import { ConditionalContainerQuestion } from '../styles';
import { ActivityTypeIcon, ActivityTypeIconWrapper } from '../../styles';
import {
  Container,
  GroupTitle,
  HelpIcon,
  HelpText,
  HistoricActionPlanContainer,
  HistoricImage,
  HistoricItemAnswer,
  HistoricItemColumn,
  HistoricItemDate,
  HistoricTitle,
  NextQuestionIcon,
  PreviousQuestionIcon,
  QuestionContainer,
  QuestionNumber,
  QuestionTitle,
  ToggleHistoricButton,
} from './styles';
import AnswerController from './AnswerController';
import { capitalizeFirstLetter, formatValueByType } from './rules';
import { formatTime, formatDate, moment, findWithIndex } from '@utils';
import { ActivityKindSquare } from '../../../../../../ui-teammove';

export default function Answer() {
  const { idActivity, idChecklist } = useParams();
  const { state } = useLocation();
  const history = useHistory();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const theme = useSelector(({ app }) => app.get('theme'));

  const {
    checklistQuestionGroups: checklistWithGroups,
    loadingChecklistQuestionGroups: loadingChecklistWithGroups,
    successChecklistQuestionGroups: successChecklistWithGroups,
    answersHistoric: historic,
    loadingAnswersHistoric: loadingHistoric,
    loadingFinishChecklist,
    successFinishChecklist,
    successChecklistFinishPreview,
    successSaveAnswers,
    loadingSaveAnswers
  } = useSelector(({ checklists }) => ({
    checklistQuestionGroups: checklists.get('checklistQuestionGroups'),
    loadingChecklistQuestionGroups: checklists.get('loadingChecklistQuestionGroups'),
    successChecklistQuestionGroups: checklists.get('successChecklistQuestionGroups'),
    answersHistoric: checklists.get('answersHistoric'),
    loadingAnswersHistoric: checklists.get('loadingAnswersHistoric'),
    loadingFinishChecklist: checklists.get('loadingFinishChecklist'),
    successFinishChecklist: checklists.get('successFinishChecklist'),
    successChecklistFinishPreview: checklists.get('successChecklistFinishPreview'),
    successSaveAnswers: checklists.get('successSaveAnswers'),
    loadingSaveAnswers: checklists.get('loadingSaveAnswers')
  }));

  const activitiesTypes = useSelector(({ configuracoesAtividadesTipos }) =>
    configuracoesAtividadesTipos.get('tiposAtividadesPlanosAcao')
  );

  const [currentGroupId, setCurrentGroupId] = useState(state?.group);
  const [currentQuestionId, setCurrentQuestionId] = useState(state?.question);
  const [currentQuestion, setCurrentQuestion] = useState();
  const [visibleHistoric, setVisibleHistoric] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [finishingChecklist, setFinishingChecklist] = useState(false);
  const [idParentQuestionAnswer, setIdParentQuestionAnswer] = useState();

  const currentGroup = useMemo(() => {
    return currentGroupId
      ? findWithIndex(
        checklistWithGroups?.groups,
        ({ idGroup }) => idGroup === currentGroupId
      )
      : currentQuestionId
        ? findWithIndex(checklistWithGroups?.groups, ({ asks }) =>
          asks.find(({ id }) => id === currentQuestionId)
        )
        : findWithIndex(checklistWithGroups?.groups, ({ asks }) =>
          asks.find(({ answered }) => answered)
        ) || checklistWithGroups?.groups?.[0];
  }, [currentGroupId, successChecklistWithGroups, currentQuestionId]);

  const totalAsksAnswered = useMemo(() => {
    return checklistWithGroups?.groups?.reduce(
      (state, { asks }) =>
        state + asks.filter(({ answered }) => answered).length,
      0
    );
  }, [successChecklistWithGroups, currentQuestionId]);

  const totalAsks = useMemo(() => {
    return checklistWithGroups?.groups?.reduce(
      (state, { asks }) => state + asks.length,
      0
    );
  }, [checklistWithGroups, currentQuestionId]);

  const currentOrderAsk = useMemo(() => {
    let correctAskOrder = 1;
    const newChecklistGroup = checklistWithGroups?.groups?.reduce(
      (state, group) => {
        return [
          ...state,
          ...group.asks.map((ask) => ({ ...ask, askOrder: correctAskOrder++ })),
        ];
      },
      []
    );
    return newChecklistGroup?.find(({ id }) => id === currentQuestionId)
      ?.askOrder;
  }, [successChecklistWithGroups, currentQuestionId]);

  useEffect(() => {
    return () => {
      dispatch(resetSaveSuccesses());
    };
  }, []);

  useEffect(() => {
    form.resetFields();
    const newQuestion = currentQuestionId
      ? currentGroup?.asks?.find(({ id }) => id === currentQuestionId)
      : currentGroup?.asks[0];
    if (!currentQuestionId) {
      setCurrentQuestionId(newQuestion?.id);
    }
    setCurrentQuestion(newQuestion);

    if (successSaveAnswers && successChecklistWithGroups) {
      handleNextQuestionClick();
    }
  }, [currentGroup, currentQuestionId]);

  useEffect(() => {
    if (idChecklist) {
      dispatch(getChecklistQuestionGroups(idChecklist));
    }
  }, [idChecklist]);

  useEffect(() => {
    if (idChecklist && currentQuestionId) {
      dispatch(resetAnswersHistoric());
      dispatch(getAnswersHistoric(idChecklist, currentQuestionId));
    }
  }, [currentQuestionId]);

  useEffect(() => {
    if (checklistWithGroups.isCancelationChecklist && successFinishChecklist) {
      history.push(
        `/atividades/agenda/${idActivity}/checklist/${idChecklist}/finished`
      );
    }
  }, [checklistWithGroups, successFinishChecklist]);

  useEffect(() => {
    if (successChecklistFinishPreview && finishingChecklist) {
      setFinishingChecklist(false);
      history.push(
        `/atividades/agenda/${idActivity}/checklist/${idChecklist}/finish`
      );
    }
  }, [successChecklistFinishPreview]);

  useEffect(() => {
    if (!successSaveAnswers) return;
    const parentQuestionOption = currentQuestion.options.find(
      ({ id, enableConditionalQuestions }) =>
        enableConditionalQuestions && id === idParentQuestionAnswer
    );
    if (parentQuestionOption) {
      dispatch(getChecklistQuestionGroups(idChecklist));
    } else {
      handleNextQuestionClick();
    }
  }, [successSaveAnswers]);

  const handlePreviousQuestionClick = () => {
    const currentIndex = currentGroup.asks.findIndex(
      ({ id }) => id === currentQuestion.id
    );

    if (currentIndex === 0) {
      const currentGroupIndex = checklistWithGroups.groups.findIndex(
        ({ idGroup }) => idGroup === currentGroup.idGroup
      );
      if (currentGroupIndex > 0) {
        const previousGroup = checklistWithGroups.groups[currentGroupIndex - 1];
        setCurrentGroupId(previousGroup.idGroup);
        setCurrentQuestionId(
          previousGroup.asks[previousGroup.asks.length - 1].id
        );
      }
    } else {
      setCurrentQuestionId(currentGroup.asks[currentIndex - 1].id);
    }
  };

  const handleNextQuestionClick = () => {
    const currentIndex = currentGroup.asks.findIndex(
      ({ id }) => id === currentQuestion.id
    );

    if (currentIndex === currentGroup.asks.length - 1) {
      const currentGroupIndex = checklistWithGroups.groups.findIndex(
        ({ idGroup }) => idGroup === currentGroup.idGroup
      );

      if (
        currentGroupIndex >= 0 &&
        currentGroupIndex < checklistWithGroups.groups.length - 1
      ) {
        const newGroup = checklistWithGroups.groups[currentGroupIndex + 1];
        setCurrentGroupId(newGroup?.id);
        setCurrentQuestionId(
          newGroup.asks.find((question, index) => index === 0).id
        );
      } else {
        if (checklistWithGroups.isCancelationChecklist) {
          history.goBack();
        } else {
          setFinishingChecklist(true);
          dispatch(getChecklistFinishPreview(idChecklist));
        }
        return;
      }
    } else {
      setCurrentQuestionId(currentGroup.asks[currentIndex + 1].id);
    }
    dispatch(resetSaveSuccesses());
  };

  const handleAnswerClick = () => {
    form
      .validateFields()
      .then((values) => {
        if (
          currentQuestion?.type === 'TEXTO' ||
          currentQuestion?.type === 'TEXTO_PARAGRAFO' ||
          currentQuestion?.type === 'DATA' ||
          currentQuestion?.type === 'HORARIO' ||
          currentQuestion?.type === 'NUMERICO' ||
          currentQuestion?.type === 'ANEXO'
        ) {
          const answers = currentQuestion?.answers
            ? [
              {
                ...currentQuestion.answers[0],
                value: values.answer
                  ? formatValueByType(values.answer, currentQuestion)
                  : null,
                observation: values.observation,
                uploads: [
                  ...(currentQuestion.type === 'ANEXO' && values.answer
                    ? values.answer
                      .filter(({ response }) => response)
                      .map((upload) => ({ url: upload.response }))
                    : []),
                  ...(values.upload?.map((upload) => ({
                    url: upload.response || upload.url,
                    idTemp: upload.idTemp,
                    idUpload: Number.isNaN(Number(upload.uid))
                      ? null
                      : upload.uid,
                  })) || []),
                ],
              },
            ]
            : [
              {
                value: values.answer,
                uploads: values.upload
                  ? values.upload.map((upload) => ({
                    url: upload.response || upload.url,
                    idTemp: upload.idTemp,
                    idUpload: Number.isNaN(Number(upload.uid))
                      ? null
                      : upload.uid,
                  }))
                  : null,
              },
            ];
          dispatch(
            postAnswers(
              idChecklist,
              currentQuestionId,
              answers.map((answer) => ({
                ...answer,
                uploads: answer.uploads?.length === 0 ? null : answer.uploads,
              }))
            )
          );
        } else {
          if (currentQuestion?.type === 'MULTIPLA_ESCOLHA') {
            const keys = Object.keys(values);
            if (
              keys.some(
                (key) => key.includes('option') && key.includes('Activity')
              ) &&
              !keys.some((key) => key.includes('titleActivity'))
            ) {
              keys.forEach((key) => {
                if (key.includes('option') && key.includes('Activity')) {
                  const id = parseInt(
                    key.substring(key.indexOf('-') + 1, key.lastIndexOf('-'))
                  );
                  if (
                    keys.some((key) => key.includes('option-' + id)) &&
                    !keys.some((key) =>
                      key.includes('option-' + id + '-titleActivity')
                    )
                  ) {
                    values['option-' + id + '-titleActivity'] =
                      activitiesTypes.find(
                        ({ id: idActivityType }) =>
                          idActivityType ===
                          values['option-' + id + '-idTypeActivity']
                      )?.name;
                  }
                }
              });
            }
            const answer = Object.keys(values).reduce(
              (state, key) => {
                return parseInt(
                  key.substring(key.indexOf('-') + 1, key.lastIndexOf('-'))
                ) === values.answer
                  ? {
                    ...state,
                    [key.substring(key.lastIndexOf('-') + 1)]: values[key],
                  }
                  : { ...state };
              },
              { ...currentQuestion?.answers[0], value: values.answer }
            );
            answer.uploads = [
              ...(answer.uploads?.map(({ response, url, uid, idTemp }) => ({
                url: response || url,
                extension: (response || url).substring(
                  (response || url).length - 3
                ),
                idUpload: response ? null : uid,
                idTemp,
              })) || []),
              ...(values.uploads?.map(({ response }) => ({ url: response })) ||
                []),
            ];
            delete answer.upload;
            setIdParentQuestionAnswer(values.answer);
            dispatch(
              postAnswers(idChecklist, currentQuestionId, [
                {
                  ...answer,
                  uploads: answer.uploads?.length > 0 ? answer.uploads : null,
                },
              ])
            );
          } else if (currentQuestion?.type === 'CAIXA_SELECAO') {
            const answers = values.answer.reduce((state, value) => {
              return [
                ...state,
                {
                  ...Object.keys(values).reduce((state, key) => {
                    return parseInt(
                      key.substring(key.indexOf('-') + 1, key.lastIndexOf('-'))
                    ) === value
                      ? {
                        ...state,
                        [key.substring(key.lastIndexOf('-') + 1)]:
                          values[key],
                      }
                      : { ...state };
                  }, {}),
                  idAnswer: currentQuestion.answers[0].idAnswer,
                  idAnswerTemp: currentQuestion.answers[0].idAnswerTemp,
                  idAskOption: value,
                  value: true,
                },
              ];
            }, []);
            const allAnswers = currentQuestion.options.map(({ id }) => {
              // .map((answer) => ({ ...answer, uploads: answer.upload })).forEach((answer) => delete answer.upload)
              const optionAnswer = answers.find(
                ({ idAskOption }) => idAskOption === id
              ) || {
                idAnswer: currentQuestion.answers[0].idAnswer,
                idAnswerTemp: currentQuestion.answers[0].idAnswerTemp,
                idAskOption: id,
                value: false,
              };
              optionAnswer.uploads = optionAnswer.uploads?.map(
                ({ response, url, uid, idTemp }) => ({
                  url: response || url,
                  extension: (response || url).substring(
                    (response || url).length - 3
                  ),
                  idUpload: response ? null : uid,
                  idTemp,
                })
              );
              return {
                ...optionAnswer,
                uploads:
                  optionAnswer.uploads?.length > 0
                    ? optionAnswer.uploads
                    : null,
                idAnswerValue:
                  currentQuestion.answers.find(
                    ({ idAskOption }) => idAskOption === id
                  )?.idAnswerValue || null,
              };
            });
            dispatch(postAnswers(idChecklist, currentQuestionId, allAnswers));
          }
        }
      })
      .catch((err) => console.error(err));
  };

  const getActivityKindSquare = (activityKindId) => {
    const activityKind = activitiesTypes.find(
      ({ id }) => id === activityKindId
    );

    return (
      activityKind && (
        <Link to={`/configuracoes/atividades?id=${activityKindId}`}>
          <ActivityKindSquare
            name={activityKind.name}
            color={activityKind.color}
            icon={activityKind.icon}
          />
        </Link>
      )
    );
  };

  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title={
            <Row gap="14px" align="center">
              {checklistWithGroups?.nameActivity}
              <ActivityTypeIconWrapper
                color={checklistWithGroups?.colorTypeActivity}
              >
                <ActivityTypeIcon
                  type={checklistWithGroups?.iconTypeActivity}
                />
              </ActivityTypeIconWrapper>
            </Row>
          }
          onBack={() => history.goBack()}
          breadcrumb={
            <Breadcrumb
              items={[
                { path: '/', name: 'Home' },
                { path: '/atividades/agenda', name: 'Agenda' },
                {
                  path: `/atividades/agenda/${idActivity}`,
                  name: checklistWithGroups?.nameActivity || '...',
                },
                {
                  path: `/atividades/agenda/${idActivity}/checklist/${idChecklist}`,
                  name: 'Checklist',
                },
                {
                  path: `/atividades/agenda/${idActivity}/checklist/${idChecklist}/answer`,
                  name: 'Questionário',
                },
              ]}
            />
          }
        >
          {loadingChecklistWithGroups ? (
            <Container>
              <Column gap="10px">
                <Row justify="space-between" align="center">
                  <Skeleton.MinorLine />
                  <Skeleton.MinorLine />
                </Row>
                <Skeleton.SearchBar />
              </Column>

              <QuestionContainer>
                <Column gap="26px">
                  <Row justify="space-between">
                    <Skeleton.MinorLine />
                    <Skeleton.MinorLine />
                  </Row>
                  <Column>
                    <Skeleton.SearchBar />
                    <Row justify="space-between" align="center">
                      <Row gap="18px" align="center">
                        <Button type="primary" disabled>
                          Responder
                        </Button>
                        <Skeleton.MinorLine />
                      </Row>
                      <Skeleton.MinorLine />
                    </Row>
                  </Column>
                </Column>
              </QuestionContainer>
            </Container>
          ) : (
            <Container>
              <Column gap="10px">
                <Row justify="space-between" align="center">
                  <GroupTitle>{currentGroup?.name}</GroupTitle>
                  <QuestionNumber>
                    Pergunta {currentOrderAsk} de {totalAsks}
                  </QuestionNumber>
                </Row>
                <Progress
                  percent={(totalAsksAnswered / totalAsks) * 100}
                  showInfo={false}
                  strokeColor={checklistWithGroups?.colorTypeActivity}
                  strokeWidth={10}
                />
              </Column>

              <QuestionContainer>
                {currentOrderAsk !== 1 && (
                  <PreviousQuestionIcon
                    type="chevron-left"
                    size="2rem"
                    onClick={handlePreviousQuestionClick}
                  />
                )}
                <NextQuestionIcon
                  type="chevron-right"
                  size="2rem"
                  onClick={handleNextQuestionClick}
                />
                <Column
                  gap={
                    currentQuestion?.type === 'TEXTO'
                      ? '26px'
                      : currentQuestion?.type === 'ANEXO'
                        ? '24px'
                        : '32px'
                  }
                >
                  <Row justify="space-between">
                    <QuestionTitle>
                      {currentOrderAsk} - {currentQuestion?.title}
                      {!currentQuestion?.optional && (
                        <span className="required-mark">*</span>
                      )}
                    </QuestionTitle>
                    {currentQuestion?.observation && (
                      <Row gap="6px" align="center">
                        <HelpText>Ajuda</HelpText>
                        <Tooltip title={currentQuestion?.observation}>
                          <HelpIcon type="help-circle" />
                        </Tooltip>
                      </Row>
                    )}
                  </Row>

                  <Column
                    gap={
                      currentQuestion?.type === 'MULTIPLA_ESCOLHA' ||
                        currentQuestion?.type === 'CAIXA_SELECAO'
                        ? '32px'
                        : '18px'
                    }
                  >
                    {currentQuestion?.idParentQuestion && (
                      <ConditionalContainerQuestion>
                        {currentQuestion?.titleParentQuestion}{' '}
                        {currentQuestion?.titleParentQuestionOption}
                      </ConditionalContainerQuestion>
                    )}
                    <AnswerController
                      form={form}
                      question={currentQuestion}
                      historic={historic && historic[0]}
                      setLoadingUpload={setLoadingUpload}
                    />
                    <Row justify="space-between" align="center">
                      <Row gap="18px" align="center">
                        <Button
                          type="primary"
                          loading={
                            loadingFinishChecklist ||
                            loadingUpload ||
                            loadingSaveAnswers
                          }
                          onClick={handleAnswerClick}
                        >
                          Responder
                        </Button>
                      </Row>
                      {currentQuestion?.type !== 'ANEXO' && (
                        <ToggleHistoricButton
                          type="link"
                          onClick={() => setVisibleHistoric(!visibleHistoric)}
                        >
                          {visibleHistoric ? 'Ocultar' : 'Ver'} histórico de
                          respostas
                        </ToggleHistoricButton>
                      )}
                    </Row>
                  </Column>
                </Column>
              </QuestionContainer>

              {visibleHistoric &&
                currentQuestion?.type !== 'ANEXO' &&
                (!loadingHistoric ? (
                  <QuestionContainer>
                    <Column gap="20px">
                      <HistoricTitle>Histórico de respostas</HistoricTitle>
                      {historic.length > 0 ? (
                        historic.map(
                          ({
                            date,
                            answer,
                            uploads,
                            observation,
                            taskTitle,
                            taskAssignee,
                            taskKindId,
                          }) => (
                            <HistoricItemColumn key={date + '-' + answer}>
                              <HistoricItemDate>
                                {formatTime(date)} -{' '}
                                {capitalizeFirstLetter(
                                  moment(date).format('ddd')
                                )}{' '}
                                - {formatDate(date)}
                              </HistoricItemDate>
                              <HistoricItemAnswer>{answer}</HistoricItemAnswer>
                              <Row>
                                {uploads &&
                                  uploads.map((url) => (
                                    <HistoricImage
                                      key={url}
                                      src={url}
                                      width="64px"
                                      height="64px"
                                    />
                                  ))}
                              </Row>
                              {observation && <Text>{observation}</Text>}
                              {(taskTitle || taskAssignee || taskKindId) && (
                                <HistoricActionPlanContainer>
                                  <Row align="center" gap="4px">
                                    {taskKindId &&
                                      getActivityKindSquare(taskKindId)}
                                    {taskTitle && <Text>{taskTitle}</Text>}
                                  </Row>
                                  {taskAssignee && (
                                    <Text lowOpacity>{taskAssignee}</Text>
                                  )}
                                </HistoricActionPlanContainer>
                              )}
                            </HistoricItemColumn>
                          )
                        )
                      ) : (
                        <HistoricItemAnswer>
                          Não há registro de respostas
                        </HistoricItemAnswer>
                      )}
                    </Column>
                  </QuestionContainer>
                ) : (
                  <QuestionContainer>
                    <Skeleton.MediumLine />
                  </QuestionContainer>
                ))}
            </Container>
          )}
        </PageHeader>
      </Body>
    </App>
  );
}
