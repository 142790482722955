import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Checkbox } from '@components-teammove';
import { useDispatch, useSelector } from 'react-redux';
import {
  getWorkingJourneyList,
  getHomeIndicatorList,
} from '../../../../../store/ducks/configuracoesUsuariosPerfis';
import ActivityTypes from '../../../../../ui-teammove/ActivityTypes';

const { Option } = Select;

const colors = [
  { label: 'Vermelho', value: '#F44336' },
  { label: 'Rosa', value: '#E91E63' },
  { label: 'Roxo', value: '#9C27B0' },
  { label: 'Roxo Escuro', value: '#673AB7' },
  { label: 'Índigo', value: '#3F51B5' },
  { label: 'Azul', value: '#2196F3' },
  { label: 'Azul Claro', value: '#03A9F4' },
  { label: 'Ciano', value: '#00BCD4' },
  { label: 'Verde-água', value: '#009688' },
  { label: 'Verde', value: '#4CAF50' },
  { label: 'Verde Claro', value: '#8BC34A' },
  { label: 'Lima', value: '#CDDC39' },
  { label: 'Amarelo', value: '#FFC107' },
  { label: 'Laranja', value: '#FF9800' },
  { label: 'Laranja Escuro', value: '#FF5722' },
  { label: 'Marrom', value: '#795548' },
  { label: 'Cinza Escuro', value: '#212121' },
  { label: 'Bordô', value: '#9C2222' },
];

const CadastroTab = ({ form, cadastroData }) => {
  const dispatch = useDispatch();
  const [activities, setActivities] = useState([]);
  const [isGuest, setIsGuest] = useState(false);
  const [isSalesman, setIsSalesman] = useState(false);

  const workingJourneyList = useSelector(
    (state) =>
      state.configuracoesUsuariosPerfis.get('workingJourneyList')?.toArray() ||
      []
  );

  const homeIndicatorList = useSelector(
    (state) =>
      state.configuracoesUsuariosPerfis.get('homeIndicatorList')?.toArray() ||
      []
  );

  useEffect(() => {
    setActivities(cadastroData?.activitiesTypes || []);
    setIsGuest(cadastroData?.guest || false);
    setIsSalesman(cadastroData?.salesman || false);
  }, [cadastroData]);

  useEffect(() => {
    dispatch(getWorkingJourneyList());
    dispatch(getHomeIndicatorList());
  }, [dispatch]);

  return (
    <>
      <Form.Item
        name="parentProfileId"
        label="Perfil pai"
        style={{ display: 'none' }}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="description"
        label="Nome do perfil"
        rules={[
          {
            required: true,
            message: 'Insira um nome para o perfil',
          },
        ]}
      >
        <Input placeholder="Adicione um nome para o perfil" />
      </Form.Item>

      <Form.Item
        name="color"
        label="Cor"
        rules={[
          {
            required: true,
            message: 'Insira uma cor para o perfil',
          },
        ]}
      >
        <Select
          placeholder="Selecione uma cor"
          dropdownStyle={{ backgroundColor: '#fff' }}
          style={{ width: '100%' }}
          filterOption={(input, option) =>
            option.children.props.children[1]
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        >
          {colors?.map((color) => (
            <Option key={color.value} value={color.value}>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <div
                  style={{
                    width: '24px',
                    height: '24px',
                    backgroundColor: color.value,
                    borderRadius: '4px',
                  }}
                />
                {color.label}
              </div>
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="guest" valuePropName="checked">
        <Checkbox onChange={(value) => setIsGuest(value)}>Visitante</Checkbox>
      </Form.Item>

      {!isGuest && (
        <Form.Item name="salesman" valuePropName="checked">
          <Checkbox onChange={(value) => setIsSalesman(value)}>
            Vendedor
          </Checkbox>
        </Form.Item>
      )}

      {!isSalesman && (
        <Form.Item name="independent" valuePropName="checked">
          <Checkbox>Independente</Checkbox>
        </Form.Item>
      )}

      <Form.Item name="activitiesTypesPermissions">
        <ActivityTypes activities={activities} />
      </Form.Item>

      <Form.Item name="workingJourneyId" label="Jornada de trabalho">
        <Select
          placeholder="Selecione a jornada do perfil"
          options={workingJourneyList.map((journey) => ({
            label: journey.description,
            value: journey.id,
          }))}
        />
      </Form.Item>

      <Form.Item name="usesWebView" label="Web view">
        <Select
          placeholder="Selecione a visão"
          options={[
            { label: 'Sim', value: true },
            { label: 'Não', value: false },
          ]}
        />
      </Form.Item>

      <Form.Item name="indicadoresHome" label="Indicadores - HOME">
        <Select
          placeholder="Selecione o indicador"
          options={homeIndicatorList.map((dashboard) => ({
            label: dashboard.description,
            value: dashboard.id,
          }))}
        />
      </Form.Item>

      <Form.Item name="indicadoresUnidade" label="Indicadores - UNIDADE">
        <Select
          placeholder="Selecione o indicador"
          options={homeIndicatorList?.map((dashboard) => ({
            label: dashboard.description,
            value: dashboard.id,
          }))}
        />
      </Form.Item>
    </>
  );
};

export default CadastroTab;
