import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import App from '@app';
import {
  Body,
  PageHeader,
  Breadcrumb,
  Form,
  Button,
  Tabs,
  Column,
  Skeleton,
  Modal,
  Text,
} from '@components-teammove';
import { useSelector, useDispatch } from 'react-redux';
import { Session, Notification, getSearchParams } from '@utils';
import CadastroTab from './Tabs/CadastroTab';
import PermissoesTab from './Tabs/PermissoesTab';
import VisualizacoesTab from './Tabs/VisualizacoesTab';
import RestricoesTab from './Tabs/RestricoesTab';
import {
  createProfile,
  getProfileRegisterData,
  updateProfile,
  deleteProfile,
  resetProfileRegisterData,
  resetProfileHierarchy,
  resetCreateOrUpdateProfile,
} from '@ducks/configuracoesUsuariosPerfis';
import moment from 'moment';

export default function CadastroPerfilUsuario() {
  const dispatch = useDispatch();
  const theme = useSelector(({ app }) => app.get('theme'));
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState(
    Session.get('PERFIL_USUARIOS_TAB') || 'Cadastro'
  );
  const [form] = Form.useForm();
  const [weekDaysIds, setWeekDaysIds] = useState([]);
  const [hasInitialized, setHasInitialized] = useState(false);
  const [visibleRemoveModal, setVisibleRemoveModal] = useState(false);

  const profileId = getSearchParams('perfil');

  const {
    profileRegisterData: profileData,
    loadingProfileRegisterData,
    loadingCreateProfile,
    loadingUpdateProfile,
    successCreateProfile,
    successUpdateProfile,
    loadingDeleteProfile,
    successDeleteProfile,
  } = useSelector(({ configuracoesUsuariosPerfis }) =>
    configuracoesUsuariosPerfis.toJS()
  );

  const loading = useMemo(() => {
    return loadingCreateProfile || loadingUpdateProfile || !profileData;
  }, [loadingCreateProfile, loadingUpdateProfile, loadingDeleteProfile]);

  const successCreateOrUpdateProfile = useMemo(() => {
    return successCreateProfile || successUpdateProfile;
  }, [successCreateProfile, successUpdateProfile]);

  const handleDelete = () => {
    dispatch(deleteProfile(profileId));
    setVisibleRemoveModal(false);
  };

  const handleRemoveModal = () => setVisibleRemoveModal(!visibleRemoveModal);

  const handleSave = async () => {
    try {
      const values = await form.validateFields();

      const profileDto = {
        id: profileId ? parseInt(profileId) : undefined,
        parentProfileId: values.parentProfileId,
        description: values.description,
        color: values.color,
        guest: values.guest || false,
        salesman: values.salesman || false,
        independent: values.independent || false,
        shownInSalesmanList: false,
        usesWebView: values.usesWebView || false,
        homeDashboardId: null,
        companyDashboardId: null,
        workingJourneyId: values.workingJourneyId || null,
        active: true,

        activitiesTypesPermissions:
          values.activitiesTypesPermissions?.map((activity) => ({
            activityTypeId: activity.id,
            canView: activity.canView || false,
            canInsert: activity.canInsert || false,
            fastAction: activity.fastAction || false,
            order: activity.order || 0,
          })) || [],

        actionsPermissions: values?.permissions,

        visualizationsPermissions: values?.views,

        weekDays: values.weekDays || [],

        timeIntervals:
          values.timeIntervals?.map((interval) => ({
            id: interval.id,
            start: interval.start?.format('HH:mm:ss'),
            end: interval.end?.format('HH:mm:ss'),
          })) || [],
      };

      if (profileId) {
        dispatch(updateProfile(profileDto));
      } else {
        dispatch(createProfile(profileDto));
      }
    } catch (error) {
      Notification.error('Por favor, verifique os campos e tente novamente.');
    }
  };

  const handleChangeTab = (newTab) => {
    setCurrentTab(newTab);
    Session.set('PERFIL_USUARIOS_TAB', newTab);
  };

  const handleReset = () => {
    dispatch(resetProfileRegisterData());
    dispatch(resetProfileHierarchy());
    dispatch(resetCreateOrUpdateProfile());
  };

  useEffect(() => {
    function transformPermissions(data, parentId = null) {
      return data
        .map((item) => {
          const permissionId = item.key;
          const transformed = {
            permissionId,
            parentPermissionId: parentId,
            value: item.valueProfile,
            order: item.orderProfile,
          };

          // If there are children, transform them as well
          const children = item.children
            ? transformPermissions(item.children, permissionId)
            : [];

          return [transformed, ...children];
        })
        .flat();
    }

    if (profileData && !hasInitialized) {
      const data = profileData;

      if (data && Object.keys(data).length > 0) {
        const profile = data.profile || {};

        const formValues = {
          // CadastroTab data
          parentProfileId: profile.parentProfileId || undefined,
          description: profile.description,
          color: profile.color,
          guest: Boolean(profile.guest),
          salesman: Boolean(profile.salesman),
          independent: Boolean(profile.independent),
          usesWebView: Boolean(profile.usesWebView),
          workingJourneyId: profile.workingJourneyId || undefined,
          activitiesTypesPermissions: data.activitiesTypes || [],
          homeDashboardId: profile.homeDashboardId,
          companyDashboardId: profile.companyDashboardId,
          active: profile.active,

          // PermissoesTab data
          permissions: Array.isArray(data.actionPermissions)
            ? transformPermissions(data.actionPermissions)
            : [],

          // VisualizacoesTab data
          views: Array.isArray(data.visualizationPermissions)
            ? transformPermissions(data.visualizationPermissions)
            : [],

          // RestricoesTab data
          weekDays: Array.isArray(profile.weekDays)
            ? profile.weekDays
            : [
              { weekDay: 'SUNDAY', value: false },
              { weekDay: 'MONDAY', value: false },
              { weekDay: 'TUESDAY', value: false },
              { weekDay: 'WEDNESDAY', value: false },
              { weekDay: 'THURSDAY', value: false },
              { weekDay: 'FRIDAY', value: false },
              { weekDay: 'SATURDAY', value: false },
            ],

          timeIntervals: Array.isArray(profile.timeIntervals)
            ? profile.timeIntervals
              .filter((interval) => interval)
              .map((interval) => ({
                id: interval.id,
                start: interval.start
                  ? moment(interval.start, 'HH:mm:ss')
                  : null,
                end: interval.end ? moment(interval.end, 'HH:mm:ss') : null,
              }))
            : [],
        };

        const currentValues = form.getFieldsValue();
        const hasChanges = Object.keys(formValues).some(
          (key) =>
            JSON.stringify(formValues[key]) !==
            JSON.stringify(currentValues[key])
        );
        if (hasChanges) {
          setWeekDaysIds(profile.weekDays);
          form.setFieldsValue(formValues);
          setHasInitialized(true);
        }
      } else {
        console.warn('Profile data is empty or invalid');
      }
    }
  }, [profileData]);

  useEffect(() => {
    if (successDeleteProfile) {
      // Clear all relevant state before navigation
      handleReset();
      history.push('/configuracoes/perfil_usuarios');
    }

    // Cleanup on component unmount
    return () => {
      handleReset();
    };
  }, [successDeleteProfile, dispatch, history]);

  useEffect(() => {
    if (successCreateOrUpdateProfile) {
      history.push('/configuracoes/perfil_usuarios');
      handleReset();
    }
    return () => {
      handleReset();
    };
  }, [successCreateOrUpdateProfile]);

  useEffect(() => {
    dispatch(getProfileRegisterData(profileId));
    return () => {
      dispatch(resetProfileRegisterData());
    };
  }, [profileId, dispatch]);

  useEffect(() => {
    if (!profileId) {
      dispatch(resetProfileRegisterData());
    }
  }, []);

  const actionPermissions = useMemo(
    () => profileData?.actionPermissions || [],
    [profileData?.actionPermissions]
  );
  const visualizationPermissions = useMemo(
    () => profileData?.visualizationPermissions || [],
    [profileData?.visualizationPermissions]
  );

  const pageTitle = useMemo(() => {
    const baseTitle = 'Perfil de Usuários';
    const { description } = form.getFieldsValue();

    if (currentTab !== 'Cadastro' && description) {
      return `${baseTitle} - ${description}`;
    }
    return baseTitle;
  }, [currentTab, form]);

  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title={pageTitle}
          breadcrumb={
            <Breadcrumb
              items={[
                { path: '/', name: 'Home' },
                { path: '/configuracoes', name: 'Configurações' },
                {
                  path: '/configuracoes/perfil_usuarios',
                  name: 'Perfil de Usuários',
                },
                {
                  path: '/configuracoes/perfil_usuarios/cadastro',
                  name: 'Cadastro',
                  current: true,
                },
              ]}
            />
          }
          extra={[
            profileId && (
              <Button
                key="delete-button"
                type="danger"
                size="small"
                context="header"
                onClick={handleRemoveModal}
                loading={loadingDeleteProfile}
              >
                Excluir
              </Button>
            ),
            <Button
              key="cancel-button"
              type="secondary"
              size="small"
              context="header"
              onClick={() => history.goBack()}
            >
              Cancelar
            </Button>,
            <Button
              key="save-button"
              type="primary"
              size="small"
              context="header"
              loading={loading}
              onClick={handleSave}
            >
              Salvar
            </Button>,
          ]}
          onBack={() => history.push('/configuracoes/perfil_usuarios')}
        >
          {loadingProfileRegisterData && (
            <Column gap="12px">
              <Skeleton height="200px" />
              <Skeleton height="200px" />
              <Skeleton height="200px" />
              <Skeleton height="200px" />
              <Skeleton height="200px" />
            </Column>
          )}
          {!loadingProfileRegisterData && (
            <Column gap="12px">
              <Tabs
                current={currentTab}
                onChange={handleChangeTab}
                options={[
                  { name: 'Cadastro' },
                  { name: 'Permissões' },
                  { name: 'Visualizações' },
                  { name: 'Restrições' },
                ]}
                style={{ marginBottom: '16px' }}
              />
              <Form form={form} layout="vertical">
                <div
                  key="cadastro"
                  style={{
                    display: currentTab === 'Cadastro' ? 'block' : 'none',
                  }}
                >
                  <CadastroTab
                    form={form}
                    cadastroData={profileData?.profile}
                  />
                </div>
                <div
                  key="permissoes"
                  style={{
                    display: currentTab === 'Permissões' ? 'block' : 'none',
                  }}
                >
                  <PermissoesTab
                    form={form}
                    actionPermissions={actionPermissions}
                  />
                </div>
                <div
                  key="visualizacoes"
                  style={{
                    display: currentTab === 'Visualizações' ? 'block' : 'none',
                  }}
                >
                  <VisualizacoesTab
                    form={form}
                    visualizationPermissions={visualizationPermissions}
                  />
                </div>
                <div
                  key="restricoes"
                  style={{
                    display: currentTab === 'Restrições' ? 'block' : 'none',
                  }}
                >
                  <RestricoesTab form={form} />
                </div>
              </Form>
              {visibleRemoveModal && (
                <Modal
                  visible={visibleRemoveModal}
                  onCancel={() => setVisibleRemoveModal(false)}
                  onOk={handleDelete}
                  okText="Excluir"
                  title="Deseja excluir o perfil?"
                >
                  <Text>
                    Ao excluir o perfil, todos os usuários vinculados ao perfil
                    serão desvinculados.
                  </Text>
                </Modal>
              )}
            </Column>
          )}
        </PageHeader>
      </Body>
    </App>
  );
}
