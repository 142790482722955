import React, { useEffect, useState } from 'react';
import {
  Modal,
  Form,
  Select,
  FormItem,
  View,
  TreeSelect,
  RangePicker,
  Input,
  Checkbox,
  Switch,
  Row,
  Text,
} from '@components-teammove';
import moment from '@utils/Datas';
import { manipulationHeight } from '@utils';
import { useDispatch } from 'react-redux';
import { ContentTreeSelect, FormItemContainer } from './styles';

const Filter = ({
  visible,
  onClose,
  onSearch,
  filtros,
  filters,
  loading,
  onChangeValues,
  onChangeDependsOnFilter,
  ...props
}) => {
  const dispatch = useDispatch();

  const dependentsFields = filters?.filter((item) => 'dependsOn' in item);
  const independentFields = filters?.filter((item) => !('dependsOn' in item));

  const initialFiltros = filters.reduce(
    (state, filter) => ({ ...state, [filter.name]: undefined }),
    {}
  );

  const [filtrosState, setFiltrosState] = useState(filtros);

  const [openSelect, setOpenSelect] = useState(false);
  const [dropdownHeight, setDropdownHeight] = useState(0);

  const [form] = Form.useForm();

  useEffect(() => {
    if (visible) {
      filters.forEach((filter) => {
        if (filtros && filtros[filter.name]) {
          switch (filter.type) {
            case 'RANGE':
              filtros[filter.name] = filtros[filter.name].map((date) =>
                moment(date)
              );
              break;
            case 'TREE_SELECT':
              filtros[filter.name] = filtros[filter.name];
              break;
            case 'TEXT':
              // Add handling for TEXT type to maintain consistency
              filtros[filter.name] = filtros[filter.name];
              break;
            default:
              break;
          }
        }
      });

      form.setFieldsValue({
        ...initialFiltros,
        ...filtros,
      });
    }
  }, [visible]);

  const updateFormFields = (filtros, data) => {
    handleDescFiltros(filtros, data, true);
    form.setFieldsValue({ ...form.getFieldValue(), [filtros.name]: data });
  };

  const handleDescFiltros = (
    filter,
    data,
    changeOtherFieldsWithoutTouching
  ) => {
    let valueToSet = Array.isArray(data)
      ? filter.type === 'RANGE'
        ? data
        : filter.type === 'TREE_SELECT'
          ? data.map((item) => item.label)
          : data?.map((item) => item.title || item.label || item.children)
      : filter.type === 'TEXT'
        ? data
        : data;

    handleSetDescFiltro(filter.name, valueToSet, data);

    setFiltrosState({ ...filtrosState, [filter.name]: data });

    if (
      onChangeDependsOnFilter &&
      onChangeDependsOnFilter[filter.name] &&
      filter.isToChangeOtherTypeOptions &&
      !changeOtherFieldsWithoutTouching
    ) {
      onChangeDependsOnFilter[filter.name](
        filter.name,
        data,
        { ...filtrosState, [filter.name]: data },
        updateFormFields
      );
    }
  };

  const handleSetDescFiltro = (field, descriptionValue, value) => {
    const fieldValue = descriptionValue?.length > 0 ? descriptionValue : 0;

    form.setFieldsValue({
      descFiltros: {
        ...form.getFieldValue('descFiltros'),
        [field]: fieldValue,
      },
    });

    const childFields = dependentsFields.filter(
      (dependentField) => dependentField.dependsOn === field
    );

    console.log('childFields: ', childFields);

    if (value?.length > 0 && childFields.length > 0) {
      childFields.forEach((item) => {
        if (item.request) {
          dispatch(
            item.request(item.hasParam && value.map((obj) => obj.value))
          );
        }
      });
    }
  };

  const handleSearch = () => {
    form
      .validateFields()
      .then(() => {
        const formValues = {
          ...form.getFieldsValue(),
        };

        filters.forEach((filter) => {
          if (filter.type === 'TREE_SELECT') {
            formValues[filter.name] = formValues[filter.name]?.map(
              (item) => item.value || item
            );
          }
          // Add handling for TEXT type in form submission
          if (filter.type === 'TEXT') {
            formValues[filter.name] = formValues[filter.name] || '';
          }
        });
        onSearch(formValues);
        onClose();
      })
      .catch(Form.scrollToFirstError);
  };

  const handleCancel = () => {
    const haveFiltersChanged = Object.keys(form.getFieldsValue()).some(
      (key) =>
        key !== 'descFiltros' && form.getFieldValue(key) !== filtros?.[key]
    );

    if (haveFiltersChanged) {
      handleSearch();
    } else {
      handleClose();
    }
  };

  const handleClose = (clear) => {
    onClose(clear);
    form.resetFields();
  };

  const handleClear = () => {
    form.setFieldsValue(initialFiltros);
    form
      .validateFields()
      .then(() => {
        onSearch(initialFiltros);
        handleClose(true);
      })
      .catch(Form.scrollToFirstError);
  };

  const handleSetFiltrosManual = (filtros, data) => {
    handleDescFiltros(filtros, data);
    form.setFieldsValue({ ...form.getFieldValue(), ...data });
  };

  return (
    <Modal
      title="Filtros"
      width={666}
      visible={visible}
      onCancel={handleCancel}
      onOk={handleSearch}
      okText="Filtrar"
      cancelText="Limpar"
      cancelButtonProps={{ onClick: handleClear }}
      {...props}
    >
      <Form layout="vertical" form={form}>
        <FormItem hidden name="descFiltros">
          <Input />
        </FormItem>
        {[
          ...independentFields,
          ...dependentsFields?.filter((filter) => {
            const verifyFiltros =
              (filtrosState &&
                filtrosState[filter.dependsOn]?.find(
                  (item) => item === filter.idDepends
                )) ||
              (filtrosState &&
                filtrosState[filter.dependsOn]?.find(
                  (item) => item.value === filter.idDepends
                ));

            return filter?.idDepends && filtrosState
              ? verifyFiltros
              : filtrosState && filtrosState[filter.dependsOn]?.length > 0;
          }),
        ]
          .sort((a, b) => (a.order || 999) - (b.order || 999))
          .map((filter) => (
            <View key={filter.name}>
              {filter.type === 'SELECT' ? (
                <FormItemContainer
                  selectOpen={openSelect}
                  dropdownHeight={filters.length === 1 ? dropdownHeight : 0}
                  name={filter.name}
                  label={filter.label}
                  rules={
                    filter.required && [
                      {
                        required: true,
                        message: 'Insira um valor para esse filtro',
                      },
                    ]
                  }
                >
                  <Select
                    loading={loading}
                    mode={filter.mode || 'multiple'}
                    onChange={(value, options) =>
                      handleDescFiltros(filter, options)
                    }
                    action={filter.action || null}
                    options={filter.renderOption ? undefined : filter.options}
                    placeholder={filter.placeholder}
                    optionLabelProp={filter.optionLabelProp}
                    onDropdownVisibleChange={(open) =>
                      manipulationHeight(open, setOpenSelect, setDropdownHeight)
                    }
                    listHeight={200}
                  >
                    {filter.renderOption &&
                      filter.options.map(filter.renderOption)}
                  </Select>
                </FormItemContainer>
              ) : filter.type === 'TREE_SELECT' ? (
                <ContentTreeSelect hasSwitch={onChangeValues}>
                  <FormItem
                    marginless={filter.switch}
                    name={filter.name}
                    label={filter.label}
                    rules={
                      filter.required && [
                        {
                          required: true,
                          message: 'Insira um valor para esse filtro',
                        },
                      ]
                    }
                  >
                    <TreeSelect
                      loading={loading}
                      strategy="SHOW_CHILD"
                      labelInValue
                      treeData={filter.options}
                      treeCheckable
                      onChange={(value) => handleDescFiltros(filter, value)}
                      placeholder={filter.placeholder}
                      margin={'0 0 14px 0'}
                    />
                  </FormItem>
                  {onChangeValues &&
                    onChangeValues[filter.name].change &&
                    filter.switch && (
                      <>
                        <Row gap="12px">
                          <Switch
                            checked={onChangeValues[filter.name].value}
                            onChange={() => {
                              onChangeValues[filter.name].change();
                              handleSetFiltrosManual(filter, {
                                [filter.name]: undefined,
                              });
                            }}
                          />
                          <Text size="14px">Mostrar categorias inativas</Text>
                        </Row>
                      </>
                    )}
                </ContentTreeSelect>
              ) : filter.type === 'RANGE' ? (
                <FormItem
                  name={filter.name}
                  label={filter.label}
                  rules={
                    filter.required && [
                      {
                        required: true,
                        message: 'Insira um valor para esse filtro',
                      },
                    ]
                  }
                >
                  <RangePicker
                    format="DD/MM/YYYY"
                    onChange={(date, dateString) =>
                      handleDescFiltros(filter, dateString)
                    }
                    ranges={{
                      Hoje: [moment().startOf('day'), moment().endOf('day')],
                      'Sem. passada': [
                        moment().startOf('week').subtract(1, 'week'),
                        moment().endOf('week').subtract(1, 'week'),
                      ],
                      'Esta Semana': [
                        moment().startOf('week'),
                        moment().endOf('week'),
                      ],
                      'Mês passado': [
                        moment().startOf('month').subtract(1, 'months'),
                        moment().endOf('month').subtract(1, 'months'),
                      ],
                      'Este Mês': [
                        moment().startOf('month'),
                        moment().endOf('month'),
                      ],
                      'Este Ano': [
                        moment().startOf('year'),
                        moment().endOf('year'),
                      ],
                    }}
                  />
                </FormItem>
              ) : filter.type === 'CHECKBOX' ? (
                <Form form={form}>
                  <FormItem
                    name={filter.name}
                    label={filter.label}
                    valuePropName="checked"
                    labelSide="right"
                    colon={false}
                    rules={
                      filter.required && [
                        {
                          required: true,
                          message: 'Insira um valor para esse filtro',
                        },
                      ]
                    }
                  >
                    <Checkbox
                      onChange={(value) => handleDescFiltros(filter, value)}
                    />
                  </FormItem>
                </Form>
              ) : filter.type === 'TEXT' ? (
                <FormItem
                  name={filter.name}
                  label={filter.label}
                  rules={
                    filter.required && [
                      {
                        required: true,
                        message: 'Insira um valor para esse filtro',
                      },
                    ]
                  }
                >
                  <Input
                    placeholder={filter.placeholder}
                    onChange={(e) => handleDescFiltros(filter, e.target.value)}
                  />
                </FormItem>
              ) : (
                <></>
              )}
            </View>
          ))}
      </Form>
    </Modal>
  );
};

export default Filter;