import styled from 'styled-components';
import { View, Upload, Image, Text, List, Button, Form, Input, Icon } from '@components-teammove';

export const FlexRow = styled(View)`
  flex-direction: row;
  gap: 15px;
  align-items: center;
`;

export const FlexRowBiggerGap = styled(FlexRow)`
  gap: 30px;
`;

export const FormItemFill = styled(Form.Item)`
  flex: 1;
`;

export const SelfAlignEndFormItem = styled(Form.Item)`
  margin-left: auto;
`;

export const MarginfulButton = styled(Button)`
  margin-bottom: .75rem;
`;

export const MarginBottom = styled(View)`
  margin-bottom: 1.5rem;
`;

export const UploadFill = styled(Upload.Dragger)`
  &.ant-upload.ant-upload-drag {
    height: 159px;

    .ant-upload {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const UploadIcon = styled(Icon.Feather)`
  color: #1890FF;

  * {
    color: #1890FF;
  }
`;

export const UploadText = styled(Text)`
  &.ant-typography {
    color: #F5F5F5;
    opacity: 50%;
  }
`;

export const ImageCover = styled(Image)`
  object-fit: contain;
  max-height: 150px;
  cursor: pointer;
`;

export const IconRemoveCover = styled(Icon.CloseOutlined)`
  position: absolute;
  right: 3px;
  top: 3px;
  cursor: pointer;
`;

export const PrizesView = styled(MarginBottom)`
  width: 50%;
`;

export const SpaceBetweenRow = styled(FlexRow)`
  justify-content: space-between;
`;

export const ContentSizeButton = styled(Button)`
  width: fit-content;
`;

export const StyledList = styled(List)`
  margin-bottom: 1.5rem;
  cursor: pointer;
`;

export const FixedTextArea = styled(Input.TextArea)`
  &.ant-input {
    height: 8.875rem;
  }
`;

export const TrophyIcon = styled(Icon.TrophyOutlined)`
  height: 16px;
`;

export const ExpiredPositivationCampaignContainer = styled.div`
  background-color: ${({ theme }) => theme.darkSecondaryColor};
  padding: .25rem .5rem;
  border-radius: 8px;
`;