import { useCallback } from 'react';

export const useTreeOperations = () => {
  const findParent = useCallback((key, data) => {
    for (const item of data) {
      if (item.children?.some((child) => child.key === key)) {
        return item;
      }
      if (item.children) {
        const nestedParent = findParent(key, item.children);
        if (nestedParent) return nestedParent;
      }
    }
    return null;
  }, []);

  const traverseTree = useCallback((data, key, callback) => {
    return data.map((item) => {
      if (item.key === key) {
        return callback(item);
      }
      if (item.children) {
        return {
          ...item,
          children: traverseTree(item.children, key, callback),
        };
      }
      return item;
    });
  }, []);

  const isDescendant = useCallback((parent, child) => {
    if (!parent.children) return false;
    return parent.children.some((node) => 
      node.key === child.key || isDescendant(node, child)
    );
  }, []);

  return {
    findParent,
    traverseTree,
    isDescendant,
  };
};