import { del, get, post, put } from '../api';
import { dateToAnsi, DATE_FORMATS, InfoUsuario, getQueryString } from '@utils';

export const getCampaigns = (idApi = '-1'): Promise<any[]> => {
  return get(`/api/campaigns/v2?userCustomId=${idApi}&visualizeValues=${InfoUsuario.perm('campanhasVendasVisualizarValores') || false}&visualizeAll=${InfoUsuario.perm('campanhasVendasVisualizarTudo') || false}&userId=${InfoUsuario.get('cdUsuario')}`);
};

export const getCampaignsExpired = (idApi = '-1', filters: any): Promise<any> => {
  return get(`/api/campaigns/v2/expired?&userCustomId=${idApi}&visualizeValues=${InfoUsuario.perm('campanhasVendasVisualizarValores') || false}&visualizeAll=${InfoUsuario.perm('campanhasVendasVisualizarTudo') || false}&startAt=${dateToAnsi(filters.startAt, DATE_FORMATS.DATE, DATE_FORMATS.ANSI_DATE)}&limitAt=${dateToAnsi(filters.limitAt, DATE_FORMATS.DATE, DATE_FORMATS.ANSI_DATE)}&userId=${InfoUsuario.get('cdUsuario')}`);
};

export const getCampaign = (externalId: string, userId = '-1', idApi = '-1'): Promise<any> => {
  return get(`/api/campaigns/rankings/v2/${externalId}?&userId=${userId}&userCustomId=${idApi}&visualizeValues=${InfoUsuario.perm('campanhasVendasVisualizarValores') || false}`);
};

export const getCampaignsUsers = (): Promise<any> => {
  return get('/api/campaigns/users');
};

export const getAllCampaignsRegisters = ({ search, limit }: { search: string; limit: number }): Promise<any[]> => {
  const queryString = getQueryString({ search, limit: limit || 50 });
  return get(`/api/campaigns/register${queryString ? `?${queryString}` : ''}`);
};

export const getCampaignRegister = (externalId: string): Promise<any> => {
  return get(`/api/campaigns/register/${externalId}`);
};

export const postCampaign = (campaign: any): Promise<any> => {
  return post('/api/campaigns/register', campaign);
};

export const putCampaign = (campaign: any): Promise<any> => {
  return put('/api/campaigns/register', campaign);
};

export const deleteCampaign = (id: any): Promise<any> => {
  return del(`/api/campaigns/register/${id}`);
};

export const updateResultsCampaignsByExcel = (results: any): Promise<any> => {
  return post('/api/campaigns/import/excel', results);
};

export const notifyResultsCampaigns = (): Promise<any> => {
  return post('/api/campaigns/notifications/notify');
};

type GetSellingCampaignsParams = {
  onlyActive?: boolean;
  type?: ('POS' | 'SALE')[];
  orderByField?: string[];
  orderByDirection?: ('asc' | 'desc')[];
}
type GetSellingCampaignsResponse = Promise<{
  id: number;
  name: string;
  startAt: Date;
  endAt: Date;
}[]>;
export const getSellingCampaigns = (params: GetSellingCampaignsParams): GetSellingCampaignsResponse => {
  return get(`/api/selling-campaign?${getQueryString(params)}`, {}, true);
};