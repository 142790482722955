import React, { useContext, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Column, Row, Tooltip, Skeleton } from '@components-teammove';
import { 
  CloseIcon,
  ContentDataGroup, 
  DataIcons, 
  Divider, 
  FeatherIcon, 
  MenuClosed, 
  MenuOpen, 
  RowContainer, 
  StyleButton, 
  TagContainer, 
  TextStyle,  
} from './styles';

import { formatDate, InfoUsuario, TelasNomenclaturas, useSearchParams } from '@utils';
import { changeCategoria, changeResponsavel, putCompany } from '@ducks/chamadosHistorico';

import { setVisibleTicketMenu } from '@ducks/chamadosKanban';

import Actions from './Actions';
import { STATUS } from '../../rules';
import ModalResponsavel from '../ModalResponsavel';
import ModalCategoria from '../ModalCategoria';
import Steps from './Steps';
import Classificacoes from './Classificacoes';
import HistoricModal from './HistoricModal';
import ModalCompany from '../ModalCompany';
import { ThemeContext } from 'styled-components';
import ModalQuestionarioReply from '../../Questionario/Reply';

export default function MenuItem({
  data,
  collapsed,
  setCollapsed,
  stickyHeader,
  visible,
  onCancel,
  possibleAssignees,
  handleOpenChat,
  ...props
}) {
  const { id: paramTicketId } = useParams();
  const { id: queryParamTicketId } = useSearchParams();
  const id = paramTicketId || queryParamTicketId;
  const dispatch = useDispatch();

  const usuario = InfoUsuario.get();
  const theme = useContext(ThemeContext);

  const permiteAtendimento = InfoUsuario.perm('chamadosAtendimento', usuario);
  const permiteAcoesRapidas = InfoUsuario.perm('chamadosAcoesRapidas', usuario);
  const permiteExibirDetalhesUnidade = InfoUsuario.perm('exibeDetalhesUnidade', usuario);
  
  const ticket = useSelector(({ chamadosHistorico }) => chamadosHistorico.get('chamado'));

  const loading = useSelector(({ chamadosHistorico }) => chamadosHistorico.get('loading'));
  const loadingCategoria = useSelector(({ chamadosHistorico }) => chamadosHistorico.get('loadingCategoria'));
  const successCategoria = useSelector(({ chamadosHistorico }) => chamadosHistorico.get('successCategoria'));
  const loadingResponsavel = useSelector(({ chamadosHistorico }) => chamadosHistorico.get('loadingResponsavel'));
  const successResponsavel = useSelector(({ chamadosHistorico }) => chamadosHistorico.get('successResponsavel'));
  const loadingPutCompany = useSelector(({ chamadosHistorico }) => chamadosHistorico.get('loadingPutCompany'));
  const successPutCompany = useSelector(({ chamadosHistorico }) => chamadosHistorico.get('successPutCompany'));

  const successFinishBriefingReply = useSelector(({ chamadosBriefingReply }) => chamadosBriefingReply.get('successFinishBriefingReply'));
  
  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  const isAberto = data?.status === STATUS.ABERTO;
  const isEmAtendimento = data?.status === STATUS.EM_ATENDIMENTO;
  const isReaberto = data?.status === STATUS.REABERTO;
  const isCancelado = data?.status === STATUS.CANCELADO;

  const [visibleChangeResponsavel, setVisibleChangeResponsavel] = useState(false);
  const [visibleChangeCategoria, setVisibleChangeCategoria] = useState(false);
  const [visibleHistoricModal, setVisibleHistoricModal] = useState(false);
  const [visibleChangeCompany, setVisibleChangeCompany] = useState(false);
  const [visibleQuestionarioReply, setVisibleQuestionarioReply] = useState(false);

  const handleChangeResponsavel = (novoResponsavel) => {
    dispatch(changeResponsavel(id, novoResponsavel));
  };

  const handleChangeCategoria = (categoria) => {
    dispatch(changeCategoria(id, categoria));
  };

  const handleChangeCompany = (company)=> {
    dispatch(putCompany(id, company));
  };

  useEffect(() => {
    if (successCategoria) {
      setVisibleChangeCategoria(false);

      if (ticket.status === 'AGUARDANDO_BRIEFING') {
        setVisibleQuestionarioReply(true);
      }
    }
  }, [successCategoria]);

  useEffect(() => {
    if(!successFinishBriefingReply && ticket?.status === 'AGUARDANDO_BRIEFING') return;
    setVisibleQuestionarioReply(false);
  }, [successFinishBriefingReply]);

  useEffect(() => {
    if (successResponsavel) {
      setVisibleChangeResponsavel(false);
    }
  }, [successResponsavel]);

  useEffect(() => {
    if(successPutCompany) {
      setVisibleChangeCompany(false);
    }
  }, [successPutCompany]);

  return (
    <>
      {collapsed ? (
        <MenuClosed>
          <Tooltip title='Expandir menu Lateral'>
            <StyleButton
              height
              type='tertiary'
              size='smaller'
              icon={<FeatherIcon type='chevrons-left' />}
              onClick={() => setCollapsed(false)}
            />
          </Tooltip>
          <Divider />
          <Tooltip title='Dados do ticket'>
            <StyleButton
              height
              type='tertiary'
              size='smaller'
              icon={<FeatherIcon type='list' />}
              onClick={() => setCollapsed(false)}
            />
          </Tooltip>
          <Tooltip title='Ações'>
            <StyleButton
              height
              type='tertiary'
              size='smaller'
              icon={<FeatherIcon type='file' />}
              onClick={() => setCollapsed(false)}
            />
          </Tooltip>
          <Tooltip title='Etapas'>
            <StyleButton
              height
              type='tertiary'
              size='smaller'
              icon={<FeatherIcon type='flag' />}
              onClick={() => setCollapsed(false)}
            />
          </Tooltip>
          <Tooltip title='Classificações'>
            <StyleButton
              height
              type='tertiary'
              size='smaller'
              icon={<FeatherIcon type='tag' />}
              onClick={() => setCollapsed(false)}
            />
          </Tooltip>
        </MenuClosed>
      ) : (
        <MenuOpen fromKanban={data?.fromKanban}>
          {data?.fromKanban && (
            <CloseIcon type='X' onClick={() => dispatch(setVisibleTicketMenu(false))}/>
          )}
          <Row padding='0px 0px 0px 20px' align='center' gap='4px'>
            <StyleButton
              width='259px'
              type={data?.fromKanban ? 'primary' : 'tertiary' }
              size='small'
              onClick={() => data?.fromKanban ? handleOpenChat() : setCollapsed(true)}
            >
              {data?.fromKanban ? 'Abrir chat' : <FeatherIcon type='chevrons-right' />}
            </StyleButton>
          </Row>
          <Divider />
          <ContentDataGroup padding='0 0 0 20px'>
            <Row
              align='center'
              gap='8px'
            >
              <FeatherIcon type='list' />
              <TextStyle
                font='16px'
                weight='700'
              >
                Dados do ticket
              </TextStyle>
              <FeatherIcon         
                $marginLeft= 'auto'
                cursor='pointer'
                onClick={() => setVisibleHistoricModal(true)}
                type='clock'
              />
            </Row>

            <Row
              align='flex-start'
              justify='space-between'
            >
              <Column>
                <TextStyle
                  font='12px'
                  weight='700'
                  $lowOpacity
                >
                  Responsável:
                </TextStyle>
                {loading ? (
                  <Skeleton.MinorLine />
                ) : (
                  <div>
                    <TextStyle
                      font='14px'
                      weight='400'
                    >
                      {data?.nmUsuarioAtendimento}
                    </TextStyle>
                    {permiteAcoesRapidas &&
                      permiteAtendimento &&
                      (isAberto || isEmAtendimento || isReaberto) && (
                      <DataIcons
                        onClick={() => setVisibleChangeResponsavel(true)}
                        $opacity
                        size='20'
                        type='edit'
                      />
                    )}
                  </div>
                )}
              </Column>
              <Column>
                <TextStyle
                  font='12px'
                  weight='700'
                  $lowOpacity
                >
                  Prazo:
                </TextStyle>
                {loading ? (
                  <Skeleton.MinorLine />
                ) : (
                  <TextStyle
                    $lineHeight={
                      permiteAcoesRapidas &&
                      permiteAtendimento &&
                      (isAberto || isEmAtendimento || isReaberto) &&
                      '32px'
                    }
                    font='14px'
                    weight='400'
                  >
                    {formatDate(data?.prazo)}
                  </TextStyle>
                )}
              </Column>
            </Row>

            <Column>
              <TextStyle
                font='12px'
                weight='700'
                $lowOpacity
              >
                {companyNomenclature.nomenclatura}:
              </TextStyle>
              {loading ? (
                <Skeleton.MinorLine />
              ) : (
                <Row justify='space-between'>
                  <RowContainer align='center'>
                    <TextStyle
                      font='14px'
                      weight='400'
                      
                    >
                      {data?.nmUnidade} {permiteExibirDetalhesUnidade &&  `- ${data?.nmUnidadeCompleto}`}
                    </TextStyle>
                    {InfoUsuario.perm('chamadosAdministrador') && 
                    (!isCancelado && (
                      <DataIcons
                        $opacity
                        size='20'
                        type='edit'
                        onClick={() => setVisibleChangeCompany(true)}
                      />
                    ))
                    }
                  </RowContainer>
                  {permiteExibirDetalhesUnidade && (
                    <Link to={`/unidades/${data?.idUnidade}`}>
                      <TagContainer $bgColor={theme.actionColor}>
                      + Info
                      </TagContainer>
                    </Link>
                  )}
                </Row>
              )}
            </Column>
            <Column>
              <TextStyle
                font='12px'
                weight='700'
                $lowOpacity
              >
                Categoria:
              </TextStyle>
              {loading ? (
                <Skeleton.MinorLine />
              ) : (
                <div>
                  <TextStyle
                    font='14px'
                    weight='400'
                  >
                    {data?.nmCategoriaCompleto}
                  </TextStyle>
                  {permiteAcoesRapidas &&
                    permiteAtendimento &&
                    (isAberto || isEmAtendimento || isReaberto) && (
                    <DataIcons
                      $opacity
                      size='20'
                      type='edit'
                      onClick={() => setVisibleChangeCategoria(true)}
                    />
                  )}
                </div>
              )}
            </Column>

            <Actions />
          </ContentDataGroup>

          <ContentDataGroup>
            <Steps mainTicket={data} />

            <ModalCompany
              visible={visibleChangeCompany}
              loading={loadingPutCompany}
              onCancel={() => setVisibleChangeCompany(false)}
              onChangeCompany={handleChangeCompany}
              currentCompany={data?.nmUnidadeCompleto}
              idTicket={data?.id}
            />
            
            <ModalCategoria
              visible={visibleChangeCategoria}
              loading={loadingCategoria}
              onCancel={() => setVisibleChangeCategoria(false)}
              onChangeCategoria={handleChangeCategoria}
              categoriaAtual={data?.nmCategoriaCompleto}
              idsCategoria={data?.idsCategoria}
            />

            <ModalResponsavel
              possibleAssignees={possibleAssignees}
              chamado={data}
              visible={visibleChangeResponsavel}
              loading={loadingResponsavel}
              onCancel={() => setVisibleChangeResponsavel(false)}
              onChangeResponsavel={handleChangeResponsavel}
            />
          </ContentDataGroup>

          {!data?.fromKanban && (
            <ContentDataGroup>
              <Classificacoes ticketId={id} statusCancelado={isCancelado}/>
            </ContentDataGroup>
          )}
          
          {visibleHistoricModal && (
            <HistoricModal
              data={props.historicos}
              visible={visibleHistoricModal}
              onCancel={() => setVisibleHistoricModal(false)}
            />
          )}

          {visibleQuestionarioReply && (
            <ModalQuestionarioReply
              visible={visibleQuestionarioReply}
              onCancel={() => {
                setVisibleQuestionarioReply(false);
              }}
              tipo={'BRIEFING'}
              chamado={ticket}
            />
          )}
        </MenuOpen>
      )}
    </>
  );
}
