import styled from 'styled-components';
import { Column, Text, Form, Icon, Row } from '@components-teammove';

export const TitleText = styled(Text)`
    font-size: 16px;
    font-weight: 600;
    color: ${({ theme }) => theme.textColor};
`;

export const CardItem = styled(Column)`
    border: 2px ${({ theme }) => theme.darkHighlightColor} solid;
    border-radius: 5px;
    padding: 20px;
    gap: 20px;
`;

export const FormStyled = styled(Form.Item)`
    grid-column: ${({ gridColumn }) => gridColumn};
    padding-left: ${({ hasPadding }) => hasPadding ? '20px' : '0'};
`;

export const IconProfileContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: ${({ cor }) => cor};
    padding: 5px;
    border-radius: 50%;
`;

export const FormProfileStyled = styled(Form.Item)`
    flex: 0 !important;
`;

export const MinimizeIcon = styled(Icon.Feather)`
    cursor: pointer;
    align-self: flex-end; 
    display: flex;
`;

export const RowProfile = styled(Row)`
    opacity: ${({ disabled }) => disabled ? 0.6 : 1};
`;