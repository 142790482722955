/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Modal, Checkbox, Grid, Skeleton, Column, Select, Row, Text, Icon } from '@components-teammove';
import { getStrategicActionTemplate, putStrategicActionTemplate, resetStrategicAction } from '@ducks/checklistTemplates';
import { Notification } from '@utils';
import { CardItem, FormProfileStyled, FormStyled, IconProfileContainer, MinimizeIcon, RowProfile, TitleText } from './styles';

const ConfiguracoesAcoesEstrategicas = ({ visible, onCancel, idChecklistTemplate }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [closeProfles, setCloseProfles] = useState(false);	
  const [closeCheckGrids, setCloseCheckGrids] = useState(false);
  const [enabledProfiles, setEnabledProfiles] = useState(false);
  const [acaoEstrategicaPadraoOptions, setAcaoEstrategicaPadraoOptions] = useState([]);

  const strategicActionTemplate = useSelector(({ checklistTemplates }) => checklistTemplates.get('strategicActionTemplate'));
  const loadingStrategicActionTemplate = useSelector(({ checklistTemplates }) => checklistTemplates.get('loadingStrategicActionTemplate'));
  const successStrategicActionTemplate = useSelector(({ checklistTemplates }) => checklistTemplates.get('successStrategicActionTemplate'));

  const successSaveStrategicActionTemplate = useSelector(({ checklistTemplates }) => checklistTemplates.get('successSaveStrategicActionTemplate'));
  const loadingSaveStrategicActionTemplate = useSelector(({ checklistTemplates }) => checklistTemplates.get('loadingSaveStrategicActionTemplate'));

  useEffect(() => {
    if(!idChecklistTemplate) return;
    dispatch(getStrategicActionTemplate(idChecklistTemplate));
    return () => {
      dispatch(resetStrategicAction());
    };
  }, []);

  useEffect(() => {
    if (!successStrategicActionTemplate) return;
  
    const perfilValues = strategicActionTemplate.perfis.reduce((acc, perfil) => {
      acc[`perfil_${perfil.id}`] = perfil.utiliza || false;
      return acc;
    }, {});

    const formValues = {
      ...strategicActionTemplate,
      ...perfilValues,
      acao_estrategica_padrao: strategicActionTemplate?.tarefa_tipo_selecionada?.id,
    };
    setEnabledProfiles(strategicActionTemplate.utiliza_usuario_cadastrado);
    setAcaoEstrategicaPadraoOptions(strategicActionTemplate.tarefa_tipo.map(({ id, nome }) => ({ label: nome, value: id })));
  
    form.setFieldsValue(formValues);
  }, [successStrategicActionTemplate]);

  useEffect(() => {
    if (!successSaveStrategicActionTemplate) return;
    Notification.success('Plano de ação salvo com sucesso');
    onCancel();
  }, [successSaveStrategicActionTemplate]);

  const onOk = () => {
    form.validateFields().then((values) => {
      const utilizaUsuarioCadastrado = values.utiliza_usuario_cadastrado;
    
      const perfisSelecionados = strategicActionTemplate.perfis.some(
        (perfil) => values[`perfil_${perfil.id}`]
      );
  
      if (utilizaUsuarioCadastrado && !perfisSelecionados) {
        Notification.warning('Você deve selecionar pelo menos um perfil.');
        return;
      }

      const perfis = strategicActionTemplate.perfis.map((perfil) => {
        perfil.utiliza = values[`perfil_${perfil.id}`];
        return {
          ...perfil,
          id_checklist_template: Number(idChecklistTemplate),
        };
      });

      const filteredValues = Object.keys(values).reduce((acc, key) => {
        if (!key.startsWith('perfil_')) {
          acc[key] = values[key] ? 'S' : 'N';
        }
        return acc;
      }, {});
      
      dispatch(putStrategicActionTemplate({
        ...filteredValues,
        id_checklist_template: parseInt(idChecklistTemplate, 10),
        id_checklist_template_plano_acao: strategicActionTemplate.id,
        perfis,
      }));
    });
  };

  return (
    <Modal
      title="Plano de ação"
      visible={visible}
      onCancel={onCancel}
      onOk={onOk}
      okButtonProps={{ loading: loadingSaveStrategicActionTemplate }}
    >
      {loadingStrategicActionTemplate ? (
        <Skeleton.Form/>
      ) : (
        <>
          <Form form={form} layout='vertical'>
            <Form.Item label='Ação estratégica padrão' name='acao_estrategica_padrao' placeholder='Selecione'>
              <Select placeholder='Ação estratégica padrão' options={acaoEstrategicaPadraoOptions}/>
            </Form.Item>
          </Form>
          <Form form={form}>
            <Column gap='20px'>
              <CardItem>
                <Row justify='space-between' align='center'>
                  <TitleText>Utiliza</TitleText>
                  <MinimizeIcon onClick={() => setCloseCheckGrids(!closeCheckGrids)} type={closeCheckGrids ? 'plus' : 'minus'}/>
                </Row>
                {!closeCheckGrids && (
                  <Grid templateColumns='1fr 2fr 1fr' gap='0 20px' width='100%'>
                    <FormStyled labelSide='right' colon={false} gridColumn='span 2' name="utiliza_objetivo" valuePropName="checked" label="Objetivo">
                      <Checkbox/>
                    </FormStyled>
                    <FormStyled labelSide='right' colon={false} gridColumn='span 1' name="utiliza_responsavel" valuePropName="checked" label="Responsável">
                      <Checkbox/>
                    </FormStyled>
                    <FormStyled labelSide='right' colon={false} gridColumn='span 2' name="utiliza_data" valuePropName="checked" label="Quando">
                      <Checkbox/>
                    </FormStyled>
                    <FormStyled labelSide='right' colon={false} gridColumn='span 1' name="utiliza_observacao" valuePropName="checked" label="Como">
                      <Checkbox/>
                    </FormStyled>
                  </Grid>
                )}
              </CardItem>
              <FormStyled hasPadding marginless labelSide='right' colon={false} name="utiliza_usuario_cadastrado" valuePropName="checked" label="Utiliza responsável cadastrado">
                <Checkbox onChange={(value) => setEnabledProfiles(value)}/>
              </FormStyled>
              <CardItem>
                <MinimizeIcon onClick={() => setCloseProfles(!closeProfles)} type={closeProfles ? 'plus' : 'minus'}/>
                {!closeProfles && (
                  <Grid numberOfColumns={3} gap='20px' width='100%'>
                    {strategicActionTemplate.perfis && strategicActionTemplate.perfis.map((perfil) => (
                      <RowProfile disabled={!enabledProfiles} key={perfil.id} gap='4px' align='center'>
                        <FormProfileStyled marginless name={`perfil_${perfil.id}`} valuePropName="checked">
                          <Checkbox disabled={!enabledProfiles}/>
                        </FormProfileStyled>
                        <IconProfileContainer cor={perfil.perfil.cor}>
                          <Icon.Feather type='briefcase' size={20}/>
                        </IconProfileContainer>
                        <Text>{perfil.perfil.descricao}</Text>
                      </RowProfile>
                    ))}
                  </Grid>
                )}
              </CardItem>
            </Column>
          </Form>
        </>
      )}
    </Modal>
  );
};

export default ConfiguracoesAcoesEstrategicas;