import styled from 'styled-components';
import { Tree } from 'antd';

export const StyledTree = styled(Tree)`
  .ant-tree-node-content-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  
  &.ant-tree {
    background-color: transparent;

    .ant-tree-treenode {
      align-items: center;
      gap: 12px;
      padding-bottom: 9px;
      display: flex;

      .ant-tree-node-content-wrapper {
        gap: 10px;
        padding: 0;
        :hover {
          background-color: transparent;
        }
      }
    }

    .ant-tree-checkbox {
      width: 24px;
      height: 24px;
      border-radius: 8px;
      margin: 0;
      align-self: center;
      
      .ant-tree-checkbox-inner {
        width: 24px;
        height: 24px;
        border-radius: 8px;
        background-color: ${({ theme }) => theme.darkPrimaryColor} !important;
        border-color: ${({ theme }) => theme.darkHighlightColor} !important;

        &:after {
          width: 8px;
          height: 12px;
          top: 45%;
        }
      }

      &:hover .ant-tree-checkbox-inner {
        border-color: ${({ theme }) => theme.actionColor} !important;
      }

      &.ant-tree-checkbox-checked {
        .ant-tree-checkbox-inner {
          background-color: ${({ theme }) => theme.actionColor} !important;
          border-color: ${({ theme }) => theme.actionColor} !important;
        }
      }
    }

    .ant-tree-title {
      color: ${({ theme }) => theme.textColor};
      font-family: 'Open Sans';
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0%;
      text-align: left;
      line-height: 24px;
      display: flex;
      align-items: center;
    }

    .ant-tree-switcher, .ant-tree-iconEle {
      color: ${({ theme }) => theme.textColor};

      &.ant-tree-switcher_close {
        transform: rotate(-90deg);
      }
    }
  }
`;