import React, { useState } from 'react';
import RegraFolga from './RegraFolga';
import TiposUnidades from './TiposUnidades';
import Agrupadores from './Agrupadores';
import Clusters from './Clusters';
import CamposPersonalizados from './CamposPersonalizados';
import IdApiDuplicados from './IdApiDuplicados';
import App from '@app';
import { Body, PageHeader, Column, Tabs, Breadcrumb, Button } from '@components-teammove';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Session, TelasNomenclaturas } from '@utils';
import { CompanyImport } from './CompanyImport';
import { Contacts } from './Contacts';

export default function ConfiguracoesUnidades() {
  const theme = useSelector(({ app }) => app.get('theme'));
  const history = useHistory();
  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  const [currentTab, setCurrentTab] = useState(Session.get('CONFIG_COMPANIES_TAB') || `Tipos de ${(companyNomenclature.nomenclatura)}`);
  const [visibleRegisterTipoUnidade, setVisibleRegisterTipoUnidade] = useState(false);
  const [visibleRegisterAgrupador, setVisibleRegisterAgrupador] = useState(false);
  const [visibleRegisterCluster, setVisibleRegisterCluster] = useState(false);

  const ExtraTipo = () => [
    <Button key="1" type="primary" size='small' context='header' onClick={() => setVisibleRegisterTipoUnidade(true)}>Novo</Button>,
  ];

  const ExtraAgrupador = () => [
    <Button key="1" type="primary" size='small' context='header' onClick={() => setVisibleRegisterAgrupador(true)}>Novo</Button>,
  ];

  const ExtraCluster = () => [
    <Button key="1" type="primary" size='small' context='header' onClick={() => setVisibleRegisterCluster(true)}>Novo</Button>,
  ];

  const ExtraCampoPersonalizado = () => [
    <Button key="1" type="primary" size='small' context='header' onClick={() => history.push('/configuracoes/unidades/campos_personalizados')}>Editar</Button>
  ];

  const handleChangeTab = (newTab) => {
    setCurrentTab(newTab);
    Session.set('CONFIG_COMPANIES_TAB', newTab);
  };

  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title={companyNomenclature.plural}
          extra={currentTab === 'Regra de Folga' ? null : currentTab === `Tipos de ${companyNomenclature.nomenclatura}` ? <ExtraTipo/> : currentTab === 'Agrupadores' ? <ExtraAgrupador/> : currentTab === 'Clusters' ? <ExtraCluster/> : currentTab === 'Campos Personalizados' && <ExtraCampoPersonalizado/> }
          breadcrumb={(
            <Breadcrumb
              items={[
                { path: '/', name: 'Home' },
                { path: '/configuracoes', name: 'Configurações' },
                { path: 'configuracoes/unidades', name: `Configurações de ${companyNomenclature.plural}`, current: true }
              ]}
            />
          )}
          onBack={() => window.history.back()}
        >
          <Column gap='12px'>
            <Tabs 
              options={[
                { name: `Tipos de ${companyNomenclature.nomenclatura}` }, 
                { name: 'Agrupadores' }, 
                { name: 'Clusters' }, 
                { name: 'Campos Personalizados' }, 
                { name: 'Id api duplicado' }, 
                { name: 'Regra de Folga' }, 
                { name: 'Importação' }, 
                { name: 'Contatos' },
              ]} 
              current={currentTab} 
              onChange={handleChangeTab}
            />
            {currentTab === 'Regra de Folga' ? (
              <RegraFolga/>
            ) : currentTab === `Tipos de ${companyNomenclature.nomenclatura}` ? (
              <TiposUnidades visibleRegister={visibleRegisterTipoUnidade} setVisibleRegister={setVisibleRegisterTipoUnidade}/>
            ) : currentTab === 'Agrupadores' ? (
              <Agrupadores visibleRegister={visibleRegisterAgrupador} setVisibleRegister={setVisibleRegisterAgrupador}/>
            ) : currentTab === 'Clusters' ? (
              <Clusters visibleRegister={visibleRegisterCluster} setVisibleRegister={setVisibleRegisterCluster}/>
            ) : currentTab === 'Campos Personalizados' ? (
              <CamposPersonalizados/>
            ) : currentTab === 'Id api duplicado' ? (
              <IdApiDuplicados />
            ) : currentTab === 'Importação' ? (
              <CompanyImport />
            ) : <Contacts />
            }
          </Column>
        </PageHeader>
      </Body>
    </App>
  );
}
