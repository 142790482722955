import styled from 'styled-components';

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  padding: 16px;
  gap: 4px;
  border: 2px solid ${({ theme, $isMenuVisible }) => theme[$isMenuVisible ? 'actionColor' : 'darkHighlightColor']};
  background-color: ${({ theme }) => theme.darkPrimaryColor};
  width: 100%;
  min-width: 350px;
  cursor: pointer;
`;
