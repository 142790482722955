import React, { useEffect, useState } from 'react';
import { ContainerInput } from './styles';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import AllCountries from 'react-intl-tel-input/dist/components/AllCountries';

const InternationalPhone = ({ value, onChange, codCountry, viewMode = false, fromCompanies = false }) => {
  const [selectedCountry, setSelectedCountry] = useState('br');
  const [pasteNumber, setPasteNumber] = useState(false);
  const [isFromCompanies, setIsFromCompanies] = useState(fromCompanies);

  useEffect(() => {
    if (viewMode && codCountry) {
      const allCountriesList = AllCountries.getCountries();
      const country = allCountriesList.find((country) => country.dialCode === codCountry);
      if (country) {
        setSelectedCountry(country.iso2);
      }
    }
  }, [codCountry, viewMode, selectedCountry]);

  const handlePaste = (value) => {
    const matchCountryCode = value.match(/^\D*(\d+)/);
    let country = '55';

    if (matchCountryCode && value.startsWith('+')) {
      const allCountriesList = AllCountries.getCountries();
      const countryCode = matchCountryCode[1];
      const matchedCountry = allCountriesList.find((country) => country.dialCode === countryCode);
    
      if (matchedCountry) {
        country = matchedCountry.iso2;
        setSelectedCountry(country); 
      }
    } 
    setPasteNumber(true);
    onChange(value, country);
  };

  const cleanNumber = (number) => number.replace(/\D/g, '');

  const getExpectedLength = (countryIso2) => {
    if (window.intlTelInputUtils) {
      const exampleNumber = window.intlTelInputUtils.getExampleNumber(
        countryIso2, 
        true, 
        window.intlTelInputUtils.numberType.MOBILE
      );
      return cleanNumber(exampleNumber).length; 
    }
    return null;
  };

  return (
    <ContainerInput customClass='intl-tel-input' viewMode={viewMode}>
      <IntlTelInput
        containerClassName='intl-tel-input'
        inputClassName='form-control tel-input'
        value={value}
        disabled={viewMode}
        allowDropdown={!viewMode}
        defaultCountry={selectedCountry}
        onSelectFlag={(currentNumber, selectCountry) => {
          if(!viewMode) {
            let cleanNum = cleanNumber(currentNumber);
            const expectedLength = getExpectedLength(selectCountry.iso2);

            if (expectedLength && cleanNum.length > expectedLength) {
              cleanNum = cleanNum.slice(-expectedLength);
            }

            const formattedNumber = window.intlTelInputUtils.formatNumber(cleanNum, selectCountry.iso2, window.intlTelInputUtils.numberFormat.INTERNATIONAL);
            onChange(formattedNumber, selectCountry.dialCode);
          }
        }}
        onPhoneNumberChange={(isValid, value, countryData) => {
          if(!pasteNumber && !isFromCompanies) {
            if(!viewMode) {
              onChange(value, countryData.dialCode);
            }
            setSelectedCountry(countryData.iso2);
          }
          setIsFromCompanies(false);
          setPasteNumber(false);
        }}
        preferredCountries={['us', 'br', 'fr', 'jp']}
        format
        telInputProps={{
          onPaste: (e) => handlePaste(e.clipboardData.getData('Text'), e),
        }}
      />
    </ContainerInput>
  );
};

export default InternationalPhone;