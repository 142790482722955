import styled from 'styled-components';
import Text from '../Text';

export const TabName = styled(Text)`
  &.ant-typography {
    font-size: 16px;
    font-weight: 400;
    color: ${({ theme }) => theme.textColor};
  }
`;

export const Tab = styled.div`
  border-radius: 12px 12px 0 0;
  background-color: ${({ current, theme }) => current ? theme.actionColor : theme.darkPrimaryColor};
  padding: 8px 12px;
  cursor: pointer;

  ${TabName} {
    &.ant-typography {
      font-weight: ${({ current }) => current ? 700 : 400};
      color: ${({ current, theme }) => current ? theme.textContrastColor : theme.textColor};
      white-space: nowrap;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  ${({ layout, disabled, theme }) => layout === 'inline' ? `
    ${Tab} {
      padding: 8px 30px;
      ${TabName} {
        &.ant-typography {
          font-size: 16px;
          font-weight: 600;
        }
      }

      :first-child {
        border-radius: 12px 0 0 12px;
      }
      border-radius: 0;
      :last-child {
        border-radius: 0 12px 12px 0;
      }

      ${disabled && `
        opacity: .5;
        pointer-events: none;
        cursor: not-allowed;
      `}
    }
  ` : `
    gap: 8px;
    border-bottom: 4px solid ${theme.actionColor};
    overflow-x: auto;
    scrollbar-width: thin;
  `}
`;