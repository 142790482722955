import React, { useEffect } from 'react';
import {
  StyledCheckbox,
  TimeContainer,
  StyledFormItem,
  StyledTimePicker,
  AddHoursButton,
  RemoveHoursButton,
  FormListRow,
  FormListColumn,
  WeekFormItem,
  Label,
  ColumnTab,
} from './styles';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { Input, Form as AntdForm } from 'antd';

const RestricoesTab = ({ form }) => {
  const weekDayOptions = [
    { value: 'SUNDAY', label: 'D' },
    { value: 'MONDAY', label: 'S' },
    { value: 'TUESDAY', label: 'T' },
    { value: 'WEDNESDAY', label: 'Q' },
    { value: 'THURSDAY', label: 'Q' },
    { value: 'FRIDAY', label: 'S' },
    { value: 'SATURDAY', label: 'S' },
  ];

  useEffect(() => {
    if (!form.getFieldValue('weekDays')) {
      form.setFieldsValue({
        weekDays: weekDayOptions.map((day) => ({
          id: null,
          weekDay: day.value,
          value: false,
        })),
        timeIntervals: [],
      });
    }
  }, [form]);

  return (
    <ColumnTab>
      <Label>Dias da semana</Label>
      <FormListRow>
        <AntdForm.List name="weekDays">
          {(fields) =>
            fields.map((field) => {
              const weekDay = form.getFieldValue([
                'weekDays',
                field.name,
                'weekDay',
              ]);
              const label =
                weekDayOptions.find((item) => item.value === weekDay)?.label ||
                '';

              return (
                <div key={`weekday-${field.key}`}>
                  <WeekFormItem
                    {...field}
                    name={[field.name, 'id']}
                    style={{ display: 'none' }}
                  >
                    <Input />
                  </WeekFormItem>
                  <WeekFormItem
                    {...field}
                    name={[field.name, 'weekDay']}
                    style={{ display: 'none' }}
                  >
                    <Input />
                  </WeekFormItem>
                  <WeekFormItem
                    {...field}
                    name={[field.name, 'value']}
                    valuePropName="checked"
                    style={{ width: 'min-content' }}
                  >
                    <StyledCheckbox>{label}</StyledCheckbox>
                  </WeekFormItem>
                </div>
              );
            })
          }
        </AntdForm.List>
      </FormListRow>

      <FormListColumn name="timeIntervals">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => (
              <TimeContainer key={`time-${field.key}`}>
                <StyledFormItem
                  {...field}
                  name={[field.name, 'id']}
                  style={{ display: 'none' }}
                >
                  <Input />
                </StyledFormItem>

                <StyledFormItem
                  {...field}
                  name={[field.name, 'start']}
                  label="Início"
                  rules={[
                    {
                      required: true,
                      message: 'Horário de início é obrigatório',
                    },
                  ]}
                >
                  <StyledTimePicker format="HH:mm" />
                </StyledFormItem>

                <StyledFormItem
                  {...field}
                  name={[field.name, 'end']}
                  label="Fim"
                  rules={[
                    { required: true, message: 'Horário de fim é obrigatório' },
                  ]}
                >
                  <StyledTimePicker format="HH:mm" />
                </StyledFormItem>

                <RemoveHoursButton
                  onClick={() => remove(field.name)}
                  aria-label="Remover horário"
                >
                  <DeleteOutlined />
                </RemoveHoursButton>
              </TimeContainer>
            ))}

            <AddHoursButton
              onClick={() => add({ start: null, end: null })}
              type="button"
            >
              <PlusOutlined />
              Adicionar horário
            </AddHoursButton>
          </>
        )}
      </FormListColumn>
    </ColumnTab>
  );
};

export default React.memo(RestricoesTab);
