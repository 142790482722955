import styled from 'styled-components';
import { Form, Row, Button, TimePicker, Column } from '@components-teammove';
import { Checkbox, Form as AntdForm } from 'antd';

export const WeekDaysContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin-bottom: 12px;
`;

export const StyledDayButton = styled(Button)`
  &.ant-btn {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme, selected }) =>
  selected ? theme.actionColor : theme.darkPrimaryColor};
    color: ${({ theme }) => theme.textColor};
    border: none;

    &:hover {
      background-color: ${({ theme, selected }) =>
    selected ? theme.actionColor : theme.darkHighlightColor} !important;
      opacity: ${({ selected }) => (selected ? 0.9 : 1)};
    }

    &:active,
    &:focus {
      background-color: ${({ theme, selected }) =>
      selected ? theme.actionColor : theme.darkHighlightColor} !important;
      opacity: ${({ selected }) => (selected ? 0.9 : 1)};
    }
  }
`;

export const FormListRow = styled.div`
  display: flex !important;
  flex-direction: row !important;
  gap: 8px !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  margin-bottom: 20px !important;
`;

export const ColumnTab = styled(Column)`
  display: flex !important;
  flex-direction: column !important;
  gap: 12px !important;
`;

export const FormListColumn = styled(AntdForm.List)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StyledCheckboxGroup = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

export const StyledCheckbox = styled(Checkbox)`
  && {
    margin: 0;
    padding: 0;

    .ant-checkbox {
      display: none;
    }

    .ant-checkbox + span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      margin: 0;
      padding: 0;
      color: ${({ theme }) => theme.textColor};
      background-color: ${({ theme }) => theme.darkPrimaryColor};
      border: none;
      font-size: 14px;
      font-weight: 500;
      transition: all 0.3s;
      cursor: pointer;

      &:hover {
        background-color: ${({ theme }) => theme.darkHighlightColor} !important;
        opacity: 1;
      }
    }

    &.ant-checkbox-wrapper-checked {
      .ant-checkbox + span {
        background-color: ${({ theme }) => theme.actionColor};

        &:hover {
          opacity: 0.9;
        }
      }
    }
  }
`;

export const TimeContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: flex-start;
  margin-bottom: 16px;
`;

export const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0;
`;

export const Label = styled.label`
  font-size: 12px !important;
  color: ${(props) => props.theme.textColor};
  margin-left: 8px !important;
  font-weight: 600 !important;
`;

export const WeekFormItem = styled(Form.Item)`
  margin-bottom: 0;
  width: min-content !important;
  max-width: min-content !important;
`;

export const StyledTimePicker = styled(TimePicker)`
  width: 120px;
`;

export const AddHoursButton = styled(Button)`
  margin-bottom: 24px;
`;

export const RemoveHoursButton = styled(Button)`
  margin-top: 26px !important;
  &.ant-btn {
    width: 55px;
    height: 55px;
  }
`;

export const StyledForm = styled(Form)`
  gap: 12px;
`;

export const StyledRow = styled(Row)`
  gap: 12px;
`;
