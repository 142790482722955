import styled from 'styled-components';
import { Text, Empty, Icon, Row, Avatar } from '@components-teammove';

export const NoDataText = styled(Text)`
  color: ${({ theme }) => theme.textColor} !important;
  opacity: 0.5;
`;

export const EmptyStyled = styled(Empty)`
    .ant-empty-image {
        opacity: 0.3;
    }
`;

export const TitleCard = styled(Text)`
    font-size: 16px;
    font-weight: 700;
    color: ${({ theme }) => theme.textColor};
`;

export const OpacityText = styled(Text)`
    color: ${({ theme }) => theme.textColor};
    opacity: 0.5;
    font-size: ${({ type }) => type ? 14 : 12}px;
    font-weight: 400;
    line-height: 16px;
`;

export const BoldText = styled(Text)`
    font-weight: 700;
    color: ${({ theme }) => theme.textColor};
`;

export const IconStyled = styled(Icon.Feather)`
    color: ${({ theme }) => theme.textColor};
    width: ${({ clock }) => clock ? 18 : 16}px;
    height: ${({ clock }) => clock ? 18 : 16}px;
    ${({ clock }) => clock && `
        opacity: 0.5;
    `}
`;

export const RoundedContainer = styled.div`
    width: 28px;
    height: 28px;
    border-radius: 50%;
    padding: 12px;
    background-color: ${({ theme }) => theme.actionColor};
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const RowClassificacao = styled(Row)`
  flex-wrap: wrap;
`;

export const SmallAvatar = styled(Avatar)`
  width: 24px;
  height: 24px;
`;