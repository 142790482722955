/* eslint-disable camelcase */
import { Column, Form, Input, InputNumber, Row, Select, Text, Checkbox } from '@components-teammove';
import React, { useEffect, useState } from 'react';
import AnexosPergunta from '../Anexos';
import { optionsSelectInOptions } from '../rules';
import { ButtonStyled, ColumnCardOption, DesvioCondicionalText, FormItemStyledWidth, RowStyledConditional, SelectStyled, TextConditionalStyled } from './styles';
import { FormItemDynamic } from '../styles';
import { formatterToSetStatesInOptions, optionsConditionalQuestion } from './rules';

const MultiSelect = ({ 
  handleOpenHintAttachment, 
  anexosOpcao, 
  form, 
  isUniqueChoiceType,
  setAnexosOpcao,
  groups
}) => {
  const [options, setOptions] = useState([]);
  const [conditionalAsks, setConditionalAsks] = useState({});
  const [isObservacaoHintOpen, setIsObservacaoHintOpen] = useState({});
  const [isPlanoHintOpen, setIsPlanoHintOpen] = useState({});
  const [isPesoDisabled, setIsPesoDisabled] = useState({});

  useEffect(() => {
    const allFields = form.getFieldsValue(true);
    if(allFields.temp_id_opcao_0 || allFields.id_opcao_0) {
      const opcaoCount = Object.keys(allFields).filter((key) => key.includes(('temp_id_opcao_' || 'id_opcao_'))).length;
      const newOptions = [];

      for (let index = 0; index < opcaoCount; index++) {
        // evita duplicacao de ids
        const selectedQuestionsIds = [...new Set(allFields[`enabled_questions_opcao_${index}`] || [])];
        form.setFieldsValue({ [`enabled_questions_opcao_${index}`]: selectedQuestionsIds });

        newOptions.push({ ordem: index });
      }

      setOptions(newOptions);
      formatterToSetStatesInOptions(
        allFields,
        setConditionalAsks,
        setIsObservacaoHintOpen,
        setIsPlanoHintOpen,
        setIsPesoDisabled,
        setAnexosOpcao
      );
    } else {
      form.setFieldsValue({ 
        tipo_upload_opcao_0: 'NAO_PERMITE',
        tipo_observacao_opcao_0: 'NAO_PERMITE',
        gerar_tarefa_opcao_0: 'NAO_PERMITE',
        enabled_questions_opcao_0: []
      });
      setOptions([{ ordem: 0 }]);
    }
  }, []);

  const handleAddOption = () => {
    setOptions([...options, { ordem: options.length }]);
    form.setFieldsValue({ 
      [`tipo_upload_opcao_${options.length}`]: 'NAO_PERMITE', 
      [`tipo_observacao_opcao_${options.length}`]: 'NAO_PERMITE', 
      [`gerar_tarefa_opcao_${options.length}`]: 'NAO_PERMITE',
      [`enabled_questions_opcao_${options.length}`]: []
    });
  };

  const handleToggleHint = (value, index, type) => {
    if (type === 'observacao') {
      setIsObservacaoHintOpen((prev) => ({ ...prev, [index]: value !== 'NAO_PERMITE' }));
    } else {
      setIsPlanoHintOpen((prev) => ({ ...prev, [index]: value !== 'NAO_PERMITE' }));
    }
  };

  const handleDeleteOption = (index) => {
    setOptions(options.filter((option) => option.ordem !== index));
  };

  const handleSetNullQuestion = (value, index) => {
    form.setFieldsValue({ [`peso_opcao_${index}`]: 0 });
    setIsPesoDisabled((prevState) => ({
      ...prevState,
      [index]: value
    }));
  };
 
  return (
    <Column>
      {options && options.map((option, index) => (
        <ColumnCardOption key={option.ordem} >
          <Row width="100%" align="center" gap="12px">
            <Form.Item hidden name={`id_opcao_${index}`}/>
            <Form.Item hidden name={`temp_id_opcao_${index}`}/>
            <Form.Item
              label={(
                <Text>{`Opção ${index + 1}`}</Text>
              )}
              name={`valor_opcao_${index}`}
              rules={[{ required: true, message: 'Por favor, insira uma opção' }]}
            >
              <Input placeholder='Adicionar opção de resposta' />
            </Form.Item>
            <FormItemStyledWidth maxWidth="80px" label="Peso" name={`peso_opcao_${index}`}>
              <InputNumber placeholder="peso" disabled={isPesoDisabled[index]}/>
            </FormItemStyledWidth>
          </Row>
          <Row justify='space-between' width='100%' gap='14px'>
            <RowStyledConditional hasToRender={conditionalAsks[index]} justify='center' gap='8px' width='100%'>
              <TextConditionalStyled>Desvio condicional para: </TextConditionalStyled>
              <Form.Item name={`enabled_questions_opcao_${index}`}>
                <SelectStyled mode="tags" tokenSeparators={[',']} placeholder='pergunta para o desvio'  options={optionsConditionalQuestion(index, form.getFieldsValue(true), groups)}  />
              </Form.Item>
            </RowStyledConditional>
            {!conditionalAsks[index] && isUniqueChoiceType && (
              <DesvioCondicionalText onClick={() => setConditionalAsks((prev) => ({ ...prev, [index]: true }))}> 
                Adicionar desvio condicional
              </DesvioCondicionalText>
            )}
            <FormItemDynamic hasToRender={isUniqueChoiceType} name={`anula_pergunta_opcao_${index}`} valuePropName='checked'>
              <Checkbox flexEnd onChange={(value) => handleSetNullQuestion(value, index)}>
                  Nulo
              </Checkbox>
            </FormItemDynamic>
          </Row>
          <Row align='center' gap='12px'>
            <Form.Item label="Anexos" name={`tipo_upload_opcao_${index}`}>
              <Select options={optionsSelectInOptions} onChange={(value) => handleOpenHintAttachment(value, index)} />
            </Form.Item>
            <FormItemDynamic label='Detalhes' hasToRender={anexosOpcao[index]} name={`upload_dica_opcao_${index}`}>
              <Input placeholder='Detalhes' />
            </FormItemDynamic>
          </Row>
          <AnexosPergunta form={form} anexosOpcao={anexosOpcao} optionIndex={index} />
          <Row width='100%' align='center' gap='12px'>
            <Form.Item label="Observação" name={`tipo_observacao_opcao_${index}`}>
              <Select options={optionsSelectInOptions} onChange={(value) => handleToggleHint(value, index, 'observacao')} />
            </Form.Item>
            <FormItemDynamic hasToRender={isObservacaoHintOpen[index]} label="Dica" name={`observacao_dica_opcao_${index}`}>
              <Input placeholder='Dica' />
            </FormItemDynamic>
          </Row>
          <Row width='100%' align='center' gap='12px'>
            <Form.Item label="Plano de ação" name={`gerar_tarefa_opcao_${index}`}>
              <Select options={optionsSelectInOptions} onChange={(value) => handleToggleHint(value, index, 'plano')} />
            </Form.Item>
            <FormItemDynamic hasToRender={isPlanoHintOpen[index]} label="Dica" name={`tarefa_dica_opcao_${index}`}>
              <Input placeholder='Dica' />
            </FormItemDynamic>
          </Row>
          <DesvioCondicionalText onClick={() => handleDeleteOption(index)}> Remover Opcao</DesvioCondicionalText>
        </ColumnCardOption>
      ))}
      <ButtonStyled size='small' type='primary' onClick={handleAddOption}>Adicionar opção de resposta</ButtonStyled>
    </Column>
  );
};

export default MultiSelect;