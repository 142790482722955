import { PageHeader } from 'antd';
import Title from '../Title';
import Text from '../Text';
import View from '../View';
import Icon from '../Icon';
import styled, { css } from 'styled-components';

export const Row = styled.div`
  display: flex;
  ${({ gap }) => gap && `
    gap: ${gap};
  `}
  ${({ align }) => align && `
    align-items: ${align};
  `}
`;

export const DarkPageHeader = styled(PageHeader)`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;

  .ant-page-header-heading {
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px;
    background-color: inherit;
  }

  .ant-page-header-content {
    width: 100%;
    flex: 1;
  }

  ${(props) =>
  props.fixed &&
    css`
      padding-top: 0px !important;

      .ant-page-header-content {
        padding-top: 0px !important;
      }

      .ant-page-header-heading {
        padding-top: 16px;
        padding-bottom: 12px;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 2;
      }
    `}

  ${({ padding }) => padding &&
    css`
      padding: ${padding};
    `}

  @media(max-width: 768px) {
    span[class*='extra'] {
      .ant-btn-group {
        button {
          span ~ span {
            display: none;
          }
        }
      }
    }
  }

  @media (max-width: 576px) {
    span[class*='extra'] {
      display: unset;
      float: right;
      width: unset;
    }
  }
  background-color: ${(props) => props.theme.backgroundColor} !important;

  .ant-page-header-heading {
    padding: 0;
    border: none;
    display: flex;
    margin-bottom: ${({ marginlessHeader }) => marginlessHeader ? '0' : '28px'};
    ${({ stickyHeader }) => stickyHeader && `
      position: sticky;
      top: 0;
      background-color: ${(props) => props.theme.backgroundColor};
      z-index: 1;
    `}

    .ant-page-header-back {
      display: flex;
      align-items: center;
      .ant-page-header-back-button {
        color: ${({ theme }) => theme.textColor};
        font-size: 20px;
      }
    }

    .ant-page-header-heading-title {
      padding: 0;
      flex: 1;
    }

    .ant-page-header-heading-extra {
      display: flex;
      align-self: flex-end;
      gap: 18px;

      * {
        margin-left: 0;
      }
    }
  }
`;

export const GapView = styled(View)`
  gap: 16px;
`;

export const SpaceBetweenRow = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Extra = styled(View)`
  flex-direction: row;
  gap: 18px;
`;

export const Back = styled(Icon.Bootstrap)`
  width: 1.75rem;
  height: 1.75rem;
  cursor: pointer;
`;

export const PageTitle = styled(Title)`
  &.ant-typography {
    font-size: 28px !important;
    font-weight: 700 !important;
    color: ${({ theme }) => theme.textColor} !important;
    margin-bottom: 0 !important;
    display: flex;
    align-items: center;
    gap: 20px;
  }
`;

export const PageSubtitle = styled(Text)`
  &.ant-typography {
    font-size: 28px;
    font-weight: 400;
    color: ${({ theme }) => theme.textColor};
    ${({ darkenedSubTitle }) => darkenedSubTitle && `
      opacity: .7;
    `}
  }
`;