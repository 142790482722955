import React, { useRef, useEffect, useCallback } from 'react';
import OrganogramNode from './OrganogramNode';
import { OrganogramContainer } from './style';

const OrganogramBase = ({ data, onToggle, onNodeClick }) => {
  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const isDragging = useRef(false);
  const startPos = useRef({
    x: 0,
    y: 0,
  });

  // Center the content when it's loaded
  useEffect(() => {
    const container = containerRef.current;
    const content = contentRef.current;

    if (container && content) {
      // Wait for next frame to ensure dimensions are calculated
      requestAnimationFrame(() => {
        const containerWidth = container.clientWidth;
        const containerHeight = container.clientHeight;
        const contentWidth = content.scrollWidth;
        const contentHeight = content.scrollHeight;

        // Calculate center position
        container.scrollLeft = (contentWidth - containerWidth) / 2;
        container.scrollTop = (contentHeight - containerHeight) / 2;
      });
    }
  }, []);

  const handleMouseDown = useCallback((e) => {
    if (e.button === 1) {
      // Middle mouse button
      isDragging.current = true;
      startPos.current = {
        x: e.clientX,
        y: e.clientY,
        scrollLeft: containerRef.current.scrollLeft,
        scrollTop: containerRef.current.scrollTop,
      };
      containerRef.current.style.cursor = 'grabbing';
      e.preventDefault();
    }
  }, []);

  const handleMouseMove = useCallback((e) => {
    if (isDragging.current) {
      const dx = e.clientX - startPos.current.x;
      const dy = e.clientY - startPos.current.y;
      containerRef.current.scrollLeft = startPos.current.scrollLeft - dx;
      containerRef.current.scrollTop = startPos.current.scrollTop - dy;
    }
  }, []);

  const handleMouseUp = useCallback((e) => {
    if (e.button === 1) {
      // Middle mouse button
      isDragging.current = false;
      containerRef.current.style.cursor = 'grab';
    }
  }, []);

  useEffect(() => {
    const container = containerRef.current;
    container.addEventListener('mousedown', handleMouseDown);
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      container.removeEventListener('mousedown', handleMouseDown);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [handleMouseDown, handleMouseMove, handleMouseUp]);

  return (
    <OrganogramContainer ref={containerRef}>
      <div
        ref={contentRef}
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '20px',
          alignItems: 'flex-start',
          justifyContent: 'center',
          minHeight: '100%',
          padding: '32px',
          width: '100%',
        }}
      >
        {Array.isArray(data) ? (
          data.map((node, index) => (
            <OrganogramNode
              key={node.id || index}
              node={node}
              onToggle={onToggle}
              onNodeClick={onNodeClick}
              isRoot
              level={0}
            />
          ))
        ) : (
          <OrganogramNode
            node={data}
            onToggle={onToggle}
            onNodeClick={onNodeClick}
            isRoot
            level={0}
          />
        )}
      </div>
    </OrganogramContainer>
  );
};

export default OrganogramBase;
