import React, { useEffect, useState } from 'react';
import { Image } from '@components';
import { Modal, Row, Column, InternationalPhone } from '@components-teammove';
import { setInterruptRequest } from '@ducks/voip';
import { customFields } from '../../rules';
import { formatCnpj, formatDate, openPopup, InfoUsuario, processInternationalPhoneNumber } from '@utils';
import { AddressRow, Answer, ContentCityStateCep, ContentMap, CopyIcon, DetailView, GapFlexCol, ImagePreview, Label, PhoneIcon, SpaceBetweenRow } from './styles';
import { AddressView } from '../styles';
import Endereco from '../Endereco';
import { useDispatch } from 'react-redux';

export default function Detalhes({ company, handleOnClosePopUp }) {
  const dispatch = useDispatch();
  const [modalImg, setModalImg] = useState(false);

  useEffect(() => {
    removeCustomFieldsAlpha(company);
  }, []);

  const removeCustomFieldsAlpha = (unity) => {
    Object.keys(unity).forEach((key) => {
      if (key === customFields.CNPJ || key === customFields.IE) {
        unity[key] = unity[key].replaceAll(/[^0-9a-z]/gi, '');
      }
    });
  };
  
  const handleDifferentCustomFields = (customField) => {
    if (customField.field === 'INAUGURACAO') {
      return formatDate(company[customFields[customField.field]]);
    }
    if (customField.field === 'TELEFONE_1' || customField.field === 'TELEFONE_2' || customField.field === 'CELULAR_1' || customField.field === 'CELULAR_2') {
      const phoneInfo = processInternationalPhoneNumber(company[customFields[customField.field]]);
      return (
        <Row gap='12px'>
          <InternationalPhone viewMode value={phoneInfo.numero} codCountry={phoneInfo.dialCode} />
          {InfoUsuario.perm('voip', InfoUsuario.get()) && <PhoneIcon type='phone' onClick={()=> handleDisc(phoneInfo.numero)}/> }
        </Row>
        
      );
    }
    if (customField.field === 'CNPJ') {
      return formatCnpj(company[customFields[customField.field]]);
    }
    if (customField.field === 'IE') {
      return parseInt(company[customFields[customField.field]]) ? (company[customFields[customField.field]]) : company[customFields[customField.field]];
    }
    return company[customFields[customField.field]];
  };

  const joinedCompanyInfo = [company.city, company.state, company.cep].filter((info) => info).join(' - ');
  const fieldIsPhone = (field) => {
    return field === 'TELEFONE_1' || field === 'TELEFONE_2' || field === 'CELULAR_1' || field === 'CELULAR_2';
  };

  const handleDisc = (number) => {
    dispatch(setInterruptRequest(true));
    const popupUrl = `${window.location.origin}/voip?number=${number}&name=${company.name}&CompanyId=${company.id}`;
    openPopup(popupUrl, '_blank', 400, 970, '/voip', handleOnClosePopUp);
  };

  return(
    <>
      <AddressView>
        <GapFlexCol>
          {company.customFields.map((customField) => customField.previewDetail && !!company[customFields[customField.field]] && (
            <SpaceBetweenRow key={customField.field}>
              <DetailView key={customField.field}>
                <Label>{customField.name}</Label>
                <Row>
                  <Answer>
                    {handleDifferentCustomFields(customField)}
                  </Answer>
                </Row>
              </DetailView>
              <CopyIcon type='copy' onClick={() => navigator.clipboard.writeText(fieldIsPhone() ? company[customFields[customField.field]] : handleDifferentCustomFields(customField))}/>
            </SpaceBetweenRow>
          ))}

          {company.dynamicFields.map((dynField) => dynField.previewDetail && dynField.value?.value && (
            <DetailView key={dynField.id}>
              <Label>{dynField.alias}</Label>
              {dynField.kind === 'IMAGE' ? (
                <ImagePreview 
                  src={dynField.value.value} 
                  onClick={() => setModalImg(dynField.value.value)}
                />
              ) : (
                <SpaceBetweenRow>
                  {dynField.alias === 'Site' ? (
                    <a href={dynField.value.value} target='blank' rel="noopener noreferrer">
                      <Answer>{dynField.value.value}</Answer>
                    </a>
                  ) : (
                    <Answer>{dynField.value.value}</Answer>
                  )}
                  <CopyIcon type='copy' onClick={() => navigator.clipboard.writeText(dynField.value.value)}/>
                </SpaceBetweenRow>
              )}
            </DetailView>
          ))}
          <DetailView>             
            <SpaceBetweenRow>
              <Column>
                {company.address && (
                  <>
                    <Label>Endereço</Label>
                    <AddressRow>{company.address}</AddressRow>
                  </>
                )}
                <ContentCityStateCep>
                  <Row>
                    {joinedCompanyInfo}
                  </Row>
                </ContentCityStateCep>  
              </Column>   
              <CopyIcon type='copy' onClick={() => navigator.clipboard.writeText((company.address ? company.address + ' ' : '') + joinedCompanyInfo)}/>
            </SpaceBetweenRow>
          </DetailView>
          
        </GapFlexCol>
        <ContentMap>
          <Endereco company={company}/>
        </ContentMap>
      </AddressView>
      <Modal
        visible={!!modalImg}
        closable={false}
        onCancel={() => setModalImg(false)}
        onOk={() => window.open(modalImg)}
        cancelText='Fechar'
        okText={'Abrir'}
        width='fit-content'
      >
        <Image src={modalImg}/>
      </Modal>
    </>
  );
}