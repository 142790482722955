import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import App from '@app';
import { Icon, Body, PageHeader, Breadcrumb, SearchBar, Filter, Button, ContentHeader, ViewControl } from '@components-teammove';

import { useDispatch, useSelector } from 'react-redux';
import { FILTERS_KEY , changeSearch } from '@ducks/agenda';
import { Filtros, InfoUsuario } from '@utils';
import { initialFilters, removableFilters, sortFilters } from './rules';
import ActivitiesList from './List';
import { getAtividades, reset } from '@ducks/atividades';
import { getTiposUnidades } from '@ducks/configuracoesUnidadesTiposUnidades';
import { getTiposAtividades } from '@ducks/configuracoesAtividadesTipos';
import { getUsuarios } from '@sdk/Usuarios';
import Calendar from './Calendar';
import { CalendarIcon } from './styles';
import Cadastro from './Cadastro';
import { Goals } from './Goals';

const VIEW_MODE_KEY = 'activities_view_mode';

export default function Agenda() {
  const dispatch = useDispatch();
  const location =  useLocation();
  const { viewModeFromNotification } = useParams();

  const theme = useSelector(({ app }) => app.get('theme'));

  const search = useSelector(({ agenda }) => agenda.get('search'));
  const [filtros, setFiltros] = useState(() => {
    const savedFiltros = Filtros.get(FILTERS_KEY) || {};
    return savedFiltros;
  });
  const tiposUnidades = useSelector(({ configuracoesUnidadesTiposUnidades }) => configuracoesUnidadesTiposUnidades.get('tiposUnidades'));
  const successTiposUnidades = useSelector(({ configuracoesUnidadesTiposUnidades }) => configuracoesUnidadesTiposUnidades.get('successTiposUnidades'));
  const tiposAtividades = useSelector(({ configuracoesAtividadesTipos }) => configuracoesAtividadesTipos.get('active'));
  const successTiposAtividades = useSelector(({ configuracoesAtividadesTipos }) => configuracoesAtividadesTipos.get('successTiposAtividades'));
  const successSave = useSelector(({ atividades }) => atividades.get('successSave'));
  const [visibleFilters, setVisibleFilters] = useState(false);
  const [visibleActivityRegister, setVisibleActivityRegister] = useState(false);
  const [viewBy, setViewBy] = useState(localStorage.getItem(VIEW_MODE_KEY) || 'Lista');
  const [filters, setFilters] = useState(viewBy === 'Meta de atividades' ? initialFilters.filter((item)=> item.name !== 'period' && item.name !== 'createdByMyself') : initialFilters);
  
  const [usuarios, setUsuarios] = useState([]);
  const [orderBy, setOrderBy] = useState();
  const [idCompany, setIdCompany] = useState(null);

  useEffect(() => {
    if (viewBy === 'Meta de atividades') {
      setFilters((prevFilters) =>
        prevFilters.filter((item)=> item.name !== 'period' && item.name !== 'createdByMyself')
      );
    } else if(!filters.find((item)=> item.name === 'createdBy') && !filters.find((item)=> item.name === 'period')) {
      setFilters((prevFilters) => [ ...prevFilters, ...removableFilters ]);
    }
  }, [viewBy]);

  useEffect(() => {
    dispatch(getTiposUnidades());
    dispatch(getTiposAtividades());
    const { idCompany } = location.state ? location.state : {};
    
    if (idCompany) {
      setIdCompany(idCompany);
      setVisibleActivityRegister(true);
    }

    (async () => {
      try {
        const resp = await getUsuarios();
        const userList = resp.filter((item) => InfoUsuario.get('hierarquia')?.includes(item.key))
          .map(({ key, title }) => ({ value: key, label: title }));
        setUsuarios(userList);

        const loggedUserId = InfoUsuario.get('cdUsuario');
        const loggedUser = userList.find((user) => user.value === loggedUserId);

        const usersFromFiltros = (Filtros.get(FILTERS_KEY)?.users || []).filter((user) => user !== loggedUserId);
        const descUsersFromFiltros = (Filtros.get(FILTERS_KEY)?.descFiltros?.users || []).filter((user) => user !== loggedUser.label);
        
        if (loggedUser) {
          const defaultFiltros = {
            ...Filtros.get(FILTERS_KEY),
            users: [loggedUserId, ...usersFromFiltros],
            descFiltros: {
              ...(Filtros.get(FILTERS_KEY)?.descFiltros || {}),
              users: [loggedUser.label, ...descUsersFromFiltros],
            },
          };
          Filtros.set(FILTERS_KEY, defaultFiltros);
          setFiltros(defaultFiltros);
        }

      } catch (error) {
        console.error('Error fetching users:', error);
      }
    })();

    return () => {
      dispatch(reset());
    };
  }, []);

  useEffect(() => {
    if (usuarios || successTiposUnidades || successTiposAtividades) {
      setFilters(filters.map((filter) => {
        if (filter.name === 'users' && usuarios) {
          return { ...filter, options: usuarios };
        }
        if (filter.name === 'typesCompanies' && successTiposUnidades) {
          return { ...filter, options: tiposUnidades.map((tipoUnidade) => ({ label: tipoUnidade.name, value: tipoUnidade.id })) };
        }
        if (filter.name === 'typesActivities' && successTiposAtividades) {
          return { ...filter, options: tiposAtividades.map((tipoUnidade) => ({ label: tipoUnidade.nome, value: tipoUnidade.id })) };
        }
        return { ...filter };
      }));
    }
  }, [usuarios, successTiposUnidades, successTiposAtividades]);

  useEffect(() => {
    handleSimpleSearch();
  }, [filtros, orderBy]);

  useEffect(() => {
    if (successSave && viewBy === 'Lista') {
      handleSimpleSearch();
    }
  }, [successSave]);

  const handleViewByChange = (viewBy) => {
    setViewBy(viewBy);
    localStorage.setItem(VIEW_MODE_KEY, viewBy);
    if (viewBy === 'Lista') {
      handleSimpleSearch({ viewBy: viewBy });
    }
  };

  const handleNewActivityClick = () => {
    setVisibleActivityRegister(true);
  };

  const handleSearchChangingNewFilters = (filtros) => {
    Filtros.set(FILTERS_KEY, filtros);
    setFiltros(filtros);
  };

  const handleSimpleSearch = (params) => {
    const searchParam = (params?.search !== undefined && params?.search?.search !== '') ? params.search : search;
    const viewByParam = params?.viewBy || viewBy;
    dispatch(changeSearch(searchParam));
    if (viewByParam === 'Lista') {
      dispatch(getAtividades({ filtros, search: searchParam, orderBy: orderBy?.field, orderDiretion: orderBy?.order, limit: 50 }));
    }
  };

  return(
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title='Agenda'
          breadcrumb={(
            <Breadcrumb 
              items={[
                { path: '/', name: 'Home' }, 
                { path: '/atividades', name: 'Atividades', current: true },
                { path: '/atividades/agenda', name: 'Agenda', current: true },
              ]}
            />
          )}
          extra={[
            <Button key='filter' size='small' type='secondary' icon={<Icon.MDI type='filter-outline' />} onClick={() => setVisibleFilters(true)}>Filtros</Button>,
            <Button key='new' size='small' type='primary' onClick={handleNewActivityClick}>Cadastrar atividade</Button>
          ]}
        >
          <SearchBar
            placeholder="Buscar atividades"  
            initialValue={search}
            onSearch={(search) => handleSimpleSearch({ search })}        
            activeFiltersProps={{ filtros, onSearch: handleSearchChangingNewFilters, filters }}
          />

          <ContentHeader 
            title={viewBy} 
            extra={(
              <ViewControl 
                options={[
                  { icon: <Icon.Feather type='pie-chart'/>, name: 'Meta de atividades' },
                  { icon: <Icon.MDI type='format-list-bulleted'/>, name: 'Lista' }, 
                  { icon: <CalendarIcon type='calendar'/>, name: 'Calendário' }, 
                ]} 
                current={viewBy} 
                onChange={handleViewByChange}
              />
            )}
          />

          {viewBy === 'Lista' ? (
            <ActivitiesList orderBy={orderBy} setOrderBy={setOrderBy}/>
          ) : viewBy === 'Calendário' ? (
            <Calendar filtros={filtros}/>
          ) : <Goals filtros={filtros} orderBy={orderBy} setOrderBy={setOrderBy} search={search} viewModeFromNotification={viewModeFromNotification}/>
          }
        </PageHeader>
        <Filter
          visible={visibleFilters}
          onClose={() => setVisibleFilters(false)}
          filtros={viewBy === 'Meta de atividades' ? { ...filtros, period:undefined, descFiltros: { ...filtros.descFiltros, period:undefined } } : filtros}
          filters={sortFilters(filters)}
          onSearch={handleSearchChangingNewFilters}
        />

        {visibleActivityRegister && (
          <Cadastro visible={visibleActivityRegister} onCancel={() => setVisibleActivityRegister(false)} idCompany={idCompany}/>
        )}
      </Body>
    </App>
  );
}