import styled from 'styled-components';

export const StyledTag = styled.div`
  display: flex;
  flex: none;
  align-items: center;
  width: fit-content;
  height: fit-content;
  border-radius: 27px;
  padding: ${({ small }) => small ? '4px 12px' : '6px 12px'};
  background-color: ${({ bgColor, theme, dark }) => bgColor || (dark ? theme.darkSecondaryColor : theme.darkPrimaryColor)};
  color: ${(props) => props.theme.textColor};
  transition: all .1s;
  font-size: 12px;
  font-weight: 400;

  .ant-typography {
    color: ${(props) => props.theme.textColor};
  }

 .close-icon {
    display: flex;
    font-weight: 700;
    cursor: pointer;
    margin-left: 6px;
  }
`;