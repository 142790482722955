import styled from 'styled-components';
import { View, Button, Icon } from '@components-teammove';

export const Container = styled(View)`
  gap: 12px;
`;

export const ActivityColumnRow = styled(View)`
  flex-direction: row;
  gap: 24px;
`;

export const IconView = styled(View)`
  background-color: ${({ color }) => color};
  padding: 28px;
  justify-content: center;
`;

const Text = styled.span`
  color: ${({ theme }) => theme.textColor};
  ${({ lineThrough }) => lineThrough && `
    text-decoration: line-through;
  `}
`;

export const ActivityName = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.textColor};
  max-width: 450px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const CompanyName = styled(Text)`
  font-size: 20px;
  font-weight: 700;
  color: ${({ theme }) => theme.textColor};
`;

export const SimpleText = styled(Text)`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.textColor};
`;

export const ActivityIcon = styled(Icon.MDI)`
  width: 2.5rem !important;
  height: 2.5rem !important;
  color: ${({ theme }) => theme.textColor};
`;

export const CellPaddingContainer = styled(View)`
  padding: 20px;
`;

export const CellVerticalPaddingContainer = styled.div`
  padding: 10px 0;
`;

export const LoadMoreButton = styled(Button)`
  align-self: center;
`;

export const SmallText = styled(Text)`
  font-size: 12px;
  font-weight: 400;
`;

export const SecondaryText = styled(SmallText)`
  opacity: .5;
  font-size: ${({ companyLongName }) => companyLongName ? '14px' : '12px'};
`;

export const RegularCellText = styled(Text)`
  font-size: 16px;
  font-weight: 400;
`;