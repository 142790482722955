import React, { useState } from 'react';
import { DndContext, MouseSensor, useSensor, useSensors } from '@dnd-kit/core';
import OrganogramBase from './OrganogramBase';
import { Notification } from '@utils';

export default function Organogram({ data, onNodeClick, onUpdateNodeParent }) {
  const [orgData, setOrgData] = useState(
    Array.isArray(data) ? data : [data].filter(Boolean)
  );
  const [selectedNodeId, setSelectedNodeId] = useState(null);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
  );

  const findAndUpdate = (nodes, id, updateFn) => {
    return nodes.map((node) => {
      if (node.id === id) {
        return updateFn(node);
      }
      if (node.children?.length > 0) {
        return {
          ...node,
          children: findAndUpdate(node.children, id, updateFn),
        };
      }
      return node;
    });
  };

  const removeNode = (nodes, id) => {
    let removed = null;
    const newNodes = nodes.filter((node) => {
      if (node.id === id) {
        removed = node;
        return false;
      }
      return true;
    });

    if (!removed) {
      for (const node of nodes) {
        if (node.children?.length > 0) {
          const [newChildren, removedFromChildren] = removeNode(
            node.children,
            id
          );
          if (removedFromChildren) {
            removed = removedFromChildren;
            return [
              nodes.map((nodeItem) =>
                nodeItem.id === node.id
                  ? { ...nodeItem, children: newChildren }
                  : nodeItem
              ),
              removed,
            ];
          }
        }
      }
    }

    return [newNodes, removed];
  };

  // Function to check if a node is at the root level
  const isRootNode = (nodeId) => {
    if (nodeId === 'company' || nodeId === 'independent') {
      return true;
    }
    return false;
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (!over || active.id === over.id) return;

    const draggedId = active.id;
    const targetId = over.id;

    // Check if the target is a root node - don't allow dragging to root level
    if (isRootNode(targetId)) {
      Notification.error('Cannot move nodes to root level');
      return;
    }

    // Proceed with the parent update
    onUpdateNodeParent(draggedId, targetId);
  };

  const findNodeById = (nodes, id) => {
    for (const node of nodes) {
      if (node.id === id) return node;
      if (node.children?.length > 0) {
        const found = findNodeById(node.children, id);
        if (found) return found;
      }
    }
    return null;
  };

  const handleToggle = (id) => {
    setOrgData((prev) =>
      findAndUpdate(prev, id, (node) => ({
        ...node,
        isExpanded: !node.isExpanded,
      }))
    );
  };

  const handleNodeClick = (id) => {
    setSelectedNodeId(id);
    if (onNodeClick) {
      onNodeClick(id);
    }
  };

  return (
    <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
      <OrganogramBase
        data={orgData}
        onToggle={handleToggle}
        onNodeClick={handleNodeClick}
      />
    </DndContext>
  );
}
