import { combineReducers } from 'redux';
import app from '@ducks/app';
import notificacoes from '@ducks/notificacoes';

import login from '@ducks/login';
import perfil from '@ducks/perfil';
import alterarSenha from '@ducks/alterarSenha';
import recuperarSenha from '@ducks/recuperarSenha';

import wikis from '@ducks/wikis';
import wikisAcoes from '@ducks/wikisAcoes';
import wikisAudiencia from '@ducks/wikisAudiencia';
import wikisEditor from '@ducks/wikisEditor';
import wikisPersonalizacao from '@ducks/wikisPersonalizacao';
import wikisCadastro from '@ducks/wikisCadastro';
import wikisComentario from '@ducks/wikisComentario';
import wikisComentarioAcao from '@ducks/wikisComentarioAcao';

import comunicados from '@ducks/comunicados';

import wikisEAD from '@ducks/wikisEAD';
import wikisEADQuiz from '@ducks/wikisEADQuiz';
import wikisEADTrilhas from '@ducks/wikisEADTrilhas';
import wikisEADTrilha from '@ducks/wikisEADTrilha';

import chamadosTickets from '@ducks/chamadosTickets';
import chamadosCadastro from '@ducks/chamadosCadastro';
import chamadosHistorico from '@ducks/chamadosHistorico';
import chamadosDashboard from '@ducks/chamadosDashboard';
import chamadosBriefingReply from '@ducks/chamadosBriefingReply';
import chamadosEtapas from '@ducks/chamadosEtapas';

import chamadosKanban from '@ducks/chamadosKanban';

import classificacoes from '@ducks/classificacoes';

import gestaoEquipes from '@ducks/gestaoEquipes';

import unidades from '@ducks/unidades';
import contatos from '@ducks/contatos';

import report from '@ducks/report';

import configuracoesChamados from '@ducks/configuracoesChamados';
import configuracoesChamadosCategoria from '@ducks/configuracoesChamadosCategoria';
import configuracoesChamadosAtendimento from '@ducks/configuracoesChamadosAtendimento';
import configuracoesChamadosGeral from '@ducks/configuracoesChamadosGeral';

import atividades from '@ducks/atividades';

import checklists from '@ducks/checklists';

import agenda from '@ducks/agenda';

import configuracoesGestaoEquipe from '@ducks/configuracoesGestaoEquipe';

import configuracoesUsuarios from '@ducks/configuracoesUsuarios';
import configuracoesUsuariosAtributo from '@ducks/configuracoesUsuariosAtributo';

import configuracoesLogoImpressao from '@ducks/configuracoesLogoImpressao';

import configuracoesAtendimento from '@ducks/configuracoesAtendimento';

import configuracoesTelasNomenclaturas from '@ducks/configuracoesTelasNomenclaturas';

import afastamento from '@ducks/afastamento';

import home from '@ducks/home';

import novidades from '@ducks/novidades';

import campanhaLista from '@ducks/campanhaLista';
import campanhaDetalhe from '@ducks/campanhaDetalhe';
import campanhasCadastro from '@ducks/campanhasCadastro';
import campanhaImportacaoResultadosExcel from '@ducks/campanhaImportacaoResultadosExcel';

import configuracoesUnidadesAgrupadores from '@ducks/configuracoesUnidadesAgrupadores';
import configuracoesUnidadesCamposCustomizaveis from '@ducks/configuracoesUnidadesCamposCustomizaveis';
import configuracoesUnidadesCamposDinamicos from '@ducks/configuracoesUnidadesCamposDinamicos';
import configuracoesUnidadesClusters from '@ducks/configuracoesUnidadesClusters';
import configuracoesUnidadesTiposUnidades from '@ducks/configuracoesUnidadesTiposUnidades';
import configuracoesUnidadesIdApiDuplicados from '@ducks/configuracoesUnidadesIdApiDuplicados';

import configuracoesCampanhasTipos from '@ducks/configuracoesCampanhasTipos';
import configuracoesCampanhasAgrupadores from '@ducks/configuracoesCampanhasAgrupadores';

import configuracoesReportPerfis from '@ducks/configuracoesReportPerfis';

import configuracoesAtividadesTipos from '@ducks/configuracoesAtividadesTipos';
import configuracoesMetaAtividades from '@ducks/configuracoesMetaAtividades';
import configuracoesWhitelabel from '@ducks/configuracoesWhitelabel';

import userRemoteness from '@ducks/userRemoteness';

import checklistTemplates from '@ducks/checklistTemplates';

import pedidos from '@ducks/pedidos';
import pedidosDashboard from '@ducks/pedidosDashboard';
import ordersKinds from '@ducks/settingsOrdersOrdersKinds';

import produtos from '@ducks/produtos';
import families from '@ducks/settingsProductsFamilies';
import categories from '@ducks/settingsProductsCategories';
import priceTables from '@ducks/settingsProductsPriceTables';
import extraFields from '@ducks/settingsProductsExtraFields';

import integracoes from '@ducks/integracoes';

import gestaoCarteira from '@ducks/gestaoCarteira';

import atividadesChecklists from '@ducks/atividadesChecklists';
import atividadesGestaoAgenda from '@ducks/atividadesGestaoAgenda';
import atividadesCheckInOut from '@ducks/atividadesCheckInOut';

import metas from '@ducks/metas';
import activitiesGoals from '@ducks/activitiesGoals';

import companyImport from '@ducks/companyImport';

import companyDelinquency from '@ducks/companyDelinquency';

import companyDelinquencyConfiguration from '@ducks/companyDelinquencyConfiguration';

import pendingGeolocationCompanies from '@ducks/pendingGeolocationCompanies';

import contentConfiguration from '@ducks/contentConfig';

import configuracoesVoip from '@ducks/configuracoesVoip';
import voip from '@ducks/voip';
import whatsapp from '@ducks/whatsapp';
import notificacoesWhatsApp from '@ducks/notificacoesWhatsApp';

import callActivity from '@ducks/callActivity';
import settingsClassificationContacts from '@ducks/settingsClassificationContacts';

import configuracoesAgrupadoresMetasAtividades from '@ducks/configuracoesAgrupadoresMetasAtividades';

import configuracoesUsuariosPerfis from './ducks/configuracoesUsuariosPerfis';

const Reducers = combineReducers({
  app,
  login,
  notificacoes,
  perfil,
  alterarSenha,
  recuperarSenha,
  wikis,
  wikisAcoes,
  wikisAudiencia,
  wikisEditor,
  wikisPersonalizacao,
  wikisCadastro,
  wikisComentario,
  wikisComentarioAcao,
  comunicados,
  wikisEAD,
  wikisEADQuiz,
  wikisEADTrilhas,
  wikisEADTrilha,
  chamadosTickets,
  chamadosCadastro,
  chamadosHistorico,
  chamadosDashboard,
  chamadosBriefingReply,
  gestaoEquipes,
  atividades,
  checklists,
  agenda,
  unidades,
  contatos,
  report,
  configuracoesChamados,
  configuracoesChamadosCategoria,
  configuracoesChamadosAtendimento,
  configuracoesGestaoEquipe,
  configuracoesChamadosGeral,
  configuracoesUsuarios,
  configuracoesUsuariosAtributo,
  configuracoesTelasNomenclaturas,
  afastamento,
  home,
  novidades,
  campanhaLista,
  campanhaDetalhe,
  campanhasCadastro,
  campanhaImportacaoResultadosExcel,
  configuracoesUnidadesAgrupadores,
  configuracoesUnidadesCamposCustomizaveis,
  configuracoesUnidadesCamposDinamicos,
  configuracoesUnidadesClusters,
  configuracoesUnidadesTiposUnidades,
  configuracoesUnidadesIdApiDuplicados,
  configuracoesCampanhasTipos,
  configuracoesCampanhasAgrupadores,
  configuracoesReportPerfis,
  configuracoesAtividadesTipos,
  configuracoesWhitelabel,
  userRemoteness,
  checklistTemplates,
  pedidos,
  produtos,
  families,
  categories,
  priceTables,
  extraFields,
  ordersKinds,
  integracoes,
  chamadosEtapas,
  gestaoCarteira,
  classificacoes,
  atividadesChecklists,
  configuracoesLogoImpressao,
  configuracoesAtendimento,
  pedidosDashboard,
  atividadesGestaoAgenda,
  configuracoesMetaAtividades,
  atividadesCheckInOut,
  metas,
  activitiesGoals,
  companyImport,
  companyDelinquency,
  companyDelinquencyConfiguration,
  pendingGeolocationCompanies,
  contentConfiguration,
  configuracoesVoip,
  voip,
  whatsapp,
  notificacoesWhatsApp,
  callActivity,
  settingsClassificationContacts,
  configuracoesAgrupadoresMetasAtividades,
  configuracoesUsuariosPerfis,
  chamadosKanban,
});

export default Reducers;
