import React, { useState } from 'react';
import { Column, Row, Form } from '@components-teammove';
import { postFinishActivityChat } from '@ducks/whatsapp';
import { 
  IconContainer, IconFeedback, TextFeedback, TitleText,
  ObservationBox, FormContainer, ButtonFooter, RowButton,
  SubTitle, Title
} from './styles';
import { useDispatch } from 'react-redux';

const Classification = ({ info, classification: propClassification, setClassification: propSetClassification, activityId, userId }) => {
  const { number, name } = info;
  const [form] = Form.useForm();
  const [localClassification, setLocalClassification] = useState(null);

  const dispatch = useDispatch();
  const classification = propClassification ?? localClassification;
  const setClassification = propSetClassification ?? setLocalClassification;

  const options = [
    { id: 'Relevante', label: 'Relevante', borderColor: 'green', iconColor: 'green', iconType: 'thumbs-up' },
    { id: 'Nao_Relevante', label: 'Não relevante', borderColor: 'red', iconColor: 'red', iconType: 'thumbs-down' },
    { id: 'Sem_Contato', label: 'Sem contato', borderColor: 'white', iconType: 'user-x' }
  ];

  const onFinish = () => {
    const values = form.getFieldsValue();
    const allValuesToSend = {
      activityId,
      userId,
      classificationChat: classification,
      notesChat: values.notes,
    };
    dispatch(postFinishActivityChat(allValuesToSend));
  };
  
  return (
    <Column gap='20px' align='center' justify='center' width='100%'>
      <Title marginTitle>{name}</Title>
      <SubTitle>{number}</SubTitle>
      <TitleText>Classifique a importância dessa conversa</TitleText>

      {!classification ? (
        <Row gap='47px' justify='center' align='center'>
          {options.map((option) => (
            <Column key={option.id} gap='7px' justify='center' align='center'>
              <IconContainer onClick={() => setClassification(option.id)}>
                <IconFeedback colorIcon={option.iconColor} type={option.iconType} />
              </IconContainer>
              <TextFeedback>{option.label}</TextFeedback>
            </Column>
          ))}
        </Row>
      ) : (
        <Column gap='7px' justify='center' align='center'>
          <IconContainer
            onClick={() => setClassification(null)}
            borderColor={options.find((option) => option.id === classification).borderColor}
          >
            <IconFeedback
              colorIcon={options.find((option) => option.id === classification).iconColor}
              type={options.find((option) => option.id === classification).iconType}
            />
          </IconContainer>
          <TextFeedback>{options.find((option) => option.id === classification).label}</TextFeedback>
        </Column>
      )}

      <FormContainer form={form} layout='vertical'>
        <Form.Item name='notes' label='Observações'>
          <ObservationBox placeholder='Escreva suas observações aqui' />
        </Form.Item>
      </FormContainer>

      <RowButton gap='20px' align='center' justify='center' width='100%'>
        <ButtonFooter onClick={onFinish} disabled={!classification}>Finalizar</ButtonFooter>
      </RowButton>
    </Column>
  );
};

export default Classification;
