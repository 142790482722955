import React, { useEffect, useMemo, useState } from 'react';
import App from '@app';
import { Body, PageHeader, Breadcrumb, Button, Form, Column, Grid, Select, Input, InputNumber, Skeleton, Modal, Text } from '@components-teammove';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Container, DeleteButton, DeleteIcon, FillHeightButton, TableHeaderLabel } from './styles';
import { getUnidades } from '@sdk/Unidades';
import { statusOptions } from '../rules';
import ProductSelection from './ProductSelection';
import { getProducts } from '@ducks/produtos';
import { postOrder, putOrder, getOrderRegister , putStatus, resetSuccessSave , resetRegister } from '@ducks/pedidos';
import { updateOrderItemsFromCategoriesWithProducts } from './ProductSelection/rules';
import { formatCurrency, TelasNomenclaturas, formatDate } from '@utils';
import { getRemotenessesListByUser } from '../../../store/ducks/userRemoteness';

export default function OrderRegister() {
  const { id } = useParams();
  const history = useHistory();

  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const theme = useSelector(({ app }) => app.get('theme'));

  const orderRegister = useSelector(({ pedidos }) => pedidos.get('orderRegister'));
  const successOrderRegister = useSelector(({ pedidos }) => pedidos.get('successOrderRegister'));
  const successSave = useSelector(({ pedidos }) => pedidos.get('successSave'));
  const loadingSave = useSelector(({ pedidos }) => pedidos.get('loadingSave'));
  const successStatusSave = useSelector(({ pedidos }) => pedidos.get('successStatusSave'));
  const loadingStatusSave = useSelector(({ pedidos }) => pedidos.get('loadingStatusSave'));
  const categoriesWithProducts = useSelector(({ produtos }) => produtos.get('categoriesWithProducts'));
  const successProducts = useSelector(({ produtos }) => produtos.get('successProducts'));

  const userRemotenessesList = useSelector(({ userRemoteness }) => userRemoteness.get('userRemotenessesList'));
  const userRemotenessesListTotal = useSelector(({ userRemoteness }) => userRemoteness.get('userRemotenessesListTotal'));
  const loadingUserRemotenessesList = useSelector(({ userRemoteness }) => userRemoteness.get('loadingUserRemotenessesList'));

  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [products, setProducts] = useState([]);
  const [visibleProductSelection, setVisibleProductSelection] = useState(false);

  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  const hasProducts = useMemo(() => products.length > 0, [products]);

  useEffect(() => {
    dispatch(getRemotenessesListByUser());

    if (id !== '-1') {
      dispatch(getOrderRegister(id));
    }
    (async () => {
      const companies = await getUnidades();
      setCompanies(companies.map((company) => ({ ...company, label: company.title })));
    })();

    return () => {
      dispatch(resetSuccessSave());
      dispatch(resetRegister());
    };
  }, []);

  useEffect(() => {
    if (companies && companies.length === 1 && id === '-1') {
      form.setFieldsValue({ companyId: companies[0].key });
      setSelectedCompany(companies[0].key);
    }
  }, [companies]);

  useEffect(() => {
    if (successOrderRegister) {
      const productsQuantities = orderRegister.items.reduce((obj, product) => ({ ...obj, [`product${product.productId}-quantity`]: product.quantity }), {});
      form.setFieldsValue({ ...orderRegister, ...productsQuantities });
      setSelectedCompany(orderRegister.companyId);
      setSelectedStatus(orderRegister.status);
    }
  }, [successOrderRegister]);
  
  useEffect(() => {
    if (selectedCompany || selectedCompany === 0) {
      if (successOrderRegister) {
        setProducts(orderRegister.items.map((product) => ({ itemId: product.id, id: product.productId, name: product.productName, quantity: product.quantity, price: product.productPrice, weight: product.productWeight })));
      }
      dispatch(getProducts(selectedCompany));
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (successProducts && !orderRegister) {
      setProducts(updateOrderItemsFromCategoriesWithProducts(products, categoriesWithProducts));
    }
  }, [successProducts]);

  useEffect(() => {
    if (successSave || successStatusSave) {
      history.goBack();
    }
  }, [successSave, successStatusSave]);

  const handleItemDelete = (id) => {
    setProducts(products.filter((product) => product.id !== id));
  };
  
  const handleOrderFinish = () => {
    dispatch(putStatus({ orderId: parseInt(id), newStatus: 'ENTREGUE' }));
  };

  const handleOrderSave = () => {
    form.validateFields().then((values) => {
      Object.keys(values).forEach((key) => {
        if (key.includes('product')) {
          delete values[key];
        }
      });

      values = {
        ...values, 
        createdAt: new Date(values.createdAt?.valueOf()),
        dueDate: new Date(values.dueDate?.valueOf()),
        items: products.map(({ itemId, id, quantity, price, weight }) => ({ id: itemId, productId: id, quantity, productPrice: price, productWeight: weight }))
      };

      if (id === '-1') {
        dispatch(postOrder(values));
      } else {
        dispatch(putOrder(values));
      }
    }).catch(Form.scrollToFirstError);
  };

  const customValidator = (rule, value) => {
    if (parseFloat(String(value).replaceAll(',', '.')) < 1) {
      return Promise.reject(new Error('O limite mínimo para esta resposta é de ' + 1));
    }
    return Promise.resolve();
  };

  return(
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title={id === '-1' ? 'Novo pedido' : 'Editar pedido'}
          onBack={() => history.goBack()}
          breadcrumb={
            <Breadcrumb 
              items={[
                { path: '/', name: 'Home' }, 
                { path: '/pedidos', name: 'Pedidos' },
                { path: '/pedidos/' + id, name: 'Cadastro', current: true },
              ]}
            />
          }
          extra={[
            <Button
              visible={id !== '-1' && selectedStatus !== 'ENTREGUE'} loading={loadingStatusSave} key='finish' size='small' context='header' type='secondary' disabled={!hasProducts} title={!hasProducts && 'Adicione ao menos um produto ao pedido'}
              onClick={handleOrderFinish}
            >Pedido entregue</Button>,
            <Button key='save' loading={loadingSave} size='small' context='header' type='primary' disabled={!hasProducts} title={!hasProducts && 'Adicione ao menos um produto ao pedido'} onClick={handleOrderSave}>Salvar pedido</Button>,
          ]}
        >
          {loadingUserRemotenessesList ? (
            <Skeleton.Form/>
          ) : userRemotenessesListTotal > 0 ? (
            <Modal visible footer={null} onCancel={() => history.goBack()}>
              <Text>
                Esta página está bloqueada por conta de um ou mais afastamentos ativos ({
                  userRemotenessesList.reduce((joinedReasons, userRemoteness, index) => joinedReasons + (index > 0 ? ', ' : '') + userRemoteness.reason, '')
                }). Ela voltará a estar disponível em {
                  formatDate(
                    userRemotenessesList.reduce((maxUserRemotenessEndDate, userRemoteness) => {
                      const userEndDate = new Date(userRemoteness.end);
                      return userEndDate > maxUserRemotenessEndDate ? userEndDate : maxUserRemotenessEndDate;
                    }, new Date())
                  )
                }.
              </Text>
            </Modal>
          ) : (
            <Container>
              <Form layout='vertical' form={form}>
                <Form.Item name='id' hidden/>
                <Form.Item name='createdAt' hidden/>
                <Form.Item name='dueDate' hidden/>
                <Column gap='30px'>
                  <Grid gap='20px' templateColumns={(id === '-1' ? '.88fr' : '.66fr .22fr') + ' .12fr'}>
                    <Form.Item name='companyId' label={companyNomenclature.nomenclatura} rules={[{ required: true, message: `Insira ${(companyNomenclature.artigo) === 'a' ? 'uma' : 'um'} ${(companyNomenclature.nomenclatura).toLowerCase()}` }]}>
                      <Select 
                        placeholder={'Selecione ' + ((companyNomenclature.artigo) === 'a' ? 'uma' : 'um') + ' ' + (companyNomenclature.nomenclatura).toLowerCase()} 
                        action={getUnidades} 
                        optionLabelProp='title'
                        onChange={(company) => setSelectedCompany(company)}
                      />
                    </Form.Item>
                    {id !== '-1' && (
                      <Form.Item name='status' label='Status do pedido' rules={[{ required: true, message: 'Insira um status' }]}>
                        <Select 
                          placeholder='Selecionar status'
                          options={statusOptions} 
                          onChange={(status) => setSelectedStatus(status)}
                        />
                      </Form.Item>
                    )} 
                  </Grid>
                  {products.length > 0 && (
                    <Column>
                      <Grid gap='20px' align='start' templateColumns='.55fr .11fr .11fr .11fr .12fr'>
                        <TableHeaderLabel>Produto</TableHeaderLabel>
                        <TableHeaderLabel>Peso</TableHeaderLabel>
                        <TableHeaderLabel>Quantidade</TableHeaderLabel>
                        <TableHeaderLabel>Valor unitário</TableHeaderLabel>
                        <div/>
                      </Grid>
                      <Grid gap='20px' align='start' templateColumns='.55fr .11fr .11fr .11fr .12fr'>
                        {products.map(({ itemId, id, name, weight, quantity, price }) => (
                          <>
                            <Form.Item marginless>
                              <Input value={name} disabled/>
                            </Form.Item>
                            <Form.Item marginless>
                              <InputNumber precision={2} value={(weight || '0') + ' Kg'}  disabled/>
                            </Form.Item>
                            <Form.Item 
                              name={`product${id}-quantity`}  
                              marginless
                              rules={
                                [
                                  { required: true, message: 'Resposta obrigatória' },
                                  (({ getFieldValue }) => ({ validator: customValidator }))
                                ]
                              }
                            >
                              <InputNumber precision={0} onChange={(value) => setProducts(products.map((product) => product.id === id ? ({ ...product, quantity: value }) : ({ ...product })))}/>
                            </Form.Item>
                            <Form.Item marginless>
                              <Input value={formatCurrency(price)} disabled/>
                            </Form.Item>
                            <Form.Item>
                              <DeleteButton
                                title='Deseja remover este produto?'
                                onConfirm={() => handleItemDelete(id)}
                              >
                                <FillHeightButton type='danger' transparent bordered coloredContent icon={<DeleteIcon type='fi-trash2'/>}/>
                              </DeleteButton>
                            </Form.Item>
                            
                          </>
                        ))}
                      </Grid>
                    </Column>
                  )}
                  <Column>
                    <Grid gap='20px' align='start' templateColumns='.55fr .11fr .11fr .11fr .12fr'>
                      <div/>
                      <TableHeaderLabel>Peso total</TableHeaderLabel>
                      <TableHeaderLabel>Quantidade</TableHeaderLabel>
                      <TableHeaderLabel>Total</TableHeaderLabel>
                      <div/>
                    </Grid>
                    <Grid gap='20px' align='end' templateColumns='.55fr .11fr .11fr .11fr .12fr'>
                      <FillHeightButton type='secondary' disabled={!selectedCompany && selectedCompany !== 0} title={!selectedCompany && `Insira a ${(companyNomenclature.nomenclatura).toLowerCase()} para poder adicionar produtos`} onClick={() => setVisibleProductSelection(true)} fillWidth>+ Adicionar produto</FillHeightButton>
                      <Form.Item marginless>
                        <InputNumber precision={2} value={products?.reduce((total, { weight, quantity }) => total + (weight * quantity), 0)?.toFixed(2) + ' Kg'} disabled/>
                      </Form.Item>
                      <Form.Item marginless>
                        <InputNumber value={products?.reduce((total, product) => total + (parseInt(product.quantity) || 0), 0)} disabled/>
                      </Form.Item>
                      <Form.Item marginless>
                        <Input value={formatCurrency(products?.reduce((total, { price, quantity }) => total + (price * quantity), 0))} disabled/>
                      </Form.Item>
                    </Grid>
                  </Column>
                </Column>
              </Form>
            </Container>
          )}

          {visibleProductSelection && (
            <ProductSelection
              visible={visibleProductSelection}
              onCancel={() => setVisibleProductSelection(false)}
              selectedProducts={products}
              onOk={(selectedProducts) => {
                setProducts(selectedProducts.map((product) => {
                  const foundProduct = products.find(({ id }) => id === product.id);
                  return { ...foundProduct, ...product, quantity: foundProduct?.quantity || 0 };
                }));
                form.setFieldsValue(selectedProducts.reduce((obj, product) => ({ ...obj, [`product${product.id}-quantity`]: products.find(({ id }) => id === product.id)?.quantity || 0 }), {}));
              }}
            />
          )}
        </PageHeader>
      </Body>
    </App>
  );
}