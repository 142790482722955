import styled from 'styled-components';
import { Collapse, Grid } from '@components-teammove';

export const StyledCollapse = styled(Collapse)`
  width: 100% !important;
  border: none !important;
  background: none !important;
  margin-bottom: 16px !important;

  .ant-collapse-arrow {
    width: 24px !important;
    height: 24px !important;
    transition: all 0.25s !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    user-select: none !important;

    svg {
      color: ${({ theme }) => theme.textColor} !important;
      width: 1rem !important;
      height: 1rem !important;
      stroke: ${({ theme }) => theme.textColor} !important;
      opacity: .5;
      fill: currentColor !important;
      stroke-width: 2 !important;
      stroke-linecap: round !important;
      stroke-linejoin: round !important;
    }
  }

  &.ant-collapse {
    border: none !important;
    background: none !important;
  }

  .ant-collapse-item {
    border: none !important;
    background: none !important;
    margin: 0 !important;
    padding: 0 !important;
    
    .ant-collapse-header {
      padding: 12px 16px !important;
      background-color: ${({ theme }) => theme.darkPrimaryColor} !important;
      color: ${({ theme }) => theme.textColor} !important;
      border: 2px solid ${({ theme }) => theme.darkHighlightColor} !important;
      border-radius: 8px !important;
      display: flex !important;
      align-items: center !important;
      width: 100% !important;
      padding-left: 16px !important;

      .ant-collapse-header-text {
        text-align: left !important;
        margin-right: auto !important;
        font-size: 16px !important;
        font-family: Open Sans;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0%;
      }

      .ant-collapse-expand-icon {
        order: 2 !important;
        margin-left: auto !important;
        color: ${({ theme }) => theme.textColor} !important;
        width: 24px !important;
        height: 24px !important;
        opacity: 0.5 !important;
        transition: all 0.25s !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
      }
    }

    &.ant-collapse-item-active {
      .ant-collapse-header {
        border-radius: 8px 8px 0 0 !important;
        border-bottom: none !important;
      }

      .ant-collapse-content {
        border-radius: 0 0 8px 8px !important;
      }
    }

    &:hover .ant-collapse-header {
      border-color: ${({ theme }) => theme.actionColor} !important;
    }

    &:hover .ant-collapse-content {
      border-color: ${({ theme }) => theme.actionColor} !important;
    }
  }
  
  .ant-collapse-content {
    background-color: ${({ theme }) => theme.darkBackgroundColor} !important;
    border: 2px solid ${({ theme }) => theme.darkHighlightColor} !important;
    border-top: none !important;
    border-radius: 0 0 8px 8px !important;
    
    .ant-collapse-content-box {
      padding: 16px 20px !important;
      max-height: 300px;
      overflow-y: auto;
    }
  }

  .ant-checkbox-wrapper {
    color: ${({ theme }) => theme.textColor} !important;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }

  .ant-checkbox {
    .ant-checkbox-inner {
      background-color: ${({ theme }) => theme.darkPrimaryColor} !important;
      border-color: ${({ theme }) => theme.darkHighlightColor} !important;
    }

    &:hover .ant-checkbox-inner {
      border-color: ${({ theme }) => theme.actionColor} !important;
    }
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: ${({ theme }) => theme.actionColor} !important;
      border-color: ${({ theme }) => theme.actionColor} !important;
    }
  }
`;

export const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;
`;

export const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  margin-bottom: 8px;

  .column-header {
    color: ${({ theme }) => theme.textColor};
    opacity: 0.6;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0%;
  }
`;

export const ActivityRow = styled(Grid)`
  gap: 32px;
  margin-left: 10px;
`;