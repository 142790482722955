import { View, Form, Button, Divider , Icon, Row } from '@components-teammove';

import styled from 'styled-components';

export const FlexContainer = styled(View)`
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;

export const FlexContainerWithMargin = styled(FlexContainer)`
  margin-bottom: 1rem;
`;  

export const CheckboxContainer = styled(FlexContainer)`
  align-items: center;
  gap: 10px;
`;

export const CheckboxesContainer = styled(Row)`
  margin-bottom: .25rem;
  gap: 10px;
`;  

export const DeleteIcon = styled(Icon.MDI)`
  cursor: pointer;
  margin-left: auto;

  path{
    fill: #A6A6A6 !important;
  }
`;

export const FlexFormItem = styled(Form.Item)`
  margin-bottom: 0;
  
  .ant-form-item-control{
    flex: 1;
  }
`;

export const FixedLabelFormItem = styled(Form.Item)`
  margin-bottom: 1rem;

  .ant-form-item-label{
    padding: 0;
  }
`;

export const ColFormItem = styled(FlexFormItem)`
  flex: 1;
`;

export const MarginLeft = styled(CheckboxContainer)`
  margin-left: .5rem;
`;

export const CenteredButton = styled(Button)`
  margin: 1.25rem auto 0;

  .anticon{
    font-size: 12px;
  }
`;

export const FieldContainer = styled(View)`
  margin-left: 0;
  gap: 12px;
`;

export const FieldTitle = styled.span`
  font-size: 20px;
  font-weight: 400;
  color: ${({ theme }) => theme.textColor};
  opacity: .5;
`;

export const ColoredDivider = styled(Divider)`
  border-color: ${({ theme }) => theme.textColor};
  opacity: .5;
`;