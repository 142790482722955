import styled from 'styled-components';
import { Icon } from '@components-teammove';

export const ActivityTypeIconWrapper = styled.div`
  display: flex;
  border-radius: 8px;
  background-color: ${({ color }) => color};
  padding: ${({ size }) => size === 'small' ? '8px' : '16px'};
`;

export const ActivityTypeIcon = styled(Icon.MDI)`
  color: ${({ theme }) => theme.textColor};
`;