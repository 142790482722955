import { dateToAnsi, DATE_FORMATS, InfoUsuario } from '@utils';

export const queryParamsKanban = (filtros, search, limit, count) => {
  if (!filtros) return '';
  
  const query = [];
  const { unidade, categoria, responsavel, perfil, status, usuarioCriacao, dataCriacao, avaliacao, meusTickets } = filtros;

  if ((dataCriacao) && (dataCriacao.length > 0)) {
    query.push(`creationDateFrom=${dateToAnsi(dataCriacao[0], null, DATE_FORMATS.ANSI_DATE)}`);
    query.push(`creationDateTo=${dateToAnsi(dataCriacao[1], null, DATE_FORMATS.ANSI_DATE)}`);
  }
  if ((unidade) && (unidade.length > 0)) query.push(`companies=${unidade.join('+')}`);
  if ((categoria) && (categoria.length > 0)) query.push(`categories=${categoria.join('+')}`);
  if ((responsavel) && (responsavel.length > 0)) query.push(`assignees=${responsavel.join('+')}`);
  if ((perfil) && (perfil.length > 0)) query.push(`profiles=${perfil.join('+')}`);
  if ((status) && (status.length > 0)) query.push(`status=${status.join('+')}`);
  if ((usuarioCriacao) && (usuarioCriacao.length > 0)) query.push(`creationUsers=${usuarioCriacao.join('+')}`);
  if ((avaliacao) && (avaliacao.length > 0)) query.push(`evaluation=${avaliacao.join('+')}`);
  if (meusTickets) query.push(`onlyLoggedUserTickets=${meusTickets}+`);

  if (limit) query.push(`limit=${limit}`);
  if (search) query.push(`search=${search}`);

  query.push(`loggedUser=${InfoUsuario.get('cdUsuario')}`);

  if (query.length === 0) return '';
  return '?' + query.join('&');
};