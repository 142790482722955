import React from 'react';
import { Column, Row, Icon, PopConfirm, View, Dropdown, Menu, Button } from '@components-teammove';
import { TitleText } from '../AcoesEstrategicasChecklist/styles';
import { optionTypeActivity } from '../NovaPergunta/rules';
import { Card } from '../styles';
import { IconQuestionType } from './rules';
import { AbsoluteDiv, AddAskButton, ColumnContainer, ColumnStyled, ConditionalText, 
  ContainerAsk, ContainerButton, ContainerConditional,ContainerWeight, DeleteIcon, 
  EditIcon, HeaderGroupColored, HoverableContainer, IconTypeFeather, IconTypeMdi,
  MenuButton, QuestionActionButton, RowContainerTitle, TitleAsk, TypeAsk, WeightText 
} from './styles';

const GruposChecklist = ({ groups, setVisibleAddAsk, handleEditGroup, setGroupId, handleDeleteGroup, handleDeleteQuestion, handleEditQuestion, handleReorderGroup, handleReorderQuestion, handleCopyQuestion }) => (
  groups.sort((a, b) => a.ordem - b.ordem).map((group, index) => {
    const grupoPeso = group.perguntas.reduce((acc, pergunta) => acc + (pergunta.peso || 0), 0);
    return (
      <ColumnContainer key={group.id || group.temp_id} width='100%'>
        <HeaderGroupColored firstGroup={index === 0}/>
        <Card hasGroupsToRender={groups} >
          <RowContainerTitle justify='space-between' width='100%' align='center'>
            <TitleText>{group.nome}</TitleText>
            <Row gap='18px' align='center'>        
              {grupoPeso > 0 && (
                <ContainerWeight headerWeightInfo>
                  <WeightText>Peso: {grupoPeso}</WeightText>
                </ContainerWeight>
              )}
              <EditIcon
                type='edit-3' onClick={() => handleEditGroup(group.nome, (group.id || group.temp_id))}
              />       
              {index > 0 && (
                <PopConfirm
                  title={
                    <span>
                      {group.perguntas.length > 0 
                        ? (
                          <>
                    Ao excluir o grupo, todas as perguntas serão excluídas.<br />
                    Deseja continuar?
                          </>
                        )
                        : 'Deseja realmente excluir este grupo?'}
                    </span>
                  }
                  onConfirm={() => handleDeleteGroup(group.id || group.temp_id)}
                  onCancel={null}
                  okText="Excluir grupo"
                  cancelText="Cancelar"
                >
                  <DeleteIcon type='trash'/>
                </PopConfirm>
              )}
            </Row>
          </RowContainerTitle>
          {group.perguntas && group.perguntas.length > 0 && group.perguntas
            .sort((a, b) => a.ordem - b.ordem)
            .map((pergunta, index) => {
              const previousQuestion = index > 0 ? group.perguntas[index - 1] : null;
              const firstQuestion = group.perguntas[0];
              const isFirstQuestionConditional = firstQuestion.idPerguntaPai;
              const isCurrentQuestionParentSameAsFirst = firstQuestion.idPerguntaPai === pergunta.idPerguntaPai;              
              const shouldRenderConditionalContainer = (!previousQuestion || previousQuestion.idPerguntaPai !== pergunta.idPerguntaPai || previousQuestion.idOpcaoPerguntaPai !== pergunta.idOpcaoPerguntaPai) && pergunta.idPerguntaPai;
              const nextQuestionIsConditional = group.perguntas[index + 1] && group.perguntas[index + 1].idPerguntaPai;
              const differentParentThanPreviousQuestion = previousQuestion && previousQuestion.idPerguntaPai !== pergunta.idPerguntaPai;
              const tipoSelecionadoFormatado = optionTypeActivity.find((option) => option.value === pergunta.tipo)?.label;

              const hasMargin = !(isFirstQuestionConditional && isCurrentQuestionParentSameAsFirst); 
              return (
                <ColumnStyled width='100%' key={pergunta.id || pergunta.temp_id}>
                  {shouldRenderConditionalContainer && (
                    <ContainerConditional hasMargin={index !== 0}>
                      <ConditionalText>Condicional {pergunta.nomePerguntaPai} - {pergunta.nomeOpcaoPerguntaPai}</ConditionalText>
                    </ContainerConditional>
                  )}
                  {pergunta.idPerguntaPai && <AbsoluteDiv hasMargin={hasMargin}/>}
                  <ContainerAsk
                    onClick={(e) =>{
                      e.preventDefault(); 
                      e.stopPropagation();
                      handleEditQuestion(pergunta);
                      setGroupId(group.id || group.temp_id);
                    }} 
                    isFirstIndex={index === 0}
                    isConditional={pergunta.idPerguntaPai}
                    nextQuestionIsConditional={nextQuestionIsConditional}
                    renderConditionalTag={shouldRenderConditionalContainer}
                    differentParentThanPreviousQuestion={differentParentThanPreviousQuestion}
                  >
                    <Row width='100%' align='center' justify='space-between'>
                      <Column>
                        <Row gap='4px'>
                          <TitleAsk>{index + 1}</TitleAsk>
                          <TitleAsk>-</TitleAsk>
                          <TitleAsk title>{pergunta.titulo}</TitleAsk>
                        </Row>
                        <Row gap='4px' align='center'>
                          {pergunta.tipo !== 'NUMERICO' ? (
                            <IconTypeFeather type={IconQuestionType(pergunta.tipo)} />
                          ) : (
                            <IconTypeMdi type='numeric' />
                          )}
                          <TypeAsk>{tipoSelecionadoFormatado || pergunta.tipo || ''}</TypeAsk>
                        </Row>
                      </Column>
                      <Row gap='12px' align='center'>
                        {pergunta.peso !== null && pergunta.peso > 0 && (
                          <ContainerWeight>
                            <WeightText>Peso: {pergunta.peso}</WeightText>
                          </ContainerWeight>
                        )}
                        <QuestionActionButton 
                          type='tertiary' 
                          size='small' 
                          icon={<Icon.UpOutlined />} 
                          disabled={index === 0}  
                          onClick={(e) => { 
                            e.stopPropagation();
                            handleReorderQuestion((group.id || group.temp_id), pergunta.ordem, pergunta.ordem - 1); 
                          }}
                        />
                        <QuestionActionButton 
                          type='tertiary' 
                          size='small' 
                          icon={<Icon.DownOutlined />} 
                          disabled={index === group.perguntas.length - 1} 
                          onClick={(e) => {
                            e.stopPropagation();
                            handleReorderQuestion((group.id || group.temp_id), pergunta.ordem, pergunta.ordem + 1);
                          }}
                        />
                        <View
                          onClick={(e) => (e.stopPropagation())}
                        >
                          <Dropdown
                            key="more"
                            overlay={(
                              <Menu
                                zIndexPopup={100}
                              >                     
                                <Menu.Item
                                  onClick={(e) => { e.domEvent.stopPropagation(); handleCopyQuestion(pergunta, (group.id || group.temp_id)); }} 
                                >
                                  <MenuButton type="icon" icon={<Icon.CopyOutlined/>}>
                                  Copiar pergunta
                                  </MenuButton>               
                                </Menu.Item>
          
                                <Menu.Item
                                  onClick={(e) => { e.domEvent.stopPropagation(); handleEditQuestion(pergunta); setGroupId(group.id || group.temp_id); }} 
                                >
                                  <MenuButton type="icon" icon={<Icon.EditOutlined/>}>
                                  Editar pergunta
                                  </MenuButton>                    
                                </Menu.Item>
          
                                <ContainerButton>
                                  <HoverableContainer>
                                    <PopConfirm
                                      title={`Deseja realmente excluir a pergunta ${pergunta.titulo}?`}
                                      onConfirm={(e) => {
                                        e.stopPropagation();
                                        handleDeleteQuestion(pergunta, group);
                                      }}
                                      onCancel={(e) => {
                                        e.stopPropagation();
                                      }}
                                      okText="Excluir pergunta"
                                      cancelText="Cancelar"
                                      zIndex={1050}
                                    >
                                      <MenuButton onClick={(e) => e.stopPropagation()} type="icon" icon={<Icon.DeleteOutlined/>}>
                                        Excluir pergunta
                                      </MenuButton>    
                                    </PopConfirm>  
                                  </HoverableContainer>  
                                </ContainerButton>                                      
                              </Menu> 
                            )}
                          >
                            <Button type="icon">
                              <Icon.MDI type="dots-vertical" />
                            </Button>
                          </Dropdown>
                        </View>   
                      </Row>
                    </Row>
                  </ContainerAsk>
                </ColumnStyled>
              );
            })}
          <Row align='center' justify='space-between' width='100%'>
            <AddAskButton
              size='small'
              type="primary"
              onClick={() => {
                setVisibleAddAsk(true);
                setGroupId(group.id || group.temp_id);
              }
              }
            >
          Adicionar pergunta
            </AddAskButton>
            {index > 0 && (
              <Row gap='12px' align='center'>
                <QuestionActionButton type='primary' size='small' icon={<Icon.UpOutlined />} disabled={index < 2}  onClick={() => handleReorderGroup(group.ordem, group.ordem - 1)}/>
                <QuestionActionButton type='primary' size='small' icon={<Icon.DownOutlined />} disabled={index === groups?.length - 1 || index === 0} onClick={() => handleReorderGroup(group.ordem, group.ordem + 1)}/>
              </Row>
            )}
          </Row>
        </Card>
      </ColumnContainer>
    );})
);

export default GruposChecklist;