import React, { ReactElement } from 'react';
import { Tag } from '@components-teammove';
import { TextClassificacao } from './styles';

const ClassificacoesTag = (
  { classification }: {
    classification: { id: number; chosenOptionColor: string; chosenOptionName: string };
  }
): ReactElement => {  
  return(
    <Tag small key={classification.id} bgColor={classification.chosenOptionColor}>
      <TextClassificacao>
        {classification.chosenOptionName}
      </TextClassificacao>
    </Tag>  
  );
};

export default ClassificacoesTag;