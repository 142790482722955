export const getDetailsHTML = (item) => {
  return (
    '<div style="line-height: 1.2;">' +
    '<b>' + (item?.nm_reduzido ? item?.nm_reduzido.toUpperCase() : '') + '</b> <br/>' +
    '<b>' + (item?.nm_empresa ? item?.nm_empresa.toUpperCase() : '') + '</b> <br/>' +
    (item?.endereco ? item?.endereco.toUpperCase() : '') + '<br>' +
    (item?.cidade ? item?.cidade.toUpperCase() : '') + ' - ' + (item?.estado ? item?.estado.toUpperCase() : '') +
    '</div>'
  );
};

const customFieldsMap = {
  'cnpj': 'cgc',
  'stateSubscription': 'inscricao_estadual',
  'socialName': 'razao_social',
  'telephone1': 'telefone',
  'telephone2': 'telefone_2',
  'cellphone1': 'celular',
  'cellphone2': 'celular_2',
  'licensed': 'licenciado',
  'measures': 'medidas_vitrine',
  'metric': 'metragem_total',
  'participation': 'participacao',
  'system': 'sistema',
  'location': 'localizacao',
  'project': 'tipo_projeto',
  'segment': 'segmento',
  'openingDate': 'inauguracao'
};

export const getQueryParamsUnidades = (filtros, includeQuestionMark = true) => {
  if (!filtros || typeof filtros !== 'object') return '';

  const queryParams = [];

  const customFieldsLookup = {};
  if (typeof customFieldsMap === 'object') {
    Object.entries(customFieldsMap).forEach(([key, value]) => {
      customFieldsLookup[value.toLowerCase()] = key;
    });
  }

  Object.entries(filtros).forEach(([key, value]) => {
    if (key === 'descFiltros') return;

    let paramKey = key;
    const keyLower = key.toLowerCase();

    if (customFieldsLookup[keyLower]) {
      paramKey = customFieldsLookup[keyLower];
    }

    if (Array.isArray(value)) {
      const validValues = value.filter((val) => val !== undefined && val !== null && val !== '');

      if (validValues.length > 0) {
        if (validValues.length > 1) {
          queryParams.push(`${paramKey}=${validValues.join(' ')}`);
        } else {
          queryParams.push(`${paramKey}=${validValues[0]}`);
        }
      }
    } else if (value !== undefined && value !== null && value !== '') {
      queryParams.push(`${paramKey}=${value}`);
    }
  });

  if (queryParams.length === 0) {
    return '';
  }

  const prefix = includeQuestionMark ? '?' : '&';
  return `${prefix}${queryParams.join('&')}`;
};