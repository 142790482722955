import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { InfoUsuario, useSearchParams } from '@utils';
import App from '@app';
import { Body, PageHeader, Table, SearchBar, Grid } from '@components-teammove';
import { getIndicadores } from '@sdk/Indicadores';
import { getAllUnidades } from '@sdk/Unidades';
import { DetailCard, MarginTopButton } from '../styles';
import { useSelector } from 'react-redux';
import { getValueFromHtml } from './rules';
import { StyledTable, TotalCell } from './styles';

const Detalhes = () => {
  const { idUnidade } = useParams();
  const { idVisualizacao } = useSearchParams();
  const history = useHistory();
  const user = InfoUsuario.get();
  const [detalhes, setDetalhes] = useState();
  const [unidade, setUnidade] = useState();
  const [detailsFiltered, setDetailsFiltered] = useState();
  const [search, setSearch] = useState({});

  const theme = useSelector(({ app }) => app.get('theme'));
  
  useEffect(() => {
    const unlisten = history.listen(() => window.location.reload());
    const getIndicadoresDetalhes = async () => {
      const detalhesAns = await getIndicadores(user, 'UNIDADE', parseInt(idUnidade), parseInt(idVisualizacao));
      setDetalhes(detalhesAns);
    };
    const getUnidade = async () => {
      const unitys = await getAllUnidades();
      setUnidade(unitys.find(({ cdEmpresa }) => cdEmpresa === parseInt(idUnidade)));
    };
    getIndicadoresDetalhes();
    getUnidade();

    return () => unlisten();
  }, []);

  useEffect(() => {
    if (!detalhes) return;
    const detailsInTables = detalhes?.filter((detail) => isDetailTable(detail));

    setDetailsFiltered(detailsInTables.map((detail) => {
      return {
        ordemGeral: detail.ordemGeral,
        rows: detail.resultado.registrosTabela.map(({ valores }) => valores.reduce((state, col) => ({ ...state, [col.coluna.nome]: col.valor }), {})),
      };
    }));
  }, [detalhes]);

  const isDetailTable = (detail) => detail.resultado && detail.resultado.registrosTabela.length > 0;

  const getDetailColumns = (detail) => {
    return detail.resultado.colunas.map((col) => ({
      align: 'center',
      colSpan: col.colspan,
      title: col.nome.replaceAll('&nbsp;', ''),
      sorter: (col.nome === '%') ? (a, b) => {
        return parseFloat(getValueFromHtml(a[col.nome])) > parseFloat(getValueFromHtml(b[col.nome])) ? 1 : -1;
      } : (col.nome === 'Mês') ? (a, b) => {
        return getValueFromHtml(a[col.nome]) > getValueFromHtml(b[col.nome]) ? 1 : -1;
      } : (col.typeName === 'currency') ? (a, b) => {
        return parseFloat(getValueFromHtml(a[col.nome]).substring(2)) > parseFloat(getValueFromHtml(b[col.nome]).substring(2)) ? 1 : -1;
      } : true,
      dataIndex: col.nome,
      render: (text) => text && <div dangerouslySetInnerHTML={{ __html: text }}/>
    }));
  };

  const handleSearch = (searchParam, ordemGeral) => {
    setSearch({ ...search, [ordemGeral]: searchParam });
  };

  const filterData = (dataSource, ordemGeral) => {
    if (search && search[ordemGeral]) {
      const searchUpperCase = search[ordemGeral].toUpperCase();
      return dataSource.filter((row) => Object.keys(row).some((key) => row[key]?.toUpperCase()?.includes(searchUpperCase)));
    } else {
      return dataSource;
    }
  };

  return(
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader title={unidade ? `${unidade.nmReduzido} - ${unidade?.razaoSocial}` : 'Carregando...' } onBack={() => history.goBack()}>
          {detalhes ? detalhes.filter((detalhe) => detalhe.resultado).map((detalhe) => (
            <DetailCard
              key={detalhe.ordemGeral}
              title={detalhe.visualizacao.nomeOriginal}
              extra={<div dangerouslySetInnerHTML={{ __html: detalhe.visualizacao.visualizacaoTexto?.expressaoCabecalho }}/>}
            >
              {isDetailTable(detalhe) ? (      
                <>
                  <SearchBar
                    placeholder="Pesquisar"  
                    initialValue={search && search[detalhe.ordemGeral]}
                    onSearch={(searchParam) => handleSearch(searchParam, detalhe.ordemGeral)}
                  />
                  <StyledTable 
                    columns={detailsFiltered ? getDetailColumns(detalhe) : []} 
                    dataSource={filterData(detailsFiltered?.find((detail) => detail.ordemGeral === detalhe.ordemGeral)?.rows, detalhe.ordemGeral)} 
                    rowKey='posicao'
                    loading={!detailsFiltered || !detalhes}
                    hidePagination
                    footer={detalhe.resultado.registrosTabelaTotal ? (() => (
                      <Grid numberOfColumns={4}>
                        {detalhe.resultado.registrosTabelaTotal.valores.map((valor) => (
                          <>
                            <TotalCell>
                              {valor.coluna.nome}
                            </TotalCell>
                            <div/>
                            <div/>
                            <TotalCell>
                              <div dangerouslySetInnerHTML={{ __html: valor.valor }}/>
                            </TotalCell>
                          </>
                        ))}
                      </Grid>
                    )) : null}
                  />
                </>
              ) : (
                <div dangerouslySetInnerHTML={{ __html: detalhe.retornoTexto }}/>
              )}
              {detalhe.possuiClick && <MarginTopButton onClick={() => history.push(`/unidades/${idUnidade}/detalhes?idVisualizacao=${detalhe.visualizacao.id}`)}>Ver Detalhes</MarginTopButton>}
            </DetailCard>
          )) : (
            <Table loading/>
          )}
        </PageHeader>
      </Body>
    </App>
  );
};

export default Detalhes;