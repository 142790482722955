import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import { Modal, DatePicker, Form, Row, Radio, Select, Input, Switch, View, Skeleton } from '@components-teammove';
import { getUsuarios } from '@sdk/Usuarios';
import { getPerfis } from '@sdk/Perfil';
import { putAfastamento, putAfastamentoReset } from '@ducks/afastamento';
import { ansiToMoment } from '@utils';

export default function AfastamentoCadastro({ visible, onCancel }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { state } = useLocation();

  const loading = useSelector(({ afastamento }) => afastamento.get('loadingPutAfastamento'));
  const success = useSelector(({ afastamento }) => afastamento.get('successPutAfastamento'));
  const history = useHistory();
  const initialValues = {
    ativo: true,
    ...state,
    inicio: state?.inicio ? ansiToMoment(state.inicio) : null,
    fim: state?.fim ? ansiToMoment(state.fim) : null,
    perfis: state?.perfis?.filter(Boolean).length ? state.perfis : undefined,
  };

  useEffect(() => {
    return () => {
      dispatch(putAfastamentoReset());
      history.push('#');
    };
  }, []);

  useEffect(() => {
    if (success) {
      onCancel();
    }
  }, [success]);

  const handleSalvar = () => {
    form.validateFields().then((values) => {
      dispatch(putAfastamento(values));
    });
  };

  return (
    <Modal
      visible={visible}
      title="Configurações / Afastamentos"
      numberOfButtons={1}
      onCancel={onCancel}
      okButtonProps={{ loading }}
      onOk={handleSalvar}
    >
      {loading ? (
        <View>
          <Skeleton.Form/>
        </View>
      ) : (
        <Form layout="vertical" form={form} initialValues={initialValues}>
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>

          <Form.Item label="Motivo" name="motivo" rules={[{ required: true, message: 'Informe o motivo!' }]}>
            <Input placeholder="Informe o motivo" />
          </Form.Item>

          <Row gap='25px'>
            <Form.Item label="Início" name="inicio" rules={[{ required: true, message: 'Informe a data início!' }]}>
              <DatePicker format="DD/MM/YYYY HH:mm" placeholder="Informe o início" showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }} />
            </Form.Item>

            <Form.Item label="Fim" name="fim" rules={[{ required: true, message: 'Informe a data fim!' }]}>
              <DatePicker format="DD/MM/YYYY HH:mm" placeholder="Informe o fim" showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }} />
            </Form.Item>
          </Row>

          <Form.Item label="Usuários" name="usuarios">
            <Select form={form} name="usuario" mode="multiple" action={getUsuarios} placeholder="Selecione um usuário" />
          </Form.Item>

          <Form.Item label="Perfis (todos os usuários são considerados)" name="perfis">
            <Select form={form} name="perfil" mode="multiple" action={getPerfis} placeholder="Selecione um perfil" />
          </Form.Item>

          <Form.Item label="Bloqueio" name="bloqueio">
            <Radio.Group>
              <Radio value="TOTAL">Total</Radio>
              <Radio value="CHECKIN">Checkin/Checkout</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item label="Ativo" name="ativo" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Form>)}
    </Modal>
  );
}
