import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setVisibleTicketMenu, setTicketInfo } from '@ducks/chamadosKanban';
import { CardContainer } from './styles';

const CardLayout = ({ cardInfo, setVisibleMenu, children }) => {
  const ticketInfo = useSelector(({ chamadosKanban }) => chamadosKanban.get('ticketInfo'));

  const dispatch = useDispatch();
  const handleOpenTicketInfo = () => {
    
    if(!cardInfo) return;
    dispatch(setVisibleTicketMenu(true));
    dispatch(setTicketInfo({
      ...cardInfo,
      fromKanban: true
    }));
  };

  return (
    <CardContainer $isMenuVisible={ticketInfo.id === cardInfo.id} onClick={() => handleOpenTicketInfo()}>
      {children}
    </CardContainer>
  );
};

export default CardLayout;