/* eslint-disable camelcase */
import { Checkbox, Column, Select } from '@components-teammove';
import React, { useEffect, useState } from 'react';
import { FormItemDynamic } from '../styles';
import { optionsAttachments, optionsConfigurationAttachment } from './rules';

const AnexosPergunta = ({ anexosOpcao, optionIndex = -1, form }) => {
  const [anexoOptionsRequired, setAnexoOptionsRequired] = useState(false);

  useEffect(() => {
    const isEditing = form.getFieldValue('temp_id') || form.getFieldValue('id');

    if (!isEditing && anexosOpcao[optionIndex]) {
      form.setFieldsValue({
        [(optionIndex !== -1)
          ? `permissao_camera_galeria_opcao_${optionIndex}`
          : 'permissao_camera_galeria']: 'I',
      });
    }

    const tipoUpload = form.getFieldValue((optionIndex !== -1)
      ? `tipo_upload_opcao_${optionIndex}`
      : 'tipo_upload');

    const tipoPergunta = form.getFieldValue((optionIndex !== -1)
      ? `tipo_opcao_${optionIndex}`
      : 'tipo');

    const isRequiredOrOptional = tipoUpload === 'OBRIGATORIO' || tipoUpload === 'OPCIONAL' || tipoPergunta === 'ANEXO';
    setAnexoOptionsRequired(isRequiredOrOptional);
  }, [form, optionIndex, anexosOpcao]);

  return (
    <Column>
      <FormItemDynamic rules={[{ required: anexoOptionsRequired, message: 'Selecione uma opção' }]} hasToRender={anexosOpcao[optionIndex]} label="Anexos por:" name={(optionIndex !== -1) ? `opcoes_anexo_opcao_${optionIndex}` : 'opcoes_anexo'}>
        <Checkbox.Group name="opcoes_anexo">
          {optionsAttachments.map(({ value, label }) => (
            <Checkbox key={value} value={value}>{label}</Checkbox>
          ))}
        </Checkbox.Group>
      </FormItemDynamic>
      <FormItemDynamic hasToRender={anexosOpcao[optionIndex]} name={(optionIndex !== -1) ? `permissao_camera_galeria_opcao_${optionIndex}` : 'permissao_camera_galeria'} label="Configuração Câmera/Galeria">
        <Select placeholder='Selecione uma opção' options={optionsConfigurationAttachment} />
      </FormItemDynamic>
    </Column>
  );
};

export default AnexosPergunta;