import styled from 'styled-components';
import { Collapse } from 'antd';
import { darken } from 'polished';

export const OrganogramContainer = styled.div`
  background: ${({ theme }) => theme.darkSecondaryColor};
  cursor: 'grab';
  overflow: 'auto';
  border-radius: 8px;
  padding: 128px;
  height: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  overflow: auto;
  scrollbar-width: thin; /* Firefox */

  /* Hide Scrollbar for WebKit Browsers */
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.darkPrimaryColor};
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.darkHighlightColor};
    border-radius: 8px;
  }

  /* Ensure proper scrolling and sizing */
  & > div {
    display: flex;
    min-width: max-content;
    padding: 0 32px;
  }
`;

export const NodeContainer = styled.div`
  padding: 32px;
  display: flex;
  align-items: center;
  position: relative;

  &:not(:last-child)::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 2px;
    height: 0;
    background-color: ${({ theme }) => theme.darkHighlightColor};
    display: block;
  }
`;

export const TreeNodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TreeNode = styled.div`
  display: flex;
  padding-left: ${({ level }) => level * 32}px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: ${({ level }) => (level - 1) * 32 + 16}px;
    top: 50%;
    width: 16px;
    height: 2px;
    background-color: ${({ theme }) => theme.darkHighlightColor};
    display: ${({ level }) => (level === 0 ? 'none' : 'block')};
  }

  &::after {
    content: '';
    position: absolute;
    left: ${({ level }) => (level - 1) * 32 + 16}px;
    top: -50%;
    width: 2px;
    height: 100%;
    background-color: ${({ theme }) => theme.darkHighlightColor};
    display: ${({ level }) => (level === 0 ? 'none' : 'block')};
  }
`;

export const TreeChildrenContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
`;

export const NodeWrapper = styled.div`
  /* Layout */
  display: flex;
  flex-direction: ${({ isRoot }) => (isRoot ? 'column' : 'row')};
  align-items: center; /* Simplified: both states use center alignment */
  justify-content: ${({ isRoot }) => (isRoot ? 'space-between' : 'center')};
  gap: 4px;
  width: ${({ isRoot }) => (isRoot ? '300px' : '180px')};
  height: ${({ isRoot }) => (isRoot ? 'auto' : '80px')};
  position: relative;

  /* Spacing */
  padding: 12px 16px;

  /* Visuals */
  background-color: ${({ theme, isOver, nodeColor }) => {
  const baseColor = nodeColor || theme.darkPrimaryColor;
  return isOver ? darken(0.2, baseColor) : baseColor;
}};
  color: ${({ theme }) => theme.textColor};
  border: 2px solid
    ${({ theme, nodeColor }) =>
  darken(0.2, nodeColor || theme.darkHighlightColor)};
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  opacity: ${({ isDragging }) => (isDragging ? 0.5 : 1)};

  /* Interaction */
  user-select: none;
  cursor: move; /* Default cursor, overridden in hover for isRoot */

  /* Hover State */
  &:hover {
    background-color: ${({ theme, isRoot, nodeColor }) =>
    isRoot
      ? theme.darkPrimaryColor
      : darken(0.2, nodeColor || theme.darkHighlightColor)};
    cursor: ${({ isRoot }) => (isRoot ? 'auto' : 'move')};
    opacity: ${({ isRoot }) => (isRoot ? 1 : 0.8)};
    transform: ${({ isRoot }) => (isRoot ? 'none' : 'scale(1.01)')};
  }
`;

export const ToggleButton = styled.button`
  padding: 4px;
  border: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);

  color: ${({ theme }) => theme.textColor};

  &:hover {
    background: transparent;
    color: ${({ theme }) => theme.actionColor};
  }
`;

export const ChildrenContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  position: relative;
  margin-left: 16px;
  padding-left: 16px;
  border-left: 2px solid ${({ theme }) => theme.darkHighlightColor};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 2px;
    height: 100%;
    background-color: ${({ theme }) => theme.darkHighlightColor};
    display: none;
  }
`;

export const VerticalConnector = styled.div`
  width: 2px;
  height: 20px;
  background-color: ${({ theme }) => theme.darkHighlightColor};
  margin: 0 auto;
`;

export const HorizontalNodeRow = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 32px;
  margin-top: 20px;

  & > * {
    position: relative;
    flex: 1;

    &::before {
      content: '';
      position: absolute;
      top: -20px;
      left: 50%;
      transform: translateX(-50%);
      width: 2px;
      height: 20px;
      background-color: ${({ theme }) => theme.darkHighlightColor};
    }
  }

  & > *:not(:only-child):before {
    content: '';
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: 20px;
    background-color: ${({ theme }) => theme.darkHighlightColor};
  }

  & > *:first-child:not(:only-child):after {
    content: '';
    position: absolute;
    top: -20px;
    left: calc(50% - 1px);
    width: calc(50% + 17px);
    height: 2px;
    background-color: ${({ theme }) => theme.darkHighlightColor};
  }

  & > *:last-child:not(:only-child):after {
    content: '';
    position: absolute;
    top: -20px;
    right: calc(50% - 1px);
    width: calc(50% + 17px);
    height: 2px;
    background-color: ${({ theme }) => theme.darkHighlightColor};
  }

  & > *:not(:first-child):not(:last-child):after {
    content: '';
    position: absolute;
    top: -20px;
    left: -16px;
    width: calc(100% + 32px);
    height: 2px;
    background-color: ${({ theme }) => theme.darkHighlightColor};
  }
`;

export const NodeTitle = styled.div`
  width: 100%;
  font-weight: 500;
  color: ${({ theme }) => theme.textColor};
  white-space: normal;
  word-break: break-word;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  max-lines: 2;
  text-align: center;
  font-size: ${({ isRoot }) => (isRoot ? '24px' : '14px')};
  margin-bottom: ${({ isRoot }) => (isRoot ? '24px' : '0px')};
`;

export const StyledCollapse = styled(Collapse)`
  width: 100%;
  border-radius: 50px;
  margin-bottom: 0px !important;
  &.ant-collapse {
    background-color: ${({ theme }) => theme.darkPrimaryColor};
    color: ${({ theme }) => theme.textColor};
    border: none !important;
    border-radius: 8px !important;

    * {
      color: ${({ theme }) => theme.textColor};
    }

    .ant-collapse-header {
      border: none !important;
      color: ${({ theme }) => theme.textColor} !important;
      text-align: center;
      font-size: 18px !important;
      margin-bottom: 0px !important;
      background-color: ${({ theme }) => darken(0.1, theme.darkPrimaryColor)};
      border-radius: 8px !important;
    }

    .ant-collapse-arrow {
      right: 12px !important;
    }

    .ant-collapse-item {
      border: none !important;
    }

    .ant-collapse-extra {
      order: 2 !important;
      margin-left: auto !important;
    }

    .ant-collapse-content {
      background-color: ${({ theme }) => theme.darkPrimaryColor};
      border: none !important;
      color: ${({ theme }) => theme.textColor};

      .ant-collapse-content-box {
        padding-top: 12px !important;
        width: 100%;
        max-height: ${({ itemsLen }) => (itemsLen > 30 ? '300px' : 'auto')};
        overflow-y: auto;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;

export const IndependentProfilesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: none;
  color: ${({ theme }) => theme.textColor};
  white-space: normal;
  word-break: break-word;
  text-align: center;
  font-size: 16px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;
