import { get, post } from '../api';

export const getWhatsAppHistory = (userId, contactId, limit) => {
  return get(`/api/message?userId=${userId}&contactId=${contactId}&limit=${limit}`, {}, true);
  // return get('/api/message/history?userId=9441&contactId=529&limit=40', {}, true); 
};

export const sendWhatsAppMessage = (body) => {
  return post('/api/message/save', body, {}, true);
};

export const getWhatsAppTemplate = () => {
  return get('/api/template', {}, true);
};

export const postFinishActivityChat = (body) => {
  return post('/api/chat/finish', body, {}, true);
};

export const postUpdateChatStatus = (body) => {
  return post('/api/chat', body, {}, true);
};